import styled from "styled-components";

export const Container = styled.div`
  @media (max-width: 955px) {
    width: 80% !important;
  }
  max-width: 700px;
  margin-top: auto;
  width: 60%;
  background-color: #fff;
  padding: 25px 25px 25px 25px;
  border-radius: 10px;
  position: relative;

  .wrapper-line {
    background: #944af2;
    @media (max-width: 955px) {
      height: 2px;
      width: 80%;
      margin: auto;
    }

    @media (min-width: 955px) {
      width: 2px;
      margin: 0 20px;
    }
  }
  article {
    display: flex;
    @media (max-width: 955px) {
      flex-direction: column;
    }
    h1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    section {
      @media (min-width: 955px) {
        width: 100%;
      }
      margin: 10px;
    }
  }

 
  .linkPassword {
    color: #944af2 !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 21px;
    text-decoration: underline;
    display: block;
    text-align: right;

    @media (max-width: 955px) {
   
      bottom: 20px;
      margin: 0 auto !important;
    }

  }

  .link {
    color: #944af2 !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    text-decoration: underline;

    @media (max-width: 955px) {
      position: absolute;
      bottom: 20px;
      margin: 0 auto !important;
    }
  }

  .logo {
    position: fixed;
    left: 30px;
    top: 30px;
  }

  .styleBtn {
    background-color: #944af2;
    color: #fff;
    width: 150px;
    text-align: center;
    margin-top: 12%;
    margin-bottom: 5%;
    margin-right: 5%;
  }

  .shadow {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
  }

  .input-style {
     


    @media (max-width: 955px) {
    }
    display: flex;
    position: relative;
  
`;
