import styled from "styled-components";

export const ProductsContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  @media (max-width: 955px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
  }

  margin: 20px 0;
  --test: 10vh;
  @media (min-height: 600px) {
    --test: 11vh;
  }
  @media (min-height: 620px) {
    --test: 13vh;
  }
  @media (min-height: 640px) {
    --test: 14vh;
  }

  ${({ horizontal }) =>
    horizontal &&
    `display: grid;
    grid-template-columns: 1fr 1fr;column-gap: 10px;
    margin:
    margin-bottom: -140px;`}

  @media (max-width: 955px) {
    background: rgba(248, 248, 248, 1);
    margin: 5px 0;
  }

  .rec-item-wrapper {
    height: 100%;
  }

  .react-card-flip {
    @media (max-width: 955px) {
      padding: 0 0 0 15px;
      margin-top: 20px;
    }
  }

  .scrollbars {
    display: flex;
    align-items: center;
  }

  .showing-now {
    outline: 0;
    animation-duration: 1s;
    max-width: 132px;
    min-width: 100px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid var(--corPrimaria); */
    transform: scale(1.1);

    -webkit-transition: all 2s ease-in-out;
    -moz-transition: all 2s ease-in-out;
    -o-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out;

    @media (max-width: 412px) {
      width: 220px;
      height: 194px;
    }

    article {
      background: var(--corPrimaria);
      color: white;
      text-align: center;
      z-index: 2;
      width: 100%;
      font-size: 12px;
      align-self: center;
      padding: 3px 5px;
      @media (max-width: 955px) {
        font-size: 10px;
      }
    }

    // less than everything
    @media (min-width: 460px) and (min-height: 750px) {
      transform: scale(0.9);
    }

    //Iphone 5/SE
    @media (min-device-height: 568px) and (max-device-width: 320px) and (orientation: portrait) {
      transform: scale(0.7);
      margin-bottom: 12px;
    }

    //Moto G4 - Galaxy S5
    @media (min-device-height: 640px) and (min-device-width: 360px) and (orientation: portrait) {
      transform: scale(0.8);
    }

    //Pixel 2
    @media (min-device-height: 731px) and (max-device-width: 411px) and (orientation: portrait) {
      transform: scale(1);
    }

    //Iphone 6/7/8
    @media (min-device-height: 667px) and (max-device-width: 375px) and (orientation: portrait) {
      transform: ${({ vertical }) => vertical && "scale(1)"};
    }

    //Iphone 6/7/8 Plus
    @media (min-device-height: 736px) and (max-device-width: 414px) and (orientation: portrait) {
      transform: scale(1);
    }
  }

  .products-prod {
    outline: 0;
    margin: 10px 10px;
    width: 183px;
    height: 300px;
    border-radius: 10px;
    background-color: white;
    @media (max-width: 955px) {
      height: 300px;
    }

    @media (max-width: 480px) {
      margin: 20px auto;
      height: 268px;

      &:nth-last-child(-n + 1) {
        margin-bottom: 10rem !important;
      }
    }

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    label {
      background: var(--corPrimaria);
      color: white;
      text-align: center;
      z-index: 1;
      margin-top: 3px;
      transform: translateY(-13px);
      font-size: 12px;
      align-self: center;
      border-radius: 4px;
      padding: 3px 5px;
      @media (max-width: 795px) {
        font-size: 10px;
        transform: translateY(-11px);
      }
    }

    .content-description {
      display: block;
      align-items: space-between;
      width: 100%;
      height: 114px;
      padding: 5px 8px 8px 8px;
    }

    .content-price {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;

      width: 100%;

      @media (max-width: 795px) {
        width: 70%;

        h3 {
          font-size: 0.9em;
          margin-bottom: 0px;
          margin-right: 10px;
        }
        h5 {
          font-size: 0.7em;
          margin-bottom: 0px;
          margin-right: 10px;
        }
      }
    }

    .button-add {
      width: 100%;
      height: 30px;
      margin-top: 2px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 795px) {
        padding: 6px 5px 0px 5px;
        border-radius: 15px;
        width: 100%;
        font-size: 1em;
      }
    }

    .button-add-quantity {
      width: 100%;
      height: 30px;
      margin-top: 18px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 795px) {
        padding: 6px 5px 0px 5px;
        border-radius: 15px;
        width: 100%;
        font-size: 1em;
      }
    }
    .product-img {
      width: 100%;
      height: 55%;
      margin-top: -20px;
      object-fit: contain;

      /* max-width: 100%;
      max-height: calc(100% - 2vw); */
      @media (max-width: 955px) {
        width: 100%;
        height: 50%;
        object-fit: scale-down;
      }
      cursor: pointer;
      border-radius: 10px 10px 0 0;
      // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    }

    .product-img-grayscale {
      max-width: 100%;
      max-height: 100%;
      margin-top: 20px;
      cursor: pointer;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      filter: grayscale(1);
    }

    .product-price {
      height: 24px;
      display: flex;
      align-items: baseline;
      justify-content: flex-start;

      h5 {
        text-align: start;
        font-size: 13px;
        font-weight: bold;
        color: black;
        margin-bottom: 0;
        margin-right: 5px;
      }
      h4 {
        text-align: center;
        font-size: 13px;
        color: gray;
        margin-bottom: 0;
      }
    }

    @media (max-width: 425px) {
      .product-price {
        h5 {
          font-size: 12px;
        }
        h4 {
          font-size: 12px;
        }
      }
    }

    @media (max-width: 795px) {
      .product-price {
        transform: translateY(-8px);
        font-size: 13px;
        padding: 3px 5px;
        h5 {
          margin: auto 2px 3px 0;
        }
      }
      max-width: 170px;
      min-height: 160px;

      img {
        min-width: 20px;
        margin-left: 2px;
      }
    }
  }

  .no-products-yet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 300px;
    /* border: 1px solid var(--corPrimaria); */
    border-radius: 10px;
    background: white;
    margin-top: 10px;
    margin-left: 10px;

    @media (max-width: 955px) {
      width: 170px;
      height: 268px;
      /* border: 1px solid var(--corPrimaria); */
      border-radius: 10px;
      background: white;
      margin-top: 20px;
      margin-left: 10px;
    }

    article {
      display: flex;
      position: absolute;
      background: ${({ corPrimaria: c }) => c};
      color: white;
      border-radius: 12px;
      padding: 8px 22px;
      top: 4px;
      transform: translateX(60px);
      label {
        margin: 0 2px 2px 0;
        font-size: 12px;
        line-height: calc(5px + 0.1vw);
        color: white;
      }
    }

    label {
      color: var(--corSecundaria);
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      margin-bottom: 6px;
    }

    span {
      color: grey;
      font-size: 10px;
      text-align: center;
    }
  }
`;

export const ProductsVertical = styled.div`
  position: fixed;
  bottom: 156px;
  color: white;
  font-size: 18px;
  right: 0px;
  z-index: 2;
`;
export const VariationsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  .wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    label {
      background: red;
      color: white;
      width: 102px;
      text-align: center;
      z-index: 1;
      opacity: 0.3;
      font-size: 12px;
      border-radius: 0 0 12px 12px;
    }
    img {
      border-radius: 12px 12px 0 0;
      width: 104px;
      height: 104px;
      cursor: pointer;
      border-top: 2px solid transparent;
      border-right: 2px solid transparent;
      border-left: 2px solid transparent;
    }
    .var-selected {
      border-color: ${({ corPrimaria: c }) => c};
    }
  }
`;
export const SizesMobile = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-bottom: 20px;
  p {
    color: gray;
    font-size: 13px;
  }

  @media (max-width: 1126px) {
    p {
      font-size: 12px;
      margin: 0;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align: center;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
  }
`;
export const CheckOutContainer = styled.div`
  width: 100%;
  background: #f8f8f8;
  margin-top: 20px;
  border-radius: 12px;
  padding: 0 20px 20px 20px;
  @media (max-width: 955px) {
    margin: 0;
    border-radius: 0px;
    box-shadow: none;
    padding: 0;
    background: white;
    section:last-child {
    }
  }

  section:last-child {
    display: flex;
    flex-wrap: wrap;
    background: white;
    border-radius: 12px;
    padding: 20px;
    @media (max-width: 555px) {
      padding: 10px;
      display: grid;
      .col {
        width: 100% !important;
        display: grid;
      }
      button {
        margin: 0 auto;
      }
    }
    .col {
      min-width: 250px;
      p {
        margin: 0;
        color: ${({ corPrimaria: c }) => c};
        font-size: 18px;
        font-weight: 600;
      }
      width: 50%;
      display: grid;
    }
  }
`;
export const Title = styled.label`
  width: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #757575;
  font-family: "Roboto";
  padding: 20px !important;
  @media (max-width: 955px) {
    background: rgba(248, 248, 248, 1);
    padding: 10px 0 5px 0 !important;
    font-size: 14px;
  }
  img {
    margin-right: 8px;
    width: 1.2em;
    height: 1.2em;
  }
`;
export const SubTitle = styled(Title)`
  font-weight: 400;
  font-size: 14px;
  width: 90%;
  margin: 10px 0;
`;

export const ShowingNowGradient = styled.div`
  height: 165px;
  width: 42px;
  transform: translateX(32px);
  left: 10vw;
  z-index: 2;
  background: linear-gradient(
    90deg,
    #f3f3f3 29.41%,
    rgba(255, 255, 255, 0) 89.47%
  );

  @media (max-width: 955px) {
    transform: translateX(20px);
    height: ${({ vertical }) => vertical && 120}px;

    background: linear-gradient(
      90deg,
      #f8f8f8 29.41%,
      rgba(255, 255, 255, 0) 89.47%
    );
  }

  //Iphone 5/SE
  @media (min-device-height: 568px) and (max-device-width: 320px) and (orientation: portrait) {
    height: 90px;
    margin-top: 10px;
    transform: translateX(14px);
  }

  //Moto G4 - Galaxy S5
  @media (min-device-height: 640px) and (max-device-width: 360px) and (orientation: portrait) {
    height: 120px;
    margin-top: ${({ vertical }) => (vertical ? 22 : 45)}px;
  }

  //Iphone 6/7/8
  @media (min-device-height: 667px) and (max-device-width: 375px) and (orientation: portrait) {
    height: ${({ vertical }) => (vertical ? 150 : 130)}px;
    transform: translateX(14px);
  }

  //Pixel 2
  @media (min-device-height: 731px) and (max-device-width: 411px) and (orientation: portrait) {
    height: 122px;
    margin-top: 30px;
  }

  //Iphone 6/7/8 Plus
  @media (min-device-height: 736px) and (max-device-width: 414px) and (orientation: portrait) {
    height: 130px;
    transform: translateX(14px);
    margin-top: 4px;
  }

  //Iphone X
  @media (min-device-height: 812px) and (orientation: portrait) {
    height: ${({ vertical }) => vertical && 150}px;
  }

  //Pixel 2 XL
  @media (min-device-height: 823px) and (max-device-width: 411px) and (orientation: portrait) {
    height: 148px;
  }
`;

export const CardFlipContainer = styled.div`
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 955px) {
    margin-right: 30px;
    transform: scale(1.2);
  }

  // less than everything
  @media (min-width: 460px) and (min-height: 750px) and (max-width: 954px) {
    transform: translateY(-38px);
  }

  //Iphone 5/SE
  @media (min-device-height: 568px) and (max-device-width: 320px) and (orientation: portrait) {
    max-height: 10vh;
    width: 130px;
  }

  //Moto G4 - Galaxy S5
  @media (min-device-height: 640px) and (max-device-width: 360px) and (orientation: portrait) {
    transform: ${({ vertical }) =>
      vertical ? "translateY(-3px)" : "translateY(-14px)"};
    max-height: 14vh;
  }

  //Pixel 2
  @media (min-device-height: 731px) and (max-device-width: 411px) and (orientation: portrait) {
    transform: translateY(-36px);
  }

  //Pixel 2 XL
  @media (min-device-height: 823px) and (max-device-width: 411px) and (orientation: portrait) {
    transform: translateY(-27px);
  }

  //Iphone 6/7/8
  @media (min-device-height: 667px) and (max-device-width: 375px) and (orientation: portrait) {
    transform: translateY(-34px);
    width: 130px;
  }

  //Iphone 6/7/8 Plus
  @media (min-device-height: 736px) and (max-device-width: 414px) and (orientation: portrait) {
    transform: translateY(-28px);
    width: 130px;
  }

  //Iphone X
  @media (min-device-height: 812px) and (orientation: portrait) {
    transform: translateY(-22px);
  }
`;
