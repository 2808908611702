import React, { useEffect, useState } from "react";
import { Modal, Table, Radio, Input, message } from "antd";
import { useTranslation } from "react-i18next";
import { formatCash } from "../../../../utils";
import * as s from "./styled-products-tab";

const XmlCatalog = ({
  open,
  onFinish,
  onCancel,
  unidadeMonetaria,
  products = [],
  catalog,
}) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [renderControl, setRenderControl] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchField, setSearchField] = useState("codigo");

  const [filteredCatalog, setFilteredCatalog] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  useEffect(() => {
    setSelectedRows([]);
    if (!renderControl) {
      setRenderControl(true);
      setTimeout(() => setRenderControl(false), 100);
    }
  }, [open]);

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "SKU's",
      dataIndex: "codigo",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.codigo - b.codigo,
    },
    {
      title: "Produto",
      dataIndex: "thumbnail",
      render: (dataIndex, row) => (
        <img src={dataIndex} style={{ width: "100px" }} />
      ),
    },
    {
      title: "Nome",
      dataIndex: "descricao",
    },

    {
      title: "Preço Original",
      dataIndex: "precoOriginal",
      render: (dataIndex) => (
        <label style={{ whiteSpace: "nowrap" }}>
          {formatCash(dataIndex, false, unidadeMonetaria)}
        </label>
      ),
    },
    {
      title: "Preço",
      dataIndex: "preco",
      render: (dataIndex) => (
        <label style={{ whiteSpace: "nowrap" }}>
          {formatCash(dataIndex, false, unidadeMonetaria)}
        </label>
      ),
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, rowsSelected) => {
      setSelectedRows(rowsSelected);
    },
    getCheckboxProps: (record) => ({
      disabled: products.map((p) => p.codigo).includes(record.codigo),
    }),
    selectedRows,
  };

  const filterCatalogBySearch = (text) => {
    if (text)
      if (searchField === "descricao") {
        setFilteredCatalog(
          catalog.filter((item) =>
            item.descricao.toLowerCase().includes(text.toLowerCase())
          )
        );
      }
    if (searchField === "codigo") {
      setFilteredCatalog(
        catalog.filter((item) =>
          item.codigo.toLowerCase().includes(text.toLowerCase())
        )
      );
    }
  };

  return (
    <>
      <Modal
        visible={open}
        onCancel={() => onCancel()}
        cancelText={t("APP.ComeBack")}
        width={"100%"}
        title={t("PRODUCT-TAB.CatalogXml")}
        okText={t("DRAWER.Add")}
        onOk={() => onFinish(selectedRows)}
      >
        {open && !renderControl && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <s.SearchContainer>
              <label>
                {t("PRODUCTS-TAB-CATALOG.SearchFor")}
                <Radio.Group
                  onChange={({ target: t }) => {
                    setSearchField(t.value);
                  }}
                  value={searchField}
                  disabled={loadingSearch}
                >
                  <Radio value={"codigo"}>SKU</Radio>
                  <Radio value={"descricao"}>{t("MODAL-SPLIT.Name")}</Radio>
                </Radio.Group>
              </label>
              <Input
                // onSearch={(v) => filterCatalogBySearch(v)}
                placeholder={t("APP.Search")}
                style={{ width: "350px", margin: "0 20px 0 0px" }}
                value={searchText}
                onChange={({ target: { value: v } }) => {
                  if (!v || v === "") setFilteredCatalog([]);
                  setSearchText(v);
                  filterCatalogBySearch(v);
                }}
                disabled={loadingSearch}
              />
            </s.SearchContainer>
            <Table
              rowSelection={{
                type: `checkbox`,
                ...rowSelection,
              }}
              style={{ width: "100%" }}
              columns={tableColumns}
              rowKey="id"
              dataSource={
                filteredCatalog.length > 0 ? filteredCatalog : catalog
              }
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default XmlCatalog;
