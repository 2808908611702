const CHANNEL = "aw";
const CURRENCY = "BRL";

const initMasterTag = (advertiserId) => {
  //Cria a master tag, obrigatório
  if (!window.AWIN) {
    var awMastertag = document.createElement("script");
    awMastertag.setAttribute("defer", "defer");
    awMastertag.src = `https://www.dwin2.com/pub.${advertiserId}.js`;
    awMastertag.type = "text/javascript";
    return document.getElementsByTagName("body")[0].appendChild(awMastertag);
  }
};

const confirmConversionPixel = (amountInCents, orderRef) => {
  //Aciona a master tag dizendo que houve uma conversão, obrigatório
  let { AWIN } = window;
  if (AWIN) {
    let totalAmount = (parseFloat(amountInCents) / 100).toFixed(2);
    AWIN.Tracking = {};
    AWIN.Tracking.Sale = {};
    AWIN.Tracking.Sale.amount = parseFloat(totalAmount).toFixed(2);
    AWIN.Tracking.Sale.channel = CHANNEL;
    AWIN.Tracking.Sale.currency = CURRENCY;
    AWIN.Tracking.Sale.orderRef = orderRef;
    AWIN.Tracking.Sale.parts = "DEFAULT:" + parseFloat(totalAmount).toFixed(2);
    AWIN.Tracking.Sale.test = "0";
    return AWIN;
  }
};

const fallBackConversionPixel = (amountInCents, orderRef) => {
  //Método de tracking alternativo, obrigatório
  let totalAmount = (parseFloat(amountInCents) / 100).toFixed(2);
  var awPixel = new Image(0, 0);
  awPixel.src =
    "https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=1001&amount=" +
    parseFloat(totalAmount).toFixed(2) +
    "&ch=" +
    CHANNEL +
    "&parts=DEFAULT:" +
    parseFloat(totalAmount).toFixed(2) +
    "&ref=" +
    orderRef +
    "&vc=" +
    "" + //Não tem voucher, deixar vazio
    "&cr=" +
    CURRENCY +
    "&testmode=0";
  return document.getElementById("root").appendChild(awPixel);
};

export const awinServices = {
  initMasterTag,
  confirmConversionPixel,
  fallBackConversionPixel,
};
