import React, { createContext } from "react";
import useUser from "./hooks/use-user";

const UserContext = createContext({
  user: {},
  setUser: () => {},
  openedFrom: "",
  setOpenedFrom: () => {},
});

const UserProvider = ({ children }) => {
  const { user, setUser, openedFrom, setOpenedFrom } = useUser();
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        openedFrom,
        setOpenedFrom,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
