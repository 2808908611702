import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  section {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    width: 300px;
  }
`;

export const imgProdStyle = {
  width: "140px",
  height: "120px",
  background: "white",
  borderRadius: "2px",
};
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px;
`;

export const SpaceBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const Dots = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  div,
  span {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    margin: 0 3px;
  }
  div {
    background-color: white;
  }
  span {
    background-color: #d9d9d9;
  }
`;

export const Card = styled.div`
  width: 100px;
  height: 80px;
  display: flex;
  border-radius: 12px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 12px;
  }
  section {
    width: 22px;
    height: 22px;
    color: white;
    background: ${({ corSecundaria: c }) => c};
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      width: 11px;
    }
    border-radius: 7px;
    bottom: 13px;
    right: -20px;
  }
`;
export const SocialMediaWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
//---------------------------------------------------------------
//                         Realtime Tab Styles
//---------------------------------------------------------------
export const FakePLayer = styled.div`
  width: 500px;
  height: 300px;
  position: relative;
  display: flex;
  border-top: 1px solid #f0f0f0;
  background: #f4f4f4;
`;
//---------------------------------------------------------------
//                         Room Tab Styles
//---------------------------------------------------------------
export const WarningCard = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 14px;
    color: black;
    font-weight: bold;
  }

  legend {
    font-size: 12px;
    color: grey;
  }
`;
export const ModalNewUserSplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  * {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
export const InvalidLabel = styled.span`
  color: red;
  margin-bottom: 5px;
`;
