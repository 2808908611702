import styled from "styled-components";

export const OrdersContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 10px ${({ vertical: v }) => (v ? `0px 20px 0px` : `20px 20px 20px`)};

  :first-child {
    padding: 0 0 20px 0;
  }

  @media (max-width: 955px) {
    background: rgba(248, 248, 248, 1);
  }
`;
export const OrdersVertical = styled.div`
  position: fixed;
  bottom: 78px;
  color: white;
  font-size: 18px;
  right: 0px;
  z-index: 3;

  .ant-drawer-content {
    border-radius: 16px 16px 0px 0px!;
  }
`;
export const Order = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  background: white;

  .content-container {
    width: 100%;
    padding-bottom: 15px;

    h3 {
      display: flex;
      justify-content: space-between;
    }
  }

  .order-date {
    display: flex;
    align-items: center;

    .date-dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 10px;
    }

    p {
      color: grey;
    }
  }

  .order-products {
    display: flex;
    flex-direction: column;
  }

  .order-product {
    margin: 2px 0px 10px 10px;
    padding-bottom: 10px;

    b {
      color: var(--corPrimaria);
    }
  }

  h3,
  p {
    margin: 0;
  }
  @media (max-width: 955px) {
    background: rgba(248, 248, 248, 1);
    padding: 10px 10px 0 10px;
    margin-bottom: 10px;
    border-radius: 0;
    box-shadow: none;
    border-top: 1px solid #ccc;
    :first-child {
      border: none;
      padding-top: 0;
    }
    h3 {
       font-size: 15px;
    }
    p {
        font-size: 12px;
    }
  }
`;
export const Title = styled.div`
  width: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #757575;
  font-family: "Roboto";
  padding: 20px !important;
  @media (max-width: 955px) {
    background: rgba(248, 248, 248, 1);
    padding: 10px 0 5px 0 !important;
    font-size: 14px;
  }
  img {
    margin-right: 8px;
    width: 1.2em;
    height: 1.2em;
  }
`;
export const OrderActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 955px) {
    position: relative;
    button {
      position: absolute;
      top: 0px;
      right: 5px;
    }
    h3 {
      text-align: right;
      min-width: 80vw;
      font-size: 12px;
      position: absolute;
      right: 10px;
      bottom: 0px;
    }
  }
`;
export const EmptyOrder = styled.span`
  max-width: 300px;
  text-align: center;
  margin-top: 40px;
  color: gray;
`;
