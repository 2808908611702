import React, { useContext, useEffect, useState } from "react";
import { Checkbox, InputNumber, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars";
import { DeleteOutlined } from "@ant-design/icons";
import * as s from "./styled-products-tab";
import { formatCash } from "../../../../utils";
import { PaymentContext } from "../../../../context";

const { Option } = Select;
const ModalProductSplit = ({
  visible,
  onOk = () => {},
  onCancel,
  splitGeral = [],
  unidadeMonetaria,
}) => {
  const { t } = useTranslation();
  const { services, pagarmeFees } = useContext(PaymentContext);
  const { pagarme = [] } = services;
  const [split, setSplit] = useState(visible.splitRules || []);
  const [useSplitGeral, setUseSplitGeral] = useState(
    visible.useSplitGeral === null
      ? false
      : typeof visible.useSplitGeral === "boolean"
      ? visible.useSplitGeral
      : true
  ); //se usa o split geral ou usa split próprio

  useEffect(() => {
    if (useSplitGeral) setSplit(splitGeral);
    else if (visible.splitRules) setSplit(visible.splitRules);
  }, [useSplitGeral]);

  const renderReceiver = (receiver) => {
    const { legalName: name, percentage = 0 } = receiver;
    let disabled = useSplitGeral ? true : receiver.disabled;
    return (
      <s.ReceiverContainer key={name}>
        {name}
        <div className="cash">
          ~
          {formatCash(
            (percentage / 100) * visible.preco,
            false,
            unidadeMonetaria
          )}
        </div>
        <InputNumber
          type="number"
          min={0}
          max={100}
          defaultValue={percentage}
          style={{ width: "75px" }}
          maxLength={5}
          disabled={disabled}
          parser={(value) => value.replace(",", ".")}
          value={percentage}
          // onChange={(v) => {
          //   setSplit(
          //     split.map((s) => ({
          //       ...s,
          //       percentage: s.legalName === name ? parseFloat(v) : s.percentage,
          //     }))
          //   );
          // }}
          onChange={(v) => {
            setSplit(
              split.map((s) => ({
                ...s,
                percentage:
                  s.legalName === name
                    ? !isNaN(parseFloat(v))
                      ? parseFloat(v) > 100
                        ? 100
                        : parseFloat(v)
                      : 0
                    : s.percentage,
              }))
            );
          }}
        />
        <span style={{ color: "gray", marginLeft: 5 }}>%</span>
        <DeleteOutlined
          style={{ opacity: disabled ? 0 : 1, marginLeft: "10px" }}
          onClick={() =>
            !disabled && setSplit(split.filter((p) => p.legalName !== name))
          }
        />
      </s.ReceiverContainer>
    );
  };

  var porcent = [...split]
    .map((s) => parseFloat(s.percentage))
    .reduce((a, b) => a + b, 0);

  var comissionPercent = pagarmeFees?.comissao?.porcentagem
    ? pagarmeFees.comissao.porcentagem
    : false;
  var pixFee = pagarmeFees?.pix?.length > 0 ? pagarmeFees.pix[0].valor : false;
  var creditCardFixedFeeAntifraud =
    pagarmeFees?.cartao?.fixa.length > 0
      ? pagarmeFees.cartao.fixa[0].valor
      : false;
  var creditCardFixedFeeGateway =
    pagarmeFees?.cartao?.fixa?.length > 0
      ? pagarmeFees.cartao.fixa[1].valor
      : false;
  var creditCardMDR =
    pagarmeFees?.cartao?.mdr?.length > 0 ? pagarmeFees.cartao.mdr : false;

  return (
    <Modal
      title={`Split ${visible.descricao}`}
      visible={visible}
      onOk={() => onOk(split, useSplitGeral)}
      onCancel={onCancel}
      okText={t("PRODUCTS-TAB-SPLIT-MODAL.SaveSplit")}
      cancelText={t("PRODUCTS-TAB-SPLIT-MODAL.Back")}
      okButtonProps={{
        disabled: porcent !== 100,
      }}
      bodyStyle={{ padding: 0 }}
    >
      <div style={{ margin: "20px" }}>
        <label>{t("PRODUCTS-TAB-SPLIT-MODAL.SplitDescription")}</label>
        <br />
        <label>{t("PRODUCTS-TAB-GENERAL-SPLIT-MODAL.Fee")}:</label>
        {comissionPercent && (
          <p style={{ fontSize: "11px", margin: 0 }}>
            •{" "}
            {`${t(
              "PRODUCTS-TAB-GENERAL-SPLIT-MODAL.Commission"
            )}: ${comissionPercent}%`}
          </p>
        )}
        {pixFee && (
          <p style={{ fontSize: "11px", margin: 0 }}>
            • {`Pix: ${formatCash(pixFee / 100, false, "R$")}`}
          </p>
        )}
        <p style={{ fontSize: "11px", margin: 0 }}>
          {`• ${t("CART.CreditCard")}: `}
          {creditCardFixedFeeAntifraud &&
            `${t("PRODUCTS-TAB-GENERAL-SPLIT-MODAL.Antifraud")} (${formatCash(
              creditCardFixedFeeAntifraud / 100,
              false,
              "R$"
            )}), `}
          {creditCardFixedFeeGateway &&
            `Gateway (${formatCash(
              creditCardFixedFeeGateway / 100,
              false,
              "R$"
            )}), `}
          {creditCardMDR &&
            `MDR: ${creditCardMDR.map(
              (fee) =>
                ` ${fee.parcelaInferior}-${fee.parcelaSuperior} (${fee.porcentagem}%)`
            )}`}
        </p>
        <div
          style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
        >
          <label>{t("PRODUCTS-TAB-SPLIT-MODAL.Receivers")}: </label>
          <Select
            placeholder={t("PRODUCTS-TAB-SPLIT-MODAL.AddReceiver")}
            style={{ width: "170px", marginLeft: "10px" }}
            showSearch
            disabled={useSplitGeral}
            onChange={(v) =>
              setSplit([
                ...split,
                {
                  ...pagarme.filter((r) => r.legalName === v)[0],
                  percentage: 1,
                },
              ])
            }
          >
            {pagarme
              .filter(
                (r) => !split.map((s) => s.legalName).includes(r.legalName)
              )
              .map((r) => (
                <Option value={r.legalName} key={r.legalName}>
                  {r.legalName}
                </Option>
              ))}
          </Select>
          <Checkbox
            style={{ margin: "0 10px 0 auto" }}
            checked={useSplitGeral}
            onChange={() => setUseSplitGeral(!useSplitGeral)}
          />
          {t("PRODUCTS-TAB-SPLIT-MODAL.UseGeneralSplit")}
        </div>
      </div>
      <Scrollbars
        style={{
          width: "100%",
          height: "300px",
        }}
      >
        <div style={{ margin: "0 20px" }}>
          {split.map((s) => renderReceiver(s))}
          {renderReceiver({
            legalName: "Total",
            percentage: porcent,
            disabled: true,
          })}
        </div>
      </Scrollbars>
    </Modal>
  );
};

export default ModalProductSplit;
