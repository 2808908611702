import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as s from "../styles";
import { Card, Button, notification } from "antd";
import { HeartOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { managerService } from "../../../../services";
import "firebase/database";

const ReportsTab = ({ live }) => {
  const { t } = useTranslation();
  //Control
  const [loadingCart, setLoadingCart] = useState(false); //buscando relatório dos carrinhos
  const [loadingOrder, setLoadingOrder] = useState(false); //buscando relatório dos pedidos

  const downloadData = (data, filename, format) => {
    var downloadLink = document.createElement("a");
    if (format === "excel") {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `${filename}.xlsx`;
    } else {
      var blob = new Blob(["\ufeff", data]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `${filename}.${format}`;
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const feedback = (success, key, description) => {
    if (success)
      notification.success({
        key,
        message: t("REPORTS-TAB.DownloadSuccess"),
        onClick: () => notification.close(key),
        duration: 10,
      });
    else
      notification.error({
        key,
        message: "Ops!",
        description:
          description && typeof description === "string"
            ? description
            : t("REPORTS-TAB.DownloadError"),
        onClick: () => notification.close(key),
        duration: 10,
      });
  };

  const downloadCartReport = (format) => {
    setLoadingCart(format);
    managerService
      .cartReport(live.docId, format)
      .then((data) => {
        downloadData(
          data,
          `${t("REPORTS-TAB.LikedProducts")} - ${live.nome}`,
          format
        );
        setLoadingCart(false);
        feedback(true, "cart-report");
      })
      .catch((e) => {
        setLoadingCart(false);
        feedback(false, "cart-report", e);
      });
  };

  const downloadOrderReport = (format) => {
    setLoadingOrder(format);
    managerService
      .orderReport(live.docId, format)
      .then((data) => {
        downloadData(data, `${t("REPORTS-TAB.Orders")} - ${live.nome}`, format);
        setLoadingOrder(false);
        feedback(true, "order-report");
      })
      .catch((e) => {
        setLoadingOrder(false);
        feedback(false, "order-report", e);
      });
  };

  return (
    <s.Container className="fade-in">
      <Card
        title={
          <>
            <HeartOutlined style={{ marginRight: "10px" }} />
            {t("REPORTS-TAB.LikedProducts")}
          </>
        }
        style={{ width: "600px", margin: "5px" }}
        className="shadow"
      >
        <p>{t("REPORTS-TAB.LikedProductsReportDescription")}</p>
        <Button
          type="primary"
          onClick={() => downloadCartReport("csv")}
          style={{ margin: "20px 20px 0 0" }}
          loading={loadingCart === "csv"}
          disabled={loadingCart === "csv"}
        >
          {t("REPORTS-TAB.CSVReport")}
        </Button>
        <Button
          type="primary"
          onClick={() => downloadCartReport("excel")}
          style={{ margin: "20px 20px 0 0" }}
          loading={loadingCart === "excel"}
          disabled={loadingCart === "excel"}
        >
          {t("REPORTS-TAB.EXCELReport")}
        </Button>
      </Card>
      <Card
        title={
          <>
            <ShoppingCartOutlined style={{ marginRight: "10px" }} />
            {t("REPORTS-TAB.CompletedOrders")}
          </>
        }
        style={{ width: "600px", margin: "5px" }}
        className="shadow"
      >
        <p>{t("REPORTS-TAB.CompleteOrdersReportDescription")}</p>
        <Button
          type="primary"
          onClick={() => downloadOrderReport("csv")}
          style={{ margin: "20px 20px 0 0" }}
          loading={loadingOrder === "csv"}
          disabled={loadingOrder === "csv"}
        >
          {t("REPORTS-TAB.CSVReport")}
        </Button>
        <Button
          type="primary"
          onClick={() => downloadOrderReport("excel")}
          style={{ margin: "20px 20px 0 0" }}
          loading={loadingOrder === "excel"}
          disabled={loadingOrder === "excel"}
        >
          {t("REPORTS-TAB.EXCELReport")}
        </Button>
      </Card>
    </s.Container>
  );
};

export default ReportsTab;
