import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import * as s from "./styles";
import { Card, Button, Input, notification, Switch, message } from "antd";
import {
  ShareAltOutlined,
  CloudUploadOutlined,
  PoweroffOutlined,
  LoadingOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { managerService, firestore } from "../../../services";
import { UserContext } from "../../../context";

import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  RedditShareButton,
} from "react-share";
import {
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const { Search } = Input;
const PublishTab = ({ live, aoVivo, setLive }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [products, setProducts] = useState([]);
  const [link, setLink] = useState(""); //link amigável para live
  const [active, setActive] = useState(false); //status da live
  const [permission, setPermission] = useState(false); //permissão para salvar o link válido ou não
  //Control
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);
  const [url, setUrl] = useState(
    window.location.origin + "/live/" + user.nameParamLive + "/"
  );

  useEffect(() => {
    if (live.friendlyUrl) {
      let tmp = live.friendlyUrl.split("/");
      if (tmp[1]) {
        setLink(tmp[1]);
      } else {
        setUrl((oldUrl) => oldUrl.replace(user.nameParamLive + "/", ""));
        setLink(tmp[0]);
      }
    } else setLink(live.friendlyUrl);

    setActive(live.friendlyUrl ? true : false);
  }, [aoVivo, live]);

  useEffect(() => {
    // pegando os produtos realtime
    if (live && !live.tabelaPreco) {
      let subscribe = firestore
        .collection(`lives/${live.docId}/produtos`)
        .onSnapshot((snapshot) => {
          let mainProducts = snapshot.docs
            .map(
              (doc) =>
                doc.data().principal && {
                  ...doc.data(),
                  docId: doc.id,
                }
            )
            .filter((p) => p)
            .sort((a, b) => a.id - b.id);
          setProducts(mainProducts);
        });
      return () => subscribe();
    }
  }, []);

  const searchUrl = () => {
    setSearching(true);
    managerService
      .testUrl(link)
      .then(({ friendlyUrl }) => {
        setLink(friendlyUrl);
        setSearching(false);
        notification.success({
          key: "aviso",
          message: t("PUBLISH-TAB.LinkAvailable"),
          description: t("PUBLISH-TAB.LinkAvailableDescription"),
          onClick: () => notification.close("aviso"),
          duration: 10,
        });
        setPermission(true);
      })
      .catch(() => {
        setSearching(false);
        notification.error({
          key: "aviso",
          message: t("PUBLISH-TAB.LinkUnavailable"),
          description: t("PUBLISH-TAB.LinkUnavailableDescription"),
          onClick: () => notification.close("aviso"),
          duration: 10,
        });
      });
  };

  const validateLiveProducts = () => {
    let isValid = true;
    products.map((product, i) => {
      let percentageTotal = 0;
      for (let i = 0; i < product.splitRules.length; i++) {
        percentageTotal += product.splitRules[i].percentage;
      }
      if (parseInt(percentageTotal) !== 100) isValid = false;
    });
    return isValid;
  };

  const handlePublishLive = () => {
    if (live?.paymentService === "pagarme" && products.length > 0) {
      if (validateLiveProducts()) finish();
      else {
        message.error({
          content: t("PUBLISH-TAB.ProductWithoutSplitRules"),
          duration: 8,
          key: "productWithoutSplitRules",
        });
      }
    } else finish();
  };

  const finish = () => {
    setLoading(true);
    managerService
      .saveUrl(live.docId, link)
      .then(() => {
        setLoading(false);
        notification.success({
          key: "aviso-finish",
          message: t("PUBLISH-TAB.LivePublished"),
          description: t("PUBLISH-TAB.LivePublishedDescription"),
          onClick: () => notification.close("aviso-finish"),
          duration: 10,
        });
        setLive({
          ...live,
          friendlyUrl: `${user.nameParamLive}/${link}`,
          active: true,
        });
        setActive(true);
      })
      .catch((e) => {
        setLoading(false);
        notification.error({
          key: "aviso-finish",
          message: "Ops",
          description:
            e.response.data.message ||
            t("PUBLISH-TAB.SavingLinkErrorDescription"),
          onClick: () => notification.close("aviso-finish"),
          duration: 10,
        });
      });
  };

  const turnOff = () => {
    setDisconnecting(true);
    managerService
      .deactivateLive(live.docId)
      .then(() => {
        setDisconnecting(false);
        setActive(false);
        setPermission(false);
        setLive({ ...live, active: false, friendlyUrl: null });
        notification.success({
          key: "aviso-status",
          message: t("PUBLISH-TAB.LiveDeactivated"),
          onClick: () => notification.close("aviso-status"),
          duration: 10,
        });
        setLink("");
      })
      .catch(() => {
        setDisconnecting(false);
        notification.error({
          key: "aviso-status",
          message: t("PUBLISH-TAB.GeneralError"),
          description: t("PUBLISH-TAB.DeactivateLiveErrorDescription"),
          onClick: () => notification.close("aviso-status"),
          duration: 10,
        });
      });
  };

  return (
    <>
      <div style={{ textAlign: "center", color: "gray" }}>
        {t("PUBLISH-TAB.FulfillInformationAbove")}{" "}
        {live?.docId ? t("PUBLISH-TAB.Edit") : t("PUBLISH-TAB.Create")}{" "}
        {t("PUBLISH-TAB.YourLive")}
        <br />
        {t("PUBLISH-TAB.Fields")} <b> * </b> {t("PUBLISH-TAB.FieldsRequired")}
      </div>
      <s.Container className="fade-in">
        <Card
          title={
            <>
              <ShareAltOutlined style={{ marginRight: "10px" }} />
              {t("PUBLISH-TAB.ChooseLink")}
            </>
          }
          style={{ width: "600px", margin: "5px" }}
          className="shadow"
        >
          <strong>{t("PUBLISH-TAB.FriendlyUrl")}</strong>
          <p>{t("PUBLISH-TAB.FriendlyUrlDescription")}</p>
          {loading ? (
            <>
              <LoadingOutlined style={{ marginRight: "10px" }} />
              {t("PUBLISH-TAB.PublishingLive")}
              <br />
            </>
          ) : (
            <Search
              addonBefore={url}
              placeholder={t("PUBLISH-TAB.MyLive")}
              value={link}
              onChange={(e) => {
                setPermission(false);
                setLink(e.target.value);
              }}
              loading={searching}
              onSearch={searchUrl}
            />
          )}
          <Button
            type="primary"
            onClick={() => {
              navigator.clipboard
                .writeText(url + link)
                .then(() => message.success(t("PUBLISH-TAB.LinkCopied")));
            }}
            disabled={!active}
            style={{ marginTop: "20px" }}
          >
            <CopyOutlined style={{ marginRight: "10px" }} />
            <label id="url-live">
              {url}
              {link ? link : t("PUBLISH-TAB.MyLive")}
            </label>
          </Button>
        </Card>
        <Card
          title={
            <>
              <PoweroffOutlined style={{ marginRight: "10px" }} />
              Live{" "}
              {active
                ? t("PUBLISH-TAB.Activated")
                : t("PUBLISH-TAB.Deactivated")}
            </>
          }
          style={{ width: "600px", margin: "5px" }}
          className="shadow"
        >
          <p>{t("PUBLISH-TAB.PublishDescription")}</p>
          {disconnecting ? (
            <>
              <LoadingOutlined style={{ marginRight: "10px" }} />
              {t("PUBLISH-TAB.DeactivatingLive")}
            </>
          ) : (
            <Switch
              checked={active}
              onChange={turnOff}
              style={{ width: 30 }}
              disabled={!active || loading}
            />
          )}
        </Card>
        <Card
          title={t("PUBLISH-TAB.ShareLink")}
          style={{ width: "600px", margin: "5px" }}
          className="shadow"
        >
          <p>{t("PUBLISH-TAB.ShareLinkDescription")}</p>
          <s.SocialMediaWrapper>
            <FacebookShareButton
              disabled={!active}
              style={{ marginRight: 10 }}
              url={url + link}
            >
              <FacebookIcon />
            </FacebookShareButton>

            <WhatsappShareButton
              disabled={!active}
              style={{ marginRight: 10 }}
              url={url + link}
            >
              <WhatsappIcon />
            </WhatsappShareButton>

            <LinkedinShareButton
              disabled={!active}
              style={{ marginRight: 10 }}
              url={url + link}
            >
              <LinkedinIcon />
            </LinkedinShareButton>

            <TwitterShareButton
              disabled={!active}
              style={{ marginRight: 10 }}
              url={url + link}
            >
              <TwitterIcon />
            </TwitterShareButton>

            <TelegramShareButton
              disabled={!active}
              style={{ marginRight: 10 }}
              url={url + link}
            >
              <TelegramIcon />
            </TelegramShareButton>

            <RedditShareButton
              disabled={!active}
              style={{ marginRight: 10 }}
              url={url + link}
            >
              <RedditIcon />
            </RedditShareButton>
          </s.SocialMediaWrapper>
          {!active && <b>{t("PUBLISH-TAB.ShareLinkDescriptionTwo")}</b>}
        </Card>
        <s.ButtonContainer>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handlePublishLive}
            disabled={loading || !permission}
            loading={loading}
          >
            {loading ? t("PUBLISH-TAB.Publishing") : t("PUBLISH-TAB.Publish")}
            {!loading && <CloudUploadOutlined />}
          </Button>
        </s.ButtonContainer>
      </s.Container>
    </>
  );
};

export default PublishTab;
