import React, { useState, useContext, useEffect } from "react";
import {
  Input,
  Radio,
  Tag,
  Modal,
  Button,
  Select,
  Switch,
  Checkbox,
  message,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars";
import {
  PlusOutlined,
  PlusSquareOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import ModalNovaGrade from "./modal-grade";
import { GradesContext, UserContext } from "../../../../context";
import * as s from "./styled-products-tab";
import {
  handleChangeTime,
  preventInvalidNumberOnPaste,
  preventInvalidNumberOnKeyDown,
} from "../../../../utils";

const { Search } = Input;
const { Option } = Select;
const ModalProduct = ({
  open,
  onClose,
  onFinish,
  prod,
  disabled,
  liveWithCorreios,
  liveWithAssinatura,
  alreadyHasAssinatura,
  liveWithTabelaPreco,
  catalog,
  liveWithVtex,
  liveWithMagento,
  liveWithNeomode,
  videoDuration,
  aoVivo,
  realtime,
  externalCheckout,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext); //dados do usuário
  const { userGrades } = useContext(GradesContext); //grades do usuário
  const [descricao, setDescricao] = useState((prod && prod.descricao) || ""); //descrição do produto
  const [controlStock, setControlStock] = useState(prod?.controlStock || false);
  const [stock, setStock] = useState(prod?.stock?.length > 0 ? prod.stock : []);
  const [backupMeasures, setBackupMeasures] = useState([]);
  const [whenShow, setWhenShow] = useState(prod?.showOptions?.when || "ever"); // como o produto vai aparecer
  const [whenTime, setWhenTime] = useState(prod?.showOptions?.someTime || ""); // tempo X que o produto vai aparecer
  const [produtoAssinatura, setProdutoAssinatura] = useState(
    prod?.produtoAssinatura ? true : false
  );
  const [tipoGrade, setTipoGrade] = useState(
    (prod && prod.tipoGrade) || "aberta"
  ); //tipo da grade
  const [searchText, setSearchText] = useState("");
  const [tamanhos, setTamanhos] = useState((prod && prod.tamanhos) || []); //tamanhos da grade aberta
  const [tamanhosGradeFechada, setTamanhosGradeFechada] = useState([]); //tamanhos da grade fechada quando for controlar estoque
  const [stockGradeFechada, setStockGradeFechada] = useState([]); //stock da grade fechada quando for controlar estoque
  const [grades, setGrades] = useState((prod && prod.grades) || []); //grades do produto quando for do tipo grade fechada
  const [available, setAvailable] = useState(
    prod && typeof prod.available === "boolean" ? prod.available : true
  );
  const [quantidadeMaximaVendaProduto, setQuantidadeMaximaVendaProduto] =
    useState(prod?.quantidadeMaximaVendaProduto || null);
  const [medidas, setMedidas] = useState(
    prod?.medidas || {
      peso: "",
      largura: "",
      altura: "",
      comprimento: "",
    }
  );
  const [redirectLink, setRedirectLink] = useState(
    (prod && prod.redirectLink) || ""
  ); //link do produto
  //nova grade control
  const [modalNovaGrade, setModalNovaGrade] = useState(false);
  const [invalidValues, setInvalidValues] = useState([]);

  const liveCatalogVtex = catalog === "vtex";
  const liveCatalogMagento = catalog === "magento";
  const liveCatalogNeomode = catalog === "neomode";
  useEffect(() => {
    if (prod?.tipoGrade === "fechada") handleGridMeasures(grades, true);
  }, []);

  useEffect(() => {
    if (tamanhos.length === 0 && controlStock) setControlStock(false);
  }, [tamanhos]);

  const validateMeasurements = () => {
    const { peso, largura, altura, comprimento } = medidas;
    const errors = [];

    if (!peso || peso < 100 || peso > 30000) {
      errors.push("peso");
    }
    if (!largura || largura < 8 || largura > 100) {
      errors.push("largura");
    }
    if (!altura || altura < 1 || altura > 100) {
      errors.push("altura");
    }
    if (!comprimento || comprimento < 13 || comprimento > 100) {
      errors.push("comprimento");
    }

    setInvalidValues(errors);
  };

  const handleChangeMedidas = ({ target: { value } }, field) =>
    setMedidas({ ...medidas, [field]: parseFloat(value) });

  const handleGridMeasures = (ids, recalculate) => {
    let tmpStock = [...stock];
    let tmpMeasures = recalculate ? [...tamanhos] : [];
    let tmpBackupMeasures = [];
    let recalculateMeasures = [];

    if (recalculate) {
      for (let i = 0; i < ids.length; i++) {
        let gradeById = userGrades.find((x) => x.gradeId === ids[i]);
        for (let i = 0; i < gradeById.grade.length; i++) {
          if (!tamanhos.includes(gradeById.grade[i].tamanho)) {
            tmpMeasures.push(gradeById.grade[i].tamanho);
          } else if (
            tamanhos.length === 0 &&
            !tmpBackupMeasures.includes(gradeById.grade[i].tamanho)
          )
            tmpBackupMeasures.push(gradeById.grade[i].tamanho);

          let finalMeasures = [...recalculateMeasures, ...tmpMeasures];
          for (let i = 0; i < finalMeasures.length; i++) {
            if (!tmpStock[i]) tmpStock[i] = 1;
          }
          recalculateMeasures = finalMeasures;
          tmpMeasures = [];
          finalMeasures = [];
        }
        setTamanhos(recalculateMeasures.sort());
        setStock(tmpStock);
        setBackupMeasures(tmpBackupMeasures);
      }
    } else {
      let gradeById = userGrades.find((x) => x.gradeId === ids[ids.length - 1]);
      let newMeasuresIndexes = [];
      let oldStockIndexes = [];

      for (let i = 0; i < gradeById.grade.length; i++) {
        if (!tamanhos.includes(gradeById.grade[i].tamanho))
          tmpMeasures.push(gradeById.grade[i].tamanho);
        else tmpBackupMeasures.push(gradeById.grade[i].tamanho);
      }

      let newTamanhos = [...tamanhos, ...tmpMeasures];
      let newBackup = [...backupMeasures, ...tmpBackupMeasures];
      for (let i = 0; i < newTamanhos.length; i++) tmpStock[i] = 1;

      newTamanhos = newTamanhos.sort();
      if (prod?.stock?.length > 0) {
        for (let i = 0; i < tamanhos.length; i++) {
          newMeasuresIndexes.push(newTamanhos.indexOf(tamanhos[i]));
          oldStockIndexes[newTamanhos.indexOf(tamanhos[i])] = stock[i];
        }
      }

      for (
        let i = Math.min(...newMeasuresIndexes);
        i < newTamanhos.length;
        i++
      ) {
        tmpStock[i] = oldStockIndexes[i];
      }

      setTamanhos(newTamanhos);
      setStock(tmpStock);
      setBackupMeasures(newBackup);
    }
  };

  const handleRemoveItem = (ids, copyGrades) => {
    if (ids.length > 0) {
      let tmpGrades = [...copyGrades];
      let tmpMeasures = [...tamanhos];
      let tmpStock = [...stock];
      let tmpBackupStock = [...backupMeasures];
      let removeID = "";
      let removeMeasures = [];
      let removeBackupMeasures = [];

      for (let i = 0; i < copyGrades.length; i++) {
        if (!ids.includes(tmpGrades[i])) removeID = tmpGrades[i];
      }

      let gradeById = userGrades.find((x) => x.gradeId === removeID);
      for (let i = 0; i < gradeById.grade.length; i++) {
        if (!backupMeasures.includes(gradeById.grade[i].tamanho))
          removeMeasures.push(gradeById.grade[i].tamanho);
        else removeBackupMeasures.push(gradeById.grade[i].tamanho);
      }

      for (let i = 0; i < removeMeasures.length; i++) {
        let currentId = tmpMeasures.indexOf(removeMeasures[i]);
        tmpMeasures.splice(currentId, 1);
        tmpStock.splice(currentId, 1);
      }

      for (let i = 0; i < removeBackupMeasures.length; i++)
        tmpBackupStock.splice(tmpBackupStock.indexOf(removeBackupMeasures[i]));

      setTamanhos(tmpMeasures);
      setStock(tmpStock);
      setBackupMeasures(tmpBackupStock);
    } else {
      setTamanhos([]);
      setStock([]);
    }
  };

  const checkInvalidValues = (type) => {
    if (type === "descricao" && !descricao)
      setInvalidValues([...invalidValues, type]);
    else if (!externalCheckout && type === "tamanhos" && tamanhos?.length === 0)
      setInvalidValues([...invalidValues, type]);
    else if (type === "redirectLink" && redirectLink?.length === 0)
      setInvalidValues([...invalidValues, type]);
    else if (type === "estoque" && stock?.length === 0)
      setInvalidValues([...invalidValues, type]);
    else if (
      type === "medidas" &&
      (!medidas.peso ||
        !medidas.largura ||
        !medidas.altura ||
        !medidas.comprimento)
    )
      setInvalidValues([...invalidValues, type]);
  };
  return (
    <Modal
      title={t("PRODUCTS-TAB-PRODUCT-MODAL.MainProduct")}
      visible={open}
      onOk={() => {
        let product = {
          descricao,
          tipoGrade,
          controlStock,
          available,
          quantidadeMaximaVendaProduto,
          redirectLink,
        };
        if (liveWithAssinatura) product.produtoAssinatura = produtoAssinatura;
        if (liveWithCorreios) product.medidas = medidas;
        if (tipoGrade === "aberta") product.tamanhos = tamanhos;
        else if (tipoGrade === "fechada" && controlStock) {
          product.tamanhos = tamanhos;
          product.grades = grades;
        } else product.grades = grades;
        let tmp = [...stock];
        for (let i = 0; i < tmp.length; i++) {
          if (!tmp[i]) tmp[i] = 0;
        }
        product.stock = tmp;
        product.showOptions = {
          when: whenShow,
          someTime: whenTime,
        };
        onFinish(product);
      }}
      onCancel={onClose}
      okText="Ok"
      cancelText={t("PRODUCTS-TAB-PRODUCT-MODAL.Cancel")}
      okButtonProps={{
        disabled:
          (externalCheckout && redirectLink.length === 0) ||
          !descricao ||
          (tipoGrade === "aberta" &&
            !externalCheckout &&
            (!tamanhos || (tamanhos && tamanhos.length === 0))) ||
          (tipoGrade === "fechada" &&
            (!grades || (grades && grades.length === 0))) ||
          (controlStock && stock.length !== tamanhos.length) ||
          (whenShow === "after-some-time" && !whenTime) ||
          (videoDuration && whenTime > videoDuration) ||
          invalidValues.includes("peso") ||
          invalidValues.includes("largura") ||
          invalidValues.includes("altura") ||
          invalidValues.includes("comprimento"),
      }}
    >
      <div>
        <label>{t("PRODUCTS-TAB-PRODUCT-MODAL.ProductDescription")} *</label>
        <Input.TextArea
          style={{
            maxHeight: "100px",
            minHeight: "100px",
            border: invalidValues.includes("descricao") && "1px solid red",
          }}
          placeholder={t("PRODUCTS-TAB-PRODUCT-MODAL.Description")}
          value={descricao}
          onChange={({ target: t }) => {
            if (invalidValues.includes("descricao"))
              setInvalidValues(invalidValues.filter((x) => x !== "descricao"));
            setDescricao(t.value);
          }}
          disabled={disabled}
          onBlur={() => checkInvalidValues("descricao")}
        />
        {invalidValues.includes("descricao") && (
          <s.InvalidLabel className="fade-in">
            {t("PRODUCTS-TAB-PRODUCT-MODAL.DescriptionRequired")}
          </s.InvalidLabel>
        )}
      </div>
      {!disabled && !externalCheckout && (
        <div style={{ marginTop: "5px" }}>
          <label>{t("PRODUCTS-TAB-PRODUCT-MODAL.GridModel")} * </label>
          <br />
          <Radio.Group
            onChange={({ target: t }) => {
              if (tipoGrade === "fechada" && tamanhos.length > 0) {
                setTamanhosGradeFechada(tamanhos);
                setStockGradeFechada(stock);
              } else if (
                tipoGrade === "aberta" &&
                tamanhos.length !== tamanhosGradeFechada.length
              ) {
                setTamanhos(tamanhosGradeFechada);
                setStock(stockGradeFechada);
              }

              setTipoGrade(t.value);
            }}
            value={tipoGrade}
            disabled={disabled || externalCheckout}
          >
            <Radio value={"aberta"}>
              {t("PRODUCTS-TAB-PRODUCT-MODAL.Open")}
            </Radio>
            <Radio value={"fechada"} disabled={catalog === "vtex"}>
              {t("PRODUCTS-TAB-PRODUCT-MODAL.Close")}
            </Radio>
          </Radio.Group>
          <br />
          <label style={{ color: "gray", fontSize: "12px" }}>
            {tipoGrade === "aberta"
              ? t("PRODUCTS-TAB-PRODUCT-MODAL.OpenGridDescription")
              : t("PRODUCTS-TAB-PRODUCT-MODAL.CloseGridDescription")}
          </label>
        </div>
      )}

      <div style={{ marginTop: "5px" }}>
        <label>{t("PRODUCTS-TAB-PRODUCT-MODAL.ProductCondition")} *</label>
        <Select
          onChange={(value) => setAvailable(value)}
          style={{ width: "100%" }}
          value={available}
          disabled={liveWithVtex || liveWithMagento || liveWithNeomode}
        >
          <Option value={true}>
            {t("PRODUCTS-TAB-PRODUCT-MODAL.Available")}
          </Option>
          <Option value={false}>
            {t("PRODUCTS-TAB-PRODUCT-MODAL.Unavailable")}
          </Option>
        </Select>
      </div>

      <div style={{ marginTop: "5px" }}>
        {tipoGrade === "aberta" ? (
          <>
            <label>
              {t("PRODUCTS-TAB-PRODUCT-MODAL.Size")}
              {!disabled && `s`}
              {!externalCheckout && " *"}
            </label>
            {!disabled && (
              <Search
                placeholder={t("PRODUCTS-TAB-PRODUCT-MODAL.NewSize")}
                enterButton={<PlusOutlined />}
                value={searchText}
                style={{
                  border: invalidValues.includes("tamanhos") && "1px solid red",
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onBlur={() => checkInvalidValues("tamanhos")}
                onSearch={(v) => {
                  if (v && v.trim() !== "") {
                    if (tamanhos.includes(v.trim())) {
                      message.info(
                        t("PRODUCTS-TAB-GRID-MODAL.SizesSameNameError")
                      );
                    } else setTamanhos([...tamanhos, v]);
                    if (controlStock && stock.length > 0)
                      setStock([...stock, 1]);
                    setSearchText("");
                    if (invalidValues.includes("tamanhos"))
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "tamanhos")
                      );
                  } else if (!externalCheckout) {
                    message.warning({
                      content: t("PRODUCTS-TAB-PRODUCT-MODAL.InformValidSize"),
                      key: "invalid-size",
                    });
                  }
                }}
              />
            )}

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginTop: "10px",
              }}
            >
              {tamanhos &&
                tamanhos.map((s, i2) => (
                  <Tag
                    key={`${s}-${i2}`}
                    closable={!disabled}
                    onClose={() => {
                      setTamanhos(tamanhos.filter((e) => e !== s));
                      let tmp = [...stock];
                      tmp.splice(i2, 1);
                      setStock(tmp);
                    }}
                    style={{ margin: "0 5px 5px 0" }}
                    disabled={disabled}
                  >
                    {s}
                  </Tag>
                ))}
            </div>
            {invalidValues.includes("tamanhos") && (
              <s.InvalidLabel className="fade-in">
                {t("PRODUCTS-TAB-PRODUCT-MODAL.SizeRequired")}
              </s.InvalidLabel>
            )}
            {tamanhos && tamanhos.length > 0 && (
              <Checkbox
                disabled={
                  liveWithVtex ||
                  liveWithTabelaPreco ||
                  liveWithMagento ||
                  liveWithNeomode ||
                  externalCheckout
                }
                style={{ margin: "5px 0" }}
                checked={controlStock}
                onChange={() => {
                  if (stock.length === 0) {
                    let tmp = [];
                    for (let i = 0; i < tamanhos.length; i++) tmp[i] = 1;
                    setStock(tmp);
                  }
                  setControlStock(!controlStock);
                  if (controlStock) setStock([]);
                }}
              >
                {t("PRODUCTS-TAB-PRODUCT-MODAL.ControlStock")}
              </Checkbox>
            )}

            {controlStock && (
              <div>
                <label>
                  {t("PRODUCTS-TAB-PRODUCT-MODAL.FulfillSizeStock")} *
                </label>
                <Scrollbars style={{ width: "100%", height: "90px" }}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    {tamanhos.map((tamanho, idx) => (
                      <s.TamanhosWrapper
                        tamanho={tamanho.length}
                        key={`${tamanho}-${idx}`}
                      >
                        <s.Tamanho id={tamanho}>{tamanho}</s.Tamanho>
                        <Input
                          value={stock[idx]}
                          min={0}
                          onPaste={(e) => preventInvalidNumberOnPaste(e)}
                          onKeyDown={(e) => preventInvalidNumberOnKeyDown(e)}
                          onChange={({ target: { value: v } }) => {
                            if (v >= 0 && !isNaN(v)) {
                              let tmp = [...stock];
                              if (v) tmp[idx] = parseInt(v.replace(/\D/g, ""));
                              else tmp[idx] = 0;
                              setStock(tmp);
                            }
                            if (invalidValues.includes("estoque"))
                              setInvalidValues(
                                invalidValues.filter((x) => x !== "estoque")
                              );
                          }}
                          onBlur={() => checkInvalidValues("estoque")}
                          style={{
                            width: "100%",
                            height: 38,
                            textAlign: "center",
                          }}
                        />
                      </s.TamanhosWrapper>
                    ))}
                  </div>
                </Scrollbars>
                {invalidValues.includes("estoque") && (
                  <s.InvalidLabel className="fade-in">
                    {t("PRODUCTS-TAB-PRODUCT-MODAL.StockRequired")}
                  </s.InvalidLabel>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <label>{t("PRODUCTS-TAB-PRODUCT-MODAL.Grids")}</label>
            <div style={{ display: "flex" }}>
              <br />
              <Select
                mode="multiple"
                onChange={(s) => {
                  if (s.length > grades.length) handleGridMeasures(s);
                  else handleRemoveItem(s, grades);
                  setGrades(s);
                }}
                style={{ width: "71%" }}
                value={grades}
              >
                {userGrades &&
                  userGrades.map((g) => (
                    <Option value={g.gradeId} key={g.gradeId}>
                      {g.nome}
                    </Option>
                  ))}
              </Select>
              <Button
                type="primary"
                style={{ marginLeft: "10px" }}
                onClick={() => setModalNovaGrade(true)}
              >
                <PlusSquareOutlined />
                {t("PRODUCTS-TAB-PRODUCT-MODAL.NewGrid")}
              </Button>
              <ModalNovaGrade
                userId={user.id}
                open={modalNovaGrade}
                onClose={() => setModalNovaGrade(false)}
              />
            </div>
            {userGrades && userGrades.length > 0 && (
              <Checkbox
                style={{ margin: "10px 0" }}
                checked={controlStock}
                disabled={
                  liveWithVtex ||
                  liveWithTabelaPreco ||
                  liveWithMagento ||
                  liveWithNeomode
                }
                onChange={() => {
                  if (stock.length === 0) {
                    for (let i = 0; i < tamanhos.length; i++) stock[i] = 1;
                  }
                  setControlStock(!controlStock);
                  if (controlStock) setStock([]);
                }}
              >
                {t("PRODUCTS-TAB-PRODUCT-MODAL.ControlStock")}
              </Checkbox>
            )}
            {controlStock && (
              <div>
                <label>
                  {t("PRODUCTS-TAB-PRODUCT-MODAL.FulfillSizeGridStock")} *
                </label>
                <Scrollbars style={{ width: "100%", height: "90px" }}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    {tamanhos.map((tamanho, idx) => (
                      <s.TamanhosWrapper
                        tamanho={tamanho.length}
                        key={`${tamanho}-${idx}`}
                      >
                        <s.Tamanho id={tamanho}>{tamanho}</s.Tamanho>
                        <Input
                          value={stock[idx]}
                          onChange={({ target: { value: v } }) => {
                            let tmp = [...stock];
                            if (v && v >= 0)
                              tmp[idx] = parseInt(v.replace(/\D/g, ""));
                            else tmp[idx] = 0;
                            setStock(tmp);
                            if (invalidValues.includes("estoque"))
                              setInvalidValues(
                                invalidValues.filter((x) => x !== "estoque")
                              );
                          }}
                          onBlur={() => checkInvalidValues("estoque")}
                          style={{
                            width: "100%",
                            height: 38,
                            textAlign: "center",
                          }}
                        />
                      </s.TamanhosWrapper>
                    ))}
                  </div>
                </Scrollbars>
                {invalidValues.includes("estoque") && (
                  <s.InvalidLabel className="fade-in">
                    {t("PRODUCTS-TAB-PRODUCT-MODAL.StockRequired")}
                  </s.InvalidLabel>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div style={{ marginTop: 5 }}>
        <label>{t("PRODUCTS-TAB-PRODUCT-MODAL.ControlShowProduct")} *</label>
        <Radio.Group
          value={whenShow}
          onChange={({ target: t }) => {
            setWhenTime("");
            setWhenShow(t.value);
          }}
          style={{ display: "grid" }}
        >
          <Radio value="ever">
            {t("PRODUCTS-TAB-PRODUCT-MODAL.AlwaysShow")}
          </Radio>
          <Radio value="after-appearing-on-video">
            {t("PRODUCTS-TAB-PRODUCT-MODAL.AfterVideo")}
          </Radio>
          <Radio disabled={aoVivo || realtime} value="after-some-time">
            {t("PRODUCTS-TAB-PRODUCT-MODAL.AfterTimeOne")} <b>X</b>{" "}
            {t("PRODUCTS-TAB-PRODUCT-MODAL.AfterTimeTwo")}{" "}
            {(aoVivo || realtime) && (
              <s.DisabledOptionLabel>
                <p>*</p> &nbsp; {t("PRODUCTS-TAB-PRODUCT-MODAL.AfterTimeRule")}
              </s.DisabledOptionLabel>
            )}
          </Radio>
          <Radio value="never">
            {t("PRODUCTS-TAB-PRODUCT-MODAL.NeverShow")}
          </Radio>
        </Radio.Group>
        {!aoVivo && !realtime && whenShow === "after-appearing-on-video" && (
          <div style={{ marginTop: "10px" }}>
            <strong>{t("PRODUCTS-TAB-PRODUCT-MODAL.Attention")}!</strong>
            <br />
            {t("PRODUCTS-TAB-PRODUCT-MODAL.EntryExitTimeRequired")}
          </div>
        )}

        {whenShow === "after-some-time" && (
          <div style={{ marginTop: "10px" }}>
            <label>{t("PRODUCTS-TAB-PRODUCT-MODAL.ConfireAfterTime")}:</label>
            <Input
              placeholder="00:00:00"
              onChange={({ target: t }) =>
                handleChangeTime(t.value, whenTime || "", (v) => setWhenTime(v))
              }
              value={whenTime}
              style={{ marginBottom: 5 }}
            />
            {videoDuration && whenTime > videoDuration && (
              <s.WrongProductTiming>
                <b>{t("PRODUCTS-TAB-PRODUCT-MODAL.Attention")}:</b>{" "}
                {t("PRODUCTS-TAB-PRODUCT-MODAL.TimeLongerThanVideo")}
              </s.WrongProductTiming>
            )}
          </div>
        )}
      </div>
      {liveWithCorreios && (
        <div style={{ marginTop: "10px" }}>
          <label>{t("PRODUCTS-TAB-PRODUCT-MODAL.ProductMeasures")} *</label>
          <Tooltip
            overlayStyle={{
              maxWidth: "600px",
            }}
            title={
              <ul
                style={{
                  paddingLeft: "20px",
                }}
              >
                <li>Peso: de 100 (gramas) até 30000 (30kg);</li>
                <li>Comprimento: de 13cm até 100cm;</li>
                <li>Altura: de 1cm até 100cm;</li>
                <li>Largura: de 8cm até 100cm;</li>
                <li>
                  A soma resultante do comprimento + largura + altura não deve
                  superar 200 cm;
                </li>
                <li>
                  A soma resultante do comprimento + o dobro do diâmetro não
                  pode ser menor que 28 cm;
                </li>
              </ul>
            }
          >
            <img
              alt="informação"
              src={"/assets/icons/info.svg"}
              style={{ marginLeft: "10px", transform: "translateY(-4px)" }}
            />
          </Tooltip>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "10px" }}>
              <Input
                addonBefore={t("PRODUCTS-TAB-PRODUCT-MODAL.Weight")}
                addonAfter="gramas"
                type="number"
                min={0}
                defaultValue={medidas.peso}
                onBlur={validateMeasurements}
                onChange={(e) => {
                  handleChangeMedidas(e, "peso");
                }}
                disabled={disabled}
                style={{
                  marginBottom: "10px",
                  border: invalidValues.includes("peso") && "1px solid red",
                }}
              />
              <Input
                addonBefore={t("PRODUCTS-TAB-PRODUCT-MODAL.Width")}
                addonAfter="cm"
                type="number"
                min={0}
                defaultValue={medidas.largura}
                onBlur={validateMeasurements}
                onChange={(e) => handleChangeMedidas(e, "largura")}
                style={{
                  border: invalidValues.includes("largura") && "1px solid red",
                }}
                disabled={disabled}
              />
            </div>
            <div>
              <Input
                addonBefore={t("PRODUCTS-TAB-PRODUCT-MODAL.Height")}
                addonAfter="cm"
                type="number"
                min={0}
                defaultValue={medidas.altura}
                onBlur={validateMeasurements}
                onChange={(e) => handleChangeMedidas(e, "altura")}
                style={{
                  marginBottom: "10px",
                  border: invalidValues.includes("altura") && "1px solid red",
                }}
                disabled={disabled}
              />
              <Input
                addonBefore={t("PRODUCTS-TAB-PRODUCT-MODAL.Length")}
                addonAfter="cm"
                type="number"
                min={0}
                defaultValue={medidas.comprimento}
                onBlur={validateMeasurements}
                onChange={(e) => handleChangeMedidas(e, "comprimento")}
                style={{
                  border:
                    invalidValues.includes("comprimento") && "1px solid red",
                }}
                disabled={disabled}
              />
            </div>
          </div>
          {(invalidValues.includes("peso") ||
            invalidValues.includes("largura") ||
            invalidValues.includes("altura") ||
            invalidValues.includes("comprimento")) && (
            <s.InvalidLabel className="fade-in">
              {t("PRODUCTS-TAB-PRODUCT-MODAL.MeasuresRequired")}
            </s.InvalidLabel>
          )}
        </div>
      )}
      {liveWithAssinatura && (
        <div style={{ marginTop: "10px" }}>
          {t("PRODUCTS-TAB-PRODUCT-MODAL.SubscribeProduct")}
          <legend style={{ fontSize: "12px", color: "gray" }}>
            {t("PRODUCTS-TAB-PRODUCT-MODAL.SubscribeProductDescription")}
          </legend>
          <Switch
            checked={produtoAssinatura}
            disabled={alreadyHasAssinatura && !prod.produtoAssinatura}
            onChange={() => setProdutoAssinatura(!produtoAssinatura)}
            style={{ width: 30 }}
          />
        </div>
      )}

      <label> {t("PRODUCTS-TAB-PRODUCT-MODAL.limitProduct")} :</label>

      <Input
        style={{
          width: "68px",
          height: "30px",
          marginLeft: "5px",
          marginTop: "10px",
        }}
        placeholder="00"
        type="number"
        value={quantidadeMaximaVendaProduto}
        onChange={({ target: t }) => setQuantidadeMaximaVendaProduto(t.value)}
        min={1}
        max={5}
        disabled={
          liveCatalogVtex ||
          liveCatalogMagento ||
          liveCatalogNeomode ||
          externalCheckout
        }
        onPaste={(e) => preventInvalidNumberOnPaste(e)}
        onKeyDown={(e) => preventInvalidNumberOnKeyDown(e)}
      ></Input>

      {externalCheckout && (
        <div style={{ marginTop: "10px" }}>
          <label> {t("PRODUCTS-TAB-PRODUCT-MODAL.ProductLink")}: * </label>

          <Search
            placeholder={t("PRODUCTS-TAB-PRODUCT-MODAL.ProductLink")}
            enterButton={<ArrowUpOutlined />}
            value={redirectLink}
            onChange={(e) => {
              if (invalidValues.includes("redirectLink"))
                setInvalidValues(
                  invalidValues.filter((x) => x !== "redirectLink")
                );
              setRedirectLink(e.target.value);
            }}
            onSearch={(url) => {
              window.open(url, "_blank").focus();
            }}
            onBlur={() => checkInvalidValues("redirectLink")}
            style={{
              marginBottom: "10px",
              border: invalidValues.includes("redirectLink") && "1px solid red",
            }}
          />
          {invalidValues.includes("redirectLink") && (
            <s.InvalidLabel className="fade-in">
              {t("LANDING-PAGE.infosLive.CreateLPErrorDescription")}
            </s.InvalidLabel>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ModalProduct;
