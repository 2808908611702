import React, { createContext, useCallback } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { customerService } from "../services";
import useOrders from "./hooks/use-orders";

const OrdersContext = createContext({
  orders: {},
  setOrders: () => {},
});

const OrdersProvider = ({ children }) => {
  const { orders, setOrders } = useOrders();
  const { t } = useTranslation();

  const handleGetOrders = useCallback((user, liveId) => {
    customerService
      .getOrders(liveId, user)
      .then((data) => {
        setOrders(data);
      })
      .catch(() => {
        message.error(t("ORDER.ErrorGetProducts"));
      });
  }, []);

  return (
    <OrdersContext.Provider
      value={{
        orders,
        setOrders,
        handleGetOrders,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export { OrdersContext, OrdersProvider };
