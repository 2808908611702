import React, { useContext } from "react";
import { ThemeContext } from "../../../../context";
import * as s from "./styled-realtime";

const CardRealtime = ({
  onClick,
  imageSrc,
  imageAlt = "card-realtime",
  style,
  positionNumber,
  cardKey,
  showProductTime,
  tempoInicio,
  tempoFim,
  disabled,
  className,
}) => {
  const { corPrimaria, corSecundaria } = useContext(ThemeContext);

  return (
    <s.Card
      key={cardKey}
      corSecundaria={corSecundaria}
      className={className || "shadow2"}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      style={{
        ...style,
        background: !imageSrc && "white",
        margin: showProductTime ? "5px 65px 5px 5px" : 5,
      }}
    >
      {imageSrc ? (
        <>
          {showProductTime && (
            <div className="product-time">
              <div style={{ background: corPrimaria }}>
                {tempoInicio || "---"}
              </div>
              <div style={{ background: corSecundaria }}>
                {tempoFim || "---"}
              </div>
            </div>
          )}
          <img
            src={imageSrc || "https://i.stack.imgur.com/X2r4r.png"}
            alt={imageAlt}
            id={imageAlt || ""}
          />
        </>
      ) : (
        <label style={{ cursor: disabled ? "not-allowed" : "pointer" }}>
          {positionNumber}
        </label>
      )}
    </s.Card>
  );
};

export default CardRealtime;
