import React, { useCallback, useState } from "react";
import { Modal, Steps } from "antd";
import { useTranslation } from "react-i18next";

import {
  UserOutlined,
  EnvironmentOutlined,
  InboxOutlined,
  CreditCardOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import NeomodeLogin from "./neomode-login";
import NeomodeSellers from "./neomode-sellers";
import { useNeomode } from "../../context";
import NeomodeShipping from "./neomode-shipping";
import NeomodePayment from "./neomode-payment";
import NeomodeResume from "./neomode-resume";

const { Step } = Steps;
const CheckoutNeomode = ({
  visible,
  closeModal,
  liveId,
  liveEstrangeira,
  observacao,
  cartTotal,
}) => {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState(0); //step em que o pagamento e entrega se encontram
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const { userLogged } = useNeomode();

  const goNextStep = useCallback(
    () => setCurrentStep((current) => current + 1),
    []
  );
  const goBackStep = useCallback(
    () => setCurrentStep((current) => current - 1),
    []
  );

  return (
    <>
      <Modal
        title={t("NEOMODE.CancelCheckoutQuestion")}
        visible={confirmModalIsOpen}
        style={{ maxWidth: "90vw", width: 900 }}
        okText={t("NEOMODE.Yes")}
        onOk={closeModal}
        cancelText={t("NEOMODE.Back")}
        onCancel={() => setConfirmModalIsOpen(false)}
      >
        {t("NEOMODE.CancelCheckout")}
      </Modal>
      <Modal
        title={t("NEOMODE.LORICheckout")}
        visible={visible}
        style={{ maxWidth: "90vw", width: 900 }}
        onOk={goNextStep}
        okButtonProps={{
          disabled: currentStep === 0 && !userLogged ? true : false,
        }}
        onCancel={() => setConfirmModalIsOpen(true)}
        footer={false}
      >
        <Steps
          current={currentStep}
          size="small"
          style={{ marginBottom: "5px" }}
          responsive={false}
          direction="horizontal"
        >
          <Step icon={<UserOutlined />} />
          <Step icon={<EnvironmentOutlined />} />
          <Step icon={<InboxOutlined />} />
          <Step icon={<CreditCardOutlined />} />
          <Step icon={<CheckOutlined />} />
        </Steps>
        <NeomodeLogin
          visible={currentStep === 0}
          liveId={liveId}
          goNextStep={goNextStep}
          liveEstrangeira={liveEstrangeira}
        />
        <NeomodeSellers
          visible={currentStep === 1}
          liveId={liveId}
          goBackStep={goBackStep}
          goNextStep={goNextStep}
        />
        <NeomodeShipping
          visible={currentStep === 2}
          liveId={liveId}
          goBackStep={goBackStep}
          goNextStep={goNextStep}
        />
        <NeomodePayment
          visible={currentStep === 3}
          liveId={liveId}
          goBackStep={goBackStep}
          goNextStep={goNextStep}
        />
        <NeomodeResume
          visible={currentStep === 4}
          liveId={liveId}
          goBackStep={goBackStep}
          goNextStep={goNextStep}
          closeModal={closeModal}
          cartTotal={cartTotal}
          observacao={observacao}
        />
      </Modal>
    </>
  );
};

export default CheckoutNeomode;
