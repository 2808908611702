import React, { useContext, useRef, useState } from "react";
import { Button, Input, notification, Checkbox, Form, message } from "antd";

import { useTranslation } from "react-i18next";

import { ViewLandingPageContext } from "../../../context";
import { customerService } from "../../../services";
import { validateEmail } from "../../../utils";

import * as s from "./styles-capture-lead";

const CaptureLead = () => {
  const { t } = useTranslation();

  const { landingPage } = useContext(ViewLandingPageContext);

  const {
    corBotao,
    corPrimaria,
    corSecundaria,
    corTexto,
    imagemLogo,
    imagemPrincipal,
    nomeEmpresa,
    nomeLive,
    sobreEmpresa,
    sobreLive,
    timestamp,
    video,
    leadId,
    leadUrl,
    liveUrl,
  } = landingPage;
  const [saving, setSaving] = useState(false); //salvando dados

  const [invalidValues, setInvalidValues] = useState([]);
  const [name, setName] = useState("");
  const [fone, setFone] = useState("");
  const [email, setEmail] = useState("");
  const [privacy, setPrivacy] = useState(false);

  const [days, setDays] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);

  var date = new Date(timestamp);

  const getTime = () => {
    const time = timestamp - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  React.useEffect(() => {
    const interval = setInterval(() => getTime(timestamp), 1000);

    return () => clearInterval(interval);
  }, []);

  const checkInvalidValues = (type) => {
    if (type === "name" && !name) setInvalidValues([...invalidValues, type]);
    else if (type === "fone" && !fone) {
      setInvalidValues([...invalidValues, type]);
    } else if (type === "email" && !email) {
      setInvalidValues([...invalidValues, type]);
    } else if (type === "privacy" && !privacy) {
      setInvalidValues([...invalidValues, type]);
    }
  };

  const onChangePrivacy = () => {
    setPrivacy(!privacy);
  };

  const handleDayOfWeek = (value) => {
    switch (value) {
      case 0:
        return "DOMINGO";
        break;
      case 1:
        return "SEGUNDA-FEIRA";
        break;
      case 2:
        return "TERÇA-FEIRA";
        break;
      case 3:
        return "QUARTA-FEIRA";
        break;
      case 4:
        return "QUINTA-FEIRA";
        break;
      case 5:
        return "SEXTA-FEIRA";
        break;
      default:
        return "SÁBADO";
    }
  };
  // const handleMes = (value) => {
  //   switch (value + 1) {
  //     case 1:
  //       return "Janeiro";
  //       break;
  //     case 2:
  //       return "Fevereiro";
  //       break;
  //     case 3:
  //       return "Março";
  //       break;
  //     case 4:
  //       return "Abril";
  //       break;
  //     case 5:
  //       return "Maio";
  //       break;
  //     case 6:
  //       return "Junho";
  //       break;
  //     case 7:
  //       return "Julho";
  //       break;
  //     case 8:
  //       return "Agosto";
  //       break;
  //     case 9:
  //       return "Setembro";
  //       break;
  //     case 10:
  //       return "Outubro";
  //       break;
  //     case 11:
  //       return "Novembro";
  //       break;
  //     default:
  //       return "Dezembro";
  //   }
  // };

  function verifyFields() {
    if (!validateEmail(email)) {
      return notification.error({
        key: "email",
        message: t("CAPTURE-LEAD.validations.error"),
        description: t("HEADER.RequireEmail"),
        onClick: () => notification.close("email"),
        duration: 5,
      });
    } else onFinishLead();
  }

  const onFinishLead = async () => {
    setSaving(true);

    let data = {
      nome: name,
      email,
      telefone: fone,
    };

    if (privacy) {
      customerService
        .createLead(data, leadId)
        .then(() => {
          setSaving(false);
          notification.success({
            key: "aviso",
            message: t("CAPTURE-LEAD.validations.sucess"),
            description: t("CAPTURE-LEAD.validations.sucessDescrition"),
            onClick: () => notification.close("aviso"),
            duration: 10,
          });
          setName("");
          setFone("");
          setEmail("");
        })
        .catch(() => {
          if (!name || !fone || !email || !privacy) {
            notification.error({
              key: "aviso",
              message: t("CAPTURE-LEAD.validations.error"),
              description: t("CAPTURE-LEAD.validations.errorDescription"),
              onClick: () => notification.close("aviso"),
              duration: 10,
            });
            setSaving(false);
          } else {
            notification.error({
              key: "aviso",
              message: t("CAPTURE-LEAD.validations.error"),
              description: t("CAPTURE-LEAD.validations.errorEmail"),
              onClick: () => notification.close("aviso"),
              duration: 10,
            });
            setSaving(false);
          }
        });
    } else {
      notification.error({
        key: "aviso",
        message: t("CAPTURE-LEAD.validations.error"),
        description: t("CAPTURE-LEAD.validations.errorPrivacy"),
        onClick: () => notification.close("aviso"),
        duration: 10,
      });
      setSaving(false);
    }
  };

  function renderFooter() {
    return (
      <footer
        style={{
          backgroundColor: corSecundaria,
          width: "100%",
          display: "flex",
          height: "100px",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <a href="https://www.facebook.com/4show.live" target="_blank">
            <img
              src="/assets/images/facebook.svg"
              alt={"Facebook"}
              style={{ marginTop: "36px", marginLeft: "30px" }}
            />
          </a>
          <a href="https://www.instagram.com/4show.live/" target="_blank">
            <img
              src="/assets/images/instagram.svg"
              alt={"Instagram"}
              style={{ marginLeft: "15px", marginTop: "36px" }}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/4show-live/"
            target="_blank"
          >
            <img
              src="/assets/images/linkedIn.svg"
              alt={"LinkedIn"}
              style={{ marginLeft: "15px", marginTop: "36px" }}
            />
          </a>
        </div>
        <img
          src="/assets/images/logo.svg"
          alt={t("CART.RequestSummary")}
          style={{ marginTop: "36px", marginRight: "30px" }}
        />
      </footer>
    );
  }

  return (
    <>
      <s.Content style={{ color: corTexto }}>
        {window.innerWidth <= 955 && renderFooter()}

        <s.ContentLead style={{ backgroundColor: corPrimaria }}>
          <h1
            style={{
              color: corTexto,
              marginBottom: "0px",
              textAlign: "center",
            }}
          >
            {nomeLive}
          </h1>
          <div>
            <h2 style={{ marginTop: "20px", color: corTexto }}>
              {" "}
              {handleDayOfWeek(date.getDay())} |{" "}
              {date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/
              {date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1}{" "}
              | {date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}
              :
              {date.getMinutes() < 10
                ? `0${date.getMinutes()}`
                : date.getMinutes()}
              Hrs
            </h2>

            <s.CadastroContent>
              <Form style={{ marginBottom: "20px" }}>
                <section style={{ width: "100%" }}>
                  <div style={{ marginBottom: "20px" }}>
                    <s.TitleForm style={{ color: corTexto }}>
                      {t("CAPTURE-LEAD.name")} *
                    </s.TitleForm>
                    <Input
                      value={name}
                      onChange={({ target: t }) => {
                        if (invalidValues.includes("name"))
                          setInvalidValues(
                            invalidValues.filter((x) => x !== "name")
                          );
                        setName(t.value);
                      }}
                      onBlur={() => checkInvalidValues("name")}
                      style={{
                        border:
                          invalidValues.includes("name") && "1px solid red",
                      }}
                      placeholder="Digite seu nome"
                    />
                    {invalidValues.includes("name") && (
                      <s.InvalidLabel className="fade-in">
                        {t("CAPTURE-LEAD.validations.name")}
                      </s.InvalidLabel>
                    )}
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <s.TitleForm style={{ color: corTexto }}>
                      {t("CAPTURE-LEAD.phone")} *
                    </s.TitleForm>
                    <Input
                      onChange={({ target: t }) => {
                        if (invalidValues.includes("fone"))
                          setInvalidValues(
                            invalidValues.filter((x) => x !== "fone")
                          );
                        setFone(t.value);
                      }}
                      onBlur={() => checkInvalidValues("fone")}
                      style={{
                        border:
                          invalidValues.includes("fone") && "1px solid red",
                      }}
                      value={fone}
                      pattern="([0-9]{2}) [0-9]{5}-[0-9]{4}"
                      placeholder="(00) 00000 0000"
                      maxLength={11}
                      type="tel"
                    />
                    {invalidValues.includes("fone") && (
                      <s.InvalidLabel className="fade-in">
                        {t("CAPTURE-LEAD.validations.fone")}
                      </s.InvalidLabel>
                    )}
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <s.TitleForm style={{ color: corTexto }}>
                      {t("CAPTURE-LEAD.email")} *
                    </s.TitleForm>
                    <Input
                      required
                      placeholder="Digite um email válido"
                      id="ipt-email-login"
                      className="input-style"
                      value={email}
                      onChange={({ target: t }) => {
                        if (invalidValues.includes("email"))
                          setInvalidValues(
                            invalidValues.filter((x) => x !== "email")
                          );
                        setEmail(t.value);
                      }}
                    />
                  </div>
                </section>
                <Checkbox
                  onChange={({ target: t }) => {
                    if (invalidValues.includes("privacy"))
                      setInvalidValues(
                        invalidValues.filter((x) => x !== "privacy")
                      );
                    onChangePrivacy();
                  }}
                  onBlur={() => checkInvalidValues("privacy")}
                >
                  <a
                    href={window.location.origin + "/termos-lgpd.pdf"}
                    target="_blank"
                  >
                    {t("CAPTURE-LEAD.privacyPolicy")}
                  </a>
                  <br />
                  {invalidValues.includes("privacy") && (
                    <s.InvalidLabel className="fade-in">
                      {t("CAPTURE-LEAD.validations.privacy")}
                    </s.InvalidLabel>
                  )}
                </Checkbox>
                <Button
                  onClick={() => {
                    verifyFields();
                  }}
                  style={{
                    margin: "20px 20px 20px 0px ",
                    width: "100%",
                    backgroundColor: corBotao,
                    color: corTexto,
                  }}
                >
                  {t("CAPTURE-LEAD.singUp")}
                </Button>
              </Form>
            </s.CadastroContent>
            {Date.now() > timestamp && liveUrl ? (
              <div
                style={{
                  display: "block",
                  height: "116px",
                }}
              >
                <h1 style={{ color: corTexto }}>
                  {t("CAPTURE-LEAD.liveTitle")}
                </h1>
                <label style={{ color: corTexto }}>
                  {t("CAPTURE-LEAD.liveSubTitle")}
                </label>
                <Button
                  href={`${liveUrl}`}
                  style={{
                    margin: "10px 20px 10px 0px ",
                    width: "100%",
                    backgroundColor: corBotao,
                    color: corTexto,
                  }}
                >
                  {t("CAPTURE-LEAD.liveButton")}
                </Button>
              </div>
            ) : Date.now() > timestamp && !liveUrl ? (
              <div style={{ display: "block" }}>
                <h1 style={{ color: corTexto }}>
                  {t("CAPTURE-LEAD.liveTitle")}
                </h1>
              </div>
            ) : (
              <s.TimerContent>
                <s.TimerCardContent style={{ backgroundColor: corSecundaria }}>
                  <h1
                    style={{
                      marginBottom: "-10px",
                      color: corTexto,
                      textAlign: "center",
                    }}
                  >
                    {days < 10 ? `0${days}` : days}{" "}
                  </h1>
                  <h2 style={{ color: corTexto, textAlign: "center" }}>Dias</h2>
                </s.TimerCardContent>
                <h1
                  style={{
                    color: corTexto,
                    textAlign: "center",
                    fontSize: "35px",
                    margin: "-20px 5px 0px 5px",
                  }}
                ></h1>
                <s.TimerCardContent style={{ backgroundColor: corSecundaria }}>
                  <h1
                    style={{
                      marginBottom: "-10px",
                      color: corTexto,
                      textAlign: "center",
                    }}
                  >
                    {hours < 10 ? `0${hours}` : hours}
                  </h1>
                  <h2 style={{ color: corTexto, textAlign: "center" }}>Hrs</h2>
                </s.TimerCardContent>
                <h1
                  style={{
                    color: corTexto,
                    textAlign: "center",
                    fontSize: "35px",
                    margin: "-20px 5px 0px 5px",
                  }}
                ></h1>
                <s.TimerCardContent style={{ backgroundColor: corSecundaria }}>
                  <h1
                    style={{
                      marginBottom: "-10px",
                      color: corTexto,
                      textAlign: "center",
                    }}
                  >
                    {minutes < 10 ? `0${minutes}` : minutes}
                  </h1>
                  <h2 style={{ color: corTexto, textAlign: "center" }}>Min</h2>
                </s.TimerCardContent>
                <h1
                  style={{
                    color: corTexto,
                    textAlign: "center",
                    fontSize: "35px",
                    margin: "-20px 5px 0px 5px",
                  }}
                ></h1>
                <s.TimerCardContent style={{ backgroundColor: corSecundaria }}>
                  <h1
                    style={{
                      marginBottom: "-10px",
                      color: corTexto,
                      textAlign: "center",
                    }}
                  >
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </h1>
                  <h2 style={{ color: corTexto, textAlign: "center" }}>Seg</h2>
                </s.TimerCardContent>
              </s.TimerContent>
            )}
          </div>
        </s.ContentLead>
        <div
          style={{
            backgroundColor: corSecundaria,
            display: "flex",
            flexDirection: "column",
            alignContent: "stretch",
            borderRadius: "0",
            flex: 1,
          }}
        >
          <s.ImageContent imageBg={imagemPrincipal}></s.ImageContent>
          <s.AboutBrand style={{ backgroundColor: corSecundaria }}>
            <h1 style={{ color: corTexto }}>{nomeEmpresa}</h1>
            <label style={{ color: corTexto }}>{sobreLive}</label>
          </s.AboutBrand>

          {window.innerWidth > 955 && renderFooter()}
        </div>
      </s.Content>
    </>
  );
};

export default CaptureLead;
