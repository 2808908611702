import React, { useState, useEffect, useContext } from "react";
import { Input, Modal, Form, notification, Radio, Select } from "antd";
import { useTranslation } from "react-i18next";
import { managerService, userService } from "../../../services";
import { PaymentContext } from "../../../context";
import * as s from "./styles";

const { Option } = Select;
const ModalNewUserSplit = ({ visible = false, onCancel }) => {
  const accountTypes = [
    "conta_corrente",
    "conta_poupanca",
    "conta_corrente_conjunta",
    "conta_poupanca_conjunta",
  ];
  const { t } = useTranslation();
  const { addPagarmeRecipient } = useContext(PaymentContext);
  const [tipoConta, setTipoConta] = useState(accountTypes[0]);
  const [nome, setNome] = useState();
  const [agencia, setAgencia] = useState();
  const [agenciaDv, setAgenciaDv] = useState();
  const [conta, setConta] = useState();
  const [contaDv, setContaDv] = useState();
  const [codigo, setCodigo] = useState();
  const [codigoBanco, setCodigoBanco] = useState();
  const [banksList, setBanksList] = useState([]);
  //control
  const [loading, setLoading] = useState(false);
  const [loadingBanks, setLoadingBanks] = useState(true);
  const [isPfUser, setIsPfUser] = useState(true);

  const [invalidValues, setInvalidValues] = useState([]);

  const handleTypeUser = (e) => {
    if (e?.target?.value === "pj") setIsPfUser(false);
    if (e?.target?.value === "pf") setIsPfUser(true);
    if ("pf" && codigo.length > 11) {
      setCodigo(codigo.slice(0, 11));
    } else if ("pj" && codigo.length > 14) {
      setCodigo(codigo.slice(0, 14));
    }
  };

  useEffect(() => {
    managerService
      .getBanksList()
      .then((data) => {
        setCodigoBanco(data[0].nome + "•" + data[0].codigo);
        setBanksList(data);
        setLoadingBanks(false);
      })
      .catch(() => setLoadingBanks(false));
  }, []);

  const finish = () => {
    setLoading(true);
    let data = {
      nome,
      codigoBanco: codigoBanco.split("•")[1],
      agencia,
      agenciaDv,
      conta,
      contaDv,
      codigo,
      tipoConta,
    };
    userService
      .createRecipient("pagarme", data)
      .then(({ newRecipient }) => {
        addPagarmeRecipient(newRecipient);
        setLoading(false);
        notification.success({
          key: "createRecipient",
          message: t("MODAL-NEW-USER-SPLIT.IdCreationSuccess"),
          description: t("MODAL-NEW-USER-SPLIT.IdCreationSuccessDescription"),
          onClick: () => notification.close("createRecipient"),
          duration: 10,
        });
        setNome();
        setAgencia();
        setAgenciaDv();
        setConta();
        setContaDv();
        setCodigo();
        setCodigoBanco();
        onCancel();
      })
      .catch(() => {
        setLoading(false);
        notification.error({
          key: "createRecipient",
          message: t("MODAL-NEW-USER-SPLIT.GeneralError"),
          description: t("MODAL-NEW-USER-SPLIT.IdCreationErrorDescription"),
          onClick: () => notification.close("createRecipient"),
          duration: 10,
        });
      });
  };

  const checkInvalidValues = (type) => {
    if ((type === "nome" && !nome) || nome?.length <= 1)
      setInvalidValues([...invalidValues, type]);
    else if ((type === "agencia" && !agencia) || agencia?.length <= 3)
      setInvalidValues([...invalidValues, type]);
    else if ((type === "conta" && !conta) || conta?.length <= 8)
      setInvalidValues([...invalidValues, type]);
    else if ((type === "contaDv" && !contaDv) || contaDv?.length <= 1)
      setInvalidValues([...invalidValues, type]);
    else if (
      (type === "codigo" && isPfUser && codigo?.length <= 10) ||
      (!isPfUser && codigo?.length <= 13)
    )
      setInvalidValues([...invalidValues, type]);
  };

  return (
    <Modal
      title={t("MODAL-NEW-USER-SPLIT.IdCreation")}
      visible={visible}
      onOk={finish}
      onCancel={onCancel}
      okText={
        loading
          ? t("MODAL-NEW-USER-SPLIT.Saving")
          : t("MODAL-NEW-USER-SPLIT.Confirm")
      }
      cancelText={t("MODAL-NEW-USER-SPLIT.Back")}
      okButtonProps={{
        loading,
        disabled:
          !nome ||
          !codigoBanco ||
          !agencia ||
          !conta ||
          !contaDv ||
          (!isPfUser && codigo?.length <= 13) ||
          (isPfUser && codigo?.length <= 10),
      }}
      cancelButtonProps={{
        style: { display: "none" },
      }}
    >
      <Form>
        {t("MODAL-NEW-USER-SPLIT.CreationDescription")}
        <s.ModalNewUserSplitContainer>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "210px" }}>
              <label>{t("MODAL-NEW-USER-SPLIT.CorporateName")}*</label>
              <Input
                name="name"
                placeholder={t("MODAL-NEW-USER-SPLIT.CorporateNamePlaceholder")}
                onBlur={() => checkInvalidValues("nome")}
                onChange={({ target: t }) => {
                  if (invalidValues.includes("nome"))
                    setInvalidValues(invalidValues.filter((x) => x !== "nome"));
                  setNome(t.value);
                }}
                style={{
                  marginBottom: "10px",
                  border: invalidValues.includes("nome") && "1px solid red",
                }}
                maxLength={30}
                value={nome}
              />

              {invalidValues.includes("nome") && (
                <s.InvalidLabel className="fade-in">
                  {t("MAGENTO-CATALOG.FieldRequired")} <br />
                </s.InvalidLabel>
              )}
            </div>
            <div
              style={{
                width: "300px",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                marginLeft: "100px",
              }}
            >
              <label>{t("MODAL-NEW-USER-SPLIT.Zipcode")}*</label>
              <Radio.Group required onChange={handleTypeUser} defaultValue="pf">
                <Radio value="pf">{t("HEADER.IndividualPerson")}</Radio>
                <Radio value="pj">{t("HEADER.LegalPerson")}</Radio>
              </Radio.Group>
              <Input
                value={codigo}
                maxLength={isPfUser ? 11 : 14}
                pattern={
                  isPfUser
                    ? "\\d{3}.\\d{3}.\\d{3}-\\d{2}"
                    : "\\d{2}.\\d{3}.\\d{3}/\\d{4}-\\d{2}"
                }
                required
                onChange={({ target: t }) => {
                  if (invalidValues.includes("codigo"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "codigo")
                    );
                  setCodigo(t.value);
                }}
                onBlur={() => {
                  if (isPfUser && codigo?.length > 11) {
                    setCodigo(codigo.slice(0, 11));
                  } else if (!isPfUser && codigo?.length > 14) {
                    setCodigo(codigo.slice(0, 14));
                  }
                  checkInvalidValues("codigo");
                }}
                style={{
                  border: invalidValues.includes("codigo") && "1px solid red",
                }}
                placeholder={isPfUser ? t("HEADER.CPF") : t("HEADER.CNPJ")}
              />
              {invalidValues.includes("codigo") && (
                <s.InvalidLabel className="fade-in">
                  {t("HEADER.RequireCPF-CNPJ")} <br />
                </s.InvalidLabel>
              )}
              {/* <Form.Item
                validateTrigger={["onBlur", "onChange"]}
                rules={[
                  {
                    validator: isPfUser ? cpfValidator : cnpjValidator,

                    message: t("HEADER.RequireCPF-CNPJ"),
                  },
                ]}
                name={"codigo"}
              >
                <MaskedInput
                  mask={isPfUser ? "000.000.000-00" : "00.000.000/0000-00"}
                  value={codigo}
                  onChange={({ target: t }) => {
                    setCodigo(t.value);
                  }}
                  placeholder={isPfUser ? t("HEADER.CPF") : t("HEADER.CNPJ")}
                />
              </Form.Item> */}
              {/* <Input
              name="codigo"
              placeholder={t("MODAL-NEW-USER-SPLIT.ZipcodePlaceholder")}
              onChange={({ target: t }) => setCodigo(t.value)}
              value={codigo ? codigo.replace(/[^0-9]/g, "") : ""}
            /> */}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "203px" }}>
              <label>{t("MODAL-NEW-USER-SPLIT.AccountType")}*</label>
              <Select
                defaultValue={accountTypes[0]}
                onChange={(v) => setTipoConta(v)}
                style={{ width: "203px" }}
              >
                {accountTypes.map((type) => (
                  <Option value={type} key={type}>
                    {t(`MODAL-NEW-USER-SPLIT.${type}`)}
                    {/* {type.charAt(0).toUpperCase() +
                    type
                      .replaceAll("_", " ")
                      .substring(1)
                      .replaceAll("anca", "ança")} */}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ marginLeft: "32px" }}>
              <label>{t("MODAL-NEW-USER-SPLIT.Bank")}*</label>
              <Select
                loading={loadingBanks}
                showSearch
                defaultValue={
                  banksList.length > 0
                    ? banksList[0].nome + "•" + banksList[0].codigo
                    : ""
                }
                onChange={(v) => setCodigoBanco(v)}
                style={{ width: "237px" }}
                // value={codigoBanco}
                placeholder={t("MODAL-NEW-USER-SPLIT.SelectBank")}
              >
                {banksList.length > 0 &&
                  banksList.map((b) => (
                    <Option value={b.nome + "•" + b.codigo} key={b.codigo}>
                      {b.codigo} - {b.nome}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "203px" }}>
              <label>{t("MODAL-NEW-USER-SPLIT.Agency")}*</label>
              <Input
                name="agencia"
                placeholder={t("MODAL-NEW-USER-SPLIT.Agency")}
                onBlur={() => checkInvalidValues("agencia")}
                onChange={({ target: t }) => {
                  if (invalidValues.includes("agencia"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "agencia")
                    );
                  setAgencia(t.value.replace(/\D/g, ""));
                }}
                style={{
                  border: invalidValues.includes("agencia") && "1px solid red",
                }}
                value={agencia}
                type="text"
                maxLength="5"
              />
              {invalidValues.includes("agencia") && (
                <s.InvalidLabel className="fade-in">
                  {t("CAPTURE-LEAD.validations.errorDescription")} <br />
                </s.InvalidLabel>
              )}
            </div>
            <div style={{ marginLeft: "30px", width: "220px" }}>
              <label>{t("MODAL-NEW-USER-SPLIT.AgencyDV")}</label>
              <Input
                name="agenciaDv"
                placeholder={t("MODAL-NEW-USER-SPLIT.AgencyDV")}
                onBlur={() => checkInvalidValues("agenciaDv")}
                onChange={({ target: t }) => {
                  setAgenciaDv(t.value.replace(/\D/g, ""));
                }}
                value={agenciaDv}
                type="text"
                maxLength="1"
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "203px" }}>
              <label>{t("MODAL-NEW-USER-SPLIT.Account")}*</label>
              <Input
                name="conta"
                placeholder={t("MODAL-NEW-USER-SPLIT.Account")}
                onBlur={() => checkInvalidValues("conta")}
                onChange={({ target: t }) => {
                  if (invalidValues.includes("conta"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "conta")
                    );
                  setConta(t.value.replace(/\D/g, ""));
                }}
                style={{
                  border: invalidValues.includes("conta") && "1px solid red",
                }}
                value={conta}
                type="text"
                maxLength="9"
              />
              {invalidValues.includes("conta") && (
                <s.InvalidLabel className="fade-in">
                  {t("CAPTURE-LEAD.validations.errorDescription")} <br />
                </s.InvalidLabel>
              )}
            </div>
            <div style={{ marginLeft: "30px", width: "220px" }}>
              <label>{t("MODAL-NEW-USER-SPLIT.AccountDV")}*</label>
              <Input
                name="contaDv"
                placeholder={t("MODAL-NEW-USER-SPLIT.AccountDV")}
                onBlur={() => checkInvalidValues("contaDv")}
                onChange={({ target: t }) => {
                  if (invalidValues.includes("contaDv"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "contaDv")
                    );
                  setContaDv(t.value.replace(/\D/g, ""));
                }}
                style={{
                  border: invalidValues.includes("contaDv") && "1px solid red",
                }}
                value={contaDv}
                type="text"
                maxLength="2"
              />
              {invalidValues.includes("contaDv") && (
                <s.InvalidLabel className="fade-in">
                  {t("CAPTURE-LEAD.validations.errorDescription")} <br />
                </s.InvalidLabel>
              )}
            </div>
          </div>
        </s.ModalNewUserSplitContainer>
      </Form>
    </Modal>
  );
};

export default ModalNewUserSplit;
