import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { googleTagManager, productService } from "../../../services";
import { Modal, message } from "antd";
import { sourceImage } from "../../../utils";
import {
  ThemeContext,
  GradesContext,
  AddProductContext,
} from "../../../context";
import * as s from "./styles-cart";

const ModalVariations = ({ visible, products, onCancel, live, customer }) => {
  const { t } = useTranslation();
  const { corPrimaria } = useContext(ThemeContext);
  const { userGrades } = useContext(GradesContext); //grades do admin da live
  const { setAddProduct } = useContext(AddProductContext);
  const [selected, setSelected] = useState(false); //variações selecionadas para serem adicionadas
  //Control
  const [loading, setLoading] = useState(false); //variações selecionadas para serem adicionadas

  const onOk = () => {
    if (selected) {
      let newProduct = {
        ...selected,
        preco: parseFloat(
          (live.layoutCheckout === "ecommerce" ? 1 : 100) *
            selected.preco.toFixed(2)
        ),
        grade:
          selected.tipoGrade === "aberta" || !selected.tipoGrade
            ? [{ tamanho: selected.tamanhos[0], quantidade: 1 }]
            : userGrades.filter((g) => selected.grades.includes(g.gradeId))[0]
                .grade || [],
      };
      googleTagManager.clickProduct(newProduct, "modal-variations-cart");
      if (selected.tipoGrade === "aberta" && live.layoutCheckout !== "padrao") {
        setAddProduct(newProduct);
        setSelected();
        onCancel();
        let element = document.getElementById("fullscreenElem");
        if (element) element.style.overflowY = "auto";
      } else {
        setLoading(true);
        productService
          .addFavorite(live.liveId, {
            produto: newProduct,
            customer,
          })
          .then(() => {
            message.success({
              content: t("MODAL-VARIATIONS-LIVE.ProductAdded"),
              duration: 2,
            });
            googleTagManager.addProductToCart(
              newProduct,
              "modal-variations-cart"
            );
            setSelected(false);
            setLoading(false);
            onCancel();
            let element = document.getElementById("fullscreenElem");
            if (element) element.style.overflowY = "auto";
          })
          .catch(() => {
            message.error({
              content: t("MODAL-VARIATIONS-LIVE.EnterCartError"),
              duration: 5,
            });
            setLoading(false);
          });
      }
    } else onCancel();
  };

  const handleVariation = (v) => {
    if (selected?.docId === v?.docId) setSelected(false);
    else setSelected(v);
  };

  return (
    <Modal
      title={t("MODAL-VARIATIONS-LIVE.ProductVariations")}
      visible={visible}
      onOk={!loading ? () => onOk() : () => {}}
      onCancel={!loading ? onCancel : () => {}}
      confirmLoading={loading}
      okText={t("MODAL-VARIATIONS-LIVE.Add")}
      cancelText={t("MODAL-VARIATIONS-LIVE.Cancel")}
      style={{ maxWidth: "280px" }}
      width={"80vw"}
      disabled={!selected}
    >
      <s.VariationsList corPrimaria={corPrimaria}>
        {products.map((prod, idx) => (
          <div className="wrapper" key={prod.docId}>
            <img
              className={selected?.docId === prod.docId ? "var-selected" : ""}
              onClick={() => {
                if (prod.available && !prod.esgotado) handleVariation(prod);
                else if (prod.esgotado)
                  message.error(t("MODAL-VARIATIONS-LIVE.ProductSoldoff"));
                else
                  message.error(t("MODAL-VARIATIONS-LIVE.ProductUnavailable"));
              }}
              src={sourceImage(prod.thumbnail)}
              alt={prod.descricao}
            />
            {(!prod.available || prod.esgotado) && (
              <label
                style={{
                  left: idx % 2 === 0 && 173,
                  textTransform: "uppercase",
                }}
              >
                {prod.esgotado
                  ? t("MODAL-VARIATIONS-LIVE.ProductSoldoff")
                  : t("MODAL-VARIATIONS-LIVE.ProductUnavailable")}
              </label>
            )}
          </div>
        ))}
      </s.VariationsList>
    </Modal>
  );
};

export default ModalVariations;
