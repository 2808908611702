import React, { useContext, useEffect, useState } from "react";

import Video from "./video/video";
import Chat from "./chat/chat";
import ChatVertical from "./chat/chat-vertical";
import Cart from "./cart/cart";
import Products from "./products/products";
import Header from "./header/header";
import Orders from "./orders/orders";
import * as s from "./styles-live";
import {
  awinServices,
  customerService,
  firestore,
  googleTagManager,
} from "../../services";
import {
  GradesContext,
  ThemeContext,
  UserContext,
  useTabsNavigation,
} from "../../context";
import WarningOrientation from "./warning-orientation";
import { TabNavigation, TabNavigationVertical } from "../../components";
import Timer from "./timer";

const Live = ({ live, setLive }) => {
  const externalCheckout = live.checkout === "externo";
  const { tabActive } = useTabsNavigation();
  const { corPrimaria, corSecundaria } = useContext(ThemeContext);
  const { setUser } = useContext(UserContext);
  const { setUserGrades } = useContext(GradesContext);
  const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [started, setStarted] = useState(
    live.timeToStart === 0 && live.autoStart !== false
  );
  const [logged, setLogged] = useState(false);
  const phoneNumber = live.whatsappNumber?.replace(/\D/g, "");
  const isMobileWidth = window.innerWidth < 955;
  const whatsappIconStyle = {
    cursor: "pointer",
    position: "fixed",
    zIndex: isMobileWidth && "3",
    bottom: isMobileWidth ? (live.vertical ? "125px" : "110px") : "20px",
    right: isMobileWidth ? (live.vertical ? "-1px" : "10px") : "5px",
    height: isMobileWidth && "48px",
    width: isMobileWidth && "48px",
  };

  //login user with local storage data
  useEffect(() => {
    let testCustomer = localStorage.getItem("customer");
    if (testCustomer) testCustomer = JSON.parse(testCustomer);
    if (
      testCustomer &&
      (testCustomer.codigo ||
        (testCustomer.codigoIsEmail && testCustomer.email))
    ) {
      setUser(testCustomer);
      customerService.customerLogin(live.liveId, testCustomer);
    } else {
      var navigator_info = window.navigator;
      var screen_info = window.screen;
      var hash = navigator_info.mimeTypes.length;
      hash += navigator_info.userAgent.replace(/\D+/g, "");
      hash += navigator_info.plugins.length;
      hash += screen_info.height || "";
      hash += screen_info.width || "";
      hash += screen_info.pixelDepth || "";
      let newUser = { hash, anonimo: true };
      if (testCustomer?.name) newUser.name = testCustomer.name;
      setUser(newUser);
    }
  }, []);

  //Inicia o tracking awin
  useEffect(() => {
    if (live?.gtmld) googleTagManager.initGoogleTagManager(live.gtmld);
    if (live.awinAdvertiserId)
      awinServices.initMasterTag(live.awinAdvertiserId);
  }, [live]);

  useEffect(() => {
    if (live.autoStart === false) {
      let subscribe = firestore
        .collection("lives")
        .doc(live.liveId)
        .onSnapshot((snapshot) => {
          setLive((oldLive) => {
            let now = new Date().getTime();
            if (snapshot.data().dataInicio <= now) {
              setStarted(snapshot.data().started);
              oldLive.dataInicio = snapshot.data().dataInicio;
            }
            return oldLive;
          });
        });
      return () => subscribe();
    }
  }, []);

  //get grades from user
  useEffect(() => {
    let subscribe = firestore
      .collection(`contractor/${live.owner}/grades`)
      .onSnapshot((snapshot) => {
        setUserGrades(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            gradeId: doc.id,
          }))
        );
      });
    return () => subscribe();
  }, []);

  if (live.listaAudiencia) {
    if (!started || !logged)
      return (
        <Timer
          live={live}
          started={started}
          setStarted={setStarted}
          logged={logged}
          setLogged={setLogged}
        />
      );
  } else {
    if (!started)
      return (
        <Timer
          live={live}
          started={started}
          setStarted={setStarted}
          logged={logged}
          setLogged={setLogged}
        />
      );
  }

  return (
    <s.LiveContainer
      className="fade-in"
      vertical={live && live.vertical && mobile}
      corPrimaria={corPrimaria}
      corSecundaria={corSecundaria}
    >
      {live?.vertical && mobile && <WarningOrientation />}
      <Header live={live} />
      <s.Content>
        <s.Container vertical={live.vertical}>
          <Video live={live} />
          <Products live={live} />
          {!externalCheckout && <Cart live={live} />}
          {!externalCheckout && <Orders live={live} />}
        </s.Container>
        {window.innerWidth < 955 ? (
          live.vertical ? (
            tabActive === "chat" && <ChatVertical live={live} />
          ) : (
            tabActive === "chat" && <Chat live={live} />
          )
        ) : (
          <Chat live={live} />
        )}
      </s.Content>
      {live?.vertical && window.innerWidth < 955 ? (
        <TabNavigationVertical live={live} />
      ) : (
        <TabNavigation live={live} />
      )}
      {live.whatsappContact && live.whatsappNumber !== undefined && (
        <img
          src={"/assets/icons/whatsapp-96.svg"}
          alt="logo-WhatsApp"
          style={whatsappIconStyle}
          onClick={() => {
            const url = mobile
              ? `https://wa.me/${phoneNumber}`
              : `https://web.whatsapp.com/send?phone=${phoneNumber}`;

            window.open(url, "_blank");
          }}
        />
      )}
    </s.LiveContainer>
  );
};

export default Live;
