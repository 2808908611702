import React, { useState, useCallback, useEffect } from "react";
import { Modal, Button, message, Input, Table } from "antd";
import { useTranslation } from "react-i18next";
import { FileTextOutlined } from "@ant-design/icons";
import { csvToJson, formatCash } from "../../../../utils";

const PriceTableModal = ({
  visible,
  onOk,
  onCancel,
  tabelaPreco,
  unidadeMonetaria,
}) => {
  const { t } = useTranslation();
  const [priceTable, setPriceTable] = useState(tabelaPreco || []);
  const [filteredPriceTable, setFilteredPriceTable] = useState(false);
  const INPUT_ID = "price-table-modal-csv-input";

  const handleUploadCsv = useCallback(() => {
    document.getElementById(INPUT_ID).click();
  }, [INPUT_ID]);

  const handleInputChange = useCallback(
    ({ target: { files } }) => {
      let fileCsv = files[0];
      if (fileCsv) {
        let reader = new FileReader();
        reader.readAsText(fileCsv);
        reader.onload = (e) => {
          let csvConverted = csvToJson(e.target.result);
          setPriceTable(
            csvConverted.map((line) => ({
              ...line,
              preco: Number(line.preco) * 100,
            }))
          );
        };
        reader.onerror = () =>
          message.error(t("PRODUCTS-TAB-PRICE-TABLE-MODAL.ErrorReadFile"));
      }
    },
    [setPriceTable]
  );

  useEffect(() => {
    if (!visible) setPriceTable([]);
  }, [visible]);

  useEffect(() => setPriceTable(tabelaPreco), [tabelaPreco]);

  const columns = [
    {
      title: "SKU",
      dataIndex: "sku",
    },
    {
      title: "Tabela",
      dataIndex: "tabela_preco",
    },
    {
      title: "Preço",
      dataIndex: "preco",
      render: (preco) => (
        <label style={{ whiteSpace: "nowrap" }}>
          {formatCash(Number(preco / 100), false, unidadeMonetaria)}
        </label>
      ),
    },
  ];

  return (
    <Modal
      visible={visible}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "30px",
          }}
        >
          {t("PRODUCTS-TAB-PRICE-TABLE-MODAL.PriceTable")}
          <Button size="small" href={`/Modelo - Tabela de preços.csv`} download>
            <FileTextOutlined />
            {t("PRODUCTS-TAB-PRICE-TABLE-MODAL.DownloadModel")}
          </Button>
        </div>
      }
      onOk={() => onOk(priceTable)}
      onCancel={onCancel}
      okText={t("PRODUCTS-TAB-PRICE-TABLE-MODAL.Ready")}
      cancelText={t("PRODUCTS-TAB-PRICE-TABLE-MODAL.Back")}
      bodyStyle={{ padding: 0 }}
      okButtonProps={{ disabled: !priceTable }}
    >
      <div style={{ padding: "20px 20px 0 20px" }}>
        {t("PRODUCTS-TAB-PRICE-TABLE-MODAL.DownlaodDescription")}
        {visible && (
          <input
            type="file"
            onChange={handleInputChange}
            style={{ display: "none" }}
            id={INPUT_ID}
            accept=".csv"
          />
        )}
        <Button
          onClick={handleUploadCsv}
          style={{ display: "grid", marginTop: "10px" }}
        >
          {t("PRODUCTS-TAB-PRICE-TABLE-MODAL.Upload")}
        </Button>
        <div
          style={{
            color: "gray",
            height: "50px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {filteredPriceTable
            ? `${filteredPriceTable.length} ${t(
                "PRODUCTS-TAB-PRICE-TABLE-MODAL.ResultsFind"
              )}`
            : `${t("PRODUCTS-TAB-PRICE-TABLE-MODAL.PriceList")}: ${
                priceTable?.length || 0
              }`}
          <Input.Search
            placeholder={t("PRODUCTS-TAB-PRICE-TABLE-MODAL.SearchSKUOrTable")}
            style={{ maxWidth: "280px", marginLeft: "auto" }}
            onSearch={(value) =>
              setFilteredPriceTable(
                priceTable.filter(({ sku, tabela_preco }) =>
                  sku.toLowerCase().includes(value.toLowerCase()) ||
                  tabela_preco.toLowerCase().includes(value.toLowerCase())
                    ? sku
                    : false
                )
              )
            }
            onChange={({ target: { value } }) => {
              if (!value || value === "") setFilteredPriceTable(false);
            }}
            enterButton
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredPriceTable || priceTable}
        pagination={false}
        scroll={{ y: 240 }}
        size="middle"
        rowKey={(props) => Object.values(props).join("-")}
        pagination={{
          total: filteredPriceTable
            ? filteredPriceTable.length
            : priceTable.length,
        }}
      />
    </Modal>
  );
};

export default PriceTableModal;
