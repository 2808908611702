import React, { createContext } from "react";
import useAddProduct from "./hooks/use-add-product";

const AddProductContext = createContext({
  addProduct: false,
  setAddProduct: () => {},
  cartProduct: false,
  setCartProduct: () => {},
});

const AddProductProvider = ({ children }) => {
  const { addProduct, setAddProduct, cartProduct, setCartProduct } =
    useAddProduct();

  return (
    <AddProductContext.Provider
      value={{
        addProduct,
        setAddProduct,
        cartProduct,
        setCartProduct,
      }}
    >
      {children}
    </AddProductContext.Provider>
  );
};

export { AddProductContext, AddProductProvider };
