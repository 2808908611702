import { useState } from "react";

const useShowProduct = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  return { show, setShow, show2, setShow2 };
};

export default useShowProduct;
