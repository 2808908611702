import React, { useEffect, useCallback, useState } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import Hls from "hls.js";
import styled from "styled-components";

const TabsVideo = ({
  url,
  aoVivo,
  videoId = "tabs-video",
  style,
  onVideoLoad,
}) => {
  const { t } = useTranslation();
  var videoHtml = document.getElementById(videoId);
  let VOD = url && url.search("vod-4show") > 0;
  const [status, setStatus] = useState("connecting");

  const playAndPause = useCallback(() => {
    if (videoHtml) {
      if (videoHtml.paused) videoHtml.play();
      else videoHtml.pause();
    }
  }, [videoHtml]);

  const playHls = useCallback(() => {
    if (Hls.isSupported()) {
      var hls = new Hls();
      hls.attachMedia(videoHtml);
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        hls.loadSource(url);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          hls.currentLevel = 1;
          playAndPause();
          setStatus("connected");
          if (onVideoLoad) onVideoLoad();
        });
      });
      hls.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          setStatus("reconnecting");
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              message.error(t("TABS-VIDEO.ConnectionError"));
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              message.error(t("TABS-VIDEO.MediaError"));
              hls.recoverMediaError();
              break;
            default:
              message.error(t("TABS-VIDEO.UnknownError"));
              hls.destroy();
              setTimeout(() => {
                playHls();
              }, 3000);
              break;
          }
        }
      });
    } else if (
      videoHtml &&
      videoHtml.canPlayType("application/vnd.apple.mpegurl")
    ) {
      videoHtml.src = url;
      videoHtml.addEventListener("loadedmetadata", function () {
        playAndPause();
        setStatus("connected");
        if (onVideoLoad) onVideoLoad();
      });
    }
  }, [playAndPause, videoHtml, url]);

  useEffect(() => {
    if (url && videoHtml) {
      if (aoVivo || VOD) playHls();
      else {
        videoHtml.src = url;
        if (onVideoLoad) onVideoLoad();
      }
    }
  }, [url, playHls, videoHtml]);

  const streamStatus = {
    connected: t("TABS-VIDEO.Connected"),
    connecting: t("TABS-VIDEO.Connecting"),
    reconnecting: t("TABS-VIDEO.Reconnecting"),
  };

  return (
    <>
      {aoVivo && (
        <TagAoVivo loading={status !== "connected"}>
          {streamStatus[status]}
        </TagAoVivo>
      )}
      <video id={videoId} controls style={{ ...style }} />
    </>
  );
};

export default TabsVideo;

export const TagAoVivo = styled.div`
  color: white;
  background: ${({ loading }) => (loading ? "gray" : "red")};
  padding: 5px 15px;
  border-radius: 12px;
  position: absolute;
  z-index: 20;
  top: 10px;
  left: 10px;
  font-size: 10px;
  text-transform: uppercase;
`;
