import React, { createContext, useContext, useEffect } from "react";
import usePayment from "./hooks/use-payment";
import { UserContext } from "./";
const PaymentContext = createContext({
  services: {
    pagarme: [],
  },
  setServices: () => {},
  addPagarmeRecipient: () => {},
  resetPayments: () => {},
});

const PaymentProvider = ({ children }) => {
  const { user } = useContext(UserContext);

  const {
    services,
    setServices,
    addPagarmeRecipient,
    resetPayments,
    pagarmeFees,
    getPagarmeFees,
  } = usePayment();
  
  useEffect(() => {
    if (Object.keys(pagarmeFees).length === 0 && !!user) getPagarmeFees();
  }, [user]);

  return (
    <PaymentContext.Provider
      value={{
        services,
        setServices,
        pagarmeFees,
        addPagarmeRecipient,
        resetPayments,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export { PaymentContext, PaymentProvider };
