import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  Button,
  Card,
  notification,
  Input,
  Select,
  Checkbox,
  Tag,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  SaveOutlined,
  MailOutlined,
  PlusOutlined,
  CloudServerOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { managerService } from "../../../../services";
import { UserContext } from "../../../../context";
import ModalEmailLogo from "./modal-email-logo";
import { validateEmail } from "../../../../utils";
import * as s from "./styled-email-config";

const EmailConfigTab = ({ live }) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const { Search } = Input;
  const { docId } = live;
  const { user } = useContext(UserContext);

  // ------ Personalizar o HTML do e-mail ------
  const [corPrimaria, setCorPrimaria] = useState(
    live.customEmailLayout?.corPrimaria
      ? `#${live.customEmailLayout.corPrimaria}`
      : user?.whiteLabel?.corPrimaria
  );
  const [name, setName] = useState(live.customEmailLayout?.name || "");
  const [website, setWebsite] = useState(live.customEmailLayout?.website || "");
  const [email, setEmail] = useState(live.customEmailLayout?.email || "");
  const [logo, setLogo] = useState(live.customEmailLayout?.logo || "");
  const [logoName, setLogoName] = useState(
    live.customEmailLayout?.logoName || ""
  );
  const [openLogoModal, setOpenLogoModal] = useState(false);
  const [customEmailLayout, setCustomEmailLayout] = useState({});

  // ------ Disparo do e-mail com servidor próprio ------
  const listOfServices = ["Gmail"];
  const [availableServices, setAvailableServices] = useState(false);
  const [selectedService, setSelectedService] = useState(""); //required
  const [keysBody, setKeysBody] = useState({});
  const [receiver, setReceiver] = useState(live?.receiver || "");

  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingTest, setLoadingTest] = useState(false);
  const [saving, setSaving] = useState(false);

  // ------ Configurar email contractor ------
  const [contractorEmailList, setContractorEmailList] = useState([]);
  const [contractorEmail, setContractorEmail] = useState("");
  const [loadingSaveContractorList, setLoadingSaveContractorList] =
    useState(false);
  const [loadingDeleteContractorList, setLoadingDeleteContractorList] =
    useState(false);
  const [hasContractorList, setHasContractorList] = useState(false);
  const [invalidValues, setInvalidValues] = useState([]);

  useEffect(() => {
    managerService.getContractorEmailList(docId).then((resp) => {
      if (resp.length > 0) {
        setContractorEmailList(resp);
        setHasContractorList(true);
      }
    });
  }, []);

  const handleGetEmailServices = useCallback(() => {
    setLoadingServices(true);
    managerService
      .getEmailServices()
      .then((resp) => {
        setAvailableServices(resp.services);
      })
      .catch(() =>
        notification.error(t("EMAIL-CONFIG-TAB.EmailServicesErrorDescription"))
      )
      .finally(() => setLoadingServices(false));
  }, [docId, setAvailableServices]);

  useEffect(() => {
    handleGetEmailServices();
  }, [handleGetEmailServices]);

  const findServiceToSelect = (s) => {
    return availableServices[
      availableServices.findIndex((x) => x.service === s)
    ];
  };

  useEffect(() => {
    if (
      live.customEmailServer &&
      Object.entries(live?.customEmailServer).length > 0 &&
      availableServices
    ) {
      let { service, email, password, host, port, secure } =
        live.customEmailServer;
      if (listOfServices.includes(service))
        setSelectedService(findServiceToSelect(service));
      else setSelectedService(findServiceToSelect("Outro"));
      setKeysBody({ service, email, password, host, port, secure });
    }
  }, [availableServices]);

  const handleTestEmailServer = () => {
    setLoadingTest(true);
    let body = {
      customEmailServer: {
        ...keysBody,
      },
      receiver,
    };
    managerService
      .testEmailServer(body)
      .then(() =>
        notification.success({
          message: t("EMAIL-CONFIG-TAB.Success"),
          description: t("EMAIL-CONFIG-TAB.IntegrationTestSuccessDescription"),
        })
      )
      .catch(() =>
        notification.error({
          message: t("EMAIL-CONFIG-TAB.GeneralError"),
          description: t("EMAIL-CONFIG-TAB.IntegrationTestErrorDescription"),
        })
      )
      .finally(() => setLoadingTest(false));
  };

  const handleSaveEmailConfig = () => {
    setSaving(true);
    let newLogo = logo;
    if (logoName) newLogo = newLogo.replace(/^data:image\/[a-z]+;base64,/, "");
    let body = {
      customEmailLayout: {
        corPrimaria: corPrimaria.replace("#", ""),
        name,
        website,
        email,
        logo: newLogo,
        logoName,
      },
    };
    if (Object.entries(keysBody).length > 0)
      body = { ...body, customEmailServer: { ...keysBody } };

    managerService
      .saveEmailTrigger(docId, body)
      .then(() =>
        notification.success({
          message: t("EMAIL-CONFIG-TAB.Success"),
          description: t("EMAIL-CONFIG-TAB.ConfigurationSuccessDescription"),
        })
      )
      .catch(() =>
        notification.error({
          message: t("EMAIL-CONFIG-TAB.GeneralError"),
          description: t("EMAIL-CONFIG-TAB.ConfigurationErrorDescription"),
        })
      )
      .finally(() => setSaving(false));
  };

  const findService = (service) => {
    let serviceTmp =
      availableServices[
        availableServices.findIndex((x) => x.service === service)
      ];
    setKeysBody({ service: serviceTmp.service });
    return serviceTmp;
  };

  const isKeyRequired = (key) => ["email", "password", "service"].includes(key);

  const isEmail = (key) => key === "email";
  const isPassword = (key) => key === "password";

  const isSupportedService = (key) =>
    key === "service" && listOfServices.includes(selectedService.service);

  const renderInputByType = ({ key, type }) => {
    if (type === "boolean")
      return (
        <s.InputWrapper key={key}>
          <label>
            {key} {isKeyRequired(key) && "*"}
          </label>
          <Checkbox
            checked={keysBody[key]}
            onChange={() =>
              setKeysBody({
                ...keysBody,
                [key]:
                  typeof keysBody[key] === "boolean" ? !keysBody[key] : true,
              })
            }
          />
        </s.InputWrapper>
      );
    return (
      <s.InputWrapper key={key}>
        <label>
          {key} {isKeyRequired(key) && "*"}
        </label>
        <Input
          type={isEmail(key) ? "email" : isPassword(key) ? "password" : type}
          value={keysBody[key]}
          onChange={({ target: t }) => {
            if (invalidValues.includes(key))
              setInvalidValues(invalidValues.filter((x) => x !== key));
            setKeysBody({ ...keysBody, [key]: t.value });
          }}
          required={saving}
          disabled={saving || isSupportedService(key)}
          style={{
            marginTop: "5px",
            border: invalidValues.includes(key) && "1px solid red",
          }}
          onBlur={() =>
            (key === "email" || key === "password") && checkInvalidValues(key)
          }
        />
        {invalidValues.includes(key) && (
          <s.InvalidLabel className="fade-in">
            {t("EMAIL-CONFIG-TAB.FieldRequired")} {key}
          </s.InvalidLabel>
        )}
      </s.InputWrapper>
    );
  };

  const handleSaveContractorList = () => {
    setLoadingSaveContractorList(true);
    managerService
      .updateContractorEmailList(docId, contractorEmailList)
      .then(() => {
        setHasContractorList(true);
        notification.success({
          key: "aviso",
          message: t("EMAIL-CONFIG-TAB.ChangesSuccess"),
          description: t("EMAIL-CONFIG-TAB.ContractorListSuccessDescription"),
          onClick: () => notification.close("aviso"),
          duration: 10,
        });
      })
      .catch(() =>
        notification.error({
          key: "aviso",
          message: t("EMAIL-CONFIG-TAB.ChangesError"),
          description: t("EMAIL-CONFIG-TAB.GeneralErrorDescription"),
          onClick: () => notification.close("aviso"),
          duration: 10,
        })
      )
      .finally(() => setLoadingSaveContractorList(false));
  };

  const handleDeleteContractorList = () => {
    setLoadingDeleteContractorList(true);
    managerService
      .deleteContractorEmailList(docId)
      .then(() => {
        setContractorEmailList([]);
        setHasContractorList(false);
      })
      .catch(() =>
        notification.error({
          key: "aviso",
          message: t("EMAIL-CONFIG-TAB.ChangesError"),
          description: t("EMAIL-CONFIG-TAB.GeneralErrorDescription"),
          onClick: () => notification.close("aviso"),
          duration: 10,
        })
      )
      .finally(() => setLoadingDeleteContractorList(false));
  };

  const checkInvalidValues = (type) => {
    if (type === "email" && !keysBody?.email)
      setInvalidValues([...invalidValues, type]);
    else if (type === "password" && !keysBody?.password)
      setInvalidValues([...invalidValues, type]);
  };

  return (
    <s.Container className="fade-in">
      <Card
        title={
          <>
            <HighlightOutlined style={{ marginRight: "10px" }} />
            {t("EMAIL-CONFIG-TAB.EmailLayoutConfiguration")}
          </>
        }
        style={{ width: "735px", margin: "5px" }}
        bodyStyle={{ display: "flex" }}
        className="shadow"
      >
        <ModalEmailLogo
          open={openLogoModal}
          setOpen={setOpenLogoModal}
          logo={logo}
          setLogo={setLogo}
          customEmailLayout={customEmailLayout}
          setCustomEmailLayout={setCustomEmailLayout}
          setLogoName={setLogoName}
        />
        <s.Wrapper style={{ flexWrap: "wrap" }}>
          <div>
            <p>{t("EMAIL-CONFIG-TAB.EmailLayoutConfigurationDescription")}</p>

            <s.InputWrapper>
              <label>
                {t("EMAIL-CONFIG-TAB.MainColor")}
                <span> - {t("EMAIL-CONFIG-TAB.MainColorDescription")} </span>
              </label>
              <Input
                style={{ marginTop: "5px" }}
                type="color"
                value={corPrimaria}
                onChange={({ target: t }) => setCorPrimaria(t.value)}
                disabled={saving}
              />
            </s.InputWrapper>

            <s.InputWrapper>
              <input
                id="thumbnail-cr"
                type="file"
                accept="image/*"
                // onChange={onSelectFile}
                style={{ display: "none" }}
              />
              <label>{t("EMAIL-CONFIG-TAB.Logo")}</label>
              {logo && <img src={logo} />}
              <Button
                style={{ maxWidth: 200, margin: "0 auto" }}
                type="primary"
                onClick={() => setOpenLogoModal(true)}
              >
                <p> {t("EMAIL-CONFIG-TAB.AlterLogo")} </p>
              </Button>
            </s.InputWrapper>

            <s.InputWrapper>
              <label>{t("EMAIL-CONFIG-TAB.Name")}</label>
              <Input
                style={{ marginTop: "5px" }}
                value={name}
                onChange={({ target: t }) => setName(t.value)}
                disabled={saving}
                maxLength={65}
              />
            </s.InputWrapper>

            <s.InputWrapper>
              <label>
                {t("EMAIL-CONFIG-TAB.Website")} &nbsp;
                <span>- {t("EMAIL-CONFIG-TAB.WebsiteDescription")}</span>
              </label>
              <Input
                style={{ marginTop: "5px" }}
                value={website}
                onChange={({ target: t }) => setWebsite(t.value)}
                disabled={saving}
              />
            </s.InputWrapper>

            <s.InputWrapper>
              <label>
                {t("EMAIL-CONFIG-TAB.Email")} &nbsp;
                <span>- {t("EMAIL-CONFIG-TAB.EmailDescription")}</span>
              </label>
              <Input
                style={{ marginTop: "5px" }}
                value={email}
                onChange={({ target: t }) => setEmail(t.value)}
                disabled={saving}
              />
            </s.InputWrapper>
          </div>

          <div>
            <s.EmailPreviewContainer background={corPrimaria}>
              <div className="container">
                <div className="header">
                  <img
                    onClick={() => website && window.open(website)}
                    src={
                      logo ||
                      "https://i0.wp.com/4show.live/wp-content/uploads/2020/11/4show_marca_branco.png"
                    }
                  />
                </div>

                <div className="order-received">
                  <span>
                    {t("EMAIL-CONFIG-TAB.AutomaticEmail")}{" "}
                    <label> {name || "4Show"} </label>
                  </span>
                </div>
                <div className="order-info">
                  <div className="block" />
                  <div className="block" />
                </div>
                <div className="order-total">
                  <div className="block" />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="line" />
                    <div className="line" />
                    <div className="line" />
                  </div>
                </div>

                <div className="footer">
                  <img
                    src={
                      logo ||
                      "https://i0.wp.com/4show.live/wp-content/uploads/2020/11/4show_marca_branco.png"
                    }
                  />
                  <label>{email}</label>
                </div>
              </div>
            </s.EmailPreviewContainer>
          </div>
        </s.Wrapper>
      </Card>
      <Card
        title={
          <>
            <CloudServerOutlined style={{ marginRight: "10px" }} />
            {t("EMAIL-CONFIG-TAB.EmailServerConfiguration")}
          </>
        }
        style={{ width: "735px", margin: "5px" }}
        bodyStyle={{ display: "flex" }}
        className="shadow"
      >
        <s.Wrapper>
          <p>{t("EMAIL-CONFIG-TAB.EmailServerConfigurationDescription")}</p>

          <label>{t("EMAIL-CONFIG-TAB.ServiceType")}</label>
          <div style={{ display: "flex" }}>
            <Select
              value={selectedService?.service}
              placeholder={t("EMAIL-CONFIG-TAB.SelectService")}
              onChange={(item) => {
                setSelectedService(findService(item));
              }}
              style={{ width: "90%" }}
              disabled={loadingServices}
            >
              {availableServices?.length > 0 &&
                availableServices.map((service) => (
                  <Option key={service.service} value={service.service}>
                    {service.service}
                  </Option>
                ))}
            </Select>
            <Button
              disabled={!live.customEmailServer || !selectedService}
              style={{ marginLeft: 20 }}
              onClick={() => {
                message.success(t("EMAIL-CONFIG-TAB.RememberClickSave"));
                setKeysBody({});
                setSelectedService("");
              }}
            >
              {t("EMAIL-CONFIG-TAB.RemoveCustomEmailServer")}
            </Button>
          </div>
          {selectedService?.message && (
            <s.Message>
              <label className="important">
                {t("EMAIL-CONFIG-TAB.Important")}{" "}
                <span>- {selectedService.message}</span>
              </label>
            </s.Message>
          )}

          {selectedService?.keys?.length > 0 && (
            <>
              <s.InformativeWrapper>
                <label>{t("EMAIL-CONFIG-TAB.FulfillKeys")}</label>
                <label className="required">
                  {t("EMAIL-CONFIG-TAB.RequiredKeys")}
                </label>
              </s.InformativeWrapper>

              {selectedService.keys.map((service) =>
                renderInputByType(service)
              )}
            </>
          )}

          {selectedService && (
            <s.TestServiceWrapper>
              <label>{t("EMAIL-CONFIG-TAB.TestCustomEmailServer")}</label>
              <div>
                <div
                  style={{
                    display: "flex",
                    width: "60%",
                    alignItems: "center",
                  }}
                >
                  <label style={{ marginRight: 20, width: 110 }}>
                    {t("EMAIL-CONFIG-TAB.Receiver")} *
                  </label>
                  <Input
                    value={receiver}
                    onChange={({ target: t }) => setReceiver(t.value)}
                    required
                    style={{ marginTop: "5px" }}
                  />
                </div>
                <Button
                  type="primary"
                  style={{ maxWidth: 200, margin: "0 20px" }}
                  disabled={
                    !keysBody?.service ||
                    !keysBody?.email ||
                    !keysBody?.password ||
                    !receiver
                  }
                  onClick={() => handleTestEmailServer()}
                  loading={loadingTest}
                >
                  {t("EMAIL-CONFIG-TAB.TestService")}
                </Button>
              </div>
            </s.TestServiceWrapper>
          )}
        </s.Wrapper>
      </Card>
      <Card
        title={
          <>
            <MailOutlined style={{ marginRight: "10px" }} />
            {t("EMAIL-CONFIG-TAB.ReceiversEmailList")}
          </>
        }
        style={{ width: "735px", margin: "5px" }}
        className="shadow"
      >
        <label>{t("EMAIL-CONFIG-TAB.HowItWorks")}</label>
        <legend style={{ fontSize: "12px", color: "gray" }}>
          {t("EMAIL-CONFIG-TAB.ReceiverEmailDescription")}
        </legend>
        <legend style={{ fontSize: "12px", color: "gray" }}>
          <b>{t("EMAIL-CONFIG-TAB.Observation")}:</b>{" "}
          {t("EMAIL-CONFIG-TAB.ObservationDescription")}
        </legend>
        <div style={{ display: "flex" }}>
          <Search
            value={contractorEmail}
            style={{ marginBottom: 10 }}
            enterButton={<PlusOutlined />}
            onChange={({ target: t }) => setContractorEmail(t.value)}
            onSearch={(item) => {
              if (validateEmail(contractorEmail)) {
                setContractorEmailList([...contractorEmailList, item]);
                setContractorEmail("");
              }
            }}
          />
          <Button
            style={{ margin: "0 10px" }}
            disabled={contractorEmailList.length === 0}
            onClick={() => handleSaveContractorList()}
            loading={loadingSaveContractorList}
          >
            {t("EMAIL-CONFIG-TAB.SaveList")}
          </Button>
          <Button
            disabled={!hasContractorList}
            onClick={() => handleDeleteContractorList()}
            loading={loadingDeleteContractorList}
          >
            {t("EMAIL-CONFIG-TAB.DeleteList")}
          </Button>
        </div>
        {contractorEmail && !validateEmail(contractorEmail) && (
          <label style={{ color: "red", fontWeight: "bold" }}>
            {t("EMAIL-CONFIG-TAB.Attention")}! &nbsp;
            <span style={{ fontWeight: "normal", color: "grey" }}>
              {t("EMAIL-CONFIG-TAB.TypeValidEmail")}
            </span>
          </label>
        )}
        {contractorEmailList.length > 0 && (
          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 10 }}
          >
            <label>{t("EMAIL-CONFIG-TAB.ReceiversEmailList")}</label>
            <Scrollbars style={{ height: 300, width: "100%", marginTop: 5 }}>
              {contractorEmailList.map((email) => (
                <Tag
                  key={email}
                  closable
                  onClose={() => {
                    setContractorEmailList(
                      contractorEmailList.filter((e) => e !== email)
                    );
                  }}
                  style={{ margin: "0 5px 5px 0" }}
                >
                  {email}
                </Tag>
              ))}
            </Scrollbars>
          </div>
        )}
      </Card>

      <s.ButtonContainer>
        <Button
          type="primary"
          htmlType="submit"
          style={{ margin: "10px 0" }}
          onClick={handleSaveEmailConfig}
          loading={saving}
          disabled={
            saving ||
            (selectedService &&
              (!keysBody.service || !keysBody.email || !keysBody.password))
          }
          loading={saving}
        >
          {saving ? t("EMAIL-CONFIG-TAB.Saving") : t("EMAIL-CONFIG-TAB.Save")}
          {!saving ? <SaveOutlined /> : <></>}
        </Button>
      </s.ButtonContainer>
    </s.Container>
  );
};

export default EmailConfigTab;
