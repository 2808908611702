import styled from "styled-components";

//---------------------------------------------------------------
//                         Chat Vertical
//---------------------------------------------------------------
export const ChatVertical = styled.div`
  width: 100%;
  transition: all 0.2s;
  /* height: ${({ verticalChatHeight }) =>
    verticalChatHeight ? verticalChatHeight : "150"}px; */
  position: fixed;
  bottom: -1px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  display: flex;
  align-items: center;
  height: 265px;

  .form-send-msg-chat {
    * {
      border-radius: 0;
      outline-color: ${({ corSecundaria: c }) => c} !important;
    }
    input {
      background: rgba(0, 0, 0, 0.6);
      color: white;
    }
  }
  .form-send-msg-chat.animated-linear {
    color: white;
    left: 46px;
    position: absolute;
  }
  .toggle-chat-visibility-icon {
    position: absolute;
    color: white;
    background-color: ${({ corSecundaria }) =>
      corSecundaria ? corSecundaria : ""};
    padding: 12px 15px;
    z-index: 3;
    font-size: 16px;
    @media (min-width: 955px) {
      display: none;
    }
  }
`;
//---------------------------------------------------------------
//                         Chat Horizontal
//---------------------------------------------------------------
export const InputName = styled.div`
  position: absolute;
  z-index: 11;
  height: 100%;
  width: 100%;
  top: 0;
  right: ${({ open }) => (open ? 0 : "-320px")};
  background: rgba(0, 0, 0, 0.4);
  border-bottom-left-radius: 12px !important;
  border-top-left-radius: 12px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  section {
    padding: 20px;
    background: white;
    -webkit-transition: right 0.4s ease-in-out;
    transition: right 0.4s ease-in-out;
    border-bottom-left-radius: 12px !important;

    input {
      border-bottom-left-radius: 2px;
    }
  }
`;

export const ChatContainer = styled.div`
  position: relative;
  margin: 20px 0px 20px 20px;

  width: 100%;
  max-width: 320px;
  min-width: 320px;
  max-height: 600px;
  height: 35vw;

  padding-bottom: 40px;
  z-index: 2;
  span {
    background: transparent;
  }

  @media (min-width: 955px) {
    border-bottom-left-radius: 12px !important;
    border-top-left-radius: 12px !important;
    input {
      border-bottom-left-radius: 12px;
    }
  }

  #scrollbars-chat-horizontal {
    ${({ mobileKeyboardIsOpen: ocult }) => ocult && `display: none;`}
  }

  .form-send-msg-chat {
    position: absolute;
    bottom: 0;
    z-index: 10;
    @media (max-width: 955px) {
      bottom: 0px;
    }
  }
  @media (max-width: 955px) {
    border-radius: 10px 10px 0px 0px;
    margin: 0;
    min-width: 100%;
    max-width: 100%;
    height: 22vh;
    @media (min-height: 780px) {
      height: 30vh;
    }
    position: fixed;
    ${({ mobileKeyboardIsOpen: ocult }) =>
      ocult ? `bottom: 0px;box-shadow: none !important;` : `bottom: 9vh;`}
    box-shadow: ${({ vertical: v }) =>
      !v ? "0px -2px 10px rgba(0, 0, 0, 0.1)" : "none"};
  }
`;

export const ChatFixedMessage = styled.p`
  position: absolute;
  z-index: 10;
  background: ${({ corPrimaria }) => corPrimaria};
  color: #fff;
  border-bottom: 0.5px solid #ccc;
  padding: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  @media (max-width: 955px) {
    width: 100%;
    text-align: center;
    ${({ verticalChat }) =>
      verticalChat && "padding-left: 70px; text-align: left !important"}
  }
`;

export const ChatHeader = styled.span`
  width: 100%;
  height: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #757575;
  background: white !important;
  @media (min-width: 955px) {
    border-top-left-radius: 12px !important;
  }
  @media (max-width: 955px) {
    border-radius: 10px 10px 0px 0px;
    ${({ mobileKeyboardIsOpen: ocult }) => ocult && `display: none;`}
  }
`;

export const Msg = styled.div`
  flex: 1;
  display: flex;
  padding: ${({ vertical: v }) => (v ? "5px" : "0px 20px")};
  width: ${({ vertical: v }) => v && "fit-content"};

  div {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    background: ${({ vertical: v }) => v && "rgba(0, 0, 0, 0.2)"};
    border-radius: ${({ vertical: v }) => v && "10px"};
    padding: ${({ vertical: v }) => v && "0 10px"};
    .msg-name {
      font-size: 13px;
      @media (max-width: 955px) {
        font-size: 12px;
      }
      font-weight: 500;
      color: ${({ colorByName }) => colorByName};
      text-transform: capitalize;
      margin: 5px 5px 5px 0;
    }

    .msg-content {
      font-size: 13px;
      @media (max-width: 955px) {
        font-size: 12px;
      }
      display: flex;
      background: ${({ vertical: v }) => !v && "white"};
      font-weight: normal;
      color: ${({ vertical: v }) => (v ? "white" : "black")};

      p {
        margin-bottom: 0px;
        a {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 220px;
        }
      }
    }
  }
`;
