import TagManager from "react-gtm-module";
const currencyCode = "BRL";

const initGoogleTagManager = (gtmId) => {
  const tagManagerArgs = { gtmId };
  TagManager.initialize(tagManagerArgs);
};

const clickProduct = (product, from = "Não informado") => {
  let dataLayer = window.dataLayer; //Google Tag Manager
  dataLayer.push({ ecommerce: null });
  if (dataLayer) {
    dataLayer.push({
      event: "productClick",
      ecommerce: {
        currencyCode,
        click: {
          actionField: { list: from },
          products: [
            {
              ...product,
              id: product.docId,
              name: product.descricao,
              price: product.preco,
            },
          ],
        },
      },
    });
  }
};

const addProductToCart = (product, from = "Não informado") => {
  let dataLayer = window.dataLayer; //Google Tag Manager
  dataLayer.push({ ecommerce: null });
  if (dataLayer) {
    dataLayer.push({
      event: "addToCart",
      ecommerce: {
        currencyCode,
        add: {
          actionField: { list: from },
          products: [
            {
              ...product,
              id: product.docId,
              name: product.descricao,
              price: product.preco,
            },
          ],
        },
      },
    });
  }
};

const removeProductFromCart = (product, from = "Não informado") => {
  let dataLayer = window.dataLayer; //Google Tag Manager
  dataLayer.push({ ecommerce: null });
  if (dataLayer) {
    dataLayer.push({
      event: "removeFromCart",
      ecommerce: {
        currencyCode,
        remove: {
          actionField: { list: from },
          products: [
            {
              ...product,
              id: product.docId,
              name: product.descricao,
              price: product.preco,
            },
          ],
        },
      },
    });
  }
};

const editProduct = (product, from = "Não informado") => {
  let dataLayer = window.dataLayer; //Google Tag Manager
  dataLayer.push({ ecommerce: null });
  if (dataLayer) {
    dataLayer.push({
      event: "editProduct",
      ecommerce: {
        currencyCode,
        add: {
          actionField: { list: from },
          products: [
            {
              ...product,
              id: product.docId,
              name: product.descricao,
              price: product.preco,
            },
          ],
        },
      },
    });
  }
};

const checkoutPushStep = (productsFromCart, step) => {
  let dataLayer = window.dataLayer; //Google Tag Manager
  dataLayer.push({ ecommerce: null });
  if (dataLayer) {
    dataLayer.push({
      event: "checkout",
      ecommerce: {
        currencyCode,
        actionField: { step },
        checkout: {
          products: [
            productsFromCart.map((product) => ({
              ...product,
              id: product.docId,
              name: product.descricao,
              price: product.preco,
            })),
          ],
        },
      },
    });
  }
};

const purchase = (orderId, products, step) => {
  let dataLayer = window.dataLayer; //Google Tag Manager
  dataLayer.push({ ecommerce: null });
  if (dataLayer) {
    dataLayer.push({
      event: "checkout",
      ecommerce: {
        currencyCode,
        actionField: { step },
        orderId,
        purchase: {
          products,
        },
      },
    });
  }
};

const userRegister = (user) => {
  let dataLayer = window.dataLayer; //Google Tag Manager
  dataLayer.push({ ecommerce: null });
  if (dataLayer) {
    dataLayer.push({
      event: "userRegister",
      ecommerce: {
        currencyCode,
        user: {
          name: user.name,
          email: user.email,
          codigo: user.codigo,
          phone: "+55" + user.phone,
          state: user.state,
          country: user.country,
          city: user.city,
        },
      },
    });
  }
};

const liveWatched = (timeWatched, timeVideo) => {
  let dataLayer = window.dataLayer;
  dataLayer.push({ ecommerce: null });
  if (dataLayer) {
    dataLayer.push({
      event: "currentTimeBeforeLiveClose",
      ecommerce: {
        currencyCode,
        live: {
          timeWatched,
          timeVideo,
        },
      },
    });
  }
};

const liveStart = () => {
  let dataLayer = window.dataLayer;
  dataLayer.push({ ecommerce: null });
  if (dataLayer) {
    dataLayer.push({
      event: "liveStart",
      ecommerce: {
        currencyCode,
        liveStart: true,
      },
    });
  }
};

const liveEnd = () => {
  let dataLayer = window.dataLayer;
  dataLayer.push({ ecommerce: null });
  if (dataLayer) {
    dataLayer.push({
      event: "liveEnd",
      ecommerce: {
        currencyCode,
        liveEnd: true,
      },
    });
  }
};

const livePause = (timeWatched, timeVideo, pauseCount) => {
  let dataLayer = window.dataLayer;
  dataLayer.push({ ecommerce: null });
  if (dataLayer) {
    dataLayer.push({
      event: "livePause",
      ecommerce: {
        currencyCode,
        live: {
          timeWatchedUntilThisPoint: timeWatched,
          timeVideo,
          pauseCount,
        },
      },
    });
  }
};

const liveResume = (timeWatched, timeVideo, resumeCount) => {
  let dataLayer = window.dataLayer;
  dataLayer.push({ ecommerce: null });
  if (dataLayer) {
    dataLayer.push({
      event: "liveResume",
      ecommerce: {
        currencyCode,
        live: {
          timeWatchedUntilThisPoint: timeWatched,
          timeVideo,
          resumeCount,
        },
      },
    });
  }
};

const liveJump = (timeBeforeJump, timeAfterJump, direction = "forward") => {
  let dataLayer = window.dataLayer;
  dataLayer.push({ ecommerce: null });
  if (dataLayer) {
    dataLayer.push({
      event: "liveJump",
      ecommerce: {
        currencyCode,
        live: {
          timeBeforeJump,
          timeAfterJump,
          direction,
        },
      },
    });
  }
};

export const googleTagManager = {
  initGoogleTagManager,
  clickProduct,
  addProductToCart,
  removeProductFromCart,
  checkoutPushStep,
  purchase,
  userRegister,
  editProduct,
  liveWatched,
  liveStart,
  liveEnd,
  livePause,
  liveResume,
  liveJump,
};
