import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import { useTranslation } from "react-i18next";
import * as s from "./styles-cart";
import { formatCash } from "../../../utils";
import { Input, Button, Modal, message } from "antd";
import Scrollbars from "react-custom-scrollbars";

const GradeAberta = ({
  tamanhos,
  grade,
  preco,
  changeProducts,
  disabled,
  unavailable,
  produto,
  unidadeMonetaria,
  layoutCheckout,
  produtoCarrinho,
  setProdDelete,
  vertical,
}) => {
  const { t } = useTranslation();
  const [quantities, setQuantities] = useState({ [tamanhos[0]]: 0 }); //quantidades por tamanho
  const [render, setRender] = useState(false); //alterado para renderizar novamente
  const [sizeModal, setSizeModal] = useState(false); //modal com inputs no mobile
  const [windowMeasures, setWindowMeasures] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeWindow);
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);
  const resizeWindow = () => {
    setWindowMeasures({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const handleGrade = useCallback(() => {
    if (grade) {
      let newQuantities = {};
      tamanhos.forEach((t) => (newQuantities[t] = 0));
      grade.forEach((g) => (newQuantities[g.tamanho] = g.quantidade));
      setQuantities(newQuantities);
    }
  }, [grade, tamanhos]);

  useEffect(() => {
    handleGrade();
  }, [grade, handleGrade]);

  useEffect(() => {
    if (produto?.controlStock) {
      let tmpOutOffStock = [];
      produto.stock.map((s, idx) => {
        if (s === 0) tmpOutOffStock.push(produto.tamanhos[idx]);
      });

      if (tmpOutOffStock.length > 0)
        message.warning({
          content: `${t("OPEN-GRID.TheProduct")} ${produto.descricao} ${t(
            "OPEN-GRID.OutOfStockProductWarning"
          )}: ${tmpOutOffStock.toString()}`,
          duration: 5,
        });
    }
  }, []);

  const getValueByStockAvaliable = (size, qtd) => {
    if (!isNaN(qtd) && produto?.controlStock && parseInt(qtd) > 0) {
      let valueInStock = parseInt(
        produto.stock[produto.tamanhos.indexOf(size)]
      );
      if (valueInStock <= parseInt(qtd)) {
        if (valueInStock < parseInt(qtd))
          message.warning({
            key: `${produto.docId}-stock`,
            content: `${t("OPEN-GRID.TheProduct")} ${produto.descricao} ${t(
              "OPEN-GRID.OnlyXInStock1"
            )} ${valueInStock} ${t("OPEN-GRID.OnlyXInStock2")} ${size} ${t(
              "OPEN-GRID.OnlyXInStock3"
            )}!`,
            duration: 3,
          });
        return parseInt(valueInStock);
      } else return parseInt(qtd);
    } else return parseInt(qtd);
  };

  const changeQuantities = (key, value) => {
    if (!isNaN(value)) {
      let newQuantities = quantities;
      let valuePossible = getValueByStockAvaliable(key, value);
      newQuantities[key] = valuePossible;
      setQuantities(newQuantities);
      changeProducts(
        Object.entries(newQuantities)
          .map((q) => ({
            tamanho: q[0],
            quantidade: q[1],
          }))
          .filter((p) => p.quantidade > 0)
      );
      setRender(!render);
    } else changeQuantities(key, 0);
  };

  const filterByStockAvaliable = (size) => {
    if (produto?.controlStock) {
      if (produto.stock[produto.tamanhos.indexOf(size)] > 0) return true;
      else return false;
    } else return true;
  };

  const renderSizeInputs = (inModal) => (
    <>
      <Scrollbars
        style={{ width: "100%", minHeight: inModal ? 140 : 120 }}
        renderThumbVertical={() => <div style={{ display: "none" }} />}
      >
        <s.SizesLabels>{t("OPEN-GRID.Sizes")}</s.SizesLabels>
        <s.SizesContainer>
          {tamanhos.map(
            (t, idx) =>
              filterByStockAvaliable(t) && (
                <Input
                  className={
                    (unavailable ||
                      (produto?.stock && produto.stock[idx] === 0)) &&
                    produto?.controlStock
                      ? "input-label-bloqued"
                      : "input-label"
                  }
                  key={t}
                  type="text"
                  readOnly
                  value={t}
                  title={t}
                />
              )
          )}
        </s.SizesContainer>
        <s.SizesLabels mt={10}>{t("OPEN-GRID.Quantities")}</s.SizesLabels>
        <s.SizesContainer>
          {Object.entries(quantities).map(
            ([tamanho, qtd], idx) =>
              filterByStockAvaliable(tamanho) && (
                <Input
                  type="number"
                  className={
                    (unavailable ||
                      (produto?.stock && produto.stock[idx] === 0)) &&
                    produto?.controlStock
                      ? "input-label-bloqued"
                      : "input-label"
                  }
                  key={`qtd-${tamanho}`}
                  onChange={(e) => changeQuantities(tamanho, e.target.value)}
                  value={qtd || ""}
                  disabled={disabled}
                  onClick={() =>
                    (unavailable ||
                      (produto?.stock &&
                        produto.stock !== null &&
                        produto.stock[idx] === 0)) &&
                    message.error(
                      `${t(
                        "OPEN-GRID.ProductUnavailableWarning1"
                      )} ${tamanho} ${t(
                        "OPEN-GRID.ProductUnavailableWarning2"
                      )} :(`
                    )
                  }
                  readOnly={unavailable}
                />
              )
          )}
        </s.SizesContainer>
      </Scrollbars>
    </>
  );

  return (
    <>
      <Modal
        title={t("OPEN-GRID.Sizes")}
        visible={sizeModal}
        onCancel={() => setSizeModal(false)}
        style={{
          maxWidth: "400px",
          top:
            !vertical && windowMeasures.height <= 736
              ? "33vh"
              : !vertical
              ? "30vh"
              : windowMeasures.width < 955 && "45vh",
        }}
        width={"80vw"}
        footer={false}
        bodyStyle={{ padding: 10 }}
        maskStyle={{
          top:
            !vertical && windowMeasures.height <= 736
              ? "31vh"
              : !vertical &&
                windowMeasures.width === 375 &&
                windowMeasures.height === 812
              ? "26vh"
              : !vertical && "28vh",
          background: "rgba(0, 0, 0, 0,5)",
        }}
      >
        {renderSizeInputs(true)}
      </Modal>
      <div className="qtd-size-col" style={{ width: "150%" }}>
        {renderSizeInputs()}
      </div>
      <div className="resume-col">
        <div className="total-col">
          {windowMeasures.width < 955 ? (
            <div className="vertical-buttons">
              <div
                style={{ marginRight: 10 }}
                onClick={() => setSizeModal(true)}
              >
                <img src={"/assets/icons/editIcon.svg"} alt="editar-produto" />
              </div>
              <div onClick={() => setProdDelete(produtoCarrinho)}>
                <img
                  src={"/assets/icons/deleteIcon.svg"}
                  alt="excluir-produto"
                />
              </div>
            </div>
          ) : (
            <Button type="primary" onClick={() => setSizeModal(true)}>
              {t("OPEN-GRID.Sizes")}
            </Button>
          )}
          <div className="value-total">
            <span className="title-col">{t("OPEN-GRID.Total")}: </span>
            <strong>
              {formatCash(
                Object.entries(quantities)
                  .map((p) => p[1])
                  .reduce((a, b) => a + b, 0) * preco,
                false,
                unidadeMonetaria
              )}
            </strong>
          </div>
        </div>
      </div>
    </>
  );
};

export default GradeAberta;
