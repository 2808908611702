import React, { useContext, useState, useEffect, useCallback } from "react";
import { Layout, Menu, Breadcrumb, Select } from "antd";
import { useTranslation } from "react-i18next";
import {
  PieChartOutlined,
  VideoCameraAddOutlined,
  FolderOpenOutlined,
  DisconnectOutlined,
  FormatPainterOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Route, Switch, Link, withRouter, Redirect } from "react-router-dom";
import {
  Dashboard,
  LiveStudio,
  Grades,
  Theme,
  LandingPage,
  DashboardLandingPage,
} from "..";
import { firestore, userService } from "../../services";
import {
  UserContext,
  LivesContext,
  GradesContext,
  LanguageContext,
} from "../../context";
import { GetIconByLanguage } from "../../utils";
import * as s from "./styles-admin";

const { Content, Sider } = Layout;
const { Option } = Select;

const Admin = (props) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const { user, setUser } = useContext(UserContext); //dados do usuário
  const { setUserGrades } = useContext(GradesContext);
  const { setLives } = useContext(LivesContext);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem(`collapsed`) === "true"
  );
  const { t, i18n } = useTranslation();

  const handleSubscribe = useCallback(() => {
    let subscribe = firestore
      .collection(`contractor/${user.id}/grades`)
      .onSnapshot((snapshot) => {
        setUserGrades(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            gradeId: doc.id,
          }))
        );
      });
    return () => subscribe();
  }, [user]);

  useEffect(() => {
    handleSubscribe();
  }, [handleSubscribe]);

  useEffect(() => {
    localStorage.setItem(`collapsed`, collapsed);
  }, [collapsed]);

  const breadcrumbNameMap = {
    "/": "Home",
    "/live-studio": "Live Estúdio",
    "/grades": "Grades",
    "/theme": "Tema",
    "/dashboard-landing-page": "Cadastrar Leads",
  };

  const { location, history, setLogged } = props;
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        {!["/products", "/security"].includes(url) ? (
          <Link to={url}>{breadcrumbNameMap[url]}</Link>
        ) : (
          breadcrumbNameMap[url]
        )}
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const logout = () => {
    userService.logout().then(() => {
      setLogged(false);
      setUser(null);
      setLives(null);
    });
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <div
          style={{
            height: "32px",
            margin: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="logo"
            src={`/assets/images/${collapsed ? "4White.svg" : "logo.svg"}`}
            style={{ height: "32px" }}
          />
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["dashboard"]}
          mode="inline"
          selectedKeys={[location.pathname]}
        >
          <Menu.Item
            key="/"
            icon={<PieChartOutlined />}
            onClick={() => history.push("/")}
          >
            {t("MENU.Dashboard")}
          </Menu.Item>
          <Menu.Item
            key="/live-studio"
            icon={<VideoCameraAddOutlined />}
            onClick={() => history.push("/live-studio")}
          >
            {t("MENU.Studio")}
          </Menu.Item>
          {user.leads ? (
            <Menu.Item
              key="/dashboard-landing-page"
              icon={<SolutionOutlined />}
              onClick={() => history.push("/dashboard-landing-page")}
            >
              {t("MENU.LandingPage")}
            </Menu.Item>
          ) : null}
          <Menu.Item
            key="/theme"
            icon={<FormatPainterOutlined />}
            onClick={() => history.push("/theme")}
          >
            {t("MENU.Theme")}
          </Menu.Item>
          <Menu.Item
            key="/grades"
            icon={<FolderOpenOutlined />}
            onClick={() => history.push("/grades")}
          >
            {t("MENU.Grids")}
          </Menu.Item>
          <Menu.Item
            key="/logout"
            icon={<DisconnectOutlined />}
            onClick={logout}
          >
            {t("MENU.Signout")}
          </Menu.Item>
        </Menu>

        <s.LanguageContainer collapsed={collapsed}>
          <Select
            value={language}
            onChange={(opt) => {
              setLanguage(opt);
              i18n.changeLanguage(opt);
              window.localStorage.setItem(
                "currentLanguage",
                JSON.stringify(opt)
              );
            }}
            style={{
              width: collapsed ? 70 : 80,
            }}
            dropdownStyle={{
              minWidth: 150,
            }}
          >
            <Option value="ptBR">
              <s.DropdownImage src={GetIconByLanguage("ptBR")} />
              <label>Português</label>
            </Option>
            <Option value="en">
              <s.DropdownImage src={GetIconByLanguage("en")} />
              <label>English</label>
            </Option>
            <Option value="es">
              <s.DropdownImage src={GetIconByLanguage("es")} />
              <label>Español</label>
            </Option>
          </Select>
        </s.LanguageContainer>
      </Sider>
      <Layout
        style={{
          backgroundImage: `url(/assets/images/bg.svg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0", display: "flex" }}>
            {breadcrumbItems}
          </Breadcrumb>
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/live-studio" component={LiveStudio} />
            <Route path="/grades" component={Grades} />
            <Route path="/theme" component={Theme} />
            {user?.leads === true ? (
              <Route
                path="/dashboard-landing-page"
                component={DashboardLandingPage}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user?.leads === true ? (
              <Route path="/landing-page" component={LandingPage} />
            ) : (
              <Redirect to="/" />
            )}
          </Switch>
        </Content>
        <a
          href={window.location.origin + "/termos-de-uso.pdf"}
          target="_blank"
          style={{ margin: "auto auto 30px auto" }}
        >
          {t("APP.TermsOfUse")}
        </a>
      </Layout>
    </Layout>
  );
};

export default withRouter(Admin);
