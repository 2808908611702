import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover, Button, Select } from "antd";
import { GetIconByLanguage } from "../../../utils";
import { LanguageContext, ThemeContext, UserContext } from "../../../context";
import LoginForm from "../login-form/login-form";
import * as s from "./styles-header";
import { ChatFixedMessage } from "../../live/chat/styles-chat";

const Header = ({ live }) => {
  const { t, i18n } = useTranslation();
  const { Option } = Select;

  const { language, setLanguage } = useContext(LanguageContext);
  const { user, openedFrom, setOpenedFrom } = useContext(UserContext);
  const { corPrimaria, logo, showPoweredBy } = useContext(ThemeContext);
  const [visible, setVisible] = useState(false); //se o drawer da vertical está aberto

  const renderContent = () => (
    <LoginForm live={live} visible={visible} setVisible={setVisible} />
  );

  if (live.vertical && window.innerWidth < 955)
    return (
      <>
        <s.VerticalHeader user={user?.name}>
          <div>
            {logo && (
              <img
                alt="header-contractor-logo"
                src={logo}
                style={{ maxWidth: "20vw", maxHeight: "65px" }}
              />
            )}
            {logo && showPoweredBy ? (
              <img
                alt="header-logo"
                src={"/assets/images/poweredby.svg"}
                className="header-logo"
                style={{ maxHeight: "25px", marginLeft: "5px" }}
              />
            ) : (
              !logo && (
                <img
                  alt="header-logo"
                  src={"/assets/images/logo.svg"}
                  className="header-logo"
                  style={{ maxWidth: "100px", marginLeft: "5px" }}
                />
              )
            )}
          </div>

          <div
            style={{ marginRight: user ? "0" : "10px" }}
            className="user-interaction"
          >
            <Select
              value={language}
              onChange={(opt) => {
                setLanguage(opt);
                i18n.changeLanguage(opt);
              }}
              style={{
                width: 65,
                background: `${corPrimaria}`,
                borderColor: "transparent !important",
              }}
              dropdownStyle={{ maxWidth: 50 }}
              suffixIcon={<img src="/assets/icons/bottomArrow.svg" />}
            >
              <Option value="ptBR">
                <s.DropdownImage src={GetIconByLanguage("ptBR")} />
              </Option>
              <Option value="en">
                <s.DropdownImage src={GetIconByLanguage("en")} />
              </Option>
              <Option value="es">
                <s.DropdownImage src={GetIconByLanguage("es")} />
              </Option>
            </Select>
            {/* {((live?.listaAudiencia && logged) || !live?.listaAudiencia) && ( */}
            <Popover
              placement="topRight"
              content={renderContent()}
              trigger="click"
              onVisibleChange={(v) => {
                if (openedFrom) {
                  setTimeout(() => setOpenedFrom(""), 500);
                }
                setVisible(v);
              }}
              visible={visible}
            >
              <Button
                style={{
                  background: corPrimaria,
                  borderColor: corPrimaria,
                  width: !user?.name ? "100px" : "50px",
                }}
                size={"large"}
                id="btn-login-customer"
              >
                <img src="/assets/images/newUserIcon.svg" />
                {!user?.name && t("HEADER.Enter")}
              </Button>
            </Popover>
            {/* )} */}
          </div>
        </s.VerticalHeader>
        {live.fixedMessageChat !== null && (
          <div style={{ width: "100%" }}>
            <ChatFixedMessage verticalChat={true} corPrimaria={corPrimaria}>
              {live?.fixedMessageChat
                .split(/(https?:\/\/\S+)/)
                .map((word, index) => {
                  if (word.match(/^https?:\/\//)) {
                    return (
                      <a
                        href={word.trim()}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}
                        style={{ margin: "0 5px" }}
                      >
                        {word.trim()}
                      </a>
                    );
                  } else {
                    return <React.Fragment key={index}>{word}</React.Fragment>;
                  }
                })}
            </ChatFixedMessage>
          </div>
        )}
      </>
    );
  else
    return (
      <>
        <s.Header id="header-4show" corPrimaria={corPrimaria}>
          <div>
            {logo && (
              <img
                alt="header-contractor-logo"
                src={logo}
                style={{ maxWidth: "20vw", maxHeight: "65px" }}
              />
            )}
            {logo && showPoweredBy ? (
              <img
                alt="header-logo"
                src={"/assets/images/poweredby.svg"}
                className="header-logo"
                style={{ maxHeight: "25px", marginLeft: "5px" }}
              />
            ) : (
              !logo && (
                <img
                  alt="header-logo"
                  src={"/assets/images/logo.svg"}
                  className="header-logo"
                  style={{ maxWidth: "100px", marginLeft: "5px" }}
                />
              )
            )}
          </div>
          <div style={{ display: "flex" }}>
            <Select
              value={language}
              onChange={(opt) => {
                setLanguage(opt);
                i18n.changeLanguage(opt);
                window.localStorage.setItem(
                  "currentLanguage",
                  JSON.stringify(opt)
                );
              }}
              style={{
                width: 70,
                display: "flex",
                alignItems: "center",
              }}
              dropdownStyle={{
                minWidth: 120,
              }}
              suffixIcon={<img src="/assets/icons/bottomArrow.svg" />}
            >
              <Option value="ptBR">
                <s.DropdownImage src={GetIconByLanguage("ptBR")} />
                <label>Português</label>
              </Option>
              <Option value="en">
                <s.DropdownImage src={GetIconByLanguage("en")} />
                <label>English</label>
              </Option>
              <Option value="es">
                <s.DropdownImage src={GetIconByLanguage("es")} />
                <label>Español</label>
              </Option>
            </Select>
            <Popover
              placement="topRight"
              content={renderContent()}
              trigger="click"
              onVisibleChange={(v) => {
                if (openedFrom) {
                  setTimeout(() => setOpenedFrom(""), 500);
                }
                setVisible(v);
              }}
              visible={visible}
            >
              <Button
                style={{
                  width: !user?.name ? "100px" : "50px",
                }}
                type="primary"
                size={"large"}
                id="btn-login-customer"
              >
                <img src="/assets/images/newUserIcon.svg" />
                {!user?.name && t("HEADER.Enter")}
              </Button>
            </Popover>
          </div>
        </s.Header>
      </>
    );
};

export default Header;
