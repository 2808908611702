import styled from "styled-components";

export const CartContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 10px ${({ vertical: v }) => (v ? `0px 20px 0px` : `20px 20px 20px`)};

  @media (max-width: 955px) {
    border-radius: 12px;
    background: rgba(248, 248, 248, 1);
  }
`;

export const NewCartContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 10px ${({ vertical: v }) => (v ? `0px 20px 0px` : `8px 20px 0px`)};
  padding: 0 0px 20px 20px;
  background: white;
  max-height: 650px;
  max-width: 1200px;
  border-radius: 12px;
  justify-content: left;
  background: white;
  @media (max-width: 955px) {
    background: rgba(248, 248, 248, 1);
  }
  @media (max-width: 1600px) {
    flex-direction: column;
    max-height: 1050px;
    transform: translateX(0);
    padding: 0 0 20px 0;

    .products {
      padding: 0 20px 0 20px;
    }
  }
`;

export const CartVertical = styled.div`
  position: fixed;
  bottom: 116px;
  color: white;
  right: 0px;
  z-index: 3;

  .cartCounter {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: ${({ corPrimaria: c }) => c};
    color: #fff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
`;
export const SavingCart = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: -20px;
  color: ${({ corPrimaria: c }) => c};
  @media (max-width: 955) {
    background: rgba(248, 248, 248, 1);
  }
`;

export const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  @media (max-width: 955px) {
    box-shadow: none;
    border-radius: 0px;
    flex-direction: column;
    background: rgba(248, 248, 248, 1);
    img {
      margin: 0 0 10px 0 !important;
    }
  }
  h1 {
    font-weight: normal;
    font-size: calc(10px + 0.5vw);
    color: #3e3e3e;
    max-width: 450px;
    @media (max-width: 955px) {
      text-align: center;
    }
  }
  img {
    margin: 30px;
    max-width: 276px;
    width: 30vw;
  }
`;

export const Product = styled.div`
  width: 100%;
  margin: 20px 0;
  border-bottom: 1px solid #e4e4e4;
  :first-child {
    margin-top: 0;
  }
  :last-child {
    border-bottom: none;
  }
  display: flex;
  align-items: center;
  position: relative;
  background: white;
  --product-height: 110px;
  padding: 20px;
  @media (max-width: 955px) {
    height: 150px;
    :first-child {
      margin: 0 auto 10px auto;
    }
    width: calc(100% - 40px);
    box-shadow: 2px 4px 5px rgba(169, 169, 169, 0.16);
    padding: 10px;
    margin: 10px auto;
    border-radius: 2px;
    /* border-bottom: 1px solid #d9d9d9; */
    /* :last-child {
      border: none;
    } */

    @media (max-width: 640px) {
      width: calc(100% - 18px);
    }
  }

  .quantity-controller {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input {
      margin: 0 5px;
      height: 20px;
      border-radius: 5px;
      border-color: grey;
      padding: 2px 5px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: none;
      width: 10px;
      height: 10px;
    }
  }

  .product-img-container {
    position: relative;
    margin-right: 20px;
    height: var(--product-height);
    img:first-child {
      max-width: 110px;
      max-height: 95px;
      border-radius: 10px;

      //Iphone 5/SE
      @media (min-device-height: 568px) and (max-device-width: 320px) and (orientation: portrait) {
        max-width: 100%;
        max-height: 100%;
        /* max-width: 100px;
        max-height: 70px; */
      }
    }
    section {
      position: absolute;
      top: 63px;
      right: -13px;
      width: 28px;
      height: 28px;
      color: white;
      background: ${({ corSecundaria: c }) => c};
      align-items: center;
      justify-content: center;
      display: flex;
      img {
        width: 12px;
        height: 16px;
      }
      border-radius: 7px;
    }
    @media (max-width: 1126px) {
      height: 66px;
      margin-bottom: auto;
      section {
        position: absolute;
        top: 38px;
        right: -8px;
        width: 18px;
        height: 18px;
        color: white;
        background: ${({ corSecundaria: c }) => c};
        align-items: center;
        justify-content: center;
        display: flex;
        img {
          width: 8px;
          height: 12px;
        }
        border-radius: 6px;
      }
    }
  }
  p {
    margin: 0;
    :last-child {
      font-weight: 600;
    }
  }
  .title-col {
    color: gray;
    font-size: 13px;
  }
  .name-price-col {
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    height: var(--product-height);

    @media (max-width: 955px) {
      margin-left: 10px;
    }

    @media (max-width: 1126px) {
      min-width: 120px;
      height: 66px;
      font-size: 13px;
      /* justify-content: space-between; */
      margin: 0px 0px auto 0px;
    }
    @media (max-width: 955px) {
      margin-bottom: 50px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 10px;

    .product-size-label {
      font-size: 14px;
      color: #606060;
      margin-bottom: 10px;
    }
    p {
      :first-child {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        @media (max-width: 540px) {
          font-size: 11px;
        }
      }
    }
    @media (max-width: 995px) {
      p {
        /* :first-child { */
        font-size: 16px;
        font-weight: bold;
        color: #4a4a4a;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        /* } */
      }
    }

    //Iphone 5/SE
    @media (min-device-height: 568px) and (max-device-width: 320px) and (orientation: portrait) {
      margin: 0px 0px auto 5px;
      min-width: 88px;
      p {
        font-size: 12px;
      }
    }
  }
  .qtd-size-col,
  .resume-col {
    height: var(--product-height);
    display: flex;
    flex-direction: column;
    margin: 0 10px;

    .vertical-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        background: rgba(242, 242, 242, 1);
        border-radius: 50%;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    @media (max-width: 1126px) {
      margin: 0 5px;
    }
  }
  .qtd-size-col {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      text-align: center;
    }
    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
      text-align: center;
    }
    @media (max-width: 1126px) {
      display: none;
    }
  }
  .resume-col {
    width: 100%;
    justify-content: flex-end;
    .value-total {
      text-align: center;

      @media (max-width: 955px) {
        strong {
          padding: 6px;
          background: rgba(242, 242, 242, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 16px;
          color: rgba(96, 96, 96, 1);
          border-radius: 5px;
        }
      }
    }
    @media (max-width: 1126px) {
      .resume-details,
      .resume-content,
      .title-col {
        display: none !important;
      }
    }

    .resume-content {
      display: flex;
      .resume-size {
        span {
          height: 20px;
          padding: 0 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          :first-child {
            border: 1px solid #ccc;
          }
          border-radius: 2px;
          margin-right: 4px;
          font-size: 12px;
        }
        .unavailable {
          :first-child {
            border: 1px solid red;
          }

          color: red;
        }
      }
    }
    @media (max-width: 540px) {
      min-width: 85px;
    }
  }
  .total-col {
    position: relative;
    min-width: 135px;
    display: flex;
    margin: auto 0px 0px auto;
    button {
      display: none;
    }
    @media (max-width: 1126px) {
      height: 100%;
      button {
        display: block;
        margin-bottom: auto;
      }
      flex-direction: column;
      margin-bottom: -4px;
      min-width: 85px;
    }
    .value-total {
      position: absolute;
      right: 0;
      bottom: 0;
      @media (max-width: 1126px) {
        span,
        strong {
          display: flex !important;
          margin: 0px !important;
        }
      }
      strong {
        margin-left: 5px;
        @media (max-width: 1126px) {
          font-size: 13px;
        }
      }
    }
  }
  .buttons-container {
    margin-top: auto;
    display: flex;

    @media (max-width: 955px) {
      button {
        width: fit-content;
        height: 24px;
        margin-right: 5px;
        border-radius: 3px;
        display: flex;
        padding: 0 10px;
        align-items: center;
        justify-content: center;

        :disabled {
          span {
            color: darkgrey !important;
          }
        }

        span {
          color: white;
          font-weight: bold;
          font-size: 13px;
        }
      }

      //Iphone 5/SE
      @media (min-device-height: 568px) and (max-device-width: 320px) and (orientation: portrait) {
        button {
          span {
            font-size: 10px;
          }
        }
      }
    }

    @media (max-width: 1126px) {
      position: absolute;
      bottom: 20px;
      left: 12px;
    }
    @media (max-width: 955px) {
      margin-top: 20px;
    }
  }
`;

export const CreditCard = styled.div`
  label {
    color: gray;
    font-size: 13px;
  }
  input {
    margin-bottom: 5px;
  }
`;
export const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid grey;
  padding: 5px 0px;
  border-bottom: ${(props) => props.showMargin && "1px solid grey"};

  .title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
    margin: 0 auto;
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 6px 0;
    width: 100%;
  }
`;
export const CheckOutContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  border-radius: 12px;
  background: white;
  @media (max-width: 955px) {
    margin: 0;
    border-radius: 0px;
    box-shadow: none;
    padding: 0;
    background: rgba(248, 248, 248, 1);
    section:last-child {
    }
  }

  section:last-child {
    display: flex;
    flex-wrap: wrap;
    border-radius: 12px;
    /* padding: 20px; */
    @media (max-width: 555px) {
      /* padding: 10px; */
      display: grid;
      .col {
        width: calc(100% - 20px) !important;
        display: grid;
      }
      .col-price {
        margin-top: 20px;
        width: 100% !important;
        display: grid;
      }
      button {
        margin: 0 auto;
      }
    }
    .col {
      min-width: 250px;
      p {
        margin: 0;
        color: ${({ corPrimaria: c }) => c};
        font-size: 18px;
        font-weight: 600;
      }
      width: 50%;
      display: grid;
    }
  }
`;

export const NewCheckoutContainer = styled.div`
  display: flex;
  width: 470px;
  border-radius: 12px;
  background: white;
  @media (max-width: 1600px) {
    width: 100%;
    flex-direction: column;
    .content {
      section {
        display: flex;
      }
    }

    margin: 0;
    border-radius: 12px;
    box-shadow: none;
    padding: 0;
    background: white;
    section:last-child {
      width: 100%;
    }
  }

  section:last-child {
    .total-value {
      margin-top: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 10px 0;
        background: #e5e5e5;
        width: 100%;

        label {
          margin-left: 20px;
          font-size: 20px;
          font-weight: bold;
        }

        p {
          margin-left: 20px;
          font-size: 22px;
          color: var(--corPrimaria);
          font-weight: 700;
        }
      }

      .total-cash {
        height: 100px;
        display: inline-flex;
        justify-content: center;
        p {
          margin: 0 0 0 20px;
        }
      }

      @media (max-width: 1600px) {
        margin-top: 10px;
        div {
          padding: 0;
        }
      }
    }
    display: flex;
    flex-wrap: wrap;
    border-radius: 12px;
    height: 100%;
    padding: 20px 0 0 0;

    @media (max-width: 955px) {
      flex-wrap: initial;
      background: rgba(248, 248, 248, 1);
    }

    @media (max-width: 555px) {
      padding: 10px;
      display: grid;
      .col {
        width: 100% !important;
        display: grid;
      }
      button {
        margin: 0 auto;
      }
    }
    .col {
      min-width: 250px;
      p {
        margin: 0;
        color: ${({ corPrimaria: c }) => c};
        font-size: 18px;
        font-weight: 600;
      }
      width: 50%;
      display: grid;
    }
  }
`;

export const MobileLivePrice = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px 0;
    background: #e5e5e5;
    width: 100%;

    label {
      margin-left: 20px;
      font-size: 16px;
      font-weight: bold;
    }

    p {
      margin-left: 20px !important;
      font-size: 16px;
      color: var(--corPrimaria);
      font-weight: 700;
    }
  }

  .total-cash {
    height: 80px;
    display: inline-flex;
    justify-content: center;
    p {
      margin: 0 0 0 20px;
    }
  }
`;

export const SizesMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-bottom: 20px;
  p {
    color: gray;
    font-size: 13px;
  }

  @media (max-width: 1126px) {
    p {
      font-size: 12px;
      margin: 0;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align: center;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
  }
`;

export const SizesMobileClosedGrid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  background: white;
  /* position: absolute; */
  /* bottom: 50px; */
  /* left: 2px;
  z-index: 5; */

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 20px 0;

    label,
    img {
      margin-left: auto;
    }

    img {
      width: 16px;
      height: 17px;
      margin-right: 10px;
    }
  }

  .sizesNQuantities {
    display: flex;

    .ant-select-selector {
      width: 100px;
      height: 24px;
      padding: 0 11px 0 5px;
      border-radius: 2px;
    }

    .ant-select-selection-item {
      font-size: 13px;
      font-weight: 400;
      color: grey;
      line-height: 22px;
      padding-left: 5px;
    }

    input {
      border: none;
    }

    .custom-button {
      border: none;
      height: 20px;
      padding: 0px 10px;
      span {
        line-height: 0;
      }
    }
  }

  .table-container {
    margin: 25px 0;

    .table-scrollbars {
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    td,
    tr,
    th {
      border: 0.5px solid grey;
    }

    th {
      color: grey;
      font-weight: 500;
    }

    td {
      text-align: center;
      color: grey;
      font-weight: 300;
    }
  }

  .value-total-modal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    button {
      border-radius: 3px;
      min-width: 60px;
      span {
        color: white;
        font-weight: bold;

        :disabled {
          span {
            color: darkgrey;
          }
        }
      }
    }

    span {
      color: grey;
      font-size: 12px;
    }

    strong {
      color: grey;
      font-size: 16px;
      font-weight: 500;
    }
  }

  p {
    color: gray;
    font-size: 13px;
  }

  @media (max-width: 1126px) {
    p {
      font-size: 12px;
      margin: 0;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align: center;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #757575;
  font-family: "Roboto";
  padding: 20px !important;
  border-radius: 12px;
  @media (max-width: 955px) {
    padding: ${({ noPadding }) =>
      !noPadding ? "10px 0 5px 0 !important" : "0 0 5px 0 !important"};
    font-size: 14px;
  }
  img {
    margin-right: 8px;
    width: 1.2em;
    height: 1.2em;
  }
`;
export const SubTitle = styled(Title)`
  font-weight: 400;
  font-size: 14px;
  width: 90%;
  margin: 10px 0;
`;
export const ProductsContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  --test: 10vh;
  @media (min-height: 600px) {
    --test: 11vh;
  }
  @media (min-height: 620px) {
    --test: 13vh;
  }
  @media (min-height: 640px) {
    --test: 14vh;
  }

  ${({ horizontal }) =>
    horizontal &&
    `display: grid;
    grid-template-columns: 1fr 1fr 1fr;column-gap: 10px;
    margin-bottom: -140px;`}

  @media (max-width: 955px) {
    background: rgba(248, 248, 248, 1);
  }

  .rec-item-wrapper {
    height: 100%;
  }
  .products-prod {
    outline: 0;
    margin: 10px;
    max-width: 130px;
    max-height: var(--test);
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    label {
      background: var(--corPrimaria);
      color: white;
      text-align: center;
      z-index: 2;
      margin-top: 3px;
      transform: translateY(-13px);
      font-size: 12px;
      align-self: center;
      border-radius: 4px;
      padding: 3px 5px;
      @media (max-width: 955px) {
        font-size: 10px;
        transform: translateY(-11px);
      }
    }
    .product-img {
      max-width: 100%;
      max-height: calc(100% - 2vw);
      @media (max-width: 955px) {
        max-height: var(--test);
      }
      cursor: pointer;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    }
    .product-price {
      display: flex;
      background: ${({ corPrimaria: c }) => c};
      color: white;
      border-radius: 4px;
      font-size: 13px;
      padding: 3px 5px;
      box-shadow: 0px 6.24438px 18.7331px rgba(0, 0, 0, 0.05);
      h5 {
        margin: auto 2px 5px 0;
        font-size: 6px;
        color: white;
      }
      transform: translateY(-12px);
      z-index: 1;
    }
    @media (max-width: 955px) {
      .product-price {
        transform: translateY(-8px);
        font-size: 11px;
        padding: 3px 5px;
        h5 {
          margin: auto 2px 3px 0;
        }
      }
      max-width: 90px;
      max-height: var(--test);

      img {
        max-width: 90px;
        max-height: 80px;
      }
    }
  }

  .no-products-yet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 105px;
    /* border: 1px solid var(--corPrimaria); */
    border-radius: 8px;
    background: white;
    margin-top: 10px;
    padding: 0 10px;

    article {
      display: flex;
      position: absolute;
      background: ${({ corPrimaria: c }) => c};
      color: white;
      border-radius: 12px;
      padding: 8px 22px;
      top: 4px;
      transform: translateX(60px);
      label {
        margin: 0 2px 2px 0;
        font-size: 12px;
        line-height: calc(5px + 0.1vw);
        color: white;
      }
    }

    label {
      color: var(--corSecundaria);
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      margin-bottom: 6px;
    }

    span {
      color: grey;
      font-size: 10px;
      text-align: center;
    }
  }
`;

export const VariationsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  .wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    label {
      background: var(--corPrimaria);
      color: white;
      width: 95px;
      text-align: center;
      z-index: 1;
      margin-top: 1px;
      font-size: 12px;
      border-radius: 4px;
      align-self: center;
      transform: translateY(-70%);
    }
    img {
      border-radius: 12px;
      width: 104px;
      height: 104px;
      cursor: pointer;
      border: 2px solid transparent;
    }
    .var-selected {
      border-color: ${({ corPrimaria: c }) => c};
    }
  }
`;
export const SizesContainer = styled.div`
  display: flex;
  .input-label {
    text-align: center;
    margin-right: 5px;
    min-width: 50px;
  }
  .input-label-bloqued {
    text-align: center;
    margin-right: 5px;
    border: 1px solid red;
    min-width: 50px;
    color: red;

    :hover {
      border: 1px solid red;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align: center;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
  }
`;
export const SizesLabels = styled.p`
  margin: 0;
  margin-top: ${({ mt }) => (mt ? mt : 0)}px;
`;

export const FreeShippingModalButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 955px) {
    flex-direction: column;
  }
`;

export const CalculateInput = styled.div`
  display: flex;
  align-items: center;

  button {
    background: var(--corPrimaria);
    color: white;
  }
`;
