import React, { useState } from "react";
import { Input, Modal, message } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { firestore } from "../../../../services";

const { Search } = Input;
const ModalNovaGrade = ({ open, onClose, userId }) => {
  const { t } = useTranslation();
  const [nome, setNome] = useState(""); //nome da grade
  const [saving, setSaving] = useState(false); //salvando grade
  const [grade, setGrade] = useState([]); //nova grade a ser salva nas grades do usuário

  const addGrade = () => {
    setSaving(true);
    firestore
      .collection(`contractor/${userId}/grades`)
      .add({ nome, grade })
      .then(() => {
        setSaving(false);
        message.success(t("PRODUCTS-TAB-GRID-MODAL.GridAddSuccess"));
        setNome("");
        setGrade([]);
        onClose();
      })
      .catch(() => {
        setSaving(false);
        message.error(t("PRODUCTS-TAB-GRID-MODAL.GridAddError"));
      });
  };

  return (
    <Modal
      title={t("PRODUCTS-TAB-GRID-MODAL.NewGrid")}
      visible={open}
      onOk={addGrade}
      onCancel={!saving && onClose}
      okText={
        saving
          ? t("PRODUCTS-TAB-GRID-MODAL.Adding")
          : t("PRODUCTS-TAB-GRID-MODAL.AddGrid")
      }
      cancelText={t("PRODUCTS-TAB-GRID-MODAL.Back")}
      okButtonProps={{
        loading: saving,
        disabled: !nome || !grade || (grade && grade.length === 0),
      }}
    >
      <label>{t("PRODUCTS-TAB-GRID-MODAL.GridName")}</label>
      <Input
        value={nome}
        onChange={({ target: t }) => setNome(t.value)}
        placeholder={t("PRODUCTS-TAB-GRID-MODAL.GridName")}
        style={{ marginBottom: "10px" }}
        disabled={saving}
      />
      <label>{t("PRODUCTS-TAB-GRID-MODAL.GridSizes")}</label>
      <Search
        disabled={saving}
        placeholder={t("PRODUCTS-TAB-GRID-MODAL.NewSize")}
        enterButton={<PlusOutlined />}
        onSearch={(v) => {
          if (grade && grade.map((g) => g.tamanho).includes(v.trim()))
            message.info(t("PRODUCTS-TAB-GRID-MODAL.SizesSameNameError"));
          else if (v.trim().length > 0)
            setGrade([...grade, { tamanho: v, quantidade: 1 }]);
        }}
      />
      <Scrollbars
        style={{
          width: "100%",
          height:
            grade && grade.length < 7 ? `${grade.length * 32 + 10}px` : "220px",
        }}
      >
        <div style={{ margin: "10px 10px 0 0" }}>
          {grade &&
            grade
              .sort((a, b) => a.tamanho - b.tamanho)
              .map((g) => (
                <Input
                  type="number"
                  min={0}
                  disabled={saving}
                  key={g.tamanho}
                  addonBefore={g.tamanho}
                  suffix={
                    <DeleteOutlined
                      onClick={() => setGrade(grade.filter((e) => e !== g))}
                    />
                  }
                  value={g.quantidade}
                  onChange={({ target: t }) =>
                    setGrade(
                      [
                        ...grade.filter((g2) => g2.tamanho !== g.tamanho),
                        { tamanho: g.tamanho, quantidade: parseInt(t.value) },
                      ].sort((a, b) => a.tamanho - b.tamanho)
                    )
                  }
                />
              ))}
        </div>
      </Scrollbars>
    </Modal>
  );
};

export default ModalNovaGrade;
