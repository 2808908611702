import React, { useState, useCallback } from "react";
import { Modal, Button, message, Input, Table } from "antd";
import { useTranslation } from "react-i18next";
import { FileTextOutlined } from "@ant-design/icons";
import { csvToJson } from "../../../../../utils";

const AudienceModal = ({
  visible,
  onOk,
  onCancel,
  audienceList: oldAudienceList,
}) => {
  const { t } = useTranslation();
  const [audienceList, setAudienceList] = useState(oldAudienceList || []);
  const [filteredAudienceList, setFilteredAudienceList] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const INPUT_CSV_AUDIENCE = "audience-modal-csv-input";

  const handleUploadCsv = useCallback(() => {
    document.getElementById(INPUT_CSV_AUDIENCE).value = null;
    document.getElementById(INPUT_CSV_AUDIENCE).click();
  }, [INPUT_CSV_AUDIENCE]);

  const handleInputChange = useCallback(
    ({ target: { files } }) => {
      let fileCsv = files[0];
      if (fileCsv) {
        let reader = new FileReader();
        reader.readAsText(fileCsv);
        reader.onload = (e) => {
          let csvConverted = csvToJson(e.target.result);
          setFilteredAudienceList(false);
          setSearchValue("");
          setAudienceList(
            csvConverted.map((user) => ({
              ...user,
              emails: user.emails.split(";"),
            }))
          );
        };
        reader.onerror = () =>
          message.error(
            t("LIVE-TAB-AUDIENCE-CARD.UploadCSVFileErrorDescription")
          );
      }
    },
    [setAudienceList]
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "codigo",
    },
    {
      title: "Emails",
      dataIndex: "emails",
      render: (emails) =>
        emails && emails.length > 0
          ? emails.map((email) => <label key={email}> {email}</label>)
          : "Sem emails",
    },
    {
      title: "Tabela de preço",
      dataIndex: "idTabelaPreco",
    },
  ];

  return (
    <Modal
      visible={visible}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "30px",
          }}
        >
          {t("LIVE-TAB-AUDIENCE-CARD.AudienceList")}
          <Button
            size="small"
            href={`/Modelo - Lista de audiência.csv`}
            download
          >
            <FileTextOutlined />
            {t("LIVE-TAB-AUDIENCE-CARD.DownloadModel")}
          </Button>
        </div>
      }
      onOk={() => onOk(audienceList)}
      onCancel={onCancel}
      okText={t("MODAL-SHIPPING.Confirm")}
      bodyStyle={{ padding: 0 }}
      okButtonProps={{ disabled: !audienceList }}
      cancelButtonProps={{
        style: { display: "none" },
      }}
    >
      <div style={{ padding: "20px 20px 0 20px" }}>
        {t("LIVE-TAB-AUDIENCE-CARD.UploadFileDescription")}
        <input
          type="file"
          onChange={handleInputChange}
          style={{ display: "none" }}
          id={INPUT_CSV_AUDIENCE}
          accept=".csv"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Button
            onClick={handleUploadCsv}
            style={{ display: "grid", marginTop: "10px", marginRight: "2px" }}
          >
            {t("LIVE-TAB-AUDIENCE-CARD.Upload")}
          </Button>

          {audienceList.length > 1 && (
            <Button
              onClick={() => setAudienceList([])}
              style={{ display: "grid", marginTop: "10px" }}
            >
              <img style={{ marginTop: "25%" }} src="/assets/icons/trash.svg" />
            </Button>
          )}
        </div>

        <div
          style={{
            color: "gray",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {filteredAudienceList
            ? `${filteredAudienceList.length} ${t(
                "LIVE-TAB-AUDIENCE-CARD.Results"
              )}`
            : `${t("LIVE-TAB-AUDIENCE-CARD.AudienceList")}: ${
                audienceList.length
              }`}
          <Input.Search
            placeholder={t("LIVE-TAB-AUDIENCE-CARD.CodeSearch")}
            style={{ maxWidth: "280px", marginLeft: "auto" }}
            disabled={audienceList?.length === 0}
            onSearch={(value) =>
              setFilteredAudienceList(
                audienceList.filter(({ codigo }) =>
                  codigo.toLowerCase().includes(value.toLowerCase())
                    ? codigo
                    : false
                )
              )
            }
            value={searchValue}
            onChange={({ target: { value } }) => {
              setSearchValue(value);
              if (!value || value === "") setFilteredAudienceList(false);
            }}
            enterButton
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredAudienceList || audienceList}
        pagination={false}
        scroll={{ y: 240 }}
        size="middle"
        rowKey="codigo"
        pagination={{
          total: filteredAudienceList
            ? filteredAudienceList.length
            : audienceList.length,
        }}
      />
    </Modal>
  );
};

export default AudienceModal;
