import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  height: 100vh;
  border-radius: 0 !important;

  @media (max-width: 955px) {
    flex-direction: column-reverse;
    height: 160vh;
  }
`;

export const ContentLead = styled.div`
  padding: 35px 35px 35px 35px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 !important;
  height: 100%;
`;

export const CadastroContent = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0 !important;
`;

export const InvalidLabel = styled.label`
  color: #ff4d4f;
  margin-bottom: 5px;
`;

export const TitleForm = styled.label`
  font-weight: 700;
`;

export const ImageContent = styled.div`
  background-image: url("${({ imageBg }) => imageBg}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex: 1;
  border-radius: 0 !important;
`;

export const AboutBrand = styled.div`
  width: 100%;
  height: 150px;
  padding: 20px;
  display: flex;
  margin-top: 0;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border-radius: 0 !important;
  @media (min-width: 1400px) {
    margin-top: 10px;
    height: 242px;
  }
`;

export const TimerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimerCardContent = styled.div`
  width: 84px;
  height: 103px;
  border-radius: 8px;
  padding: 20px;

  @media (max-width: 1600px) {
    width: 74px;
    height: 93px;
    padding: 10px;
  }

  @media (max-width: 1480px) {
    width: 54px;
    height: 73px;
    padding: 5px;
  }
`;
