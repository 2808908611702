import styled from "styled-components";

export const PlayerContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  max-height: 600px;
  height: 35vw;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 20px;
  border-radius: 12px;
  @media (max-width: 955px) {
    padding: 0;
    margin: 0;
    border-radius: 0px;
    box-shadow: none;
    height: auto;

    ${({ vertical: v }) =>
      v ? `min-height: calc(100vh - 50px);min-width: 100vw;` : "z-index:3"}
  }
  @media (max-width: 320px) {
    height: ${({ vertical: v }) => (v ? 0 : "auto")};
  }
`;
export const VideoContainer = styled.div`
  ${({ vertical: v }) => (v ? `min-width: 100%;` : "")}
  height: ${({ ios }) => (ios ? "calc(100vh - 50px)" : "100%")};
  background: white;
  @media (max-width: 955px) {
    ${({ vertical: v }) => (v ? `min-width: 106%;` : "")}
    ${({ vertical: v }) => v && `width:100%;`}
    background: ${({ running: r }) => (r ? "white" : "black")};
  }
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .remove {
    animation: remove-animation 0.6s ease-out forwards;
    transform-origin: 50% 50%;
  }
  @keyframes remove-animation {
    0% {
      opacity: 1;
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(1.5);
    }
  }

  .loading-video-metadata {
    position: absolute;
    display: flex;
    flex-direction: column;

    label {
      margin-top: 10px;
      font-weight: bold;
    }
  }
`;
export const Video = styled.video`
  border-radius: 12px;
  z-index: 1;
  height: 100%;
  @media (max-width: 955px) {
    height: auto;
    width: 100%;
    border-radius: 0px;
  }
  ::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
  }
  ::--webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
  ::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }
`;
export const TagAoVivo = styled.div`
  color: white;
  background: red;
  padding: 6px 15px;
  border-radius: 2px;
  position: absolute;
  margin-left: 8px;
  z-index: 20;
  top: 10px;
  left: 20px;
  font-size: 10px;
  text-transform: uppercase;

  @media (max-width: 955px) {
    padding: 1px 2px;
    top: 8px;
    left: 14px;
  }
`;
export const TagOnlineViewers = styled.div`
  color: white;
  background: rgba(0, 0, 0, 0.2);
  padding: 6px 8px;
  border-radius: 2px;
  position: absolute;
  z-index: 20;
  top: 10px;
  left: ${({ aoVivo: l }) => (l ? 100 : 20)}px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  img {
    margin-right: 8px;
  }

  @media (max-width: 955px) {
    padding: 1px 8px;
    top: 8px;
    left: 22px;
    margin-top: 20px;
  }
`;

export const TagOnlineViewersHorizontal = styled.div`
  color: white;
  background: rgba(0, 0, 0, 0.2);
  padding: 6px 8px;
  border-radius: 2px;
  position: absolute;
  z-index: 20;
  top: 10px;
  left: ${({ aoVivo: l }) => (l ? 100 : 20)}px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  img {
    margin-right: 8px;
  }

  @media (max-width: 955px) {
    padding: 1px 8px;
    margin-top: -2px;
    margin-left: -32px;
  }
`;

export const Controls = styled.div`
  min-height: 30px;
  max-height: 50px;
  -webkit-z-index: 3;
  z-index: 3;
  display: flex;
  position: absolute;
  align-items: center;
  bottom: 0px;
  width: 100%;
  transition: all 0.3s;
  opacity: ${({ showControls: s }) => (s ? 1 : 0)};
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0 0 12px 12px;
  @media (max-width: 955px) {
    border-radius: 0px;
    /* bottom: ${({ vertical: v }) => (v ? 10 : "auto")}; */
    background: ${({ vertical: v }) =>
      v
        ? "transparent"
        : "linear-gradient(0deg, rgba(0, 0, 0, 0.27) 0%, rgba(0, 0, 0, 0) 100%);"};
  }
  ${({ vertical, verticalChatHeight, tabActive }) =>
    vertical
      ? `
      height: 44px;
      bottom: ${tabActive ? verticalChatHeight : 55}px;
      position: fixed;
      left: 0;`
      : ""}
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    margin: 0 10px;
  }
`;

export const LevelsControl = styled(Controls)`
  display: flex;
  align-items: center;
  justify-content: ${({ showViewers: sv }) =>
    sv ? "space-between" : "flex-end"};
  width: 100%;

  @media (max-width: 955px) {
    border-radius: 0px;
    /* bottom: ${({ vertical: v }) => v && 10}; */
    bottom: 170px;
    width: 100%;
  }
`;
export const UserOnline = styled.div`
  color: white;
  font-size: 13px;
  padding: 10px 13px;
  bottom: 150px;
  left: 0;
  z-index: 2;
  width: 45px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Intro = styled.div`
  z-index: 1;
  -webkit-appearance: none;
  appearance: none;
  flex: 1;
  height: 4px;
  background: rgba(255, 255, 255, 0.3);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
`;
export const Slider = styled.input`
  width: ${({ width }) => width};
  z-index: 2;
  -webkit-appearance: none;
  appearance: none;
  height: 4px;
  background: white;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  :disabled {
    background: yellow !important;
    margin-left: 10px;
  }
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background: ${({ corPrimaria: c }) => c};
    cursor: pointer;
    ${({ disabled: d }) => d && `display: none;`}
  }
  ::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: ${({ corPrimaria: c }) => c};
    cursor: pointer;
    ${({ disabled: d }) => d && `display: none;`}
  }
`;
export const PlayAndPause = styled.button`
  z-index: 1;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  width: 40px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  :disabled {
    display: none;
  }
  * {
    color: white;
    font-size: 20px;
  }
`;
export const ToggleFullscreen = styled.button`
  z-index: 1;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  width: 40px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  :disabled {
    display: none;
  }
  * {
    color: white;
    font-size: 20px;
  }
`;
export const MutedVideo = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ showControls: s }) => (s ? 1 : 0)};
  transition: 0.3s all;
  .line-muted-video {
    border-radius: 0;
    transform: rotate(45deg);
    border-left: 2px solid white;
    height: calc(30px + 1vw);
    position: absolute;
  }
`;
export const VolumeContainer = styled.div`
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
`;
export const Volume = styled.div`
  position: relative;
  padding: 0 10px 0 10px;
  * {
    color: white !important;
    font-size: 16px;
  }
  .volume-muted {
    bottom: 3px;
    right: 18px;
    border-radius: 0;
    transform: rotate(45deg);
    border-left: 2px solid white;
    position: absolute;
    height: 20px;
  }
`;
export const Poppup = styled.div`
  transition: 3.5s all;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 4;
  cursor: pointer;
  width: ${({ vertical: v, realtime: r }) =>
    v && r ? "calc(40px + 5.5vw)" : "calc(40px + 5vw)"};
  height: ${({ vertical: v, realtime: r }) =>
    v && r ? "calc(40px + 5.5vw)" : "calc(40px + 5vw)"};
  /* max-width: 130px;
  max-height: var(--test); */
  justify-content: center;
  /* position: ${({ vertical: v, realtime: r }) =>
    ((!v && r) || !r) && "absolute"}; */

  ${({ bottom: b, realtime: r }) =>
    !b && !r ? `top: calc(5px + 4vw);` : !r && `top: calc(25px + 14vw);`}

  ${({ vertical: v, realtime: r }) => v && r && "margin-right: 20px;"}

  ${({ vertical: v, realtime: r }) => v && r && "margin-left: 20px;"}

  @media (max-width: 1000px) {
    ${({ bottom: b, realtime: r }) =>
      !b && !r ? `top: calc(5px + 3vw);` : !r && `top: calc(25px + 13vw);`}
  }

  // ------------------------------------------------------------------------
  // @todo - Ajustar depois do novo layout
  /* ${({
    vertical: v,
    realtime: r,
    positionNumber: p,
    show1PosNumber: s1,
    show2PosNumber: s2,
    showOne: showOne,
  }) =>
    v &&
    r &&
    ((showOne && p === 5 && s2 !== 3) ||
      (showOne && p === 6 && s2 !== 4) ||
      (!showOne && p === 5 && s1 !== 3) ||
      (!showOne && p === 6 && s1 !== 4) ||
      (showOne && p === 3 && ((s2 !== 1 && s2 !== 5) || s2 === 5)) ||
      (showOne && p === 4 && ((s2 !== 2 && s2 !== 6) || s2 === 6)) ||
      (!showOne && p === 3 && ((s1 !== 1 && s1 !== 5) || s1 === 5)) ||
      (!showOne && p === 4 && ((s1 !== 2 && s1 !== 6) || s1 === 6)))
      ? "margin-top: auto;"
      : (p === 1 || p === 2) && v && r
      ? "margin-top: 50px;"
      : "margin-top: 20px;"}

${({
    vertical: v,
    realtime: r,
    positionNumber: p,
    show1PosNumber: s1,
    show2PosNumber: s2,
    showOne: showOne,
  }) =>
    v &&
    r &&
    ((showOne && p === 1 && s2 !== 3) ||
      (showOne && p === 2 && s2 !== 4) ||
      (!showOne && p === 1 && s1 !== 3) ||
      (!showOne && p === 2 && s1 !== 4) ||
      (showOne && p === 3 && s2 !== 1 && s2 !== 5) ||
      (showOne && p === 4 && s2 !== 2 && s2 !== 6) ||
      (!showOne && p === 3 && s1 !== 1 && s1 !== 5) ||
      (!showOne && p === 4 && s1 !== 2 && s1 !== 6))
      ? "margin-bottom: auto;"
      : "margin-bottom: 20px;"} */

  /* ${({ positionNumber: pos, vertical: v }) =>
    !v &&
    (pos === 1
      ? "left: calc(10px + 5vw); top: 20px;"
      : pos === 2
      ? "left: calc(10px + 22.5vw); top: 20px;"
      : pos === 3
      ? "right: calc(10px + 5vw); top: 20px;"
      : pos === 4
      ? "left: calc(10px + 5vw); top: calc(15px + 16vw);"
      : pos === 5
      ? "left: calc(10px + 22.5vw);top: calc(15px + 16vw);"
      : pos === 6 && "right: calc(10px + 5vw);top: calc(15px + 16vw);")} */

  /* @media(max-width: 1200px) {
    ${({
    vertical: v,
    realtime: r,
    positionNumber: p,
    show1PosNumber: s1,
    show2PosNumber: s2,
    showOne: showOne,
  }) =>
    v &&
    r &&
    ((showOne && p === 5 && s2 !== 3) ||
      (showOne && p === 6 && s2 !== 4) ||
      (!showOne && p === 5 && s1 !== 3) ||
      (!showOne && p === 6 && s1 !== 4) ||
      (showOne && p === 3 && ((s2 !== 1 && s2 !== 5) || s2 === 5)) ||
      (showOne && p === 4 && ((s2 !== 2 && s2 !== 6) || s2 === 6)) ||
      (!showOne && p === 3 && ((s1 !== 1 && s1 !== 5) || s1 === 5)) ||
      (!showOne && p === 4 && ((s1 !== 2 && s1 !== 6) || s1 === 6)))
      ? "margin-top: auto;"
      : (p === 1 || p === 2) && v && r
      ? "margin-top: 50px;"
      : "margin-top: 0px;"}
  } */
  // ------------------------------------------------------------------------

  // ------------------ PHONE ----------------------------------

  @media (max-width: 955px) {
    /* ${({ vertical: v }) => v && `margin-top: 50px;`} */
    width: calc(50px + 5vh);
    height: calc(50px + 7vh);
    // @todo - Ajustar depois do novo layout
    /* width: ${({ vertical: v, realtime: r }) =>
      v && r ? "calc(50px + 7vw);" : "calc(50px + 7vw);"};
    height: ${({ vertical: v, realtime: r }) =>
      v && r ? "calc(40px + 7vw);" : "calc(40px + 7vw);"}; */
    ${({ bottom: b, vertical: v }) =>
      v
        ? !b
          ? `top: calc(5px + 30vw);`
          : `top: calc(45px + 50vw);`
        : !b
        ? `top: calc(5px + 28vw);`
        : `top: calc(45px + 53vw);`}

    ${({ vertical: v, realtime: r }) =>
      v && r && "margin-right: 0px; margin-left: 15px;"}

    // ------------------------------------------------------------------------
    // @todo - Ajustar depois do novo layout
    /* ${({
      vertical: v,
      realtime: r,
      positionNumber: p,
      show1PosNumber: s1,
      show2PosNumber: s2,
      showOne: showOne,
      aoVivo: live,
      bottom: b,
    }) =>
      v &&
      r &&
      ((showOne && p === 5 && s2 !== 3) ||
        (showOne && p === 6 && s2 !== 4) ||
        (!showOne && p === 5 && s1 !== 3) ||
        (!showOne && p === 6 && s1 !== 4) ||
        (showOne && p === 3 && ((s2 !== 1 && s2 !== 5) || s2 === 5)) ||
        (showOne && p === 4 && ((s2 !== 2 && s2 !== 6) || s2 === 6)) ||
        (!showOne && p === 3 && ((s1 !== 1 && s1 !== 5) || s1 === 5)) ||
        (!showOne && p === 4 && ((s1 !== 2 && s1 !== 6) || s1 === 6)))
        ? "margin-top: auto;"
        : (p === 1 || p === 2) && live
        ? "margin-top: calc(15px + 15vw);"
        : b
        ? "margin-top: calc(10px + 6vw);"
        : "margin-top: calc(10px + 4vw);"}

${({
      vertical: v,
      realtime: r,
      positionNumber: p,
      show1PosNumber: s1,
      show2PosNumber: s2,
      showOne: showOne,
    }) =>
      v &&
      r &&
      ((showOne && p === 1 && s2 !== 3) ||
        (showOne && p === 2 && s2 !== 4) ||
        (!showOne && p === 1 && s1 !== 3) ||
        (!showOne && p === 2 && s1 !== 4) ||
        (showOne && p === 3 && s2 !== 1 && s2 !== 5) ||
        (showOne && p === 4 && s2 !== 2 && s2 !== 6) ||
        (!showOne && p === 3 && s1 !== 1 && s1 !== 5) ||
        (!showOne && p === 4 && s1 !== 2 && s1 !== 6))
        ? "margin-bottom: auto;"
        : "margin-bottom: calc(15px + 8vw);"} */

    /* ${({ positionNumber: pos, vertical: v }) =>
      !v &&
      (pos === 1
        ? "left: calc(10px + 5vw);"
        : pos === 2
        ? "left: calc(10px + 36vw);"
        : pos === 3
        ? "right: calc(10px + 5vw);"
        : pos === 4
        ? "left: calc(10px + 5vw); top: calc(25px + 26vw);"
        : pos === 5
        ? "left: calc(10px + 36vw);top: calc(25px + 18vw);"
        : pos === 6 && "right: calc(10px + 5vw);top: calc(25px + 18vw);")} */
  }

  //Iphone 5/SE
  /* @media (min-device-height: 568px) and (max-device-width: 320px) and (orientation: portrait) {
    ${({ bottom: b }) =>
    !b ? `top: calc(5px + 25vw);` : `top: calc(45px + 45vw);`}
  } */

  //Iphone 6/7/8 Plus
  /* @media (min-device-height: 736px) and (max-device-width: 414px) and (orientation: portrait) {
    ${({ bottom: b }) =>
    !b ? `top: calc(5px + 30vw);` : `top: calc(45px + 50vw);`}
  } */

  //Iphone X
  /* @media (min-device-height: 812px) and (orientation: portrait) {
    ${({ bottom: b }) =>
    !b ? `top: calc(5px + 44vw);` : `top: calc(45px + 64vw);`}
  } */

  //Pixel 2 XL
  /* @media (min-device-height: 823px) and (orientation: portrait) {
    ${({ bottom: b }) =>
    !b ? `top: calc(5px + 42vw);` : `top: calc(45px + 62vw);`}
  } */

  // ------------------------------------------------------------------------

  // ------------------------------------------------------------

  ${({ ladoCard: l, realtime: r }) =>
    l === "direita" && !r
      ? `right: calc(10px + 4vw);`
      : !r && `left: calc(10px + 4vw);`}

  border-radius: 10px;
  label {
    background: var(--corPrimaria);
    color: white;
    width: 95px;
    text-align: center;
    z-index: 1;
    font-size: 12px;
    border-radius: 4px;
    transform: translateY(-8px);

    @media (max-width: 955px) {
      margin-top: 3px;
      transform: translateY(-13px);
      align-self: center;
      padding: 3px 5px;
    }
  }
  .img-product {
    max-width: 100%;
    max-height: 100%;
    /* max-height: calc(100% - 2vw); */
    ${({ indisponivel: ind }) => (ind ? "opacity: 0.85;" : null)}
    @media (max-width: 955px) {
      /* max-height: var(--test); */
    }
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }

  .product-price {
    display: flex;
    background: ${({ corPrimaria: c }) => c};
    color: white;
    border-radius: 4px;
    font-size: 13px;
    padding: 3px 5px;
    box-shadow: 0px 6.24438px 18.7331px rgba(0, 0, 0, 0.05);
    transform: translateY(-12px);
    z-index: 1;
    h5 {
      margin: auto 2px 5px 0;
      font-size: 6px;
      color: white;
    }
  }

  // @todo - Ajustar depois do novo layout
  /* article {
    display: flex;
    align-items: flex-end;
    background: ${({ corPrimaria: c }) => c};
    color: white;
    border-radius: 12px;
    padding: calc(5px + 0.4vw);
    font-size: calc(10px + 0.35vw);
    line-height: calc(10px + 0.35vw);
    margin-bottom: -1vw;
    margin-right: -1vw;
    margin-left: auto;
    z-index: 5;
    h5 {
      margin: 0 2px 1px 0;
      font-size: calc(4px + 0.35vw);
      line-height: calc(4px + 0.35vw);
      color: white;
    }
  } */
`;
export const WaterMark = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  z-index: 2;
  text-align: center;
  transform: rotate(-20deg);
  font-size: 4vw;
  font-weight: bold;
  color: white;

  @media (max-width: 955px) {
    font-size: 30px;
  }
`;

export const RealtimeCardWrapper = styled.div`
  width: 33%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  align-items: ${({ left: l }) => (l ? "flex-end" : "flex-start")};
  padding-right: ${({ left: l }) => l && "35px"};
  padding-left: ${({ left: l }) => !l && "10px"};
  position: absolute;
  z-index: 4;
  left: ${({ left: l }) => (l ? "67%" : 0)};
  top: 0px;

  @media (max-width: 955px) {
    height: 65vh;
  }
`;
