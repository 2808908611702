import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, message, Radio } from "antd";
import { formatCash } from "../../../utils";
import { customerService } from "../../../services";
import * as s from "./styles-cart";

const Frete = ({
  formaEntrega: { correios, retirada },
  setEnderecoEntrega,
  enderecoEntrega,
  produtos,
  setFreteSelecionado,
  freteSelecionado,
  liveId,
  openShippingModal,
  unidadeMonetaria,
}) => {
  const { t } = useTranslation();
  const [cep, setCep] = useState();
  const [shippingValues, setShippingValues] = useState(false);
  const [shippingSelected, setShippingSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveTime, setSaveTime] = useState(false);
  const [changes, setChanges] = useState(false);
  const [oldProducts, setOldProcuts] = useState(produtos);

  useEffect(() => {
    if (shippingSelected) {
      if (shippingSelected === "retirada")
        setFreteSelecionado({
          type: "retirada",
          value: null,
          shippingTime: null,
        });
      else {
        let selected = shippingValues.filter(
          (s) => s.label === shippingSelected
        )[0];
        if (selected)
          setFreteSelecionado({ ...selected, value: parseInt(selected.value) });
      }
    }
  }, [shippingSelected]);

  const handleClearShippingStates = useCallback(() => {
    setShippingValues(false);
    setShippingSelected(false);
  }, [setShippingValues, setShippingSelected]);

  useEffect(
    () => !freteSelecionado && shippingSelected && handleClearShippingStates(),
    [freteSelecionado]
  );

  useEffect(() => {
    if (enderecoEntrega?.zipcode + "" !== cep + "") {
      handleClearShippingStates();
      setCep(enderecoEntrega.zipcode);
      checkCep(enderecoEntrega.zipcode);
    } else if (enderecoEntrega?.zipcode === cep && enderecoEntrega.state) {
      if (!correios) getFreteProprioValues(enderecoEntrega.state);
    }
  }, [enderecoEntrega]);

  useEffect(() => {
    if (produtos?.length > 0 && cep) {
      let hasChanges = false;
      for (let i = 0; i < produtos.length; i++) {
        if (
          !oldProducts[i] ||
          produtos[i].quantidade !== oldProducts[i].quantidade
        )
          hasChanges = true;
      }
      if (hasChanges) {
        setOldProcuts(produtos);
        setChanges(changes === 1 ? 2 : 1);
      }
    }
  }, [produtos]);

  useEffect(() => {
    if (correios) {
      if (changes) {
        if (saveTime) clearTimeout(saveTime);
        setSaveTime(
          setTimeout(() => {
            setLoading(true);
            setChanges(false);
            getCorreiosValues(cep);
            handleClearShippingStates();
          }, 2000)
        );
      }
    }
  }, [changes]);

  const getCorreiosValues = (cepDestino) =>
    customerService
      .getCorreiosValues(
        {
          cepOrigem: correios.cepOrigem,
          cepDestino,
          produtos,
        },
        liveId
      )
      .then((data) => {
        setLoading(false);
        if (data.length > 0) {
          setShippingValues(data);
          setFreteSelecionado(false);
        } else message.error(`${t("SHIPPING.NoShippingFound")}!`);
      })
      .catch(() => {
        setLoading(false);
        message.error(t("SHIPPING.ErrorGetFreight"));
      });

  const getFreteProprioValues = (searchState) =>
    customerService
      .getShippingValue(liveId, searchState.toUpperCase())
      .then(({ value, shippingTime }) => {
        setLoading(false);
        if (value || value === 0) {
          setShippingValues([
            {
              value: parseInt(value),
              shippingTime,
              label: "freteProprio",
              freeShipping: parseInt(value) === 0,
            },
          ]);
        } else
          message.error({
            content: t("SHIPPING.ErrorGetFreightState"),
            duration: 7,
          });
      })
      .catch(() => {
        setFreteSelecionado();
        message.error({
          content: t("SHIPPING.ErrorGetFreightState"),
          duration: 7,
        });
        setLoading(false);
      });

  const checkCep = (changedCep) => {
    let cepSearch = changedCep ? changedCep : cep;
    if (cepSearch?.length >= 8) {
      setFreteSelecionado(false);
      setShippingValues(false);
      setLoading(true);
      customerService
        .validateCep(cepSearch)
        .then((data) => {
          if (data.erro) {
            setLoading(false);
            if (correios) {
              message.error(t("SHIPPING.ErrorGetFreightCEP"));
            } else {
              if (enderecoEntrega.state)
                getFreteProprioValues(enderecoEntrega.state);
              else
                message.info({
                  content: t("SHIPPING.ErrorGetCEP"),
                  duration: 10,
                });
            }
          } else {
            setEnderecoEntrega({
              zipcode: cepSearch,
              country: "BR",
              state: data.uf || "",
              city: data.localidade || "",
              street: data.logradouro || "",
              neighborhood: data.bairro || "",
            });
            let searchState = data.uf || enderecoEntrega.state || false;
            if (correios) getCorreiosValues(cepSearch);
            else if (searchState) getFreteProprioValues(searchState);
          }
        })
        .catch(() => getCorreiosValues(cepSearch));
    }
  };

  const renderValue = () => {
    let selected = shippingValues
      ? shippingValues.filter((s) => s.label === shippingSelected)[0]
      : 0;

    return (
      <p>
        {!selected.freeShipping
          ? `${formatCash(selected.value / 100, false, unidadeMonetaria)}`
          : t("SHIPPING.FreeShipping") +
            "-" +
            selected.shippingTime +
            " " +
            t("SHIPPING.UsefullDays")}
      </p>
    );
  };

  return (
    <div
      style={{ maxWidth: "300px", display: "flex", flexDirection: "column" }}
    >
      <label>
        {loading
          ? t("SHIPPING.Calculating")
          : `${t("SHIPPING.CalcShipping")} -`}
        {!loading && shippingSelected !== "retirada" && (
          <a onClick={() => openShippingModal()}>
            {t("SHIPPING.AlterAddress")}
          </a>
        )}
      </label>
      <s.CalculateInput>
        <Input
          type="text"
          name="postal-code"
          placeholder={t("SHIPPING.CEP")}
          value={cep || ""}
          maxLength={8}
          onChange={({ target: t }) => setCep(t.value.replace(/\D/g, ""))}
          style={{ width: "200px" }}
          disabled={loading}
          loading={loading}
        />
        <Button onClick={() => checkCep(cep)} style={{ margin: "0" }}>
          {t("SHIPPING.Calculate")}
        </Button>
      </s.CalculateInput>

      <Radio.Group
        style={{ display: "grid" }}
        onChange={({ target: t }) => setShippingSelected(t.value)}
        value={shippingSelected}
      >
        {retirada ? (
          <>
            <Radio value={"retirada"}>
              {`${t("SHIPPING.TakeAt")}${
                retirada?.deadlineForTake
                  ? ` - ${retirada.deadlineForTake} ${t(
                      "SHIPPING.UsefullDays"
                    )}`
                  : ""
              }`}
            </Radio>
            {retirada && shippingValues?.length > 0 && (
              <>
                {shippingValues.map((shipping) => (
                  <Radio value={shipping.label} key={shipping.label}>
                    {shipping.label !== "freteProprio" &&
                      shipping.label + " - "}
                    {shipping.freeShipping
                      ? t("SHIPPING.FreeShipping")
                      : formatCash(
                          parseInt(shipping.value) / 100,
                          false,
                          unidadeMonetaria
                        )}
                    - {shipping.shippingTime} {t("SHIPPING.UsefullDays")}
                  </Radio>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            {shippingValues?.length > 0 && !retirada && (
              <>
                {shippingValues.map((shipping) => (
                  <Radio value={shipping.label} key={shipping.label}>
                    {shipping.label !== "freteProprio" &&
                      shipping.label + " - "}
                    {shipping.freeShipping
                      ? t("SHIPPING.FreeShipping")
                      : formatCash(
                          parseInt(shipping.value) / 100,
                          false,
                          unidadeMonetaria
                        )}{" "}
                    - {shipping.shippingTime} {t("SHIPPING.UsefullDays")}
                  </Radio>
                ))}
              </>
            )}
          </>
        )}
        {retirada && shippingSelected === "retirada" && (
          <label style={{ fontSize: "12px", color: "gray" }}>{`${
            retirada.street
          }, ${retirada.streetNumber} - ${retirada.neighborhood}. ${
            retirada.city
          }, ${retirada.state.toUpperCase()} - ${retirada.zipcode}`}</label>
        )}
        {shippingSelected && shippingSelected !== "retirada" && renderValue()}
      </Radio.Group>
    </div>
  );
};

export default Frete;
