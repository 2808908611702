import { useCallback, useState } from "react";
import { paymentService } from "../../services";

const usePayment = () => {
  const [services, setServices] = useState({ pagarme: [] });
  const [pagarmeFees, setPagarmeFees] = useState({});

  const addPagarmeRecipient = useCallback(
    (recipient) =>
      setServices({ ...services, pagarme: [...services.pagarme, recipient] }),
    [services, setServices]
  );

  const resetPayments = useCallback(
    () => setServices({ pagarme: [] }),
    [setServices]
  );

  const getPagarmeFees = useCallback(
    () => paymentService.getPagarmeFees().then((fees) => setPagarmeFees(fees)),
    []
  );


  return {
    services,
    setServices,
    addPagarmeRecipient,
    resetPayments,
    pagarmeFees,
    setPagarmeFees,
    getPagarmeFees,
  };
};

export default usePayment;
