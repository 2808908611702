import React, { useEffect, useState } from "react";
import { Input, Modal, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars";
import {
  preventInvalidNumberOnPaste,
  preventInvalidNumberOnKeyDown,
  fakeMaskChange
} from "../../../../utils";
import * as s from "./styles";

const ModalShipping = ({
  visible,
  onOk,
  onCancel,
  shippingData = [],
  unidadeMonetaria,
}) => {
  const { t } = useTranslation();
  const [shippingValues, setShippingValues] = useState([]);
  const [shippingFreteAll, setShippingFreteAll] = useState(true);

  useEffect(() => {
    setShippingValues(
      shippingData.map((shipping) => ({
        ...shipping,
        shippingTime: parseInt(shipping.shippingTime),
        value: fakeMaskChange(shipping.value / 100),
        freeShipping: shipping.value / 100 === 0 ? true : false,
      })
      )
    );
  }, [shippingData]);

  const handleChangeShipping = (value, index, field, updatedValues) => {
    let valuesArray = updatedValues ? updatedValues : shippingValues;
    return setShippingValues(
      valuesArray.map((shipping, i) =>
        index === i ? { ...shipping, [field]: value } : shipping
      )
    );
  };

  const isDone = () => {
    let test = false;
    // shippingValues.map((shipp) => {
    //   if (!shipp.value && parseInt(shipp.value) !== 0) test = true;
    //   if (!shipp.shippingTime && parseInt(shipp.shippingTime) !== 0)
    //     test = true;
    //   if (!shipp.value && parseInt(shipp.shippingTime) !== 0) test = true;
    // });

    shippingValues.map((shipp) => {
      if (shipp.freeShipping === false && parseInt(shipp.value) === 0)
        test = true;
      if (shipp.freeShipping === false && shipp.value === "") test = true;
      if (parseInt(shipp.shippingTime) === 0) test = true;
    });

    return test;
  };

  return (
    <Modal
      visible={visible}
      title={
        <div>
          {t("LIVE-TAB-SHIPPING-MODAL.ShippingTable")}
          <Checkbox
            checked={shippingFreteAll}
            onChange={(value) => {
              setShippingFreteAll(value.target.checked)
              setShippingValues(
                shippingValues.map((shipping, i) => {
                  return { ...shipping, ["freeShipping"]: value.target.checked, ["value"]: "0,00" }
                })
              );
            }}
            style={{ margin: "0 auto 0 40px" }}
          >
            {shippingFreteAll ? t("LIVE-TAB-SHIPPING-MODAL.ShippingSelectAllDisable") : t("LIVE-TAB-SHIPPING-MODAL.ShippingSelectAllEnable")}
          </Checkbox>
        </div >
      }
      onOk={() =>
        onOk(
          shippingValues.map((s) => ({
            ...s,
            shippingTime: s.shippingTime,
            value: s.value.toString().replace(/[^\d]/g, "") * 100,
          }))
        )
      }
      okButtonProps={{
        disabled: isDone(),
      }}
      onCancel={onCancel}
      okText={t("MODAL-SHIPPING.Confirm")}
      cancelText={t("MODAL-PAYMENT.Cancel")}
      bodyStyle={{ padding: 0 }}
      cancelButtonProps={{
        style: { display: "none" },
      }}
    >
      <Scrollbars style={{ width: "100%", height: "500px" }}>
        {shippingValues.map(
          ({ label, value, shippingTime, freeShipping }, i) => (
            <s.Shipping key={label}>
              <label>{label}</label>
              <Checkbox
                checked={freeShipping}
                onChange={() => {
                  let tmp = [...shippingValues];
                  tmp[i] = { ...tmp[i], freeShipping: !freeShipping };
                  handleChangeShipping(!freeShipping, i, "freeShipping");
                  if (!freeShipping) handleChangeShipping("0,00", i, "value", tmp);
                }}
                style={{ margin: "0 auto 0 40px" }}
              >
                {t("LIVE-TAB-SHIPPING-MODAL.FreeShipping")}
              </Checkbox>
              <Input
                disabled={freeShipping}
                value={value}
                onChange={({ target: { value: v } }) => {
                  if (v.replace(/[^\d]/g, "").length <= 7) {
                    let tmp = [...shippingValues];
                    tmp[i] = { ...tmp[i], freeShipping: true, value: fakeMaskChange(v) };
                    handleChangeShipping(fakeMaskChange(v), i, "value");
                    if (v === "0")
                      handleChangeShipping(true, i, "freeShipping", tmp);
                  }
                }}
                style={{ margin: "0 10px 0 auto", width: "130px" }}
                addonBefore={unidadeMonetaria || "R$"}
              />
              <Input
                defaultValue={shippingTime}
                value={parseInt(shippingTime)}
                onPaste={(e) => preventInvalidNumberOnPaste(e)}
                onKeyDown={(e) => preventInvalidNumberOnKeyDown(e)}
                onChange={({ target: { value: v } }) => {
                  if (v >= 0 && !isNaN(v) && v <= 999) {
                    handleChangeShipping(v, i, "shippingTime");
                  }
                }}
                min={0}
                addonAfter={t("LIVE-TAB-SHIPPING-MODAL.WorkDays")}
                style={{ width: "160px" }}
                type="number"
              />
            </s.Shipping>
          )
        )}
      </Scrollbars>
    </Modal >
  );
};

export default ModalShipping;
