import React, { useContext, useState, useEffect } from "react";
import { Form, Input, Button, Modal, message } from "antd";
import { useTranslation } from "react-i18next";
import * as s from "./styled-home";
import { ThemeContext } from "../../context";
import { auth } from "../../services";

const Home = ({ onFinish, loading, findLive }) => {
  const { t } = useTranslation();
  useContext(ThemeContext);

  const [modalEmailIsOpen, setModalEmailIsOpen] = useState(false);
  const [emailDataForm, setEmailDataForm] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);

  const handleChangePassword = () => {
    setLoadingResetPassword(true);
    auth
      .sendPasswordResetEmail(emailDataForm)
      .then(() => {
        message.success(t("APP.EmailSuccessfullySent"));
        setModalEmailIsOpen(false);
      })

      .catch(() => {
        message.error(t("APP.EmailErrorReset"));
      })
      .finally(() => {
        setLoadingResetPassword(false);
      });
  };

  const getFormRules = (type, message, required, requiredMessage) => {
    return [
      {
        type,
        message,
      },
      {
        required: typeof required === "boolean" ? required : true,
        message: requiredMessage || t("UTILS.TypeValidValue"),
      },
    ];
  };

  return (
    <s.Container className="shadow">
      <Modal
        okText={t("APP.SendEmail")}
        okButtonProps={{ loading: loadingResetPassword }}
        cancelText={t("APP.ComeBack")}
        onOk={(e) => {
          e.preventDefault();
          handleChangePassword();
        }}
        visible={modalEmailIsOpen}
        onCancel={() => !loadingResetPassword && setModalEmailIsOpen(false)}
      >
        {t("APP.InformEmail")}
        <Form id="form-Recovery-Password" name="form-Recovery-Password">
          <Form.Item
            validateTrigger="onBlur"
            rules={[
              ...getFormRules("email", "", true, t("HEADER.RequireEmail")),
              {
                pattern: new RegExp(/\S+@\S+\.\S+/),

                message: t("APP.InformValidEmail"),
              },
            ]}
            normalize={(value) =>
              value
                .replace(
                  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
                  ""
                )
                .replace(/\s/g, "")
            }
          >
            <Input
              required
              placeholder={t("APP.Email")}
              id="ipt-email-login"
              disabled={loading}
              onChange={(value) => {
                setEmailDataForm(value.target.value);
              }}
              className="input-style"
            />
          </Form.Item>
        </Form>
      </Modal>
      <article className="fade-in">
        <section>
          <h1>{t("APP.ManagerSpace")}</h1>
          <Form id="form-login" name="form-login" onFinish={onFinish}>
            <Form.Item
              validateTrigger="onBlur"
              rules={[
                ...getFormRules("email", "", true, t("HEADER.RequireEmail")),
                {
                  pattern: new RegExp(/\S+@\S+\.\S+/),

                  message: t("APP.InformValidEmail"),
                },
              ]}
              name={"email"}
              normalize={(value) =>
                value
                  .replace(
                    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
                    ""
                  )
                  .replace(/\s/g, "")
              }
            >
              <Input
                required
                placeholder={t("APP.Email")}
                id="ipt-email-login"
                disabled={loading}
                className="input-style"
              />
            </Form.Item>
            <Form.Item
              name={"password"}
              normalize={(value) =>
                value
                  .replace(
                    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
                    ""
                  )
                  .replace(/\s/g, "")
              }
            >
              <Input.Password
                required
                placeholder={t("APP.Password")}
                id="ipt-password-login"
                disabled={loading}
                className="input-style"
              />
            </Form.Item>
            <a
              className="linkPassword"
              onClick={() => setModalEmailIsOpen(true)}
            >
              {t("APP.ForgotPassword")} <br />
            </a>
            <Form.Item
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Button
                className="styleBtn"
                htmlType="submit"
                loading={loading === 1}
                disabled={loading}
                id="btn-sign-in"
                size="large"
              >
                {t("APP.Enter")}
              </Button>
            </Form.Item>
          </Form>
          <a className="link" href="https://www.4show.live/" target="_blank">
            {t("APP.IwantSell")} <br />
          </a>
        </section>
        <div className="wrapper-line" />
        <section>
          <h1>
            {t("APP.WatchHere")} <br />
          </h1>
          <Form
            name="url-form"
            onFinish={({ url }) => findLive(url)}
            id="url-form"
          >
            <Form.Item
              name={"url"}
              normalize={(value) =>
                value
                  .replace(
                    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
                    ""
                  )
                  .replace(/\s/g, "")
              }
            >
              <Input
                required
                placeholder={t("APP.LiveCode")}
                id="url-login"
                disabled={loading}
                className="input-style"
              />
            </Form.Item>
            <Form.Item
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Button
                className="styleBtn"
                htmlType="submit"
                disabled={loading}
                loading={loading === 2}
                size="large"
                color="#944AF2"
              >
                {t("APP.Search")}
              </Button>
            </Form.Item>
          </Form>
        </section>
      </article>
    </s.Container>
  );
};

export default Home;
