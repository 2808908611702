import { axiosApp } from ".";
//-------------------------------------------------------------
//-------------------------Services----------------------------
//-------------------------------------------------------------
const getServices = () =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`/payment/services`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const getPagarmeFees = () =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`/manager/fee`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//-------------------------Order----------------------------
//-------------------------------------------------------------
const finishOrder = (live, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`/payment/order/${live}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const refundOrder = (live, order, customer) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`/payment/refund/${live}/${order}`, customer)
      .then(({ data: { pedidos } }) => resolve(pedidos))
      .catch((e) => reject(e));
  });

const finishOrderWithPix = (live, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`/payment/order-pix/${live}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });

export const paymentService = {
  //services
  getServices,
  getPagarmeFees,
  //order
  finishOrder,
  refundOrder,
  finishOrderWithPix,
};
