import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, message } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { ThemeContext, useTabsNavigation, UserContext } from "../../context";
import * as s from "./styles-tab-navigation";
import { customerService } from "../../services";

const TabNavigationVertical = ({ live }) => {
  const { Search } = Input;
  const { user, setOpenedFrom, openedFrom } = useContext(UserContext); //dados do usuário
  const { corPrimaria, corSecundaria } = useContext(ThemeContext);
  const { tabActive, handleChangeTabActive, cartBadge } = useTabsNavigation();
  const { t } = useTranslation();
  const externalCheckout = live.checkout === "externo";

  const [msg, setMsg] = useState(""); //mensagem
  const [isChatFocused, setIsChatFocused] = useState(false);

  const tabs = [
    {
      key: "chat",
      icon: "/assets/icons/chat.svg",
    },
    {
      key: "chat-input",
    },
    {
      key: "products",
      icon: "/assets/icons/products-white.svg",
    },
    {
      key: "orders",
      icon: "/assets/icons/order.svg",
      label: t("REPORTS-TAB.Orders"),
    },
    {
      key: "cart",
      icon: "/assets/icons/cart.svg",
      badge: cartBadge,
    },
  ];

  const sendMsg = (mensagem) => {
    setMsg("");
    customerService.sendMessage(live.liveId, live.salaId, {
      mensagem,
      customer: { ...user, codigo: user.codigo || user.hash },
    });
  };

  if (window.innerWidth < 955)
    return (
      <s.TabsVerticalContainer
        bg={corPrimaria}
        badgeColor={corSecundaria}
        isChatFocused={isChatFocused}
      >
        {tabs.map(({ key, icon, label, badge = 0 }, idx) => {
          if (externalCheckout && (key === "orders" || key === "cart")) return "";
          if (key === "chat-input") {
            return (
              <Search
                placeholder={
                  live?.chat === false
                    ? t("CHAT.ChatBloqued")
                    : !live?.salaId
                      ? t("CHAT.LiveWithoutChat")
                      : user?.name
                        ? `${t("CHAT.Comment")}`
                        : t("CHAT.LoginToComment")
                }
                size="middle"
                enterButton={<SendOutlined />}
                onSearch={(v) => user?.name && sendMsg(v)}
                value={msg}
                id={"input-chat-message-vertical"}
                onClick={() => {
                  if (!user?.name) {
                    let thisInput = document.getElementById(
                      "input-chat-message-vertical"
                    );
                    if (thisInput) thisInput.blur();
                    if (!openedFrom) {
                      message.info(t("CHAT.FillNameToInteract"), 3);
                      document.getElementById("btn-login-customer").click();
                      setOpenedFrom("chat");
                      setTimeout(() => {
                        document.getElementById("input-customer-name").focus();
                      }, 500);
                    }
                  }
                }}
                onFocus={() => {
                  if (!openedFrom) {
                    setIsChatFocused(true);
                  }
                }}
                onBlur={() => {
                  // let btnChatScrollUtil = document.getElementById(
                  //   "btn-chat-vertical-scroll-util"
                  // );
                  // if (btnChatScrollUtil) btnChatScrollUtil.click();
                  setIsChatFocused(false);
                }}
                onChange={({ target: t }) => user?.name && setMsg(t.value)}
                disabled={live.chat === false || !live.salaId}
                className={`form-send-msg-chat animated-linear`}
              />
            );
          }
          return (
            <button
              className={`animated-linear fade-in`}
              style={{ display: isChatFocused && idx > 1 && "none" }}
              onClick={() => {
                tabActive === key
                  ? handleChangeTabActive(false)
                  : handleChangeTabActive(key);
              }}
              key={key}
            >
              {badge >= 1 && (
                <span style={{ width: badge > 99 ? 25 : badge > 9 ? 20 : 15 }}>
                  {badge}
                </span>
              )}
              <img src={icon} alt={label} />
            </button>
          );
        })}
      </s.TabsVerticalContainer>
    );
  return <></>;
};

export default TabNavigationVertical;
