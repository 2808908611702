import { axiosApp } from ".";
//-------------------------------------------------------------
//---------------------------MANAGER---------------------------
//-------------------------------------------------------------
const updateCredentialsNeomode = (credentials) =>
  new Promise((resolve, reject) =>
    axiosApp
      .put(`/neomode/updateCredentials`, credentials)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e))
  );
const getCatalogNeomode = (
  categoryId = null,
  page = null,
  items = null,
  query = null
) =>
  new Promise((resolve, reject) => {
    let params = { page, items };
    if (query) params.query = query;
    else if (categoryId) params.categoryId = categoryId;

    return axiosApp
      .get(`/neomode/catalog`, { params })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const getCategoriesNeomode = () =>
  new Promise((resolve, reject) =>
    axiosApp
      .get(`/neomode/categories`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e))
  );
//-------------------------------------------------------------
//---------------------------CUSTOMER--------------------------
//-------------------------------------------------------------
const checkCustomerEmail = (liveId, email) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`/neomode/customer/exists/${liveId}`, { params: { email } })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const login = (liveId, body = { email: "", password: "", codigo: "" }) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`/neomode/customer/login/${liveId}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const createCustomer = (
  liveId,
  body = {
    email: "",
    fullName: "",
    cpf: "",
    phoneNumber: "",
    password: "",
    dateOfBirth: "",
  }
) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`/neomode/customer/create/${liveId}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const recoveryPassword = (liveId, email) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`/neomode/customer/recoveryPassword/${liveId}`, { email })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const getSellers = (liveId, lat, long, codigo) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`/neomode/checkout/sellers/${liveId}`, {
        params: { lat, long, codigo },
      })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e?.response?.data?.message));
  });
const getShippingList = (liveId, codigo, seller, cep) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`/neomode/checkout/freights/${liveId}`, {
        params: { seller, cep, codigo },
      })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });

const getAddressList = (liveId, codigo) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`/neomode/customer/address/${liveId}`, {
        params: { codigo },
      })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const createAddress = (
  liveId,
  codigo,
  {
    label = "Neomode",
    cep = "05411000",
    street = "Rua Cristiano Viana",
    number = "517",
    complement = "",
    neighborhood = "Cerqueira César",
    state = "SP",
    city = "São Paulo",
    isPrimary = false,
  }
) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(
        `/neomode/customer/address/${liveId}`,
        {
          label,
          cep,
          street,
          number,
          complement,
          neighborhood,
          state,
          city,
          isPrimary,
        },
        { params: { codigo } }
      )
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const changeVoucher = (liveId, codigo, voucher, idPayment, installment) => {
  let params = { codigo, idPayment };
  if (installment) params.installment = installment;
  if (voucher) params.voucher = voucher;
  return new Promise((resolve, reject) => {
    axiosApp
      .put(`/neomode/checkout/voucher/${liveId}`, null, { params })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
};

const getPaymentMethods = (
  liveId,
  codigo,
  idModality,
  deliveryName,
  idAddress,
  cpfPickUp
) =>
  new Promise((resolve, reject) => {
    let params = { codigo, idModality };
    if (deliveryName) {
      params.deliveryName = deliveryName;
      params.idAddress = idAddress;
    } else params.cpfPickUp = cpfPickUp;
    axiosApp
      .get(`/neomode/checkout/paymentMethods/${liveId}`, {
        params,
      })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });

const addCreditCard = (liveId, codigo, data) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`/neomode/customer/creditCard/${liveId}`, data, {
        params: { codigo },
      })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });

const getInstallments = (liveId, codigo, idPayment) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`/neomode/checkout/installments/${liveId}`, {
        params: { codigo, idPayment },
      })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });

const finishOrder = (
  liveId,
  codigo,
  idPayment,
  installment,
  cvv,
  observacao
) => {
  let params = {
    codigo,
    idPayment,
  };
  if (installment && cvv) params = { ...params, installment, cvv };
  if (observacao) params = { ...params, observacao };
  return new Promise((resolve, reject) => {
    axiosApp
      .put(`/neomode/checkout/finish/${liveId}`, null, { params })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
};

export const neomodeService = {
  //manager
  getCatalogNeomode,
  getCategoriesNeomode,
  updateCredentialsNeomode,
  //customer
  checkCustomerEmail,
  createCustomer,
  recoveryPassword,
  login,
  getSellers,
  getShippingList,
  getAddressList,
  createAddress,
  changeVoucher,
  getPaymentMethods,
  addCreditCard,
  getInstallments,
  finishOrder,
};
