import axios from "axios";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage";
import "firebase/auth";
import { userService } from "./user";

//envs
const {
  REACT_APP_BASE_URL,
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABASE_URL,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_APP_ID,
  REACT_APP_MEASUREMENT_ID,
} = process.env;

//firebase
const firebaseConfig = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: REACT_APP_DATABASE_URL,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

const auth = firebase.auth();
firebase.auth().useDeviceLanguage();

const storageRef = firebase.storage().ref();
const storage = firebase.storage();
const firestore = firebase.firestore();

//axios
const axiosApp = axios.create({
  timeout: 530000,
  baseURL: REACT_APP_BASE_URL,
});

const requestHandler = async (request) => {
  let storageLanguage = window.localStorage.getItem("currentLanguage");
  if (storageLanguage) storageLanguage = JSON.parse(storageLanguage);
  else storageLanguage = "ptBR";

  let langParam = request.url.includes("?")
    ? `&lng=${storageLanguage}`
    : `?lng=${storageLanguage}`;

  //replace('ptBR', 'pt') para evitar erros com pagar.me
  request.url = request.url + langParam.replace("ptBR", "pt");

  request.headers["Content-Type"] = "application/json;charset=UTF-8";
  request.headers["Access-Control-Allow-Methods"] = "*";
  request.headers["Access-Control-Expose-Headers"] = "Authorization,Location";
  let token = await userService.verifyLogin();
  if (token) request.headers["Authorization"] = `Bearer ${token}`;
  return request;
};
const errorHandler = (error) => error;
[axiosApp].forEach((item) => {
  item.interceptors.request.use(requestHandler, errorHandler);
});

export { firebase, storageRef, storage, auth, axiosApp, firestore };
export * from "./user";
export * from "./manager";
export * from "./customer";
export * from "./product";
export * from "./payment";
export * from "./awin";
export * from "./gtag";
export * from "./neomode";
