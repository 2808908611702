import React, { useContext, useState, useEffect } from "react";
import { Button, Radio, Spin, message, Modal, Input, Select } from "antd";
import MaskedInput from "antd-mask-input";
import { useTranslation } from "react-i18next";
import { useNeomode, UserContext } from "../../context";
import { neomodeService } from "../../services";
import { formatCash, getCreditCardBrand } from "../../utils";

const NeomodePayment = ({ visible, liveId, goNextStep, goBackStep }) => {
  const { t } = useTranslation();
  const { user: user4Show } = useContext(UserContext);
  const {
    shippingSelected,
    addressSelected,
    setPaymentMethodSelected,
    paymentMethodSelected,
    cpfPickUp,
    installment,
    setInstallment,
  } = useNeomode();

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [installments, setInstallments] = useState([]);
  const [paymentStep, setPaymentStep] = useState("options"); //options - installments
  const [paymentStatus, setPaymentStatus] = useState("loading"); // success - error - loading
  const [modalNewCreditCardIsOpen, setModalNewCreditCardIsOpen] =
    useState(false); // success - error - loading
  const [creatingNewCreditCard, setCreatingNewCreditCard] = useState(false); // success - error - loading
  //dados do novo cartão
  const [number, setNumber] = useState("");
  const [expiration, setExpiration] = useState("");
  const [cvv, setCvv] = useState("");
  const [cardHolder, setCardHolder] = useState("");
  const [brand, setBrand] = useState("");
  const [cpfCardHolder, setCpfCardHolder] = useState(user4Show?.codigo);

  const creditCardBrands = [
    { label: "Mastercard", key: "master" },
    { label: "AMEX", key: "amex" },
    { label: "Discover", key: "discover" },
    { label: "Diners", key: "diners" },
    { label: "JCB", key: "jcb" },
    { label: "Visa", key: "visa" },
  ];

  const handleGetPaymentMethods = () => {
    setPaymentStatus("loading");
    setInstallments([]);
    neomodeService
      .getPaymentMethods(
        liveId,
        user4Show.codigo,
        shippingSelected.idModality,
        shippingSelected.isDelivery && shippingSelected.name,
        shippingSelected.isDelivery && addressSelected.id,
        !shippingSelected.isDelivery && cpfPickUp
      )
      .then((data) => {
        setPaymentStatus("success");
        setPaymentMethods(data);
      })
      .catch(() => setPaymentStatus("error"));
  };

  const handleGetInstallments = () => {
    setInstallment(false);
    setPaymentStatus("loading");
    neomodeService
      .getInstallments(liveId, user4Show.codigo, paymentMethodSelected.id)
      .then((data) => {
        setPaymentStatus("success");
        setInstallments(data);
      })
      .catch(() => setPaymentStatus("error"));
  };

  useEffect(() => visible && handleGetPaymentMethods(), [visible]);

  useEffect(() => {
    if (number) {
      let brandIdentified = getCreditCardBrand(number);
      if (brandIdentified) setBrand(brandIdentified);
    }
  }, [number]);

  const handleAddCreditCardNeomode = () => {
    setCreatingNewCreditCard(true);
    let data = {
      number,
      brand,
      cardHolder,
      expiration: expiration?.replace("/", ""),
      cvv,
      cpfCardHolder: cpfCardHolder.replaceAll(".", "").replaceAll("-", ""),
    };
    neomodeService
      .addCreditCard(liveId, user4Show.codigo, data)
      .then(() => {
        setModalNewCreditCardIsOpen(false);
        handleGetPaymentMethods();
        message.success(t("NEOMODE.CreditCardCreateSuccess"));
      })
      .catch(() => message.error(t("NEOMODE.CreditCardCreateError")))
      .finally(() => setCreatingNewCreditCard(false));
  };

  const renderModalNewCreditCard = () => (
    <Modal
      visible={modalNewCreditCardIsOpen}
      onCancel={() =>
        !creatingNewCreditCard && setModalNewCreditCardIsOpen(false)
      }
      footer={false}
      title={t("NEOMODE.CreateCreditCard")}
    >
      <div style={{ display: "flex" }}>
        <div style={{ width: "60%" }}>
          <label>{t("MODAL-PAYMENT.NumberLabel")}</label>
          <Input
            type="number"
            placeholder={t("MODAL-PAYMENT.CardNumber")}
            value={number}
            onChange={({ target: t }) =>
              t.value.length <= 19 && setNumber(t.value)
            }
          />
        </div>
        <div style={{ marginLeft: 24, width: "40%" }}>
          <label>{t("NEOMODE.Brand")}</label>
          <Select
            onChange={(v) => setBrand(v)}
            style={{ width: "100%" }}
            value={brand}
          >
            {creditCardBrands.map(({ label, key }) => (
              <Select.Option value={key} key={key}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <label>{t("MODAL-PAYMENT.Validity")}</label>
          <Input
            type="text"
            placeholder={t("MODAL-PAYMENT.ValidityDate")}
            value={expiration}
            onChange={({ target: { value } }) => {
              if (
                !isNaN(value[value.length - 1]) ||
                value[value.length - 1] === "/" ||
                value === ""
              ) {
                if (value.length === 2 && expiration.length < 2)
                  setExpiration(value + "/");
                else setExpiration(value);
              }
            }}
            maxLength={5}
          />
        </div>
        <div style={{ marginLeft: 24, width: "100%" }}>
          <label>{t("MODAL-PAYMENT.CVV")}</label>
          <Input
            type="number"
            placeholder={t("MODAL-PAYMENT.CardValidationNumber")}
            value={cvv}
            onChange={({ target: t }) => t.value.length <= 4 && setCvv(t.value)}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <label>{t("MODAL-PAYMENT.Name")}</label>
          <Input
            type="text"
            placeholder={t("MODAL-PAYMENT.PrintedName")}
            value={cardHolder}
            onChange={({ target: t }) => setCardHolder(t.value)}
          />
        </div>
        <div style={{ marginLeft: 24, width: "100%" }}>
          <label>{t("NEOMODE.Code")}</label>
          <MaskedInput
            mask="000.000.000-00"
            size="00"
            value={cpfCardHolder}
            placeholder={t("NEOMODE.Code")}
            onChange={({ target: t }) => setCpfCardHolder(t.value)}
          />
        </div>
      </div>

      <Button
        type="primary"
        loading={creatingNewCreditCard}
        style={{ marginTop: 24, marginLeft: "auto" }}
        onClick={handleAddCreditCardNeomode}
        disabled={
          !number ||
          (number && number.length < 13) ||
          !expiration ||
          !cvv ||
          cvv?.length < 3 ||
          !cardHolder ||
          !cpfCardHolder
        }
      >
        {t("NEOMODE.CreateCreditCard")}
      </Button>
    </Modal>
  );

  if (!visible) return <></>;
  if (paymentStep === "options")
    if (paymentStatus === "loading")
      return (
        <div
          style={{
            margin: "20px 0 0 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Spin style={{ margin: "20px auto" }} />
          <label style={{ textAlign: "center" }}>
            {t("NEOMODE.GettingPaymentOptions")}
          </label>
        </div>
      );
    else if (paymentStatus === "success" && paymentMethods.length > 0)
      return (
        <div>
          {renderModalNewCreditCard()}
          <br />
          {t("NEOMODE.ChoosePaymentOption")}
          <div
            style={{
              height: "100%",
              maxHeight: 132,
              overflowY: "auto",
              marginTop: 12,
            }}
          >
            <Radio.Group
              required
              onChange={({ target: t }) => {
                setPaymentMethodSelected(
                  paymentMethods.filter((e) => e.id === t.value)[0]
                );
              }}
              value={paymentMethodSelected?.id}
            >
              {paymentMethods.map(({ id, info }) => (
                <Radio value={id} key={id}>
                  {info}
                </Radio>
              ))}
            </Radio.Group>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 24,
            }}
          >
            <Button onClick={goBackStep}>Voltar</Button>
            <Button onClick={() => setModalNewCreditCardIsOpen(true)}>
              {t("NEOMODE.NewCreditCard")}
            </Button>
            <Button
              disabled={!paymentMethodSelected}
              onClick={() => {
                if (paymentMethodSelected.type === 1) {
                  handleGetInstallments();
                  setPaymentStep("installments");
                } else goNextStep();
              }}
            >
              Continuar
            </Button>
          </div>
        </div>
      );
    else
      return (
        <div>
          {renderModalNewCreditCard()}
          <br />
          {t("NEOMODE.PaymentFormsError")}
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 24,
            }}
          >
            <Button onClick={goBackStep}>{t("NEOMODE.Back")}</Button>
            <Button onClick={() => setModalNewCreditCardIsOpen(true)}>
              {t("NEOMODE.NewCreditCard")}
            </Button>
            <Button onClick={handleGetPaymentMethods}>
              {t("NEOMODE.TryAgain")}
            </Button>
          </div>
        </div>
      );
  else {
    if (paymentStatus === "loading")
      return (
        <div
          style={{
            margin: "20px 0 0 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Spin style={{ margin: "20px auto" }} />
          <label style={{ textAlign: "center" }}>
            {t("NEOMODE.GettingPaymentInstallments")}
          </label>
        </div>
      );
    else if (paymentStatus === "success" && installments?.length > 0)
      return (
        <div>
          <br />
          {t("NEOMODE.ChoosePaymentInstallment")}
          <div
            style={{
              height: "100%",
              maxHeight: 132,
              overflowY: "auto",
              marginTop: 12,
            }}
          >
            <Radio.Group
              required
              onChange={({ target: t }) =>
                setInstallment(
                  installments.filter(({ installments: n }) => t.value === n)[0]
                )
              }
              value={installment.installments}
              style={{ display: "grid" }}
            >
              {installments.map(({ installments: n, amount, totalAmount }) => (
                <Radio value={n} key={n}>
                  {n} x {formatCash(amount)} {` = ` + formatCash(totalAmount)}
                </Radio>
              ))}
            </Radio.Group>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
          >
            <Button
              onClick={() => setPaymentStep("options")}
              style={{ marginRight: 24 }}
            >
              {t("NEOMODE.Back")}
            </Button>
            <Button onClick={goNextStep} disabled={!installment}>
              {t("NEOMODE.Continue")}
            </Button>
          </div>
        </div>
      );
    else
      return (
        <div>
          <br />
          {t("NEOMODE.PaymentInstallmentsNotFound")}
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
          >
            <Button
              onClick={() => setPaymentStep("options")}
              style={{ marginRight: 24 }}
            >
              {t("NEOMODE.Back")}
            </Button>
            <Button onClick={handleGetInstallments}>
              {t("NEOMODE.TryAgain")}
            </Button>
          </div>
        </div>
      );
  }
};

export default NeomodePayment;
