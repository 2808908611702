import React, { useContext, useState, useEffect, useRef } from "react";
import MaskedInput from "antd-mask-input";
import {
  Button,
  Radio,
  Input,
  Spin,
  Modal,
  Form,
  Checkbox,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import { useNeomode, UserContext } from "../../context";
import { neomodeService, customerService } from "../../services";
import { formatCash } from "../../utils";

const NEOMODE_INPUT = {
  CEP: "neomode-input-cep",
};

const NeomodeShipping = ({ visible, liveId, goNextStep, goBackStep }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const { user: user4Show } = useContext(UserContext);
  const {
    sellerSelected,
    shippingSelected,
    setShippingSelected,
    cpfPickUp,
    setCpfPickUp,
    addressSelected,
    setAddressSelected,
  } = useNeomode();

  const [stepShipping, setStepShipping] = useState("cep"); //cep | types | pick-up | delivery
  const [status, setStatus] = useState("loading"); // success - error - loading - empty
  const [cep, setCep] = useState("");
  const [shippingTypes, setShippingTypes] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [modalNewAddressIsOpen, setModalNewAddressIsOpen] = useState(false);
  const [creatingNewAddress, setCreatingNewAddress] = useState(false);

  const handleGetTypesShipping = () => {
    setStatus("loading");
    setStepShipping("types");
    neomodeService
      .getShippingList(liveId, user4Show.codigo, sellerSelected, cep)
      .then((data) => {
        setShippingTypes(data);
        if (data?.length > 0) setShippingSelected(data[0]);
        setStatus("success");
      })
      .catch(() => setStatus("error"));
  };

  const handleGetsAddressList = () => {
    setStatus("loading");
    neomodeService
      .getAddressList(liveId, user4Show.codigo)
      .then((data) => {
        setAddressList(data);
        if (data?.length > 0) {
          data.forEach(
            (address) => address.isPrimary && setAddressSelected(address)
          );
          setStatus("success");
        } else setStatus("empty");
      })
      .catch(() => setStatus("error"));
  };

  useEffect(() => {
    if (visible && stepShipping === "delivery" && addressList.length === 0)
      handleGetsAddressList();
  }, [stepShipping, visible]);

  const handleAddAddressNeomode = (data) => {
    setCreatingNewAddress(true);
    neomodeService
      .createAddress(liveId, user4Show.codigo, data)
      .then((data) => {
        setModalNewAddressIsOpen(false);
        setAddressSelected(data);
        setAddressList((oldList) => [...oldList, data]);
        message.success(t("NEOMODE.AddressCreateSuccess"));
      })
      .catch(() => message.error(t("NEOMODE.AddressCreateError")))
      .finally(() => setCreatingNewAddress(false));
  };

  const checkCep = (cepSearch) => {
    customerService.validateCep(cepSearch).then((data) => {
      if (!data.erro) {
        formRef.current.setFieldsValue({
          state: data.uf || "",
          city: data.localidade || "",
          street: data.logradouro || "",
          neighborhood: data.bairro || "",
        });
      }
    });
  };

  const renderModalNewAddress = () => (
    <Modal
      visible={modalNewAddressIsOpen}
      onCancel={() => !creatingNewAddress && setModalNewAddressIsOpen(false)}
      footer={false}
      title={t("NEOMODE.CreateAddress")}
    >
      <Form
        onFinish={handleAddAddressNeomode}
        style={{ display: "flex", flexDirection: "column" }}
        initialValues={{ isPrimary: false }}
        ref={formRef}
      >
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 15, width: "100%" }}>
            {t("NEOMODE.Name")}
            <Form.Item style={{ margin: 0 }} name="label">
              <Input disabled={creatingNewAddress} type="text" required />
            </Form.Item>
          </div>
          <div style={{ width: "100%" }}>
            {t("NEOMODE.Zipcode")}
            <Form.Item style={{ margin: 0 }} name="cep">
              <Input
                id={NEOMODE_INPUT.CEP}
                disabled={creatingNewAddress}
                type="text"
                maxLength={8}
                required
                onBlur={() => {
                  let value = document.getElementById(NEOMODE_INPUT.CEP)?.value;
                  if (value) checkCep(value);
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 15, width: "100%" }}>
            {t("NEOMODE.State")}
            <Form.Item style={{ margin: 0 }} name="state">
              <Input
                disabled={creatingNewAddress}
                type="text"
                maxLength={2}
                required
              />
            </Form.Item>
          </div>
          <div style={{ marginRight: 15 }}>
            {t("NEOMODE.City")}
            <Form.Item style={{ margin: 0 }} name="city">
              <Input disabled={creatingNewAddress} type="text" />
            </Form.Item>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 15, width: "100%" }}>
            {t("NEOMODE.Address")}
            <Form.Item style={{ margin: 0 }} name="street">
              <Input disabled={creatingNewAddress} type="text" />
            </Form.Item>
          </div>
          <div style={{ width: "100%" }}>
            {t("NEOMODE.Number")}
            <Form.Item style={{ margin: 0 }} name="number">
              <Input disabled={creatingNewAddress} type="text" />
            </Form.Item>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 15, width: "100%" }}>
            {t("NEOMODE.Complement")}
            <Form.Item style={{ margin: 0 }} name="complement">
              <Input disabled={creatingNewAddress} type="text" />
            </Form.Item>
          </div>
          <div style={{ width: "100%" }}>
            {t("NEOMODE.Neighborhood")}
            <Form.Item style={{ margin: 0 }} name="neighborhood">
              <Input disabled={creatingNewAddress} type="text" />
            </Form.Item>
          </div>
        </div>
        <Form.Item
          style={{ margin: 0 }}
          required={true}
          name="isPrimary"
          valuePropName="checked"
        >
          <Checkbox disabled={creatingNewAddress}>
            {t("NEOMODE.MainAddress")}
          </Checkbox>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={creatingNewAddress}
          style={{ marginTop: 24, marginLeft: "auto" }}
        >
          {t("NEOMODE.CreateAddress")}
        </Button>
      </Form>
    </Modal>
  );
  if (!visible) return <></>;
  if (stepShipping === "cep")
    return (
      <div>
        <br />
        {t("NEOMODE.NeedZipcode")}
        <Input
          type="text"
          name="postal-code"
          placeholder={t("NEOMODE.Zipcode")}
          value={cep}
          maxLength={8}
          onChange={({ target: t }) => {
            if (
              t.value.length < cep.length ||
              (t.value && !isNaN(parseInt(t.value[t.value.length - 1])))
            )
              setCep(t.value);
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 24,
          }}
        >
          <Button onClick={goBackStep} style={{ marginRight: 24 }}>
            {t("NEOMODE.Back")}
          </Button>
          <Button
            disabled={cep?.trim().length < 8}
            onClick={handleGetTypesShipping}
          >
            {t("NEOMODE.Continue")}
          </Button>
        </div>
      </div>
    );
  else if (stepShipping === "types")
    if (status === "loading")
      return (
        <div
          style={{
            margin: "20px 0 0 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Spin style={{ margin: "20px auto" }} />
          <label style={{ textAlign: "center" }}>
            {t("NEOMODE.LookingShipping")}
          </label>
        </div>
      );
    else if (
      status === "error" ||
      (status === "success" && shippingTypes?.length === 0)
    )
      return (
        <div>
          <br />
          {t("NEOMODE.ShippingNotFound")}
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
          >
            <Button
              onClick={() => setStepShipping("cep")}
              style={{ marginRight: 24 }}
            >
              {t("NEOMODE.Back")}
            </Button>
            <Button onClick={() => handleGetTypesShipping()}>
              {t("NEOMODE.TryAgain")}
            </Button>
          </div>
        </div>
      );
    else if (status === "success")
      return (
        <div>
          <br />
          {t("NEOMODE.ChooseShippingType")}
          <div
            style={{
              height: "100%",
              maxHeight: 132,
              overflowY: "auto",
              marginTop: 12,
            }}
          >
            <Radio.Group
              required
              onChange={({ target: t }) => {
                setShippingSelected(
                  shippingTypes.filter((e) => e.id === t.value)[0]
                );
              }}
              value={shippingSelected?.id}
            >
              {shippingTypes.map(
                ({
                  id,
                  name,
                  description,
                  deliveryTime,
                  deliveryTimeUnit,
                  amount,
                }) => (
                  <Radio value={id} key={id}>
                    {name}
                    {amount && ` - R$${formatCash(amount, true)}`}
                    {deliveryTime &&
                      ` (${deliveryTime} ${
                        deliveryTimeUnit || t("NEOMODE.Days")
                      })`}
                    <br />
                    <label style={{ fontSize: "10px", color: "gray" }}>
                      {description}
                    </label>
                  </Radio>
                )
              )}
            </Radio.Group>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
          >
            <Button
              onClick={() => setStepShipping("cep")}
              style={{ marginRight: 24 }}
            >
              {t("NEOMODE.Back")}
            </Button>
            <Button
              disabled={!sellerSelected}
              onClick={() =>
                setStepShipping(
                  shippingSelected.isDelivery ? "delivery" : "pick-up"
                )
              }
            >
              {t("NEOMODE.Continue")}
            </Button>
          </div>
        </div>
      );
    else
      return (
        <div>
          <h1>404 Not found</h1>
        </div>
      );
  else if (stepShipping === "pick-up")
    return (
      <div>
        <br />
        {t("NEOMODE.NeedCodeToPickUp")}
        <MaskedInput
          mask="000.000.000-00"
          size="00"
          placeholder={"CPF"}
          onChange={({ target: t }) => setCpfPickUp(t.value)}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 24,
          }}
        >
          <Button
            onClick={() => setStepShipping("types")}
            style={{ marginRight: 24 }}
          >
            {t("NEOMODE.Back")}
          </Button>
          <Button
            disabled={!cpfPickUp || cpfPickUp?.length < 11}
            onClick={goNextStep}
          >
            {t("NEOMODE.Continue")}
          </Button>
        </div>
      </div>
    );
  else if (stepShipping === "delivery")
    if (status === "loading")
      return (
        <div
          style={{
            margin: "20px 0 0 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Spin style={{ margin: "20px auto" }} />
          <label style={{ textAlign: "center" }}>
            {t("NEOMODE.LookingAddresses")}
          </label>
        </div>
      );
    else if (status === "error")
      return (
        <div>
          <br />
          {t("NEOMODE.LookAddressesError")}
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
          >
            <Button
              onClick={() => setStepShipping("cep")}
              style={{ marginRight: 24 }}
            >
              {t("NEOMODE.Back")}
            </Button>
            <Button onClick={() => handleGetsAddressList()}>
              {t("NEOMODE.TryAgain")}
            </Button>
          </div>
        </div>
      );
    else if (status === "empty")
      return (
        <div>
          {renderModalNewAddress()}
          <br />
          {t("NEOMODE.AddressShippingNotFound")}
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
          >
            <Button
              onClick={() => setStepShipping("cep")}
              style={{ marginRight: 24 }}
            >
              {t("NEOMODE.Back")}
            </Button>
            <Button onClick={() => setModalNewAddressIsOpen(true)}>
              {t("NEOMODE.CreateAddress")}
            </Button>
          </div>
        </div>
      );
    else if (status === "success")
      return (
        <div>
          {renderModalNewAddress()}
          <br />
          {t("NEOMODE.ChooseShippingToGiveOrder")}
          <div
            style={{
              height: "100%",
              maxHeight: 132,
              overflowY: "auto",
              marginTop: 12,
            }}
          >
            <Radio.Group
              required
              onChange={({ target: t }) => {
                setAddressSelected(
                  addressList.filter((e) => e.id === t.value)[0]
                );
              }}
              value={addressSelected?.id}
            >
              {addressList.map(({ id, label, fullAddress, isPrimary }) => (
                <Radio value={id} key={id}>
                  {label}
                  {isPrimary && ` - ${t("NEOMODE.Main")}`}
                  <br />
                  <label style={{ fontSize: "10px", color: "gray" }}>
                    {fullAddress}
                  </label>
                </Radio>
              ))}
            </Radio.Group>
          </div>
          <div
            style={{ marginTop: 24, display: "flex", flexDirection: "column" }}
          >
            <Button onClick={() => setStepShipping("types")}>Voltar</Button>
            <Button onClick={() => setModalNewAddressIsOpen(true)}>
              {t("NEOMODE.CreateAddress")}
            </Button>
            <Button disabled={!addressSelected} onClick={() => goNextStep()}>
              {t("NEOMODE.Continue")}
            </Button>
          </div>
        </div>
      );
    else return <div>Page not found</div>;
  else return <div>Page not found</div>;
};

export default NeomodeShipping;
