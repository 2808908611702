import React, { useState } from "react";
import { FileTextOutlined, FileExcelOutlined } from "@ant-design/icons";
import { notification, Modal, Button } from "antd";
import { customerService } from "../../../../services";
import { useTranslation } from "react-i18next";

const ReportsModal = ({ visible, onClose, landingPage }) => {
  const { t } = useTranslation();
  const [loadingUser, setLoadingUser] = useState(false); //buscando relatório dos usuários

  const downloadData = (data, filename, format) => {
    var downloadLink = document.createElement("a");
    if (format === "excel") {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `${filename}.xlsx`;
    } else {
      var blob = new Blob(["\ufeff", data]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `${filename}.${format}`;
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const feedback = (success, key, description) => {
    if (success)
      notification.success({
        key,
        message: "Download concluído!",
        onClick: () => notification.close(key),
        duration: 10,
      });
    else
      notification.error({
        key,
        message: "Ops!",
        description:
          description ||
          "Não foi possível baixar seu relatório agora! Por favor, tente novamente ou recarregue a página.",
        onClick: () => notification.close(key),
        duration: 3,
      });
  };

  const downloadUserReport = (format) => {
    customerService
      .getCustomerLead(landingPage.leadId, format)
      .then((data) => {
        downloadData(data, `Relatório de Lead`, format);
        setLoadingUser(false);
        feedback(true, "user-report");
      })
      .catch((e) => {
        setLoadingUser(false);
        feedback(false, "user-report", e);
      });
  };
  return (
    <Modal
      title={t("DASHBOARD.LeadReport")}
      visible={visible}
      onOk={() => onClose()}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={() => onClose()}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <div style={{ marginBottom: "30px" }}>
        {t("DASHBOARD.LeadReportDescription")}
      </div>
      <Button
        type="primary"
        onClick={() => downloadUserReport("csv")}
        style={{ margin: "20px 20px 0 0" }}
        loading={loadingUser === "json"}
        disabled={loadingUser === "json"}
      >
        {!loadingUser === "csv" && <FileTextOutlined />}
        {t("ONLINE-USERS-TAB.CSVReport")}
      </Button>
      <Button
        type="primary"
        onClick={() => downloadUserReport("excel")}
        style={{ margin: "20px 20px 0 0" }}
        loading={loadingUser === "excel"}
        disabled={loadingUser === "excel"}
      >
        {!loadingUser === "excel" && <FileExcelOutlined />}
        {t("ONLINE-USERS-TAB.EXCELReport")}
      </Button>
    </Modal>
  );
};
export default ReportsModal;
