import React, { useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import * as s from "./styles-live";

const WarningOrientation = () => {
  const { t } = useTranslation();
  const [size, setSize] = useState([0, 0]);
  const [disabled, setDisabled] = useState(true);

  useLayoutEffect(() => {
    function updateSize() {
      let width = window.innerWidth;
      let height = window.innerHeight;
      setSize(([oldWidth, oldHeight]) => {
        if (height !== oldHeight && width !== oldWidth) setDisabled(false);
        else setDisabled(true);
        return [width, height];
      });
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [setDisabled, setSize]);

  return (
    !disabled && (
      <s.WarningOrientation className="warning">
        {t("LIVE.BetterExperience")}{" "}
        <label>{t("LIVE.VerticalOrientation")}</label>. {t("LIVE.Thanks")}
      </s.WarningOrientation>
    )
  );
};

export default WarningOrientation;
