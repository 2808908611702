import React, { useState } from "react";
import { Modal, Button, message, Input } from "antd";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars";
import { FileTextOutlined } from "@ant-design/icons";
import model from "./assinatura-model.csv";
import { csvToJson } from "../../../../../utils";

const ModalAssinantes = ({ visible, onOk, onCancel, listaAssinatura }) => {
  const { t } = useTranslation();
  const [assinantes, setAssinantes] = useState(listaAssinatura || []);
  const [filteredAssinantes, setFilteredAssinantes] = useState(false);

  const handleDownloadModel = () => {
    var downloadLink = document.createElement("a");
    downloadLink.href = model;
    downloadLink.download = `Modelo de assinaturas.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleUploadCsv = () => {
    document.getElementById("modal-assinantes-csv-input").click();
  };
  const handleInputChange = ({ target: { files } }) => {
    let fileCsv = files[0];
    if (fileCsv) {
      let reader = new FileReader();
      reader.readAsText(fileCsv);
      reader.onload = (e) => {
        let csvConverted = csvToJson(e.target.result);
        setAssinantes(csvConverted.map(({ email }) => email));
      };
      reader.onerror = () =>
        message.error(
          t("LIVE-TAB-SUBSCRIPTION-CARD.DownloadModelErrorDescription")
        );
    }
  };

  let list =
    filteredAssinantes && filteredAssinantes.length > 0
      ? filteredAssinantes
      : assinantes && assinantes.length > 0
      ? assinantes
      : false;

  return (
    <Modal
      visible={visible}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "30px",
          }}
        >
          {t("LIVE-TAB-SUBSCRIPTION-CARD.SubscribersList")}
          <Button size="small" onClick={handleDownloadModel}>
            <FileTextOutlined />
            {t("LIVE-TAB-SUBSCRIPTION-CARD.DownloadModel")}
          </Button>
        </div>
      }
      onOk={() => onOk(assinantes)}
      onCancel={onCancel}
      okText={t("LIVE-TAB-SUBSCRIPTION-CARD.Done")}
      cancelText={t("LIVE-TAB-SUBSCRIPTION-CARD.Return")}
      bodyStyle={{ padding: 0 }}
      okButtonProps={{ disabled: !list }}
      cancelButtonProps={{
        style: { display: "none" },
      }}
    >
      <div style={{ padding: "20px 20px 0 20px" }}>
        {t("LIVE-TAB-SUBSCRIPTION-CARD.SubscriberListDescription")}
        <input
          type="file"
          onChange={handleInputChange}
          accept=".csv"
          style={{ display: "none" }}
          id="modal-assinantes-csv-input"
        />
        <Button
          onClick={handleUploadCsv}
          style={{ display: "grid", margin: "10px 0" }}
        >
          {t("LIVE-TAB-SUBSCRIPTION-CARD.Upload")}
        </Button>
        {list && (
          <div
            style={{
              color: "gray",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #d0d0d0",
            }}
          >
            {filteredAssinantes
              ? `${filteredAssinantes.length} ${t(
                  "LIVE-TAB-SUBSCRIPTION-CARD.Results"
                )}`
              : `${t("LIVE-TAB-SUBSCRIPTION-CARD.SubscriptionList")}: ${
                  list.length
                }`}
            <Input.Search
              placeholder={t("LIVE-TAB-SUBSCRIPTION-CARD.EmailSearch")}
              style={{ maxWidth: "280px", marginLeft: "auto" }}
              onSearch={(value) =>
                setFilteredAssinantes(
                  assinantes.filter((str) =>
                    str.toLowerCase().includes(value.toLowerCase())
                      ? str
                      : false
                  )
                )
              }
              onChange={({ target: { value } }) => {
                if (!value || value === "") setFilteredAssinantes(false);
              }}
              enterButton
            />
          </div>
        )}
      </div>
      <Scrollbars style={{ width: "100%", height: list && "300px" }}>
        <div style={{ margin: "0 20px" }}>
          {list &&
            list.map((assinante) => <div key={assinante}>{assinante}</div>)}
        </div>
      </Scrollbars>
    </Modal>
  );
};

export default ModalAssinantes;
