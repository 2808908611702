import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QrcodeOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Drawer } from "antd";
import moment from "moment";
import { formatCash } from "../../../utils";
import { ModalPaymentPix } from "../../../components";
import * as s from "./styles-orders";
import "./orders.css";

import {
  OrdersContext,
  ThemeContext,
  UserContext,
  useTabsNavigation,
} from "../../../context";

const Orders = ({ live }) => {
  const { t } = useTranslation();
  const { tabActive, handleChangeTabActive } = useTabsNavigation();
  const { corPrimaria, corSecundaria } = useContext(ThemeContext);
  const { user } = useContext(UserContext); //dados do usuário
  const { orders, handleGetOrders } = useContext(OrdersContext); //dados do ordem de compra
  //control
  const [vtexOrder, setVtexOrder] = useState(false); //modal de vtex
  const [verticalDrawer, setVerticalDrawer] = useState(false); //se o drawer da vertical está aberto

  useEffect(() => {
    if (user && user.codigo) handleGetOrders(user.codigo, live.liveId);
  }, [user, live, handleGetOrders]);

  const renderContent = (v) => (
    <>
      <s.Title className="fade-in">
        <img src="/assets/icons/order.svg" alt="Order" />
        {t("ORDER.YourRequests")}
      </s.Title>

      {orders?.length > 0 ? (
        <s.OrdersContainer id="order-header" className="fade-in" vertical={v}>
          {orders
            ?.sort((a, b) => a.time + b.time)
            ?.map((o, i) => {
              //pega os tamanhos e as quantidades por produto por pedido
              let productsSizesById = [];
              let productsQtdsById = [];
              o.produtos
                .map((p) => p)
                .map((t) => {
                  t.produto.grade.map((x) => {
                    if (!productsSizesById[t.produto.id])
                      productsSizesById[t.produto.id] = [];
                    if (!productsQtdsById[t.produto.id])
                      productsQtdsById[t.produto.id] = [];
                    productsSizesById[t.produto.id].push(x.tamanho);
                    productsQtdsById[t.produto.id].push(x.quantidade);
                  });
                });

              return (
                <s.Order className="fade-in shadow" key={o.docId}>
                  <div className="content-container">
                    <h3>
                      <div>
                        {t("ORDER.Request")} {orders?.length - i} •{" "}
                        <label
                          style={{
                            textTransform: "capitalize",

                            color:
                              o.status === "pago" ? corSecundaria : corPrimaria,
                          }}
                        >
                          {o.status}
                        </label>
                      </div>
                      <p style={{ color: "gray" }}>
                        {moment(o.time).format(`DD/MM/YYYY [às] HH:mm`)}
                      </p>
                    </h3>

                    <p style={{ color: "grey" }}>
                      {t("ORDER.Code")}: {o.docId}
                    </p>

                    <p style={{ marginTop: 10 }}>
                      {t("ORDER.ProductsAmount")}: {o.produtos.length}
                    </p>

                    <div className="order-products">
                      {o.produtos.map((prod) => (
                        <div key={prod.produto.docId} className="order-product">
                          <b>• {prod.produto.descricao}</b>
                          <div>
                            <p>
                              {productsSizesById[prod.produto.id].length > 1
                                ? t("ORDER.Sizes")
                                : t("ORDER.Size")}
                              : {productsSizesById[prod.produto.id].toString()}
                            </p>
                            <p>
                              {productsQtdsById[prod.produto.id].length > 1
                                ? t("ORDER.Quantities")
                                : t("ORDER.Quantity")}
                              : {productsQtdsById[prod.produto.id].toString()}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>

                    {live.checkout === "vtex" && o.urlRedirect && (
                      <Button
                        onClick={() => {
                          var win = window.open(o.urlRedirect, "_blank");
                          win.focus();
                        }}
                        size="small"
                        type="primary"
                      >
                        {t("ORDER.CheckRequest")}
                      </Button>
                    )}
                    {live.checkout === "interno" &&
                      live.paymentService === "pagarme" &&
                      ["ambas", "pix"].includes(live.formaPagto) &&
                      o.status === "aguardando pagamento" && (
                        <QRCode qrCode={o.qrCode} />
                      )}
                  </div>

                  <s.OrderActions>
                    <h3 style={{ margin: "auto 0 0 auto" }}>
                      {t("ORDER.Total")}{" "}
                      {formatCash(o.valor / 100, false, live?.unidadeMonetaria)}
                    </h3>
                  </s.OrderActions>
                </s.Order>
              );
            })}
        </s.OrdersContainer>
      ) : (
        <div style={{ margin: "0 20px" }}>
          <s.EmptyOrder className="fade-in">
            {t("ORDER.EmptyOrders")}
          </s.EmptyOrder>
        </div>
      )}

      <Modal
        title={t("ORDER.VTEXRequest")}
        visible={vtexOrder}
        onCancel={() => setVtexOrder(false)}
        footer={<div style={{ display: "none" }} />}
        bodyStyle={{ padding: 0 }}
        style={{ minWidth: "95%" }}
      >
        <iframe
          src={vtexOrder}
          title="vtex iframe"
          style={{ width: "100%", height: "80vh" }}
        />
      </Modal>
    </>
  );

  if (window.innerWidth < 955) {
    if (live.vertical)
      return (
        <s.OrdersVertical>
          <Drawer
            placement="bottom"
            closable
            onClose={() => handleChangeTabActive("chat")}
            visible={tabActive === "orders"}
            style={{ padding: 0 }}
            drawerStyle={{ borderRadius: "16px 16px 0 0 " }}
            className="custom-drawer"
            bodyStyle={{
              padding: 0,
              background: "rgba(248, 248, 248, 1)",
              overflow: "inherit",
              position: "relative",
            }}
            headerStyle={{ display: "none" }}
            maskStyle={{ background: "transparent" }}
            height={400}
          >
            <CloseOutlined
              style={{ position: "absolute", top: 0, right: 0, padding: 20 }}
              onClick={() => handleChangeTabActive("chat")}
            />
            {renderContent(true)}
          </Drawer>
        </s.OrdersVertical>
      );
    else {
      if (tabActive === "orders") {
        if (orders?.length > 0) return renderContent();
        return (
          <div style={{ margin: "0 20px" }}>
            <s.Title className="fade-in">
              <img src="/assets/icons/order.svg" alt="Order" />
              {t("ORDER.YourRequests")}
            </s.Title>
            <s.EmptyOrder className="fade-in">
              {t("ORDER.EmptyOrders")}
            </s.EmptyOrder>
          </div>
        );
      }

      return <></>;
    }
  } else if (orders?.length > 0) return renderContent();
  else return <></>;
};

const QRCode = ({ qrCode }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <ModalPaymentPix
        visible={visible}
        closeModal={() => setVisible(false)}
        qrCode={qrCode}
      />
      <Button onClick={() => setVisible(true)} size="small" type="primary">
        <QrcodeOutlined /> QR Code
      </Button>
    </>
  );
};

export default Orders;
