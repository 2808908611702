import React, { useContext, useEffect, useState } from "react";
import { Tabs, Card, Input, Button, notification } from "antd";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { BgColorsOutlined, FileImageOutlined } from "@ant-design/icons";
import { convertImgToBase64URL } from "../../utils";
import { managerService } from "../../services";
import { UserContext } from "../../context";
import styled from "styled-components";

const { TabPane } = Tabs;
const Theme = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  //colors
  const [cor1, setCor1] = useState(
    user?.whiteLabel?.corPrimaria ? user?.whiteLabel.corPrimaria : "#6a3189"
  );
  const [cor2, setCor2] = useState(
    user?.whiteLabel?.corSecundaria ? user?.whiteLabel.corSecundaria : "#ffc05c"
  );
  const [savingColors, setSavingColors] = useState(false);
  //logo
  const [logo, setLogo] = useState({
    logo: user?.whiteLabel?.logo ? user?.whiteLabel.logo : false,
    logoName: "",
  });
  const [savingThumb, setSavingThumb] = useState(false);
  const [removingThumb, setRemovingThumb] = useState(false);
  const [labelPrimaryActionLogo, setLabelPrimaryActionLogo] = useState(
    t("THEME.ChooseImage")
  );

  useEffect(() => {
    document.title = `${t("THEME.Theme")} | 4Show`;
  }, []);

  useEffect(() => {
    if (!logo.logo) return setLabelPrimaryActionLogo(t("THEME.ChooseImage"));
    if (!logo.logo.split("base64,")[1])
      return setLabelPrimaryActionLogo(t("THEME.AlterImage"));
    return setLabelPrimaryActionLogo(t("THEME.Save"));
  }, [logo]);

  const handleChangeThumbLive = async (event) => {
    let file = event.target.files[0];
    if (file) {
      await convertImgToBase64URL(URL.createObjectURL(file), (base64) => {
        setLogo({
          logo: base64,
          logoName: file.name,
        });
      });
    }
  };

  const handleSaveThumbTheme = () => {
    setSavingThumb(true);
    let base64 = logo.logo.split("base64,")[1] || false;
    managerService
      .saveThumbTheme({ ...logo, logo: base64 })
      .then(({ logo: logoUrl }) => {
        notification.success({
          key: "aviso-img",
          message: t("THEME.ImageSaveSuccess"),
          onClick: () => notification.close("aviso-img"),
        });
        setLogo({ ...logo, logo: logoUrl });
      })
      .catch(() => {
        notification.error({
          key: "aviso-img",
          message: t("THEME.ImageGeneralError"),
          description: t("THEME.ImageSaveErrorDescription"),
          onClick: () => notification.close("aviso-img"),
          duration: 10,
        });
      })
      .finally(() => {
        setSavingThumb(false);
      });
  };
  const handleRemoveThumbTheme = () => {
    setRemovingThumb(true);
    managerService
      .deleteThumbTheme()
      .then(() => {
        setRemovingThumb(false);
        notification.success({
          key: "aviso-img",
          message: t("THEME.ImageRemoveSuccess"),
          onClick: () => notification.close("aviso-img"),
        });
        setLogo({ logo: false, logoName: false });
      })
      .catch(() => {
        setRemovingThumb(false);
        notification.error({
          key: "aviso-img",
          message: t("THEME.ImageGeneralError"),
          description: t("THEME.ImageRemoveErrorDescription"),
          onClick: () => notification.close("aviso-img"),
          duration: 10,
        });
      });
  };

  const handleSaveColors = () => {
    setSavingColors(true);
    managerService
      .saveTheme({
        corPrimaria: cor1,
        corSecundaria: cor2,
      })
      .then(() => {
        setSavingColors(false);
        notification.success({
          key: "aviso-cores",
          message: t("THEME.ColorsSaveSuccess"),
          onClick: () => notification.close("aviso-cores"),
        });
      })
      .catch(() => {
        setSavingColors(false);
        notification.error({
          key: "aviso-cores",
          message: t("THEME.ImageGeneralError"),
          description: t("THEME.ColorsSaveErrorDescription"),
          onClick: () => notification.close("aviso-cores"),
          duration: 10,
        });
      });
  };

  const handleCancelThumbTheme = () => {
    setLogo({
      logo: user?.whiteLabel?.logo ? user?.whiteLabel.logo : false,
      logoName: "",
    });
  };

  return (
    <Tabs centered size="large" activeKey={"theme"}>
      <TabPane tab={t("THEME.MyTheme")} key={"theme"}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card
            title={
              <>
                <BgColorsOutlined style={{ marginRight: "10px" }} />
                {t("THEME.PlatformColors")}
              </>
            }
            style={{ margin: "5px", width: "400px" }}
            className="shadow"
          >
            <p style={{ margin: 0, flexWrap: "wrap" }}>
              {t("THEME.ColorsDescription")}
            </p>
            <label>{t("THEME.ColorOne")}</label>
            <Input
              type="color"
              value={cor1}
              onChange={({ target: t }) => setCor1(t.value)}
              disabled={savingColors}
            />
            <label>{t("THEME.ColorTwo")}</label>
            <Input
              style={{ marginTop: "5px" }}
              type="color"
              value={cor2}
              onChange={({ target: t }) => setCor2(t.value)}
              disabled={savingColors}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Button
                type="primary"
                onClick={handleSaveColors}
                loading={savingColors}
              >
                {t("THEME.SaveColors")}
              </Button>
            </div>
          </Card>
          <Card
            title={
              <>
                <FileImageOutlined style={{ marginRight: "10px" }} />
                {t("THEME.ImageOrLogo")}
              </>
            }
            style={{ margin: "5px", width: "400px" }}
            bodyStyle={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="shadow"
          >
            <p style={{ margin: 0, flexWrap: "wrap" }}>
              {t("THEME.ImageLogoDescription")}
              <span
                style={{
                  fontSize: "12px",
                  color: "gray",
                  margin: "10px 0 0 0",
                  textAlign: "center",
                  display: "block",
                }}
              >
                {t("THEME.PngSugestion")}
              </span>
            </p>
            <div
              style={{
                border: "1px solid #f0f0f0",
                width: "300px",
                height: "150px",
                margin: "10px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {logo.logo ? (
                <LogoPreview bg={cor1}>
                  <img alt="thumb-live-img" src={logo.logo} />
                </LogoPreview>
              ) : (
                <legend
                  style={{
                    fontSize: "12px",
                    color: "gray",
                    textAlign: "center",
                  }}
                >
                  {t("THEME.NoImageOrLogo")}
                </legend>
              )}
            </div>
            <input
              type="file"
              id="thumb-live-input"
              style={{ display: "none" }}
              onChange={handleChangeThumbLive}
              accept="image/*"
            />
            <Button
              type="primary"
              onClick={() =>
                !logo.logo || (logo.logo && !logo.logo.split("base64,")[1])
                  ? document.getElementById("thumb-live-input").click()
                  : handleSaveThumbTheme()
              }
              loading={savingThumb}
              disabled={removingThumb}
              style={{ marginTop: "10px" }}
            >
              {labelPrimaryActionLogo}
            </Button>
            {logo.logo && !logo.logo.split("base64,")[1] ? (
              <Button
                type="primary"
                danger
                onClick={handleRemoveThumbTheme}
                loading={removingThumb}
                style={{ marginTop: "10px" }}
              >
                {t("THEME.RemoveImage")}
              </Button>
            ) : (
              !savingThumb && (
                <Button
                  onClick={handleCancelThumbTheme}
                  style={{ marginTop: "10px" }}
                >
                  {t("THEME.Cancel")}
                </Button>
              )
            )}
          </Card>
        </div>
      </TabPane>
    </Tabs>
  );
};

const LogoPreview = styled.div`
  height: 70px;
  width: 100%;
  text-align: center;
  background-color: ${({ bg }) => (bg ? bg : "#fff")};

  img {
    max-height: 70px;
    max-width: 100%;
    border: 1px solid red;
  }
`;

export default withRouter(Theme);
