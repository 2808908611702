import { auth, axiosApp } from "./";

const login = (email, senha) =>
  new Promise((resolve, reject) => {
    auth
      .signInWithEmailAndPassword(email, senha)
      .then((user) => resolve(user))
      .catch((error) => reject(error));
  });

const verifyLogin = () =>
  new Promise((resolve) => {
    auth.onAuthStateChanged((user) => {
      if (!user) resolve(false);
      else
        user
          .getIdToken()
          .then((t) => resolve(t))
          .catch(() => resolve(false));
    });
  });

const logout = () =>
  new Promise((resolve, reject) => {
    auth
      .signOut()
      .then(() => resolve())
      .catch((error) => reject(error));
  });

const getUser = () =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`/contractor/validatePlan`)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status === 402
        )
          reject(402);
        else reject(false);
      });
  });
//-------------------------------------------------------------
//--------------------------Recipient--------------------------
//-------------------------------------------------------------
const saveRecipient = (service, recipientID) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`/contractor/recipient/${service}`, { recipientID })
      .then(({ data }) => resolve(data))
      .catch((error) => {
        reject(error);
      });
  });
const createRecipient = (service, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`/contractor/recipient/${service}`, body)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        reject(error);
      });
  });

export const userService = {
  login,
  logout,
  verifyLogin,
  getUser,
  //recipient
  saveRecipient,
  createRecipient,
};
