import React, { useContext, useEffect, useState } from "react";
import { Tabs, Card, Input, message, Button } from "antd";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { firestore } from "../../services";
import { GradesContext, ThemeContext, UserContext } from "../../context";
import * as s from "./styles-grades";

const { TabPane } = Tabs;
const { Search } = Input;
const Grades = () => {
  const { t } = useTranslation();
  const { corSecundaria } = useContext(ThemeContext);
  const { userGrades } = useContext(GradesContext);
  const { user } = useContext(UserContext);
  const [activeKey, setActiveKey] = useState(t("GRIDS.Grids"));
  const [activeGrade, setActiveGrade] = useState();
  //Control
  const [nome, setNome] = useState(""); //nome da grade
  const [saving, setSaving] = useState(false); //salvando grade
  const [grade, setGrade] = useState([]); //nova grade a ser salva nas grades do usuário
  const [invalidValues, setInvalidValues] = useState([]);

  useEffect(() => {
    document.title = `${t("GRIDS.Grids")} | 4Show`;
    return () => (document.title = "4Show");
  }, []);

  const addGrade = () => {
    setSaving(true);
    firestore
      .collection(`contractor/${user?.id}/grades`)
      .add({ nome, grade })
      .then(() => {
        setSaving(false);
        message.success(t("GRIDS.AddGridSuccess"));
        setNome("");
        setGrade([]);
      })
      .catch(() => {
        setSaving(false);
        message.error(t("GRIDS.AddGridError"));
      });
  };

  const checkInvalidValues = (type) => {
    if (type === "nome" && !nome) setInvalidValues([...invalidValues, type]);
    else if (type === "tamanhos" && grade.length === 0)
      setInvalidValues([...invalidValues, type]);
  };

  return (
    <Tabs
      centered
      size="large"
      activeKey={activeKey}
      onChange={(key) => setActiveKey(key)}
    >
      <TabPane tab={t("GRIDS.Grids")} key={"Grades"}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card
            title={
              <>
                {t("GRIDS.GridList")}{" "}
                <p style={{ fontSize: "11px", margin: 0 }}>
                  {t("GRIDS.AllGrids")}
                </p>
              </>
            }
            bodyStyle={{ padding: "0px" }}
            style={{ margin: "5px" }}
            className="shadow"
          >
            <Scrollbars style={{ width: 400, height: 462 }}>
              <div>
                {userGrades?.map((g) => (
                  <div
                    onClick={() =>
                      setActiveGrade(
                        activeGrade && activeGrade.gradeId === g.gradeId
                          ? false
                          : g
                      )
                    }
                    key={g.gradeId}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      borderBottom: `1px solid ${
                        activeGrade && activeGrade.gradeId === g.gradeId
                          ? corSecundaria
                          : "#d9d9d9"
                      }`,
                      padding: "5px 24px",
                      color:
                        activeGrade && activeGrade.gradeId === g.gradeId
                          ? corSecundaria
                          : "black",
                      background:
                        activeGrade && activeGrade.gradeId === g.gradeId
                          ? "#fcf4e6"
                          : "transparent",
                    }}
                  >
                    {g.nome}
                    <p style={{ margin: "0", color: "gray", fontSize: "12px" }}>
                      {g.grade.map((g) => g.tamanho).join(", ")}
                    </p>
                  </div>
                ))}
              </div>
            </Scrollbars>
          </Card>
          <Card
            title={
              <>
                {activeGrade ? activeGrade.nome : t("GRIDS.Details")}
                <p style={{ fontSize: "11px", margin: 0 }}>
                  {activeGrade ? t("GRIDS.CantAlter") : t("GRIDS.SelectGrid")}
                </p>
              </>
            }
            style={{ margin: "5px", width: "400px" }}
            bodyStyle={{ paddingBottom: "14px" }}
            className="shadow"
          >
            <div
              style={{
                color: "gray",
                display: "flex",
                flexDirection: "column",
                textAlign: activeGrade ? "start" : "center",
              }}
            >
              {activeGrade ? (
                <>
                  <label>{t("GRIDS.GridName")}</label>
                  <Input
                    value={activeGrade.nome}
                    onChange={({ target: t }) =>
                      setActiveGrade({ ...activeGrade, nome: t.value })
                    }
                    placeholder={t("GRIDS.GridName")}
                    style={{ marginBottom: "10px" }}
                    disabled
                  />
                  <label>{t("GRIDS.GridSizes")}</label>
                  <Scrollbars
                    style={{
                      width: "100%",
                      height: "248px",
                    }}
                  >
                    <div style={{ margin: "10px 10px 0 0" }}>
                      {activeGrade.grade &&
                        activeGrade.grade
                          .sort((a, b) => a.tamanho - b.tamanho)
                          .map((g) => (
                            <Input
                              type="number"
                              disabled
                              key={g.tamanho}
                              addonBefore={g.tamanho}
                              value={g.quantidade}
                            />
                          ))}
                    </div>
                  </Scrollbars>
                </>
              ) : (
                t("GRIDS.NoneGridSelected")
              )}
            </div>
          </Card>
        </div>
      </TabPane>
      <TabPane tab={t("GRIDS.NewGrid")} key={"NewGrade"}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Card
            title={
              <>
                {t("GRIDS.NewGrid")}{" "}
                <p style={{ fontSize: "11px", margin: 0 }}>
                  {t("GRIDS.NewGridDescription")}
                </p>
              </>
            }
            style={{ margin: "5px" }}
            className="shadow"
          >
            <label>{t("GRIDS.GridName")}</label>
            <Input
              value={nome}
              onBlur={() => checkInvalidValues("nome")}
              onChange={({ target: t }) => {
                if (invalidValues.includes("nome"))
                  setInvalidValues(invalidValues.filter((x) => x !== "nome"));
                setNome(t.value);
              }}
              placeholder={t("GRIDS.GridName")}
              style={{
                marginBottom: "10px",
                border: invalidValues.includes("nome") && "1px solid red",
              }}
            />
            {invalidValues.includes("nome") && (
              <s.InvalidLabel className="fade-in">
                {t("GRIDS.GridNameRequired")} <br />
              </s.InvalidLabel>
            )}
            <label>{t("GRIDS.GridSizes")}</label>
            <Search
              placeholder={t("GRIDS.NewSize")}
              enterButton={<PlusOutlined />}
              onBlur={() => checkInvalidValues("tamanhos")}
              onSearch={(v) => {
                if (grade && grade.map((g) => g.tamanho).includes(v))
                  message.info(t("GRIDS.GridsSameNameError"));
                else if (v && v.trim() !== "")
                  setGrade([...grade, { tamanho: v, quantidade: 1 }]);
                if (invalidValues.includes("tamanhos"))
                  setInvalidValues(
                    invalidValues.filter((x) => x !== "tamanhos")
                  );
              }}
              style={{
                border: invalidValues.includes("tamanhos") && "1px solid red",
              }}
            />
            <Scrollbars
              style={{
                width: "100%",
                height:
                  grade && grade.length < 7
                    ? `${grade.length * 32 + 10}px`
                    : "220px",
              }}
            >
              <div style={{ margin: "10px 10px 0 0" }}>
                {grade &&
                  grade
                    .sort((a, b) => a.tamanho - b.tamanho)
                    .map((g) => (
                      <Input
                        type="number"
                        key={g.tamanho}
                        addonBefore={g.tamanho}
                        suffix={
                          <DeleteOutlined
                            onClick={() =>
                              setGrade(grade.filter((e) => e !== g))
                            }
                          />
                        }
                        value={g.quantidade}
                        min={0}
                        onChange={({ target: { value: v } }) => {
                          if (v >= 0) {
                            setGrade(
                              [
                                ...grade.filter(
                                  (g2) => g2.tamanho !== g.tamanho
                                ),
                                {
                                  tamanho: g.tamanho,
                                  quantidade: parseInt(v),
                                },
                              ].sort((a, b) => a.tamanho - b.tamanho)
                            );
                          }
                        }}
                      />
                    ))}
              </div>
            </Scrollbars>
            {invalidValues.includes("tamanhos") && (
              <s.InvalidLabel className="fade-in">
                {t("GRIDS.AtLeastOneSize")}
              </s.InvalidLabel>
            )}
          </Card>
          <Button
            type="primary"
            style={{ marginTop: "20px" }}
            onClick={addGrade}
            loading={saving}
          >
            <SaveOutlined /> {t("GRIDS.Save")}
          </Button>
        </div>
      </TabPane>
    </Tabs>
  );
};

export default withRouter(Grades);
