import React, { useContext, useState } from "react";
import { InputNumber, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars";
import ModalNewUserSplit from "../modal-new-user-split";
import { DeleteOutlined } from "@ant-design/icons";
import * as s from "../products-tab/styled-products-tab";
import { PaymentContext } from "../../../../context";

const { Option } = Select;
const ModalSplitFrete = ({
  visible,
  onOk = () => {},
  onCancel,
  splitRules: splitRulesOld = [],
}) => {
  const { t } = useTranslation();
  const { services } = useContext(PaymentContext);
  const { pagarme = [] } = services;
  const [newReceiverModal, setNewReceiverModal] = useState(false);
  const [splitRules, setSplitRules] = useState(splitRulesOld); //split geral

  const renderReceiver = (split) => {
    const { legalName: name, percentage = 0 } = split;
    return (
      <s.ReceiverContainer key={name}>
        {name}
        <InputNumber
          type="number"
          min={0}
          max={100}
          parser={(value) => value.replace(",", ".")}
          defaultValue={percentage}
          style={{ width: "75px", marginLeft: "auto" }}
          maxLength={5}
          // onChange={(v) => {
          //   setSplitRules(
          //     splitRules.map((s) => ({
          //       ...s,
          //       percentage: s.legalName === name ? parseFloat(v) : s.percentage,
          //     }))
          //   );
          // }}
          onChange={(v) => {
            setSplitRules(
              splitRules.map((s) => ({
                ...s,
                percentage:
                  s.legalName === name
                    ? !isNaN(parseFloat(v))
                      ? parseFloat(v) > 100
                        ? 100
                        : parseFloat(v)
                      : 0
                    : s.percentage,
              }))
            );
          }}
        />
        <span style={{ color: "gray", marginLeft: 5 }}>%</span>
        <DeleteOutlined
          style={{ marginLeft: "10px" }}
          onClick={() =>
            setSplitRules(splitRules.filter((p) => p.legalName !== name))
          }
        />
      </s.ReceiverContainer>
    );
  };

  var porcent =
    splitRules?.length > 0
      ? [...splitRules]
          .map((s) => parseFloat(s.percentage))
          .reduce((a, b) => a + b, 0)
      : 0;

  return (
    <Modal
      title={t("LIVE-TAB-SHIPPING-SPLIT-MODAL.SeparateShippingSplit")}
      visible={visible}
      onOk={() => onOk([...splitRules])}
      onCancel={onCancel}
      okText={t("LIVE-TAB-SHIPPING-SPLIT-MODAL.SaveSplit")}
      cancelText={t("LIVE-TAB-SHIPPING-SPLIT-MODAL.Return")}
      okButtonProps={{
        disabled: porcent !== 100,
      }}
      bodyStyle={{ padding: 0 }}
      cancelButtonProps={{
        style: { display: "none" },
      }}
    >
      <ModalNewUserSplit
        visible={newReceiverModal}
        onCancel={() => setNewReceiverModal(false)}
      />
      <div style={{ margin: "20px" }}>
        <label>
          {t("LIVE-TAB-SHIPPING-SPLIT-MODAL.SeparateShippingSplitDescription")}
        </label>
        <a onClick={() => setNewReceiverModal(true)}>
          {" "}
          {t("LIVE-TAB-SHIPPING-SPLIT-MODAL.Here")}
        </a>
        .
        <br />
        <div style={{ marginTop: "10px" }}>
          <label>{t("LIVE-TAB-SHIPPING-SPLIT-MODAL.Receivers")}: </label>
          <Select
            placeholder={t("LIVE-TAB-SHIPPING-SPLIT-MODAL.AddReceiver")}
            style={{ width: "170px", marginLeft: "10px" }}
            showSearch
            onChange={(v) =>
              setSplitRules([
                ...splitRules,
                {
                  ...pagarme.filter((r) => r.legalName === v)[0],
                  percentage: 1,
                },
              ])
            }
          >
            {pagarme
              .filter(
                (r) => !splitRules.map((s) => s.legalName).includes(r.legalName)
              )
              .map((r, key) => (
                <Option value={r.legalName} key={`${key}-${r.legalName}`}>
                  {r.legalName}
                </Option>
              ))}
          </Select>
        </div>
      </div>
      <Scrollbars
        style={{
          width: "100%",
          height: "300px",
        }}
      >
        <div style={{ margin: "0 20px" }}>
          {splitRules.map((s) => renderReceiver(s))}
          <s.TotalContainer porcent={porcent}>
            <label>{t("LIVE-TAB-SHIPPING-SPLIT-MODAL.Total")}</label>
            <label>{porcent.toFixed(2)}%</label>
          </s.TotalContainer>
        </div>
      </Scrollbars>
    </Modal>
  );
};

export default ModalSplitFrete;
