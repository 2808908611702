import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";

const CropImage = ({
  visible = false,
  onFinish = () => {},
  onCancel = () => {},
  thumb,
}) => {
  const { t } = useTranslation();
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 16 / 9.6 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [name, setName] = useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setName(e.target.files[0].name);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }
    canvas.toBlob(
      (blob) => {
        onFinish({
          objectUrl: URL.createObjectURL(blob),
          name,
        });
      },
      "image/png",
      1
    );
  }

  return (
    <Modal
      visible={visible}
      title={t("CROP-IMAGE.FormatImage")}
      okText={t("CROP-IMAGE.Done")}
      cancelText={t("CROP-IMAGE.Return")}
      onOk={() => generateDownload(previewCanvasRef.current, completedCrop)}
      onCancel={onCancel}
      bodyStyle={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
      cancelButtonProps={{
        style: { display: "none" },
      }}
    >
      {thumb && !completedCrop && (
        <img alt="croped preview" src={thumb} style={{ width: "300px" }} />
      )}
      <input
        id="thumbnail-cr"
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        style={{ display: "none" }}
      />

      <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
        style={{ maxWidth: "600px" }}
      />
      <canvas
        ref={previewCanvasRef}
        style={{
          margin: "20px 0",
          maxWidth: "600px",
          maxHeight: "400px",
          width: Math.round(completedCrop?.width ?? 0),
          height: Math.round(completedCrop?.height ?? 0),
        }}
      />
      <Button
        type="primary"
        onClick={() => document.getElementById(`thumbnail-cr`).click()}
      >
        {!completedCrop && !thumb
          ? t("CROP-IMAGE.Upload")
          : t("CROP-IMAGE.ChangeImage")}
      </Button>
    </Modal>
  );
};

export default CropImage;
