import React, { useState, useEffect } from "react";
import { Modal, Skeleton, Input, message } from "antd";
import { useTranslation } from "react-i18next";
import Compress from "compress.js";
import { useDropzone } from "react-dropzone";
import * as s from "./styled-email-config";

const ModalEmailLogo = ({ open, setOpen, logo, setLogo, setLogoName }) => {
  const { t } = useTranslation();
  const compress = new Compress();
  const { getRootProps, getInputProps, isDragActive } = useDropzone();
  const [logoLink, setLogoLink] = useState(logo || "");

  return (
    <Modal
      title={t("EMAIL-CONFIG-TAB.ModalEmailLogo")}
      visible={open}
      style={{ maxWidth: "500px" }}
      // width={"80vw"}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      okText={"Ok"}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <label>
        {t("EMAIL-CONFIG-TAB.ModalAvaibaleUpload")}: <br />
        <span>
          <b>1 -</b> {t("EMAIL-CONFIG-TAB.ModalUploadLocalFile")}
        </span>
        <br />
        <span>
          <b>2 -</b> {t("EMAIL-CONFIG-TAB.ModalUploadImageUrl")}
        </span>
      </label>

      <s.UploadWrapper>
        <div className="link">
          <label>{t("EMAIL-CONFIG-TAB.ModalImageLinkField")}</label>
          <Input
            value={logoLink ? logo : ""}
            onChange={({ target: t }) => {
              setLogo(t.value);
              setLogoLink(t.value);
              setLogoName("");
            }}
            onClick={() =>
              logo &&
              !logoLink &&
              message.error(t("EMAIL-CONFIG-TAB.ModalUploadError"))
            }
          />
        </div>
        <div
          className="upload-image"
          {...getRootProps({
            onChange: async ({ target: t }) =>
              await compress
                .compress([...t.files], {
                  size: 2,
                  quality: 1,
                  resize: true,
                })
                .then((d) => {
                  setLogo(d[0].prefix + d[0].data);
                  setLogoName(d[0].alt);
                  if (logoLink) setLogoLink("");
                }),
          })}
        >
          <input
            {...getInputProps()}
            accept="image/*"
            multiple={false}
            id={`input-dropzone`}
          />
          {logo ? (
            <img style={s.imgStyle} alt="thumbnail" src={logo} />
          ) : (
            <Skeleton.Image
              style={{
                ...s.imgStyle,
                border: "1px solid #d9d9d9",
                width: "282px",
              }}
              active
            />
          )}
        </div>
      </s.UploadWrapper>
    </Modal>
  );
};

export default ModalEmailLogo;
