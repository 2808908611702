import React, { createContext } from "react";
import useLanguage from "./hooks/use-language";

const LanguageContext = createContext({
  language: {},
  setLanguage: () => {},
});

const LanguageProvider = ({ children }) => {
  const { language, setLanguage } = useLanguage();

  return (
    <LanguageContext.Provider
      value={{
        language: language,
        setLanguage: setLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
