import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as s from "./styles";
import {
  Card,
  Button,
  notification,
  Progress,
  Switch,
  Spin,
  Input,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  NotificationOutlined,
  PlaySquareOutlined,
  ArrowRightOutlined,
  WechatOutlined,
  SaveOutlined,
  WarningOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { managerService, storageRef, storage } from "../../../services";

Spin.setDefaultIndicator(
  <LoadingOutlined style={{ fontSize: "20px", color: "gray" }} />
);
const { TextArea } = Input;
const SalaTab = ({ next, live, aoVivo, setLive }) => {
  const { t } = useTranslation();

  const [intro, setIntro] = useState(false); //url vídeo de introdução
  const [introP, setIntroP] = useState(); //progresso de upload da introdução
  const [video, setVideo] = useState(false); //url vídeo principal
  const [videoP, setVideoP] = useState(); //progresso de upload da introdução
  const [chat, setChat] = useState(true); //chat dos usuários
  const [whatsappContact, setWhatsapContact] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState("");

  const [invalidValues, setInvalidValues] = useState([]);

  //Control
  const [old, setOld] = useState(false); //controla se tem que editar ou criar a sala
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fixedMessageChat, setFixedMessageChat] = useState(
    live.fixedMessageChat ?? ""
  );

  useEffect(() => {
    if (live)
      managerService
        .getRoom(live.docId)
        .then(
          ({
            intro: i,
            video: v,
            chat: c,
            whatsappContact: w,
            whatsappNumber: n,
          }) => {
            setVideo(v ? v : false);
            setIntro(i ? i : false);
            setChat(typeof c === "boolean" ? c : true);
            setWhatsapContact(w);
            setWhatsappNumber(n);
            setLoading(false);
            if (live.salaId) setOld(true);
          }
        )
        .catch(() => setLoading(false));
    else setLoading(false);
  }, [live]);

  useEffect(
    () => video && localStorage.setItem(`${live.liveId}_url_video`, video),
    [live.liveId, video]
  );

  const openNotification = (success, intro) => {
    if (success)
      notification.success({
        key: intro ? "intro" : "video",
        message: t("ROOM-TAB.UploadSuccess"),
        description: `${t("ROOM-TAB.IntroductionSuccessDescriptionOne")} ${
          intro ? `${t("ROOM-TAB.OfIntroduction")} ` : ""
        }${t("ROOM-TAB.IntroductionSuccessDescriptionTwo")}`,
        onClick: () => notification.close(intro ? "intro" : "video"),
        duration: 10,
      });
    else
      notification.error({
        key: intro ? "intro" : "video",
        message: t("ROOM-TAB.UploadError"),
        description: `${t("ROOM-TAB.IntroductionErrorDescriptionOne")} ${
          intro ? ` $t("ROOM-TAB.OfIntroduction")} ` : ""
        }. ${t("ROOM-TAB.IntroductionErrorDescriptionTwo")}`,
        onClick: () => notification.close(intro ? "intro" : "video"),
        duration: 10,
      });
  };

  const deletePreviousVideo = (isIntro, callback) => {
    if ((video && !isIntro) || (isIntro && intro)) {
      setLoadingDelete(isIntro ? "deletingIntro" : "deletingVideo");
      let urlToDelete = isIntro ? intro : video;
      storage
        .refFromURL(urlToDelete)
        .delete()
        .finally(() => {
          setLoadingDelete(false);
          callback();
        });
    } else callback();
  };

  const uploadVideo = (file, intro) => {
    if (live) {
      const uploadTask = storageRef
        .child(
          `lives/${live.docId}/${intro ? "intro" : "video"}/${
            intro ? "intro" : "live"
          }-geral-${file.name}`
        )

        .put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (intro) setIntroP(progress);
          else setVideoP(progress);
        },
        () => {
          openNotification(false, intro);
          if (intro) setIntroP();
          else setVideoP();
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            if (intro) {
              setIntro(downloadURL);
              setIntroP();
            } else {
              setVideo(downloadURL);
              setVideoP();
            }
            deletePreviousVideo(intro, function () {
              openNotification(true, intro);
            });
          });
        }
      );
    }
  };

  const finish = () => {
    setSaving(true);
    let body = {
      video,
      intro: intro ? intro : false,
      chat,
      whatsappContact,
      whatsappNumber,
      fixedMessageChat:
        fixedMessageChat.trim()?.length > 0 ? fixedMessageChat.trim() : false,
    };
    let endRequest = (success, type) => {
      setSaving(false);
      if (success) {
        if (type === "create") setLive({ ...live, salaId: success.salaId });
        notification.success({
          key: "aviso",
          message: t("ROOM-TAB.SaveRoomSuccess"),
          description: t("ROOM-TAB.SaveRoomSuccessDescription"),
          onClick: () => notification.close("aviso"),
          duration: 5,
        });
        if (!whatsappContact) {
          setWhatsappNumber("");
        }
        if (!old) next();
      } else
        notification.error({
          key: "aviso",
          message: t("ROOM-TAB.SaveRoomError"),
          description: t("ROOM-TAB.SaveRoomErrorDescription"),
          onClick: () => notification.close("aviso"),
          duration: 5,
        });
    };
    if (old)
      managerService
        .editRoom(live.docId, body)
        .then((resp) => endRequest(resp.model))
        .catch(() => endRequest());
    else
      managerService
        .createRoom(live.docId, body)
        .then((resp) => endRequest(resp.model, "create"))
        .catch(() => endRequest());
  };

  return (
    <div className="fade-in">
      <div style={{ textAlign: "center", color: "gray" }}>
        {t("ROOM-TAB.FulfillInformation")}{" "}
        {live?.docId ? t("ROOM-TAB.Edit") : t("ROOM-TAB.Create")}{" "}
        {t("ROOM-TAB.YourLive")}
        <br />
        {t("ROOM-TAB.Fields")} <b> * </b> {t("ROOM-TAB.FieldsRequired")}
      </div>
      {!live.salaId && (
        <Card
          title={
            <>
              <WarningOutlined style={{ marginRight: "10px" }} />
              {t("ROOM-TAB.Warning")}
            </>
          }
          style={{ maxWidth: 920, margin: "10px auto" }}
          className="shadow"
        >
          <s.WarningCard>
            <label>{t("ROOM-TAB.ConcludeStep")}</label>
            <lagend>{t("ROOM-TAB.CreateRoom")}</lagend>
          </s.WarningCard>
          {aoVivo && (
            <s.WarningCard style={{ marginTop: 20 }}>
              <label>{t("ROOM-TAB.LiveLive")}</label>
              <lagend>
                {t("ROOM-TAB.JustCreateRoomOne")} <b>{t("ROOM-TAB.Save")}</b>{" "}
                {t("ROOM-TAB.JustCreateRoomTwo")}
              </lagend>
            </s.WarningCard>
          )}
        </Card>
      )}
      <Card
        title={
          <>
            <NotificationOutlined style={{ marginRight: "10px" }} />
            {t("ROOM-TAB.Introduction")}
          </>
        }
        style={{ maxWidth: 920, margin: "10px auto" }}
        className="shadow"
      >
        <p>{t("ROOM-TAB.IntroductionDescription")}</p>
        {intro && (
          <video
            src={intro}
            style={{ width: "100%", maxHeight: "460px" }}
            controls
          />
        )}
        <input
          type="file"
          id="intro-input-sala"
          accept="video/*"
          style={{ display: "none" }}
          onChange={({ target: t }) =>
            t.files[0] && uploadVideo(t.files[0], true)
          }
        />
        {introP ? (
          <Progress percent={parseInt(introP)} status="active" />
        ) : (
          <Button
            type="primary"
            onClick={() => document.getElementById("intro-input-sala").click()}
            disabled={loading}
          >
            {t("ROOM-TAB.Upload")}
          </Button>
        )}
        {intro && !loading && !introP && (
          <Button
            type="primary"
            danger
            disabled={loading}
            onClick={() => {
              deletePreviousVideo(true, function () {
                setIntro(false);
              });
            }}
            style={{ marginLeft: "20px" }}
          >
            {t("ROOM-TAB.Remove")}
          </Button>
        )}
        {loadingDelete === "deletingIntro" && (
          <>
            <Spin style={{ marginLeft: 20 }} />
            <i style={{ color: "gray", fontSize: 12, marginLeft: 10 }}>
              {t("ROOM-TAB.DeletingVideo")}...
            </i>
          </>
        )}
      </Card>
      {!aoVivo && (
        <Card
          title={
            <>
              <PlaySquareOutlined style={{ marginRight: "10px" }} />
              {t("ROOM-TAB.Video")} {!live.aoVivo && "*"}
            </>
          }
          style={{ maxWidth: 920, margin: "10px auto" }}
          className="shadow"
        >
          <p>{t("ROOM-TAB.MainVideoDescription")}</p>
          {video && (
            <video
              src={video}
              style={{ width: "100%", maxHeight: "460px" }}
              controls
              id="main-video"
            />
          )}
          <input
            type="file"
            id="video-input-sala"
            accept="video/*"
            style={{ display: "none" }}
            onChange={({ target: t }) => t.files[0] && uploadVideo(t.files[0])}
          />
          {videoP ? (
            <Progress percent={parseInt(videoP)} status="active" />
          ) : (
            <Button
              type="primary"
              onClick={() =>
                document.getElementById("video-input-sala").click()
              }
              disabled={loading}
            >
              {t("ROOM-TAB.Upload")}
            </Button>
          )}

          {loadingDelete === "deletingVideo" && (
            <>
              <Spin style={{ marginLeft: 20 }} />
              <i style={{ color: "gray", fontSize: 12, marginLeft: 10 }}>
                {t("ROOM-TAB.DeletingPreviousVideo")}...
              </i>
            </>
          )}
        </Card>
      )}

      <Card
        title={
          <>
            <WhatsAppOutlined style={{ marginRight: "10px" }} />
            {t("ROOM-TAB.Whats")}{" "}
            {whatsappContact ? t("ROOM-TAB.Enabled") : t("ROOM-TAB.Disabled")}
          </>
        }
        style={{ maxWidth: 920, margin: "10px auto" }}
        className="shadow"
      >
        <p>{t("ROOM-TAB.WhatsDescription")}</p>
        <Switch
          checked={whatsappContact}
          onChange={() => setWhatsapContact(!whatsappContact)}
          style={{ width: 30 }}
          disabled={loading}
        />
        {whatsappContact && (
          <Input
            onKeyDown={(e) => {
              const inputValue = e.target.value;
              if (e.key === "Backspace" && inputValue === "+55") {
                e.preventDefault();
                setWhatsappNumber("+55");
              }
            }}
            value={whatsappNumber?.startsWith("+55") ? whatsappNumber : "+55"}
            onChange={({ target: t }) => {
              const inputValue = t.value;
              const newValue = inputValue.startsWith("+55")
                ? inputValue
                : "+55" + inputValue.replace(/\D/g, "");
              setWhatsappNumber(newValue);
            }}
            onBlur={() => {
              if (whatsappNumber?.length < 13) {
                setInvalidValues([...invalidValues, "fone"]);
              } else {
                setInvalidValues(invalidValues.filter((x) => x !== "fone"));
              }
            }}
            style={{
              marginTop: "20px",
              display: "block",
              width: "165px",
              border: invalidValues.includes("fone") && "1px solid red",
            }}
            placeholder="(00) 00000 0000"
            type="tel"
            maxLength={14}
          />
        )}
        {whatsappContact && invalidValues.includes("fone") && (
          <s.InvalidLabel className="fade-in">
            {t("CAPTURE-LEAD.validations.fone")}
          </s.InvalidLabel>
        )}
      </Card>
      <Card
        title={
          <>
            <WechatOutlined style={{ marginRight: "10px" }} />
            {t("ROOM-TAB.Chat")}{" "}
            {chat ? t("ROOM-TAB.Enabled") : t("ROOM-TAB.Disabled")}
          </>
        }
        style={{ maxWidth: 920, margin: "10px auto" }}
        className="shadow"
      >
        <p>{t("ROOM-TAB.ChatDescription")}</p>
        <Switch
          checked={chat}
          onChange={() => setChat(!chat)}
          style={{ width: 30 }}
          disabled={loading}
        />
      </Card>
      <Card
        title={
          <>
            <WechatOutlined style={{ marginRight: "10px" }} />
            {t("ROOM-TAB.FixedMensageTitle")}
          </>
        }
        style={{ maxWidth: 920, margin: "10px auto" }}
        className="shadow"
      >
        <TextArea
          value={fixedMessageChat}
          onChange={(e) => setFixedMessageChat(e.target.value)}
          rows={4}
          placeholder={t("ROOM-TAB.FixedMensageDescription")}
        />
      </Card>
      <s.ButtonContainer>
        <Button
          type="primary"
          htmlType="submit"
          onClick={finish}
          disabled={
            live.realtime && aoVivo
              ? loading || saving
              : loading ||
                !video ||
                saving ||
                (whatsappContact && !whatsappNumber) ||
                (whatsappContact && whatsappNumber.length < 10)
          }
          loading={loading || saving}
        >
          {loading
            ? t("ROOM-TAB.Loading")
            : saving
            ? t("ROOM-TAB.Saving")
            : old
            ? t("ROOM-TAB.Save")
            : t("ROOM-TAB.Next")}
          {!saving && !loading ? (
            old ? (
              <SaveOutlined />
            ) : (
              <ArrowRightOutlined />
            )
          ) : (
            <></>
          )}
        </Button>
      </s.ButtonContainer>
    </div>
  );
};

export default SalaTab;
