import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, message } from "antd";
import Scrollbars from "react-custom-scrollbars";
import { googleTagManager, productService } from "../../services";
import { sourceImage, formatCash } from "../../utils";
import { ThemeContext, GradesContext, AddProductContext } from "../../context";
import * as s from "./styles-modal-variations";

const ModalVariations = ({
  visible,
  products,
  onCancel,
  hideText,
  live,
  customer,
}) => {
  const { t } = useTranslation();
  const { corPrimaria } = useContext(ThemeContext);
  const { userGrades } = useContext(GradesContext); //grades do admin da live
  const { setAddProduct } = useContext(AddProductContext);
  const [selected, setSelected] = useState(false); //variações selecionadas para serem adicionadas
  //Control
  const [loading, setLoading] = useState(false); //variações selecionadas para serem adicionadas
  const [isSelected, setIsSelected] = useState(false);

  const onOk = () => {
    if (selected) {
      let newProduct = {
        ...selected,
        preco: parseFloat(
          (live.layoutCheckout === "ecommerce" ? 1 : 100) *
            selected.preco.toFixed(2)
        ),
        grade:
          selected.tipoGrade === "aberta" || !selected.tipoGrade
            ? [{ tamanho: selected.tamanhos[0], quantidade: 1 }]
            : userGrades.filter((g) => selected.grades.includes(g.gradeId))[0]
                .grade || [],
      };
      googleTagManager.clickProduct(newProduct, "modal-variations-sold-off");
      if (selected.tipoGrade === "aberta" && live.layoutCheckout !== "padrao") {
        setAddProduct(newProduct);
        onCancel();
        setSelected(false);
        let element = document.getElementById("fullscreenElem");
        if (element) element.style.overflowY = "auto";
      } else {
        setLoading(true);
        productService
          .addFavorite(live.liveId, {
            produto: newProduct,
            customer,
          })
          .then(() => {
            message.success({
              content: t("MODAL-VARIATIONS-LIVE.ProductAdded"),
              duration: 2,
            });
            googleTagManager.addProductToCart(
              newProduct,
              "modal-variations-sold-off"
            );
            setSelected(false);
            setLoading(false);
            onCancel();
            let element = document.getElementById("fullscreenElem");
            if (element) element.style.overflowY = "auto";
          })
          .catch(() => {
            message.error({
              content: t("MODAL-VARIATIONS-LIVE.EnterCartError"),
              duration: 5,
            });
            setLoading(false);
          });
      }
    } else onCancel();
  };

  const handleVariation = (v) => {
    if (selected?.docId === v?.docId) setSelected(false);
    else setSelected(v);
  };
  const renderVariations = () => {
    return products.map((p) => (
      <div key={p.docId} className="products-prod fade-in">
        <img
          alt={p.descricao}
          src={sourceImage(p.thumbnail)}
          className={`shadow2 
    
        ${p.esgotado ? `product-img-grayscale` : `product-img`}`}
          style={{
            objectFit: "cover",
            width: "70%",
            height: "74px",
          }}
        />
        <div className="content-description">
          <div className="content-price">
            {p.precoOriginal && p.precoOriginal !== p.preco ? (
              <div style={{ textAlign: "end" }}>
                <h5 style={{ textDecoration: "line-through", color: "gray" }}>
                  {live?.unidadeMonetaria || "R$"}
                  {p.precoOriginal &&
                    formatCash(p.precoOriginal, true, live?.unidadeMonetaria)}
                </h5>
              </div>
            ) : null}
            <h3>
              {live?.unidadeMonetaria || "R$"}
              {p.preco && formatCash(p.preco, true, live?.unidadeMonetaria)}
            </h3>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {p.available === false || p.esgotado ? (
              <h5 style={{ color: "red" }}>Indisponível</h5>
            ) : (
              <h5 style={{ color: "green" }}>Disponível</h5>
            )}
          </div>
          <hr />
          {live.showStockOnLive && p?.controlStock && p.stock?.length !== 0 ? (
            <article
              className="product-price"
              style={{
                marginTop: window.innerWidth >= 960 ? "14px" : "7px",
              }}
            >
              <h5>{t("MODAL-VARIATIONS-LIVE.Quantity") + ": "}</h5>
              <h4>{"  (" + p.stock + ")"}</h4>
            </article>
          ) : (
            <br />
          )}
          <Button
            className="button-add"
            type="primary"
            size="large"
            onClick={() => {
              p.available && !p.esgotado && handleVariation(p);
            }}
            disabled={p.esgotado}
          >
            {selected?.docId === p.docId
              ? t("PRODUCTS-TAB-VARIATION-MODAL.Selected")
              : t("PRODUCTS-TAB-VARIATION-MODAL.Select")}
            <img
              src="/assets/icons/cartOutlined.svg"
              style={{ marginLeft: "3px" }}
            />
          </Button>
        </div>
      </div>
    ));
  };

  return (
    <Modal
      title={t("MODAL-VARIATIONS-LIVE.ProductVariations")}
      visible={visible}
      onOk={!loading ? () => onOk() : () => {}}
      onCancel={!loading ? onCancel : () => {}}
      confirmLoading={loading}
      okText={t("MODAL-VARIATIONS-LIVE.Add")}
      cancelText={t("MODAL-VARIATIONS-LIVE.Cancel")}
      width={"660px"}
      disabled={!selected}
      okButtonProps={{ style: { display: products.length === 0 && "none" } }}
      bodyStyle={{ paddingBottom: 0, background: "#f3f3f3" }}
    >
      {products.length > 0 ? (
        <>
          {!hideText && (
            <div style={{ marginBottom: "20px" }}>
              {t("MODAL-VARIATIONS-LIVE.ProductSoldoffVariations")}
            </div>
          )}
          <Scrollbars
            style={{ width: "100%", height: 300 }}
            renderThumbHorizontal={() => <div style={{ display: "none" }} />}
          >
            <s.VariationsList corPrimaria={corPrimaria}>
              {renderVariations()}
              {/* {products.map((prod, idx) => (
                <div className="wrapper" key={prod.docId}>
                  <img
                    id={`${idx}-image`}
                    className={
                      selected?.docId === prod.docId ? "var-selected" : ""
                    }
                    onClick={() => {
                      if (prod.available && !prod.esgotado)
                        handleVariation(prod);
                      else if (prod.esgotado)
                        message.error(
                          t("MODAL-VARIATIONS-LIVE.ProductSoldoffToBuy")
                        );
                      else
                        message.error(
                          t("MODAL-VARIATIONS-LIVE.ProductUnavailableToBuy")
                        );
                    }}
                    src={sourceImage(prod.thumbnail)}
                    alt={prod.descricao}
                  />
                  {(!prod.available || prod.esgotado) && (
                    <label style={{ textTransform: "uppercase" }}>
                      {prod.esgotado
                        ? t("MODAL-VARIATIONS-LIVE.ProductSoldoff")
                        : t("MODAL-VARIATIONS-LIVE.ProductUnavailable")}
                    </label>
                  )}
                </div>
              ))} */}
            </s.VariationsList>
          </Scrollbars>
        </>
      ) : (
        <label>{t("MODAL-VARIATIONS-LIVE.SoldoffNoAvailableVariations")}</label>
      )}
    </Modal>
  );
};

export default ModalVariations;
