import styled from "styled-components";

export const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;

  .ant-select.ant-select-single.ant-select-show-arrow {
    margin-right: auto !important;
    margin-left: 14px !important;
  }

  .ant-select-selector {
    display: flex !important;
    background: transparent !important;
    border: none !important;
    color: white !important;
    transition: 1s !important;
    align-items: center !important;
  }

  .ant-select-selection-item {
    label {
      display: none;
    }

    img {
      margin-right: 0;
    }
  }

  .ant-select-arrow {
    color: white !important;
  }
`;

export const DropdownImage = styled.img`
  width: 30px;
  margin-right: 10px;
`;
