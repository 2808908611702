import { useState } from "react";

const useViewLandingPage = () => {
  // const [brandName, setBrandName] = useState("");
  // const [aboutBrand, setAboutBrand] = useState("");
  // const [liveName, setLiveName] = useState("");
  // const [liveUrl, setLiveUrl] = useState("");
  // const [aboutLive, setAboutLive] = useState("");
  // const [liveTime, setliveTime] = useState("");
  // const [dateValue, onChangeDateValue] = useState([new Date(), new Date()]);
  // const [minDateCalendar, setMinDateCalendar] = useState(new Date());

  // const [thumbLogo, setThumbLogo] = useState();

  // const [colorPrimary, setColorPrimary] = useState();
  // const [colorSecundary, setColorSecundary] = useState();
  // const [colorButton, setColorButton] = useState();
  // const [textColor, setTextColor] = useState();

  // const [thumb, setThumb] = useState();
  // const [video, setVideo] = useState(); //url vídeo principal

  // const [timeStart, setTimeStart] = useState("");

  const [landingPage, setLandingPage] = useState();

  return {
    // aboutBrand,
    // aboutLive,
    // brandName,
    // colorButton,
    // colorPrimary,
    // colorSecundary,
    // textColor,
    // liveName,
    // liveUrl,
    // liveTime,
    // dateValue,
    // minDateCalendar,
    // thumb,
    // thumbLogo,
    // video,
    // timeStart,
    // setAboutBrand,
    // setAboutLive,
    // setBrandName,
    // setColorButton,
    // setColorPrimary,
    // setColorSecundary,
    // setTextColor,
    // setLiveName,
    // setLiveUrl,
    // setliveTime,
    // onChangeDateValue,
    // setMinDateCalendar,
    // setThumb,
    // setThumbLogo,
    // setVideo,
    // setTimeStart,
    landingPage,
    setLandingPage,
  };
};

export default useViewLandingPage;
