import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import { Card, notification } from "antd";
import {
  PlaySquareOutlined,
  UnorderedListOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { firestore, managerService } from "../../../../services";
import { formatTime, sourceImage } from "../../../../utils";
import CardRealtime from "./card-realtime";
import TabsVideo from "../../../../components/tabs-video/tabs-video";
import { ThemeContext } from "../../../../context";
import * as s from "./styled-realtime";

const Realtime = ({ live }) => {
  const { video, docId: liveId, aoVivo } = live;
  const { t } = useTranslation();
  const realtimeVideoId = "video-realtime";
  const videoHtml = document.getElementById(realtimeVideoId);
  const { corPrimaria, corSecundaria } = useContext(ThemeContext);

  const [products, setProducts] = useState([]); // produtos realtime da live
  const [show1, setShow1] = useState(false); // primeiro produto a aparecer
  const [show2, setShow2] = useState(false); // segundo produto a aparecer
  const [selectedProduct, setSelectedProduct] = useState(false); // produto selecionado
  const [allProductsTime, setAllProductsTime] = useState([]); // tempo de entrada de todos os produtos
  const [loading, setLoading] = useState(false); // posição do loader
  const [lastWhatShow, setLastWhatShow] = useState("show2"); // gambiarra pra funcionar
  const [startTime, setStartTime] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const getThumbnail = (position) => {
    if (show1?.positionNumber === position) return show1;
    else if (show2?.positionNumber === position) return show2;
    return "";
  };

  // @todo - Ajustar depois no novo layout
  const cards = [
    {
      // style: !live.vertical ? s.HorCard1 : s.VerCard1,
      style: !live.vertical ? s.HorCard2 : s.VerCard2,
      positionNumber: 1,
      image: sourceImage(getThumbnail(1).thumbnail),
    },
    {
      // style: !live.vertical ? s.HorCard2 : s.VerCard2,
      style: !live.vertical ? s.HorCard4 : s.VerCard4,
      positionNumber: 2,
      image: sourceImage(getThumbnail(2).thumbnail),
    },
    // {
    //   style: !live.vertical ? s.HorCard3 : s.VerCard3,
    //   positionNumber: 3,
    //   image: sourceImage(getThumbnail(3).thumbnail),
    // },
    // {
    //   style: !live.vertical ? s.HorCard4 : s.VerCard4,
    //   positionNumber: 4,
    //   image: sourceImage(getThumbnail(4).thumbnail),
    // },
    // {
    //   style: !live.vertical ? s.HorCard5 : s.VerCard5,
    //   positionNumber: 5,
    //   image: sourceImage(getThumbnail(5).thumbnail),
    // },
    // {
    //   style: !live.vertical ? s.HorCard6 : s.VerCard6,
    //   positionNumber: 6,
    //   image: sourceImage(getThumbnail(6).thumbnail),
    // },
  ];

  const getShowFirebase = async (id) => {
    let outDoc = await firestore
      .collection(`lives/${liveId}/realtime`)
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) return doc.data();
        return false;
      })
      .catch(() => false);
    return outDoc;
  };

  useEffect(async () => {
    let tmp1 = await getShowFirebase("show1");
    let tmp2 = await getShowFirebase("show2");
    setShow1(tmp1);
    setShow2(tmp2);
  }, []);

  useEffect(() => {
    managerService
      .getTransmissionStartTime(liveId)
      .then(({ startTime }) => setStartTime(new Date(startTime).getTime()));
  }, []);

  useEffect(() => {
    // pegando os produtos realtime
    if (live && !live.tabelaPreco) {
      let subscribe = firestore
        .collection(`lives/${liveId}/produtos`)
        .onSnapshot((snapshot) => {
          let mainProducts = snapshot.docs
            .map(
              (doc) =>
                doc.data().principal && {
                  ...doc.data(),
                  docId: doc.id,
                }
            )
            .filter((p) => p)
            .sort((a, b) => a.id - b.id);
          setProducts(mainProducts);
          setAllProductsTime(mainProducts.map((p) => p.tempoInicio));
        });
      return () => subscribe();
    }
  }, []);

  const saveTimeProduct = async (
    // salva tempo do produto
    tempoInicio,
    tempoFim,
    productToSave,
    positionNumber
  ) => {
    let outProduct = false;
    setLoading(positionNumber);
    await managerService
      .saveProductTime(tempoInicio, tempoFim, liveId, productToSave.docId)
      .then(({ produto }) => {
        setAllProductsTime([...allProductsTime, tempoInicio]);
        if (tempoInicio) setSelectedProduct(produto);
        outProduct = produto;
      })
      .catch(() => {
        setSelectedProduct(false);
        notification.error({
          key: "error-save-time-product",
          description: t("REALTIME-TAB.SaveTimeProductErrorDescription"),
          duration: 10,
        });
      })
      .finally(() => setLoading(false));
    return outProduct;
  };

  const setProductRealtime = (prod, positionNumber, id) => {
    let productToSet = {
      ...prod,
      positionNumber,
    };
    firestore.collection(`lives/${liveId}/realtime`).doc(id).set(productToSet);

    if (id === "show1") setShow1(productToSet);
    else setShow2(productToSet);
    setSelectedProduct(false);
  };

  const getRealStreamingTime = useCallback(() => {
    let now = new Date().getTime();
    let realStreamingStartTime = now - startTime;
    return realStreamingStartTime;
  }, [startTime]);

  const addProdToRealtime = async (posNumber) => {
    // salvar tempo do produto | salvar no firebase | setar no estado
    let timeFormated = "";
    let timeFromVideo = live?.aoVivo
      ? getRealStreamingTime()
      : videoHtml.currentTime * 1000;

    if (videoHtml)
      timeFormated = new Date(timeFromVideo).toISOString().substring(11, 19);

    if (selectedProduct && allProductsTime.includes(timeFormated)) {
      notification.error({
        key: "product-already-with-time",
        description: t("REALTIME-TAB.ProductWithSameTime"),
        duration: 10,
      });
    } else {
      if (show1 && show2) {
        let whatShow =
          show1?.positionNumber === posNumber
            ? show1
            : show2.positionNumber === posNumber
            ? show2
            : lastWhatShow === "show1"
            ? show2
            : show1;

        if (whatShow === show1) setLastWhatShow("show1");
        else setLastWhatShow("show2");

        let callback = async () => {
          await saveTimeProduct(
            timeFormated,
            "",
            selectedProduct,
            posNumber
          ).then(() => {
            let isShowOne = whatShow === show1 ? "show1" : "show2";
            setProductRealtime(selectedProduct, posNumber, isShowOne);
          });
        };

        removeProdFromRealtime(whatShow.positionNumber, callback);
      } else if (!show1) {
        await saveTimeProduct(
          timeFormated,
          "",
          selectedProduct,
          posNumber
        ).then((produto) => setProductRealtime(produto, posNumber, "show1"));
      } else if (!show2) {
        await saveTimeProduct(
          timeFormated,
          "",
          selectedProduct,
          posNumber
        ).then((produto) => setProductRealtime(produto, posNumber, "show2"));
      }
    }
  };

  const removeProdFromRealtime = async (positionNumber, callback) => {
    let isShowOne = show1 && positionNumber === show1.positionNumber;
    let timeFormated = "";
    let timeFromVideo = live?.aoVivo
      ? getRealStreamingTime()
      : videoHtml.currentTime * 1000;

    if (videoHtml)
      timeFormated = new Date(timeFromVideo).toISOString().substring(11, 19);

    let tempoFim = show1 || show2 ? timeFormated : "";

    if (isShowOne) {
      await saveTimeProduct("", tempoFim, show1, positionNumber).then(() =>
        setShow1(false)
      );
    } else {
      await saveTimeProduct("", tempoFim, show2, positionNumber).then(() =>
        setShow2(false)
      );
    }

    firestore
      .collection(`lives/${liveId}/realtime`)
      .doc(isShowOne ? `show1` : `show2`)
      .delete();

    if (callback) return callback();
  };

  return (
    <s.Container className="fade-in">
      <Card
        title={
          <>
            <PlaySquareOutlined style={{ marginRight: "10px" }} />
            {t("REALTIME-TAB.MainVideo")}
          </>
        }
        style={{ width: "735px", margin: "5px" }}
        bodyStyle={{ display: "flex" }}
        className="shadow"
      >
        <div>
          <s.FakePLayer vertical={live.vertical}>
            <TabsVideo
              url={video}
              aoVivo={aoVivo}
              videoId={realtimeVideoId}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              onVideoLoad={() => setVideoLoaded(true)}
            />
            {cards.map((card) => (
              <CardRealtime
                cardKey={card.positionNumber}
                style={card.style}
                onClick={() =>
                  videoLoaded &&
                  (card.image && !selectedProduct
                    ? removeProdFromRealtime(card.positionNumber)
                    : addProdToRealtime(card.positionNumber))
                }
                imageSrc={card.image}
                imageAlt={`realtime-card-${card.positionNumber}`}
                positionNumber={card.positionNumber}
                disabled={
                  !selectedProduct &&
                  show1.positionNumber !== card.positionNumber &&
                  show2.positionNumber !== card.positionNumber
                }
                className={
                  loading === card.positionNumber ? "pulse shadow2" : "shadow2"
                }
              />
            ))}
          </s.FakePLayer>
          <s.SelectedCardWrapper>
            <div className="selected-product">
              <CardRealtime
                onClick={() => setSelectedProduct(false)}
                imageSrc={selectedProduct.thumbnail}
                imageAlt="selected-product-realtime"
                style={{ margin: "0 10px 0 0", width: 100, height: 100 }}
              />
              <div className="loader">{loading && <LoadingOutlined />}</div>
            </div>
            <div className="divisor" />
            <div>
              <b>{t("REALTIME-TAB.Tips")}:</b>
              <br />- {t("REALTIME-TAB.TipOne")}
              <br />- {t("REALTIME-TAB.TipTwo")}
              <br />- {t("REALTIME-TAB.TipThree")}
              <br />- {t("REALTIME-TAB.TipFour")}
              <br />- {t("REALTIME-TAB.TipFive")}
            </div>
          </s.SelectedCardWrapper>
        </div>
      </Card>
      <Card
        title={
          <s.TitleWrapper>
            <UnorderedListOutlined style={{ marginRight: "10px" }} />
            <label>{t("REALTIME-TAB.ProductsList")} </label>
            <s.LegendWrapper>
              <div style={{ background: corPrimaria }} />
              <label>{t("REALTIME-TAB.EntryTime")}</label>
              <div style={{ background: corSecundaria }} />
              <label>{t("REALTIME-TAB.ExitTime")}</label>
            </s.LegendWrapper>
          </s.TitleWrapper>
        }
        bodyStyle={{ padding: "0px" }}
        style={{ margin: "5px" }}
        className="shadow"
      >
        <Scrollbars
          style={{
            width: 735,
            height: products?.length > 0 ? 560 : 100,
          }}
          renderThumbHorizontal={() => <div style={{ display: "none" }} />}
        >
          <div style={{ margin: "0 24px", display: "flex", flexWrap: "wrap" }}>
            {products?.length > 0 ? (
              products.map((product) => (
                <CardRealtime
                  cardKey={product.docId}
                  style={{
                    margin: "5px",
                    cursor: "pointer",
                    maxWidth: "120px",
                    maxHeight: "105px",
                  }}
                  onClick={() =>
                    videoLoaded
                      ? setSelectedProduct(product)
                      : notification.error({
                          key: "error-add-realtime-product",
                          description: t(
                            "REALTIME-TAB.AddRealtimeProductWithoutPlayerError"
                          ),
                          duration: 10,
                        })
                  }
                  imageSrc={product.thumbnail}
                  imageAlt={product.descricao}
                  showProductTime
                  tempoInicio={product.tempoInicio}
                  tempoFim={product.tempoFim}
                />
              ))
            ) : (
              <s.MsgEmptyProducts>
                {t("REALTIME-TAB.EmptyProductsDescriptionOne")} <br />
                {t("REALTIME-TAB.EmptyProductsDescriptionTwo")}
              </s.MsgEmptyProducts>
            )}
          </div>
        </Scrollbars>
      </Card>
    </s.Container>
  );
};

export default Realtime;
