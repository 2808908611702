import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  section {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    width: 300px;
  }
`;

export const Card = styled.div`
  width: 100px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  position: relative;
  cursor: ${({ disabled: d }) => (d ? "not-allowed" : "pointer")};

  img {
    max-width: 100px;
    max-height: 80px;
    margin: 0;
    border-radius: 12px;
  }
  section {
    width: 22px;
    height: 22px;
    color: white;
    background: ${({ corSecundaria: c }) => c};
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      width: 11px;
    }
    border-radius: 7px;
    bottom: 13px;
    right: -20px;
  }
  label {
    margin: auto;
    font-size: 30px;
    color: gray;
  }

  .loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-time {
    display: flex;
    flex-direction: column;
    position: absolute;
    transform: translateX(68px);
    z-index: 1;

    div {
      display: flex;
      align-items: center;
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.15);
      justify-content: center;
      color: white;
      width: 64px;
      margin-top: 10px;
      height: 28px;
      border-radius: 4px;
    }
  }
`;
export const FakePLayer = styled.div`
  width: 100%;
  height: ${({ vertical: v }) => (!v ? 360 : 500)}px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f0f0f0;
  background: #f4f4f4;
`;
export const MsgEmptyProducts = styled.div`
  text-align: center;
  width: 100%;
  font-size: 14px;
  color: gray;
  margin-top: 25px;
`;
export const SelectedCardWrapper = styled.div`
  display: flex;
  width: 100%;
  max-height: 100%;
  align-items: center;
  justify-content: center;

  .selected-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 40%;
    margin: 10px 0 0 10px;

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 0 0;
      width: 100%;
      height: 30px;
    }
  }

  .divisor {
    width: 1px;
    height: 150px;
    margin: 25px 20px 0 20px;
    background-color: grey;
  }

  label {
    color: gray;
    margin: 25px 0px 10px 20px;
    text-align: center;
    cursor: pointer;
  }
`;
export const NumbersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(65px, 1fr));
  margin: 0px 0px 85px 15px;
  width: 100%;
  height: calc(100% - 200px);
  padding: 0 10px 10px 10px;
`;
export const NumberButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  transition: 0.2s;
  border: 1px solid var(--corSecundaria);
  padding: 18px;
  margin: 0 auto;
  cursor: pointer;

  font-size: 14px;
  color: black;

  :disabled {
    color: lightgray;
    cursor: not-allowed;
    border: 1px solid lightgray;
  }

  :hover {
    background: var(--corSecundaria);
    color: white;
  }
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    margin-top: 1px;
  }
`;
export const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  label {
    font-size: 14px;
    margin-right: 10px;
  }

  div {
    display: flex;
    width: 35px;
    height: 15px;
    margin-right: 5px;
  }
`;

export const HorCard1 = { position: "absolute", top: "20px", left: "20px" };
// export const HorCard2 = { position: "absolute", top: "20px", right: "285px" };
export const HorCard2 = { position: "absolute", top: "65px", right: "35px" };
export const HorCard3 = { position: "absolute", top: "20px", right: "20px" };
// export const HorCard4 = { position: "absolute", top: "200px", left: "20px" };
export const HorCard4 = { position: "absolute", top: "170px", right: "35px" };
export const HorCard5 = { position: "absolute", top: "200px", right: "285px" };
export const HorCard6 = { position: "absolute", top: "200px", right: "20px" };

export const VerCard1 = { position: "absolute", top: "20px", left: "20px" };
// export const VerCard2 = { position: "absolute", top: "20px", right: "20px" }; // @todo - ajustar depois
export const VerCard2 = { position: "absolute", top: "100px", right: "20px" };
export const VerCard3 = { position: "absolute", top: "146px", left: "20px" };
// export const VerCard4 = { position: "absolute", top: "146px", right: "20px" }; // @todo - ajustar depois
export const VerCard4 = { position: "absolute", top: "226px", right: "20px" };
export const VerCard5 = { position: "absolute", top: "280px", left: "20px" };
export const VerCard6 = { position: "absolute", top: "280px", right: "20px" };
