import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "ptBR",
  lng: "ptBR",
  resources: {
    ptBR: {
      translations: require("./pt-br.json"),
    },
    en: {
      translations: require("./en.json"),
    },
    es: {
      translations: require("./es.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["ptBR", "en", "es"];

export default i18n;
