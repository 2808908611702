import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, message, Radio, Checkbox } from "antd";
import MaskedInput from "antd-mask-input";
import { ArrowRightOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context";
import { customerService, googleTagManager } from "../../../services";
import { validateCPF, validateCNPJ, validateEmail } from "../../../utils";
import * as s from "./styles-login-form";

const LoginForm = ({ live, visible, setVisible, fullWidth }) => {
  const { t } = useTranslation();
  const { setUser, user, openedFrom, setOpenedFrom } = useContext(UserContext);
  const [isPfUser, setIsPfUser] = useState(true);
  const [isLgpd, setIsLgpd] = useState(false);

  const started = live.timeToStart === 0;

  useEffect(() => {
    if (live) {
      if (live?.layoutCheckout === "padrao") setIsPfUser(false);
    }
  }, []);

  const handleTypeUser = (e) => {
    if (e?.target?.value === "pj") setIsPfUser(false);
    if (e?.target?.value === "pf") setIsPfUser(true);
  };

  const getFormRules = (type, message, required, requiredMessage) => {
    return [
      {
        type,
        message,
      },
      {
        required: typeof required === "boolean" ? required : true,
        message: requiredMessage || t("UTILS.TypeValidValue"),
      },
    ];
  };

  const cpfValidator = (_, value) =>
    !value ||
    value.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "").length <
      11
      ? Promise.reject()
      : Promise.resolve();

  const cnpjValidator = (_, value) =>
    !value ||
    value

      .replaceAll("_", "")

      .replaceAll(".", "")

      .replaceAll("-", "")

      .replaceAll("/", "").length < 14
      ? Promise.reject()
      : Promise.resolve();

  return (
    <s.Container>
      <Form
        name="login-customer"
        onFinish={({
          codigo: userCodigo = "",
          phone = "",
          type = "pf",
          name = "",
          email = "",
          country = "",
          state = "",
          city = "",
          aceiteLGPD = false || true,
        }) => {
          let finish = (codigo) => {
            if (live?.liveEstrangeira || live?.checkout === "externo") {
              setUser({
                name,
                codigo: email,
                email,
              });
            } else {
              setUser({
                ...user,
                codigo: codigo.toString().replace(/[^\d]+/g, ""),
                phone: "+55" + phone.replace(/[^0-9]+/g, ""),
                type,
                name,
                email,
                country,
                state,
                city,
                aceiteLGPD,
              });
            }
            let userInfo = {};
            if (!live?.liveEstrangeira) {
              userInfo = {
                ...user,
                codigo: codigo.toString().replace(/[^\d]+/g, ""),
                phone: "+55" + phone.replace(/[^0-9]+/g, ""),
                type,
                name,
                email,
                country,
                state,
                city,
                aceiteLGPD,
              };
            } else {
              userInfo = {
                ...user,
                name,
                codigo: email,
                email,
                aceiteLGPD,
              };
            }
            customerService
              .customerLogin(live.liveId, userInfo)
              .then(() => googleTagManager.userRegister(userInfo));
            message.success(t("HEADER.SuccessMessage"));
            if (visible) setVisible(false);
          };
          if (openedFrom) {
            setTimeout(() => setOpenedFrom(""), 500);
          }
          if (live?.liveEstrangeira) {
            if (!validateEmail(email)) message.error(t("HEADER.InvalidEmail"));
            else if (live.listaAudiencia) finish(userCodigo);
            else finish(userCodigo);
          } else {
            if (email.length > 0 && !validateEmail(email))
              message.error(t("HEADER.InvalidEmail"));
            else if (live.listaAudiencia) finish(userCodigo);
            else {
              let codigo = userCodigo.replace(/[^\d]+/g, "");
              if (type === "pf" && codigo.length > 0 && !validateCPF(codigo))
                message.error(t("HEADER.InvalidCPF"));
              else if (
                type === "pj" &&
                codigo.length > 0 &&
                !validateCNPJ(codigo)
              )
                message.error(t("HEADER.InvalidCNPJ"));
              else finish(codigo);
            }
          }
        }}
        initialValues={{
          ...user,
          type: user?.type
            ? user.type
            : live?.layoutCheckout === "padrao"
            ? "pj"
            : "pf",
          phone:
            user && user?.phone
              ? user?.phone?.substring(0, 3) === "+55"
                ? user?.phone?.substring(3)
                : user?.phone
              : "",
          codigo: user?.codigo ? user.codigo.replace(/[^\d]+/g, "") : "",
          email: user?.email ? user.email : "",
        }}
        layout="vertical"
        style={{
          height: "fit-content",
          width: fullWidth ? "100%" : "280px",
        }}
      >
        <s.InfoLabel>
          <div className="before" />
          <label style={{ textTransform: "uppercase" }}>
            {t("HEADER.RequiredData")}
          </label>
          <div className="after" />
        </s.InfoLabel>
        {live?.liveEstrangeira || live?.checkout === "externo" ? (
          <>
            <Form.Item
              validateTrigger="onBlur"
              nameValidator
              rules={getFormRules("string", "", true, t("HEADER.RequireName"))}
              name={"name"}
              style={{ marginBottom: "10px" }}
              id="input-customer-name"
            >
              <Input required placeholder={t("HEADER.Name")} />
            </Form.Item>

            <Form.Item
              validateTrigger="onBlur"
              rules={getFormRules(
                "email",
                t("HEADER.RequireEmail"),
                false,
                t("HEADER.RequireEmail")
              )}
              name={"email"}
              style={{ marginBottom: "10px" }}
            >
              <Input placeholder={t("HEADER.Email")} />
            </Form.Item>
          </>
        ) : openedFrom === "chat" ? (
          <>
            <Form.Item
              validateTrigger="onBlur"
              nameValidator
              rules={getFormRules("string", "", true, t("HEADER.RequireName"))}
              name={"name"}
              style={{ marginBottom: "10px" }}
              id="input-customer-name"
            >
              <Input required placeholder={t("HEADER.Name")} />
            </Form.Item>
            <Form.Item
              validateTrigger="onBlur"
              rules={getFormRules(
                "email",
                t("HEADER.RequireEmail"),
                false,
                t("HEADER.RequireEmail")
              )}
              name={"email"}
              style={{ marginBottom: "10px" }}
            >
              <Input placeholder={t("HEADER.Email")} />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item name={"type"} style={{ marginBottom: "10px" }}>
              <Radio.Group required onChange={handleTypeUser}>
                <Radio value="pf" disabled={live?.layoutCheckout === "padrao"}>
                  {t("HEADER.IndividualPerson")}
                </Radio>
                <Radio value="pj">{t("HEADER.LegalPerson")}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              validateTrigger="onBlur"
              rules={[
                ...getFormRules("string", "", true, t("HEADER.RequireName")),
                {
                  pattern: new RegExp(/^[a-záàâä6ãéèêíïóôõöúçñ ]+$/i),

                  message: t("HEADER.RequireValidName"),
                },
              ]}
              name={"name"}
              style={{ marginBottom: "10px" }}
              normalize={(value) => (value === " " ? value.trim() : value)}
            >
              {isPfUser ? (
                <Input
                  required
                  placeholder={t("HEADER.FullName")}
                  id="input-customer-name"
                />
              ) : (
                <Input
                  required
                  placeholder={t("HEADER.CorporateName")}
                  id="input-customer-name"
                />
              )}
            </Form.Item>
            <Form.Item
              validateTrigger="onBlur"
              rules={getFormRules(
                "email",
                t("HEADER.RequireEmail"),
                true,
                t("HEADER.RequireFullEmail")
              )}
              name={"email"}
              style={{ marginBottom: "10px" }}
              normalize={(value) => value.trim()}
            >
              <Input
                placeholder={t("HEADER.Email")}
                disabled={live?.listaAudiencia && user.codigoIsEmail && started}
              />
            </Form.Item>
            <Form.Item
              validateTrigger={["onBlur", "onChange"]}
              rules={[
                {
                  validator: isPfUser ? cpfValidator : cnpjValidator,

                  message: t("HEADER.RequireCPF-CNPJ"),
                },
              ]}
              name={"codigo"}
              style={{ marginBottom: "10px" }}
            >
              {isPfUser ? (
                <MaskedInput
                  mask="000.000.000-00"
                  size="00"
                  placeholder={t("HEADER.CPF")}
                  disabled={
                    live?.listaAudiencia && !user.codigoIsEmail && started
                  }
                />
              ) : (
                <MaskedInput
                  mask="00.000.000/0000-00"
                  size="13"
                  placeholder={t("HEADER.CNPJ")}
                  disabled={
                    live?.listaAudiencia && !user.codigoIsEmail && started
                  }
                />
              )}
            </Form.Item>

            <Form.Item
              validateTrigger="onBlur"
              rules={getFormRules("", "", false, t("HEADER.RequirePhone"))}
              name={"phone"}
              style={{ marginBottom: "10px" }}
            >
              <MaskedInput
                mask="(00)00000-0000"
                size="00"
                placeholder={t("HEADER.Phone")}
                prefix={"+55"}
              />
            </Form.Item>
            <s.InfoLabel>
              <div className="before" />
              <label style={{ textTransform: "uppercase" }}>
                {t("HEADER.OptionalData")}
              </label>
              <div className="after" />
            </s.InfoLabel>
            <div
              style={{
                height: "32px",
                display: "flex",
                justifyContent: "center",
                margin: "10px 0",
              }}
            >
              <Form.Item name={"country"}>
                <Input
                  placeholder={t("HEADER.Country")}
                  maxLength={2}
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
              <div style={{ width: fullWidth ? "10%" : "4%" }} />
              <Form.Item name={"state"}>
                <Input
                  placeholder={t("HEADER.State")}
                  maxLength={2}
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
            </div>
            <Form.Item name={"city"}>
              <Input
                placeholder={t("HEADER.City")}
                style={{ textTransform: "uppercase" }}
              />
            </Form.Item>
          </>
        )}
        <Form.Item name={"aceiteLGPD"}>
          {!user?.aceiteLGPD && (
            <Checkbox
              style={{
                marginBottom: "10px",
                fontWeight: "normal",
              }}
              checked={isLgpd}
              onChange={() => {
                setIsLgpd(!isLgpd);
              }}
              required
            >
              {t("HEADER.TermsLGPD")}
              <a
                href={window.location.origin + "/termos-lgpd.pdf"}
                target="_blank"
              >
                {t("HEADER.PrivacyPolicy")}
              </a>
            </Checkbox>
          )}
        </Form.Item>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button type="primary" htmlType="submit">
            {!user?.name ? t("HEADER.Continue") : t("HEADER.Alter")}
            <ArrowRightOutlined />
          </Button>
        </div>
      </Form>
    </s.Container>
  );
};

export default LoginForm;
