import React, { useCallback, useEffect, useState } from "react";
import { Modal, Table, message, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { managerService } from "../../../../services";
import { formatCash } from "../../../../utils";
import { useTranslation } from "react-i18next";

const MagentoCatalog = ({
  open = false,
  products = [],
  onFinish = () => {},
  onClose = () => {},
  unidadeMonetaria,
}) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const [catalogInformation, setCatalogInformation] = useState({
    lastItem: null,
    moreItems: false,
  });
  const [loading, setLoading] = useState(true);
  const [loadingNewProducts, setLoadingNewProducts] = useState(true);
  const tableColumns = [
    {
      title: t("MAGENTO-CATALOG.Id"),
      dataIndex: "produtoId",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.produtoId - b.produtoId,
    },
    {
      title: t("MAGENTO-CATALOG.CodesSKU"),
      dataIndex: "skus",
      render: (a) => a.join(" - "),
    },
    {
      title: t("MAGENTO-CATALOG.Product"),
      dataIndex: "imagemUrl",
      render: (dataIndex, row) => (
        <img src={dataIndex} style={{ width: "100px" }} alt={row.produtoNome} />
      ),
    },
    {
      title: t("MAGENTO-CATALOG.Name"),
      dataIndex: "produtoNome",
    },
    {
      title: t("MAGENTO-CATALOG.Sizes"),
      dataIndex: "tamanhos",
      render: (a) => a.join(" - "),
    },
    {
      title: t("MAGENTO-CATALOG.TotalStock"),
      dataIndex: "estoques",
      render: (a) =>
        a.join(" - ") +
        ` (${a.reduce((a, b) => parseInt(a) + parseInt(b), 0)})`,
    },
    {
      title: t("MAGENTO-CATALOG.OriginalPrice"),
      dataIndex: "precoOriginal",
      render: (dataIndex) => (
        <label style={{ whiteSpace: "nowrap" }}>
          {formatCash(dataIndex, false, unidadeMonetaria)}
        </label>
      ),
    },
    {
      title: t("MAGENTO-CATALOG.Price"),
      dataIndex: "preco",
      render: (dataIndex) => (
        <label style={{ whiteSpace: "nowrap" }}>
          {formatCash(dataIndex, false, unidadeMonetaria)}
        </label>
      ),
    },
  ];

  const getCatalog = useCallback(() => {
    managerService
      .getCatalogMagento(catalogInformation.lastItem)
      .then(({ catalog: data, moreItems, lastItem }) => {
        if (catalogInformation.moreItems)
          setCatalog((oldCatalog) => [...oldCatalog, ...data]);
        else setCatalog(data);
        setCatalogInformation({
          moreItems,
          lastItem,
        });
      })
      .catch(() =>
        message.error(t("PRODUCTS-TAB-CATALOG.CatalogErrorDescription"))
      )
      .finally(() => {
        setLoading(false);
        setLoadingNewProducts(false);
      });
  }, [catalogInformation]);

  useEffect(() => {
    getCatalog();
  }, []);

  useEffect(() => {
    setSelectedRows([]);
    if (!loading) {
      setLoading(true);
      setTimeout(() => setLoading(false), 100);
    }
  }, [open]);

  const rowSelection = {
    onChange: (_, rowsSelected) => setSelectedRows(rowsSelected),
    getCheckboxProps: (record) => ({
      disabled: products.map((p) => p.codigo).includes(record.skus[0]),
    }),
    selectedRows,
  };

  return (
    <Modal
      visible={open}
      okText={t("PRODUCTS-TAB-VTEX-CATALOG.Add")}
      okButtonProps={{ disabled: selectedRows.length === 0 }}
      onOk={() => onFinish(selectedRows)}
      cancelText={t("PRODUCTS-TAB-VTEX-CATALOG.back")}
      onCancel={() => onClose()}
      width={"100%"}
      bodyStyle={{ padding: 0 }}
      title={`${t("LIVE-TAB.Catalog")} MAGENTO`}
    >
      {catalog.length === 0 ? (
        <div style={{ textAlign: "center", margin: "50px" }}>
          {loading ? (
            <LoadingOutlined style={{ color: "gray", fontSize: "20px" }} />
          ) : (
            t("MAGENTO-CATALOG.NoProducts")
          )}
        </div>
      ) : loading ? (
        <div style={{ textAlign: "center", margin: "50px" }}>
          <LoadingOutlined style={{ color: "gray", fontSize: "20px" }} />
        </div>
      ) : (
        open && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Table
              loading={loading}
              rowSelection={{
                type: `checkbox`,
                ...rowSelection,
              }}
              style={{ width: "100%" }}
              columns={tableColumns}
              rowKey="produtoId"
              pagination={false}
              dataSource={catalog}
            />
            <Button
              onClick={() => {
                getCatalog();
                setLoadingNewProducts(true);
              }}
              type="default"
              className="styleBtn"
              style={{ margin: "20px auto" }}
              loading={loadingNewProducts}
              disabled={
                loadingNewProducts || loading || !catalogInformation.moreItems
              }
            >
              {catalogInformation.moreItems
                ? t("PRODUCTS-TAB-VTEX-CATALOG.LoadMore")
                : t("PRODUCTS-TAB-VTEX-CATALOG.NoItensToLoad")}
            </Button>
          </div>
        )
      )}
    </Modal>
  );
};

export default MagentoCatalog;
