import React, { useState } from "react";
import { Modal, Spin, Button, notification } from "antd";
import { useTranslation } from "react-i18next";
import { LoadingOutlined, FileTextOutlined } from "@ant-design/icons";
import { getFilenameFromUrl } from "../../../../utils";
import { CSVReader } from "react-papaparse";
import { managerService } from "../../../../services";

const ModalUploadCsv = ({
  liveId,
  open,
  setOpen,
  handleUploadedCsv,
  alreadyHasProducts,
  clearProducts,
}) => {
  const { t } = useTranslation();
  const [updatingPhotos, setUpdatingPhotos] = useState(false);
  const [deletingProducts, setDeletingProducts] = useState(false);
  const uploadRef = React.createRef();

  Spin.setDefaultIndicator(
    <LoadingOutlined style={{ fontSize: "40px", color: "gray" }} />
  );

  const handleOnFileLoad = (data) => {
    let dados = [...data];
    let tmpProducts = [];
    let tmpVariations = [];
    dados.shift();
    for (let i = 0; i < dados.length; i++) {
      let productData = dados[i].data;
      if (productData[1] === "s") {
        tmpProducts.push({
          codigo: productData[2],
          id: parseInt(productData[0]),
          imageName: getFilenameFromUrl(productData[4]),
          preco: productData[5],
          descricao: productData[3],
          tamanhos: productData[6].split(","),
          thumbnail: productData[4],
          tempoInicio: productData[7],
          tempoFim: productData[8],
          tipoGrade: "aberta",
          principal: true,
        });
      } else {
        let oldVariations = tmpVariations[parseInt(productData[0])]
          ? tmpVariations[parseInt(productData[0])]
          : [];
        tmpVariations[parseInt(productData[0])] = [
          ...oldVariations,
          {
            codigo: productData[2],
            id: parseInt(productData[0]),
            imageName: getFilenameFromUrl(productData[4]),
            preco: productData[5],
            descricao: productData[3],
            tamanhos: productData[6].split(","),
            thumbnail: productData[4],
            tempoInicio: productData[7],
            tempoFim: productData[8],
            tipoGrade: "aberta",
            principal: false,
          },
        ];
      }
    }

    setUpdatingPhotos(false);
    setOpen(false);
    handleUploadedCsv(tmpProducts, tmpVariations);
  };

  const handleDeleteAllProducts = () => {
    setDeletingProducts(true);
    managerService
      .deleteAllProducts(liveId)
      .then(() => {
        clearProducts();
        notification.success({
          key: "warning-delete-all-products",
          message: t("PRODUCT-TAB.DeleteAllProductsSucess"),
          onClick: () => notification.close("warning-delete-all-products"),
          duration: 10,
        });
      })
      .catch(() =>
        notification.error({
          key: "warning-delete-all-products",
          message: t("PRODUCT-TAB.DeleteAllProductsError"),
          onClick: () => notification.close("warning-delete-all-products"),
          duration: 10,
        })
      )
      .finally(() => setDeletingProducts(false));
  };

  return (
    <Modal
      visible={open}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "30px",
          }}
        >
          {t("PRODUCTS-TAB-UPLOAD-CSV-MODAL.UploadCSVFile")}
          <Button
            size="small"
            href={`/Modelo - Tabela de produtos.csv`}
            download
          >
            <FileTextOutlined />
            {t("PRODUCTS-TAB-UPLOAD-CSV-MODAL.DownloadModel")}
          </Button>
        </div>
      }
      okText={t("PRODUCTS-TAB-UPLOAD-CSV-MODAL.Conclude")}
      cancelText={t("PRODUCTS-TAB-UPLOAD-CSV-MODAL.Back")}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ disabled: deletingProducts }}
      onCancel={() => !deletingProducts && setOpen(false)}
      bodyStyle={{ padding: 0 }}
    >
      <div style={{ padding: "20px" }}>
        {alreadyHasProducts ? (
          <p>
            {t("PRODUCTS-TAB-UPLOAD-CSV-MODAL.OverrideProducts")}
            <strong> {t("PRODUCTS-TAB-UPLOAD-CSV-MODAL.Warning")} </strong>
          </p>
        ) : (
          t("PRODUCTS-TAB-UPLOAD-CSV-MODAL.Description")
        )}
        {alreadyHasProducts ? (
          <Button
            style={{ display: "grid", marginTop: "10px" }}
            type="primary"
            danger
            loading={deletingProducts}
            onClick={handleDeleteAllProducts}
          >
            {t("PRODUCT-TAB.DeleteAllProducts")}
          </Button>
        ) : (
          <Button
            onClick={(e) => uploadRef.current.open(e)}
            style={{ display: "grid", marginTop: "10px" }}
          >
            {t("PRODUCTS-TAB-UPLOAD-CSV-MODAL.Upload")}
          </Button>
        )}
        <div style={{ display: "none" }}>
          <CSVReader ref={uploadRef} onFileLoad={handleOnFileLoad}></CSVReader>
        </div>
        {updatingPhotos && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Spin style={{ margin: "10px auto" }} />
            <label>{t("PRODUCTS-TAB-UPLOAD-CSV-MODAL.UpdatingProducts")}</label>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalUploadCsv;
