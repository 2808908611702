import React, { createContext, useState, useContext } from "react";

const INITIAL_STATE = {
  tabActive: "chat",
  handleChangeTabActive: () => {},
  cartBadge: 0,
  setCartBadge: () => {},
  mainProductDocId: false,
  mainProductId: false,
};

const TabsContext = createContext(INITIAL_STATE);

const TabsProvider = ({ children }) => {
  const [tabActive, setActive] = useState(INITIAL_STATE.tabActive);
  const [previousTabActive, setPreviousTabActive] = useState(
    INITIAL_STATE.tabActive
  );
  const [cartBadge, setCartBadge] = useState(INITIAL_STATE.cartBadge);
  const [mainProductDocId, setMainProductDocId] = useState(
    INITIAL_STATE.mainProductDocId
  );
  const [mainProductId, setMainProductId] = useState(
    INITIAL_STATE.mainProductId
  );

  const tabValidDestinations = [
    "chat",
    "products",
    "orders",
    "cart",
    "variations",
  ];

  const handleChangeTabActive = (newDestination) => {
    if (tabValidDestinations.includes(newDestination)) {
      setPreviousTabActive(tabActive);
      setActive(newDestination);
    } else setActive(false);
  };

  return (
    <TabsContext.Provider
      value={{
        tabActive,
        handleChangeTabActive,
        cartBadge,
        setCartBadge,
        mainProductDocId,
        setMainProductDocId,
        mainProductId,
        setMainProductId,
        previousTabActive,
        setPreviousTabActive,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

const useTabsNavigation = () => useContext(TabsContext);

export { useTabsNavigation, TabsProvider };
