import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as s from "./styles-cart";
import { Input, Modal } from "antd";
import { UserContext } from "../../../context";

const ModalShipping = ({
  visible,
  onCancel,
  onOk,
  enderecoEntrega,
  style,
  maskStyle,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext); //dados do usuário
  const [zipcode, setZipcode] = useState(""); //código postal
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");

  useEffect(() => {
    if (enderecoEntrega) setAddressValues();
  }, [enderecoEntrega]);

  const setAddressValues = () => {
    let {
      zipcode: zipcodeEndEntrega,
      country: countryEndEntrega,
      state: stateEndEntrega,
      street: streetEndEntrega,
      streetNumber: streetNumberEndEntrega,
      city: cityEndEntrega,
      neighborhood: neighborhoodEndEntrega,
    } = enderecoEntrega;
    if (zipcodeEndEntrega) setZipcode(zipcodeEndEntrega);
    if (countryEndEntrega) setCountry(countryEndEntrega);
    if (stateEndEntrega) setState(stateEndEntrega);
    if (streetEndEntrega) setStreet(streetEndEntrega);
    if (streetNumberEndEntrega) setStreetNumber(streetNumberEndEntrega);
    if (cityEndEntrega) setCity(cityEndEntrega);
    if (neighborhoodEndEntrega) setNeighborhood(neighborhoodEndEntrega);
  };

  return (
    <Modal
      title={t("MODAL-SHIPPING.ShipAddress")}
      visible={visible}
      style={style}
      maskStyle={maskStyle}
      width={"80vw"}
      onOk={() =>
        onOk({
          zipcode,
          country: country.toUpperCase(),
          state: state.toUpperCase(),
          city,
          street,
          streetNumber,
          neighborhood,
          name: user.name,
        })
      }
      onCancel={() => {
        onCancel();
        setAddressValues();
      }}
      okText={t("MODAL-SHIPPING.Confirm")}
      okButtonProps={{
        disabled:
          !zipcode ||
          !country ||
          !state ||
          !city ||
          !street ||
          !streetNumber ||
          !neighborhood,
      }}
      cancelText={t("MODAL-SHIPPING.Back")}
    >
      <s.CreditCard className="fade-in">
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 15 }}>
            <label>{t("MODAL-SHIPPING.CEP")}</label>
            <Input
              type="text"
              name="postal-code"
              placeholder={t("MODAL-SHIPPING.PostalCode")}
              value={zipcode}
              maxLength={8}
              onChange={({ target: t }) => {
                if (
                  t.value.length < zipcode.length ||
                  (t.value && !isNaN(parseInt(t.value[t.value.length - 1])))
                )
                  setZipcode(t.value);
              }}
            />
          </div>
          <div style={{ marginLeft: "auto" }}>
            <label>{t("MODAL-SHIPPING.Country")}</label>
            <Input
              type="text"
              placeholder={t("MODAL-SHIPPING.CountryAcronym")}
              value={country}
              onChange={({ target: t }) => setCountry(t.value.toUpperCase())}
              maxLength={2}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ marginRight: window.innerWidth < 955 && 15 }}>
            <label>{t("MODAL-SHIPPING.State")}</label>
            <Input
              type="text"
              placeholder={t("MODAL-SHIPPING.StateAcronym")}
              value={state}
              onChange={({ target: t }) => setState(t.value)}
              maxLength={2}
            />
          </div>
          <div style={{ marginLeft: "auto" }}>
            <label>{t("MODAL-SHIPPING.City")}</label>
            <Input
              type="text"
              placeholder={t("MODAL-SHIPPING.YourCity")}
              value={city}
              onChange={({ target: t }) => setCity(t.value)}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ marginRight: window.innerWidth < 955 && 15 }}>
            <label>{t("MODAL-SHIPPING.Address")}</label>
            <Input
              type="text"
              placeholder={t("MODAL-SHIPPING.StreetName")}
              value={street}
              onChange={({ target: t }) => setStreet(t.value)}
            />
          </div>
          <div style={{ marginLeft: "auto" }}>
            <label>{t("MODAL-SHIPPING.Number")}</label>
            <Input
              type="text"
              placeholder={t("MODAL-SHIPPING.NumberLabel")}
              value={streetNumber}
              onChange={({ target: t }) => setStreetNumber(t.value)}
            />
          </div>
        </div>

        <label>{t("MODAL-SHIPPING.District")}</label>
        <Input
          type="text"
          placeholder={t("MODAL-SHIPPING.DistrictName")}
          value={neighborhood}
          onChange={({ target: t }) => setNeighborhood(t.value)}
        />
      </s.CreditCard>
    </Modal>
  );
};

export default ModalShipping;
