import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
const ModalPaymentPix = ({ visible, closeModal, qrCode }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("MODAL-PAYMENT.PixPaymentTitle")}
      visible={visible}
      onOk={closeModal}
      onCancel={closeModal}
      okText={t("PRODUCTS.GotIt")}
      cancelButtonProps={{
        style: { display: "none" },
      }}
    >
      <label>{t("MODAL-PAYMENT.PixPaymentDescription")}</label>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <img
          src={` https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrCode}`}
          alt="qr-code"
        />
        <br />
        <Button
          type="primary"
          onClick={() => {
            navigator.clipboard
              .writeText(qrCode)
              .then(() => message.success(t("PUBLISH-TAB.LinkCopied")));
          }}
        >
          <CopyOutlined style={{ marginRight: "12px" }} />
          <label>{qrCode?.substring(0, 10)}...</label>
        </Button>
      </div>
    </Modal>
  );
};

export default ModalPaymentPix;
