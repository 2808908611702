import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import {
  LiveTab,
  RoomTab,
  ProductsTab,
  PublishTab,
  RealtimeTab,
  OnlineUsersTab,
  EmailConfigTab,
} from "./tabs";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { paymentService, userService } from "../../services";
import ReportsTab from "./tabs/reports-tab/reports-tab";
import TransmitionTab from "./tabs/transmition-tab";
import { LivesContext, PaymentContext, UserContext } from "../../context";
import { Provider } from "react-redux";
import { store } from "./tabs/online-users-tab/redux";

const { TabPane } = Tabs;
const LiveStudio = ({ location: { search } }) => {
  const { t } = useTranslation();
  const { setUser } = useContext(UserContext);
  const { setServices } = useContext(PaymentContext);
  const { lives, setLives } = useContext(LivesContext);
  const [activeKey, setActiveKey] = useState("Live");
  const [live, setLive] = useState(false);
  const [realtime, setRealtime] = useState(false);
  const [aoVivo, setAoVivo] = useState(false);
  const [liveWithAssinatura, setLiveWithAssinatura] = useState(false);
  const [liveWithTabelaPreco, setLiveWithTabelaPreco] = useState(false);

  useEffect(() => {
    if (search) {
      let values = queryString.parse(search);
      if (values.l)
        lives.forEach((l) => {
          if (l.docId === values.l) {
            setLive(l);
            document.title = `${l.nome} | 4Show`;
          }
        });
    }
    return () => {
      document.title = "4Show";
      if (search)
        userService.verifyLogin().then(() => {
          userService.getUser().then((data) => {
            setUser(data.user);
            setLives(data.lives);
          });
        });
    };
  }, [lives, search]);

  useEffect(() => {
    if (live && live.realtime) setRealtime(live.realtime);
    if (live && live.aoVivo) setAoVivo(live.aoVivo);
    if (live && live.listaAssinatura) setLiveWithAssinatura(true);
    if (live && live.tabelaPreco) setLiveWithTabelaPreco(live.tabelaPreco);
  }, [live]);

  useEffect(() => {
    paymentService.getServices().then((d) => setServices(d));
  }, []);

  return (
    <Tabs
      centered
      size="large"
      activeKey={activeKey}
      onChange={(key) => setActiveKey(key)}
      className="fade-in"
      style={{ overflowX: "hidden" }}
    >
      <TabPane tab={"Live"} key={"Live"}>
        <LiveTab
          next={() => setActiveKey("Room")}
          live={live}
          setRealtime={setRealtime}
          setAoVivo={setAoVivo}
          setLive={setLive}
          setLiveWithAssinatura={setLiveWithAssinatura}
          setLiveWithTabelaPreco={setLiveWithTabelaPreco}
        />
      </TabPane>
      <TabPane
        tab={t("LIVE-STUDIO.RoomTab")}
        key={"Room"}
        disabled={!live.docId}
      >
        <RoomTab
          next={() => setActiveKey("Products")}
          live={live}
          aoVivo={aoVivo}
          setLive={setLive}
        />
      </TabPane>
      <TabPane
        tab={t("LIVE-STUDIO.ProductsTab")}
        key={"Products"}
        disabled={!live.docId || !live.salaId}
      >
        <ProductsTab
          next={() => setActiveKey("Publish")}
          realtime={realtime}
          live={live}
          aoVivo={aoVivo}
          liveWithAssinatura={liveWithAssinatura}
          liveWithTabelaPreco={liveWithTabelaPreco}
        />
      </TabPane>
      <TabPane
        tab={t("LIVE-STUDIO.PublishTab")}
        key={"Publish"}
        disabled={!live.docId || !live.salaId}
      >
        <PublishTab
          next={() =>
            setActiveKey(
              realtime ? "Real-time" : aoVivo ? "Transmition" : "Reports"
            )
          }
          live={live}
          setLive={setLive}
          aoVivo={aoVivo}
        />
      </TabPane>
      {live && live.docId && (
        <TabPane
          tab={t("LIVE-STUDIO.OnlineUsersTab")}
          key={"OnlineUsers"}
          disabled={!live.docId || !live.salaId}
        >
          <Provider store={store}>
            <OnlineUsersTab live={live} />
          </Provider>
        </TabPane>
      )}
      {realtime && (
        <TabPane
          tab={t("LIVE-STUDIO.RealtimeTab")}
          key={"Real-time"}
          disabled={!live.docId || !live.salaId}
        >
          <RealtimeTab
            next={() => setActiveKey(aoVivo ? "Transmition" : "Reports")}
            live={live}
          />
        </TabPane>
      )}
      {aoVivo && (
        <TabPane
          tab={t("LIVE-STUDIO.TransmissionTab")}
          key={"Transmition"}
          disabled={!live.docId || !live.salaId}
        >
          <TransmitionTab next={() => setActiveKey("Reports")} live={live} />
        </TabPane>
      )}
      {live && live.docId && (
        <TabPane
          tab={t("LIVE-STUDIO.ReportsTab")}
          key={"Reports"}
          disabled={!live.docId || !live.salaId}
        >
          <Provider store={store}>
            <ReportsTab next={() => setActiveKey("Live")} live={live} />
          </Provider>
        </TabPane>
      )}
      <TabPane
        tab={t("LIVE-STUDIO.EmailConfigTab")}
        key={"EmailConfig"}
        disabled={!live.docId || !live.salaId}
      >
        <EmailConfigTab next={() => setActiveKey("EmailConfig")} live={live} />
      </TabPane>
    </Tabs>
  );
};

export default withRouter(LiveStudio);
