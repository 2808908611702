import React, { useEffect, useState, useContext } from "react";
import { Button, Select, message, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { AddProductContext, GradesContext, UserContext } from "../../context";
import { productService, firestore, googleTagManager } from "../../services";
import { createLinkElementInsideText, formatCash } from "../../utils";
import * as s from "./styled-drawer-product";
import { ShoppingCartOutlined, CloseOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";

const DrawerProduct = ({
  live,
  products,
  openVariations,
  unidadeMonetaria,
}) => {
  let oldLayout = true;
  const { t } = useTranslation();
  const { Option } = Select;
  const { addProduct, setAddProduct, setCartProduct } =
    useContext(AddProductContext);
  const { user } = useContext(UserContext); //dados do usuário
  const { userGrades } = useContext(GradesContext); //grades do usuário
  const [measure, setMeasure] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState([]); //produtos favoritos do usuário
  const [adding, setAdding] = useState([]); //ids dos produtos que estão sendo adicionados ao carrinho
  const [loading, setLoading] = useState(false);
  const [grade, setGrade] = useState(false);
  const [gradeStock, setGradeStock] = useState(false);
  const avaliableQuantitiesFromStockSize =
    addProduct?.controlStock && measure
      ? addProduct.stock[addProduct.tamanhos.indexOf(measure)]
      : 99;

  useEffect(() => {
    if (addProduct) {
      let addedProduct = false;
      addProduct.tamanhos.map((tamanho, idx) => {
        if (!isMeasureOutOffStock(idx) && !addedProduct) {
          setMeasure(tamanho);
          addedProduct = true;
        }
      });
    }
  }, [addProduct]);

  useEffect(() => {
    setGrade(false);
    setGradeStock(false);
    if (live?.layoutCheckout === "padrao" && addProduct?.tamanhos?.length > 0) {
      let productAlreadyInCart = cart
        .map((p) => p.produto.docId)
        .includes(addProduct.docId);

      let newGrade = {};
      let cartOldGrade = {};
      let newGradeStock = {};
      if (productAlreadyInCart) {
        let editingProduct = cart.filter(
          (item) => item.produto.docId === addProduct.docId
        )[0];
        if (editingProduct?.produto?.grade) {
          editingProduct.produto.grade.map(
            ({ tamanho, quantidade }) => (cartOldGrade[tamanho] = quantidade)
          );
        }
      }
      addProduct.tamanhos.map((tamanho, index) => {
        newGrade[tamanho] =
          cartOldGrade[tamanho] !== undefined
            ? cartOldGrade[tamanho]
            : productAlreadyInCart
            ? 0
            : 1;
        newGradeStock[tamanho] = addProduct?.controlStock
          ? addProduct.stock[index]
          : 99;
      });
      setGrade(newGrade);
      setGradeStock(newGradeStock);
    }
  }, [addProduct]);

  useEffect(() => {
    if (user && (user.email || (user.hash && user.anonimo))) {
      let userCod = user.codigo || user.hash;
      let subscribe = firestore
        .collection("lives")
        .doc(live?.liveId)
        .collection("favoritos")
        .where("codigo", "==", userCod)
        .onSnapshot((snapshot) => {
          setCart(
            snapshot.docs
              .map((doc) => ({
                ...doc.data(),
                produto: {
                  ...doc.data().produto,
                  preco: parseFloat(doc.data().produto.preco),
                },
                docId: doc.id,
              }))
              .sort((a, b) => a.timeAddFavorito - b.timeAddFavorito)
          );
        });
      return () => subscribe();
    }
  }, [user]);

  useEffect(() => {
    if (
      addProduct?.controlStock &&
      quantity > avaliableQuantitiesFromStockSize
    ) {
      setQuantity(avaliableQuantitiesFromStockSize);
    }
  }, [measure]);

  useEffect(() => {
    if (!addProduct) {
      if (quantity) setQuantity(1);
      if (measure) setMeasure(false);
    }
  }, [addProduct]);

  const addProductCartOldMethod = (product) => {
    if (cart.map((p) => p.produto.docId).includes(product.docId)) {
      setLoading(true);
      setAdding([...adding, product.docId]);
      let editingProduct = cart.filter(
        (item) => item.produto.docId === product.docId
      )[0];
      editingProduct.produto.grade = Object.entries(grade).map(
        ([tamanho, quantidade]) => ({
          tamanho,
          quantidade,
        })
      );
      editingProduct.produto.preco = parseFloat(editingProduct.produto.preco);
      productService
        .editFavorite(live.liveId, editingProduct.docId, {
          produto: editingProduct.produto,
          customer: { ...user, codigo: user.codigo || user.hash },
        })
        .then(() =>
          googleTagManager.editProduct(editingProduct.produto, "drawer-product")
        )
        .finally(() => {
          setAdding((currentAdding) =>
            currentAdding.filter((id) => id !== product.docId)
          );
          setLoading(false);
          setAddProduct(false);
          setGrade(false);
          setGradeStock(false);
        });
    } else if (!adding.includes(product.docId)) {
      setLoading(true);
      if (adding.includes(product.docId))
        setAdding(adding.filter((id) => id !== product.docId));
      else setAdding([...adding, product.docId]);
      let newProduct = {
        ...product,
        preco: parseFloat((product.preco * 100).toFixed(2)),
        precoOriginal: parseFloat((product.precoOriginal * 100).toFixed(2)),
        grade: Object.entries(grade)
          .map(([tamanho, quantidade]) => ({
            tamanho,
            quantidade,
          }))
          .filter(({ quantidade }) => quantidade !== 0),
      };
      productService
        .addFavorite(live.liveId, {
          produto: newProduct,
          customer: { ...user, codigo: user.codigo || user.hash },
        })
        .then(() => {
          setCartProduct({
            measure,
            quantity,
            docId: product.docId,
          });
          googleTagManager.addProductToCart(newProduct, "drawer-product");
          message.success({ content: t("DRAWER.ProductAdded"), duration: 2 });
          setAdding((currentAdding) =>
            currentAdding.filter((id) => id !== product.docId)
          );
          setAddProduct(false);
          setGrade(false);
          setGradeStock(false);
        })
        .finally(() => setLoading(false));
    }
  };

  const addProductCart = (product) => {
    if (live?.layoutCheckout === "padrao") addProductCartOldMethod(product);
    else if (cart.map((p) => p.produto.docId).includes(product.docId)) {
      setLoading(true);
      setAdding([...adding, product.docId]);
      let editingProduct = cart.filter(
        (item) => item.produto.docId === product.docId
      )[0];
      if (
        editingProduct?.produto?.grade
          .map(({ tamanho }) => tamanho)
          .includes(measure)
      ) {
        editingProduct.produto.grade = editingProduct.produto.grade.map(
          ({ tamanho, quantidade }) => ({
            tamanho,
            quantidade: tamanho === measure ? quantity : quantidade,
          })
        );
      } else {
        editingProduct.produto.grade = [
          ...editingProduct.produto.grade,
          { tamanho: measure, quantidade: quantity },
        ];
      }
      editingProduct.produto.preco = parseFloat(editingProduct.produto.preco);
      editingProduct.produto.precoOriginal = parseFloat(
        editingProduct.produto.precoOriginal
      );
      productService
        .verifyQuantityProduct({
          liveId: live.liveId,
          codigo: user.codigo || user.anonimo,
          productId: editingProduct.produto.docId,
          quantity: editingProduct.produto.grade.reduce(
            (soma, g) => soma + g.quantidade,
            0
          ),
        })
        .then(() => {
          productService
            .editFavorite(live.liveId, editingProduct.docId, {
              produto: editingProduct.produto,
              customer: { ...user, codigo: user.codigo || user.hash },
            })
            .then(() =>
              googleTagManager.editProduct(
                editingProduct.produto,
                "drawer-product"
              )
            )
            .finally(() => {
              setAdding((currentAdding) =>
                currentAdding.filter((id) => id !== product.docId)
              );
              setLoading(false);
              setAddProduct(false);
              setMeasure(false);
              setQuantity(1);
            });
        })
        .catch((e) => {
          if (user.codigo) {
            message.error(
              e.message +
                " " +
                t("PRODUCTS-TAB-VARIATION-MODAL.Available") +
                ": " +
                e.quantityAvailable
            );
          } else {
            message.error(e.message);
          }

          setLoading(false);
        });
    } else if (!adding.includes(product.docId)) {
      setLoading(true);
      if (adding.includes(product.docId))
        setAdding(adding.filter((id) => id !== product.docId));
      else setAdding([...adding, product.docId]);
      let newProduct = {
        ...product,
        preco: parseFloat((product.preco * 100).toFixed(2)),
        precoOriginal: parseFloat((product.precoOriginal * 100).toFixed(2)),
        grade:
          product.tipoGrade === "aberta"
            ? [{ tamanho: measure, quantidade: quantity }]
            : userGrades.filter((g) => product.grades.includes(g.gradeId))[0]
                .grade || [],
        gradeId:
          product.tipoGrade === "aberta"
            ? null
            : userGrades.filter((g) => product.grades.includes(g.gradeId))[0]
                .gradeId || null,
      };

      productService
        .verifyQuantityProduct({
          liveId: live.liveId,
          codigo: user.codigo || user.anonimo,
          productId: newProduct.docId,
          quantity: newProduct.grade.reduce(
            (soma, g) => soma + g.quantidade,
            0
          ),
        })
        .then(() => {
          productService
            .addFavorite(live.liveId, {
              produto: newProduct,
              customer: { ...user, codigo: user.codigo || user.hash },
            })
            .then(() => {
              setCartProduct({
                measure,
                quantity,
                docId: product.docId,
              });
              googleTagManager.addProductToCart(newProduct, "drawer-product");
              message.success({
                content: t("DRAWER.ProductAdded"),
                duration: 2,
              });
              setAdding((currentAdding) =>
                currentAdding.filter((id) => id !== product.docId)
              );
              setAddProduct(false);
              setMeasure(false);
              setQuantity(1);
            })

            .finally(() => setLoading(false));
        })
        .catch((e) => {
          if (user.codigo) {
            message.error(
              e.message +
                " " +
                t("PRODUCTS-TAB-VARIATION-MODAL.Available") +
                ": " +
                e.quantityAvailable
            );
          } else {
            message.error(e.message);
          }
          setLoading(false);
          setAdding((currentAdding) =>
            currentAdding.filter((id) => id !== product.docId)
          );
        });
    }
  };

  const areVariationsDisabled = () => {
    if (products.length > 0 && addProduct) {
      if (live?.layoutCheckout === "padrao")
        return (
          products
            .filter((f) => f.id === addProduct.id)
            .filter((f) => !f.principal)
            .filter((f) => f.docId !== addProduct.docId)
            .filter((p) => !cart.map((f) => f.produto.docId).includes(p.docId))
            .length === 0
        );
      else {
        let isDisabled = false;
        if (
          (addProduct.available === false && !addProduct.esgotado) ||
          addProduct.esgotado ||
          !addProduct.principal
        )
          isDisabled = true;
        else {
          let productVariations = products.filter(
            (x) => x.id === addProduct.id && !x.principal
          );
          isDisabled = productVariations.length === 0 ? true : false;
        }

        return isDisabled;
      }
    }
  };

  const isMeasureOutOffStock = (idx) => {
    return addProduct.controlStock && addProduct.stock[idx] === 0;
  };

  const clearDescription = () => {
    if (document.getElementById("product-description")) {
      document.getElementById("product-description").innerHTML = "";
    }
  };

  const renderProductDescription = (id, description, isMobile) => {
    let idToSearch = isMobile ? `${id}-mobile` : id;
    let element = document.getElementById(idToSearch);
    if (description && element?.childNodes.length > 0) element.innerHTML = "";
    if (description && element?.childNodes.length === 0) {
      if (!description.includes("http:") && !description.includes("https:")) {
        element.innerHTML = description;
      } else {
        let allWords = description.trim().replace(/\n/g, " ").split(" "); // separa todas as palavras
        element.appendChild(
          createLinkElementInsideText(allWords, true, t("UTILS.ProductLink"))
        );
      }
    }
  };

  const renderDesktopContent = () => (
    <s.Container id="product-target">
      {(addProduct?.esgotado || addProduct?.available === false) && (
        <s.Status>
          {addProduct?.esgotado
            ? t("DRAWER.ProductSoldoff")
            : addProduct?.available === false
            ? t("DRAWER.ProductUnavailable")
            : ""}
        </s.Status>
      )}
      <s.Image src={addProduct?.thumbnail} />
      <div style={{ maxWidth: "100%" }}>
        <s.Description id={addProduct?.docId}>
          {renderProductDescription(addProduct?.docId, addProduct?.descricao)}
        </s.Description>
      </div>

      <s.PriceWrapper>
        <div
          style={{
            display: "flex",
            alignItem: "left",
            flexDirection: "column",
          }}
        >
          {!addProduct?.precoOriginal ||
          addProduct?.precoOriginal === addProduct?.preco ? (
            <>
              <label>
                {formatCash(addProduct?.preco, false, unidadeMonetaria)}
              </label>
            </>
          ) : (
            <>
              <label
                style={{
                  textDecoration: "line-through",
                  color: "#c4c4c4",
                  fontSize: "20px",
                  fontWeight: "normal",
                }}
              >
                {formatCash(addProduct?.precoOriginal, false, unidadeMonetaria)}
              </label>
              <label>
                {formatCash(addProduct?.preco, false, unidadeMonetaria)}
              </label>
            </>
          )}
        </div>
        <Button
          type="primary"
          onClick={() => openVariations(addProduct)}
          disabled={areVariationsDisabled()}
          size="large"
        >
          {areVariationsDisabled()
            ? t("DRAWER.NoVariations")
            : t("DRAWER.CheckVariations")}
        </Button>
      </s.PriceWrapper>
      <div className="line" />
      {addProduct?.tamanhos?.length > 0 ? (
        live?.layoutCheckout === "padrao" ? (
          <div style={{ width: "100%", marginBottom: "auto" }}>
            <label>{t("DRAWER.Sizes")}</label>
            <Scrollbars style={{ minHeight: 200 }}>
              {addProduct.tamanhos.map((tamanho) => (
                <div
                  key={tamanho + "-container-dektop"}
                  style={{
                    marginRight: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #e7e7e7",
                    padding: "10px 0",
                  }}
                >
                  <label>{tamanho}</label>
                  <Select
                    onChange={(value) =>
                      setGrade((grade) => ({ ...grade, [tamanho]: value }))
                    }
                    value={grade ? grade[tamanho] : 1}
                    style={{ width: 200 }}
                  >
                    {gradeStock &&
                      Array.from(Array(gradeStock[tamanho] + 1).keys()).map(
                        (qtd) => (
                          <Option
                            key={tamanho + "" + qtd + "tamanho-select-mobile"}
                            value={qtd}
                            title={qtd}
                          >
                            {qtd}
                          </Option>
                        )
                      )}
                  </Select>
                </div>
              ))}
            </Scrollbars>
          </div>
        ) : (
          <s.MeasuresWrapper>
            <div>
              <label>{t("DRAWER.Size")}</label>
              <Select
                disabled={
                  addProduct?.esgotado || addProduct?.available === false
                }
                value={measure || "Selecione"}
                onChange={(value) => setMeasure(value)}
                style={{ width: "100%" }}
                size="large"
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {addProduct?.tamanhos?.length > 0 &&
                  addProduct.tamanhos.map((tamanho, idx) => (
                    <Option
                      value={tamanho}
                      key={tamanho}
                      disabled={isMeasureOutOffStock(idx)}
                    >
                      {tamanho.replace(addProduct.descricao, "").length > 1
                        ? tamanho.replace(addProduct.descricao, "")
                        : tamanho}
                      {isMeasureOutOffStock(idx) && (
                        <label style={{ textTransform: "uppercase" }}>
                          {` (${t("DRAWER.ProductSoldoff")})`}
                        </label>
                      )}
                    </Option>
                  ))}
              </Select>
            </div>
            <div>
              <label>{t("DRAWER.Quantity")}</label>
              <Select
                defaultValue={1}
                onChange={(value) => setQuantity(parseInt(value))}
                value={quantity}
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentNode}
                size="large"
              >
                {Array.from(Array(avaliableQuantitiesFromStockSize).keys()).map(
                  (qtd) => (
                    <Option key={qtd + 1} value={qtd + 1} title={qtd + 1}>
                      {qtd + 1}
                    </Option>
                  )
                )}
              </Select>
            </div>
          </s.MeasuresWrapper>
        )
      ) : (
        <></>
      )}
      <s.Total>
        <label>{t("DRAWER.Total")}</label>
        <span>
          {live?.layoutCheckout === "padrao" && addProduct
            ? formatCash(
                Object.values(grade)
                  .map((qtd) => parseInt(qtd))
                  .reduce((a, b) => a + b, 0) * parseFloat(addProduct.preco),
                false,
                unidadeMonetaria
              )
            : formatCash(addProduct?.preco * quantity, false, unidadeMonetaria)}
        </span>
      </s.Total>
      <s.Footer>
        <Button
          style={s.PrimaryButtonStyle}
          type="primary"
          loading={loading}
          onClick={() => {
            clearDescription();
            addProductCart(addProduct);
          }}
          size="large"
          disabled={
            addProduct?.esgotado ||
            addProduct?.available === false ||
            !quantity ||
            !measure
          }
        >
          <ShoppingCartOutlined />

          {addProduct &&
          cart.map((p) => p.produto.docId).includes(addProduct.docId)
            ? t("DRAWER.AlterCart")
            : t("DRAWER.AddProduct")}
        </Button>
        <Button
          style={s.SecondaryButtonStyle}
          disabled={loading}
          size="large"
          onClick={() => {
            clearDescription();
            setAddProduct(false);
          }}
        >
          {t("DRAWER.Cancel")}
        </Button>
      </s.Footer>
    </s.Container>
  );

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  return (
    <Drawer
      placement={isMobile ? "bottom" : "right"}
      onClose={() => setAddProduct(false)}
      visible={addProduct}
      style={{ padding: 0 }}
      bodyStyle={{
        padding: isMobile ? 10 : 0,
        background: "rgba(248, 248, 248, 1)",
        position: "relative",
      }}
      height={isMobile ? (live?.layoutCheckout === "padrao" ? 320 : 280) : 700}
      maskStyle={{ background: isMobile ? "transparent" : "rgba(0,0,0,0.5)" }}
      headerStyle={{ display: "none" }}
      width={350}
      closable
      mask={isMobile}
    >
      <CloseOutlined
        style={{ position: "absolute", top: 0, right: 0, padding: 20 }}
        onClick={() => setAddProduct(false)}
      />
      <s.SwitchDevice>
        <div className="desktop-content">{renderDesktopContent()}</div>
        <div className="mobile-content">
          <div className="product-header">
            {(addProduct?.esgotado || addProduct?.available === false) && (
              <span style={{ textTransform: "uppercase" }} className="status">
                {addProduct?.esgotado
                  ? t("DRAWER.ProductSoldoff")
                  : addProduct?.available === false
                  ? t("DRAWER.ProductUnavailable")
                  : ""}
              </span>
            )}
            <img
              className="product-imagem"
              id="product-target"
              src={addProduct?.thumbnail}
              alt={addProduct?.descricao}
              style={{
                width: "150px",
                height: "104px",
                objectFit: "contain",
              }}
            />
            <section>
              <div
                style={{
                  maxWidth: "100%",
                }}
              >
                <s.Description id={`${addProduct?.docId}-mobile`}>
                  {renderProductDescription(
                    addProduct?.docId,
                    addProduct?.descricao,
                    true
                  )}
                </s.Description>
              </div>
              <div>
                <label>
                  {!addProduct?.precoOriginal ||
                  addProduct?.precoOriginal === addProduct?.preco ? (
                    <>
                      {formatCash(addProduct?.preco, false, unidadeMonetaria)}
                    </>
                  ) : (
                    <div>
                      <p
                        style={{
                          textDecoration: "line-through",
                          color: "#c4c4c4",
                          fontSize: "14px",
                          margin: "0",
                        }}
                      >
                        {formatCash(
                          addProduct?.precoOriginal,
                          false,
                          unidadeMonetaria
                        )}
                      </p>
                      {formatCash(addProduct?.preco, false, unidadeMonetaria)}
                    </div>
                  )}
                </label>
                <Button
                  type="primary"
                  onClick={() => openVariations(addProduct)}
                  disabled={areVariationsDisabled()}
                >
                  {areVariationsDisabled()
                    ? t("DRAWER.NoVariations")
                    : t("DRAWER.CheckVariations")}
                </Button>
              </div>
            </section>
          </div>
          {addProduct?.tamanhos?.length > 0 ? (
            live?.layoutCheckout === "padrao" ? (
              <div style={{ width: "100%", marginBottom: "auto" }}>
                <Scrollbars style={{ minHeight: 150 }}>
                  {addProduct.tamanhos.map((tamanho) => (
                    <div
                      key={tamanho + "-container"}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #e7e7e7",
                        padding: "10px 0",
                      }}
                    >
                      <label>{tamanho}</label>
                      <s.CustomSelectContainerOldMethod>
                        <select
                          id="native-select"
                          onChange={({ target: { value } }) =>
                            setGrade((grade) => ({
                              ...grade,
                              [tamanho]: parseInt(value),
                            }))
                          }
                          value={grade ? grade[tamanho] : 1}
                        >
                          <option value="Selecione" key={"Selecione"}>
                            {t("DRAWER.Select")}
                          </option>
                          {Array.from(Array(gradeStock[tamanho]).keys()).map(
                            (qtd) => (
                              <option
                                key={tamanho + "" + qtd + "" + 1}
                                value={qtd + 1}
                                title={qtd + 1}
                              >
                                {qtd + 1}
                              </option>
                            )
                          )}
                        </select>
                        <Select
                          onChange={(value) =>
                            setGrade((grade) => ({
                              ...grade,
                              [tamanho]: value,
                            }))
                          }
                          value={grade ? grade[tamanho] : 1}
                          style={{ width: 200 }}
                          dropdownRender={() => <div />}
                          dropdownStyle={{ display: "none" }}
                        />
                      </s.CustomSelectContainerOldMethod>
                    </div>
                  ))}
                </Scrollbars>
              </div>
            ) : (
              <section className="input-container">
                <div>
                  <label>{t("DRAWER.Size")}</label>
                  <div className="custom-select">
                    <select
                      id="native-select"
                      onChange={({ target: { value: v } }) => setMeasure(v)}
                      value={measure}
                    >
                      <option value="Selecione" key={"Selecione"}>
                        {t("DRAWER.Select")}
                      </option>
                      {addProduct?.tamanhos?.length > 0 &&
                        addProduct.tamanhos.map((tamanho, idx) => (
                          <option
                            value={tamanho}
                            key={tamanho + "-tamanho-select"}
                            disabled={isMeasureOutOffStock(idx)}
                          >
                            {tamanho.replace(addProduct.descricao, "").length >
                            1
                              ? tamanho.replace(addProduct.descricao, "")
                              : tamanho}
                            {isMeasureOutOffStock(idx) &&
                              ` (${t("DRAWER.ProductSoldoff")})`.toUpperCase()}
                          </option>
                        ))}
                    </select>
                    <Select
                      disabled={
                        addProduct?.esgotado || addProduct?.available === false
                      }
                      value={measure || "Selecione"}
                      style={{ width: "100%" }}
                      size="large"
                      dropdownRender={() => <div />}
                      dropdownStyle={{ display: "none" }}
                    />
                  </div>
                </div>
                <div>
                  <label>{t("DRAWER.Quantity")}</label>
                  <div className="custom-select">
                    <select
                      id="native-select"
                      onChange={({ target: { value: v } }) => {
                        setQuantity(parseInt(v));
                      }}
                      value={quantity}
                    >
                      {Array.from(
                        Array(avaliableQuantitiesFromStockSize).keys()
                      ).map((qtd) => (
                        <option
                          key={qtd + "" + 1 + "-quantidade-select"}
                          value={qtd + 1}
                          title={qtd + 1}
                        >
                          {qtd + 1}
                        </option>
                      ))}
                    </select>
                    <Select
                      defaultValue={1}
                      onChange={(value) => {
                        setQuantity(parseInt(value));
                      }}
                      value={quantity}
                      style={{ width: "100%" }}
                      size="large"
                      dropdownRender={() => <div />}
                      dropdownStyle={{ display: "none" }}
                    />
                  </div>
                </div>
              </section>
            )
          ) : (
            <></>
          )}
          <div className="footer">
            <div className="price">
              <label>{t("DRAWER.Total")}</label>
              <span>
                {live?.layoutCheckout === "padrao" && addProduct
                  ? formatCash(
                      Object.values(grade)
                        .map((qtd) => parseInt(qtd))
                        .reduce((a, b) => a + b, 0) *
                        parseFloat(addProduct.preco),
                      false,
                      unidadeMonetaria
                    )
                  : formatCash(
                      addProduct?.preco * quantity,
                      false,
                      unidadeMonetaria
                    )}
              </span>
            </div>
            <Button
              type="primary"
              size="large"
              loading={loading}
              onClick={() => {
                clearDescription();
                addProductCart(addProduct);
              }}
              disabled={
                addProduct?.esgotado ||
                addProduct?.available === false ||
                !quantity ||
                !measure ||
                measure === t("DRAWER.Select")
              }
            >
              <ShoppingCartOutlined />
              {addProduct &&
              cart.map((p) => p.produto.docId).includes(addProduct.docId)
                ? t("DRAWER.AlterCart")
                : t("DRAWER.Add")}
            </Button>
          </div>
        </div>
      </s.SwitchDevice>
    </Drawer>
  );
};

export default DrawerProduct;
