import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { managerService } from "../../services";
import Dashboard from "./components/dashboard/dashboard";

const DashboardLandingPage = ({history}) => {

    const [landingPage, setLandingPage] = useState();

    const getAllLeads = () => {
      
    }

    useEffect(() => {
      return managerService
        .getAllLeads()
        .then((data) => {
            const lpActive = data.find(({active}) => 
              active === true
            )
            setLandingPage(lpActive);
            if(!lpActive) history.push("/landing-page");
          }
        )
      }, []);

  return (
    <>
      <Dashboard landingPage={landingPage} getAllLeads={getAllLeads()} />
    </>
  );
};

export default DashboardLandingPage;
