import React from "react";
import { useTranslation } from "react-i18next";
import * as s from "./styles";

const OrientationContainer = ({ orientation, handleOrientation, ...props }) => {
  const { t } = useTranslation();
  return (
    <s.MainContainer orientation={orientation}>
      <div
        className="orientationOption horizontal"
        onClick={() => handleOrientation("horizontal")}
      >
        <div className="orientationOptionLabel">
          {t("LIVE-TAB-ORIENTATION.Horizontal")}
        </div>
        <div className="orientationOptionRadio" />
        <img
          className="orientationOptionThumb"
          src={"/assets/images/horizontal.png"}
        />
      </div>
      <div
        className="orientationOption vertical"
        onClick={() => handleOrientation("vertical")}
      >
        <div className="orientationOptionLabel">
          {t("LIVE-TAB-ORIENTATION.Vertical")}
        </div>
        <div className="orientationOptionRadio" />
        <img
          className="orientationOptionThumb"
          src={"/assets/images/vertical.png"}
        />
      </div>
    </s.MainContainer>
  );
};

export default OrientationContainer;
