import { useState } from "react";

const useAddProduct = () => {
  const [addProduct, setAddProduct] = useState(null);
  const [cartProduct, setCartProduct] = useState(null);

  return { addProduct, setAddProduct, cartProduct, setCartProduct };
};

export default useAddProduct;
