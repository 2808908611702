import styled from "styled-components";

export const imgStyleLogo = {
  width: "85px",
  height: "85px",
  background: "white",
  borderRadius: "50%",
};

export const InvalidLabel = styled.label`
  color: red;
  margin-bottom: 5px;
`;

export const imgStyle = {
  width: "440px",
  height: "348px",
  background: "white",
};

export const imgStyle1600 = {
  width: "420px",
  height: "340px",
  background: "white",
};

export const imgStyle1000 = {
  width: "304px",
  height: "206px",
  background: "white",
};

export const ImageContent = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  max-width: 615px;
  max-height: 400px;
  background: white;
  padding: 2rem;
`;

export const Block = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;

  @media (max-width: 2000px) {
    width: 80%;
  }

  @media (max-width: 1400px) {
    width: 100%;
  }

  @media (max-width: 730px) {
    grid-template-columns: 1fr;
  }
`;

export const ContentButton = styled.div`
  align-items: center;
  margin-left: 25px;
`;

export const ContentAddBrand = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 2rem;
  max-width: 450px;
`;

export const BlockInfos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 2000px) {
    width: 80%;
  }

  @media (max-width: 1400px) {
    width: 100%;
  }

  @media (max-width: 660px) {
    grid-template-columns: 1fr;
  }
`;

export const CadastroContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 615px;
  max-height: 400px;
  background: white;
  padding: 2rem;
`;

export const ImgsBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  @media (max-width: 2000px) {
    width: 80%;
  }

  @media (max-width: 1400px) {
    width: 100%;
  }

  @media (max-width: 660px) {
    grid-template-columns: 1fr;
  }
`;

export const IphoneContent = styled.div`
  background-image: url(/assets/images/phoneMockup.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 360px;
  height: 717.6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 30px 55px 30px;

  @media (max-width: 468px) {
    width: 260px;
    height: 617.6px;
  }
`;
export const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 615px;
  max-height: 400px;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  @media (max-width: 2000px) {
    width: 80%;
  }

  @media (max-width: 1400px) {
    width: 100%;
  }
`;
