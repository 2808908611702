import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { ThemeContext, useTabsNavigation } from "../../context";
import * as s from "./styles-tab-navigation";

const TabNavigation = ({ live }) => {
  const { corPrimaria, corSecundaria } = useContext(ThemeContext);
  const { tabActive, handleChangeTabActive, cartBadge } = useTabsNavigation();
  const { t } = useTranslation();
  const externalCheckout = live.checkout === "externo";
  const isKeyboardOpen = useDetectKeyboardOpen();

  var tabs = [
    {
      key: "chat",
      icon: "/assets/icons/chat.svg",
      label: t("ROOM-TAB.Chat"),
    },
    {
      key: "products",
      icon: "/assets/icons/products-white.svg",
      label: t("MODAL-SPLIT.Products"),
    },
    {
      key: "orders",
      icon: "/assets/icons/order-white.svg",
      label: t("REPORTS-TAB.Orders"),
    },
    {
      key: "cart",
      icon: "/assets/icons/cart-white.svg",
      label: t("LIVE.Cart"),
      badge: cartBadge,
    },
  ];

  if (window.innerWidth < 955 && !isKeyboardOpen)
    return (
      <s.TabsContainer
        bg={corPrimaria}
        badgeColor={corSecundaria}
        id="tab-navigation-horizontal"
      >
        {tabs.map(({ key, icon, label, badge = 0 }) => {
          if (externalCheckout && (key === "orders" || key === "cart")) return "";
          return (
            <button
              className={`animated-linear ${tabActive === key && "tab-active"}`}
              onClick={() => handleChangeTabActive(key)}
              key={key}
            >
              {badge >= 1 && (
                <span style={{ width: badge > 99 ? 25 : badge > 9 ? 20 : 15 }}>
                  {badge}
                </span>
              )}
              <img src={icon} alt={label} />
              {label}
            </button>
          )
        }
        )}
      </s.TabsContainer>
    );
  return <></>;
};

export default TabNavigation;
