import React, { useEffect, useState } from "react";
import {
  Modal,
  Table,
  Menu,
  Dropdown,
  message,
  Button,
  Input,
  Radio,
} from "antd";
import { useTranslation } from "react-i18next";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { managerService, neomodeService } from "../../../../services";
import { formatCash } from "../../../../utils";
import * as s from "./styled-products-tab";

const { SubMenu, Item } = Menu;
const NeomodeCatalog = ({
  open = false,
  products = [],
  onFinish = () => {},
  onClose = () => {},
  unidadeMonetaria,
}) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const [catalogSearch, setCatalogSearch] = useState([]);
  const [catalogInformation, setCatalogInformation] = useState({
    moreItems: false,
    page: 1,
  });
  const [catalogSearchInformation, setCatalogSearchInformation] = useState({
    moreItems: false,
    page: 1,
  });
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingNewProducts, setLoadingNewProducts] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const itemsPerPage = 20;
  const tableColumns = [
    {
      title: "ID",
      dataIndex: "produtoId",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.produtoId - b.produtoId,
    },
    {
      title: "SKU's",
      dataIndex: "skus",
      render: (a) => a.join(" - "),
    },
    {
      title: "Produto",
      dataIndex: "imagemUrl",
      render: (dataIndex, row) => (
        <img src={dataIndex} style={{ width: "100px" }} alt={row.produtoNome} />
      ),
    },
    {
      title: "Nome",
      dataIndex: "produtoNome",
    },
    {
      title: "Tamanhos",
      dataIndex: "tamanhos",
      render: (a) => a.join(" - "),
    },
    {
      title: "Estoques (Total)",
      dataIndex: "estoques",
      render: (a) =>
        a.join(" - ") +
        ` (${a.reduce((a, b) => parseInt(a) + parseInt(b), 0)})`,
    },
    {
      title: "Preço Original",
      dataIndex: "precoOriginal",
      render: (dataIndex) => (
        <label style={{ whiteSpace: "nowrap" }}>
          {formatCash(dataIndex, false, unidadeMonetaria)}
        </label>
      ),
    },
    {
      title: "Preço",
      dataIndex: "preco",
      render: (dataIndex) => (
        <label style={{ whiteSpace: "nowrap" }}>
          {formatCash(dataIndex, false, unidadeMonetaria)}
        </label>
      ),
    },
  ];

  useEffect(() => {
    neomodeService
      .getCategoriesNeomode()
      .then((data) => setCategories(data))
      .catch(() =>
        message.error(t("PRODUCTS-TAB-CATALOG.CategoriesErrorDescription"))
      );
  }, []);

  useEffect(() => {
    setSelectedRows([]);
    if (!loading) {
      setLoading(true);
      setTimeout(() => setLoading(false), 100);
    }
  }, [open]);

  useEffect(() => {
    if (!loadingSearch) getProducts();
  }, [selectedCategory]);

  // @todo testar esse get cheio de frescura
  const getProducts = (newProducts) => {
    let isSearch = catalogSearch?.length;
    let information = isSearch ? catalogSearchInformation : catalogInformation;
    if (newProducts) setLoadingNewProducts(true);
    else setLoading(true);
    neomodeService
      .getCatalogNeomode(
        selectedCategory?.id,
        newProducts && information?.page
          ? information.page + 1
          : information?.page,
        itemsPerPage
      )
      .then((resp) => {
        if (isSearch) {
          if (newProducts)
            setCatalogSearch((oldValue) => [...oldValue, ...resp.catalog]);
          else setCatalogSearch(resp.catalog);
          setCatalogSearchInformation((oldValues) => ({
            page:
              newProducts && oldValues?.page
                ? information.page + 1
                : oldValues?.page,
            moreItems: resp.moreItems,
          }));
        } else {
          if (newProducts)
            setCatalog((oldValue) => [...oldValue, ...resp.catalog]);
          else setCatalog(resp.catalog);
          setCatalogInformation((oldValues) => ({
            page:
              newProducts && oldValues?.page
                ? information.page + 1
                : oldValues?.page,
            moreItems: resp.moreItems,
          }));
        }
      })
      .catch(() =>
        message.error(t("PRODUCTS-TAB-CATALOG.CatalogErrorDescription"))
      )
      .finally(() => {
        if (newProducts) setLoadingNewProducts(false);
        else setLoading(false);
      });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, rowsSelected) => setSelectedRows(rowsSelected),
    getCheckboxProps: (record) => ({
      disabled: products.map((p) => p.codigo).includes(record.skus[0]),
    }),
    selectedRows,
  };

  const renderCategories = ({ name, children, id }) => {
    let onClick = () => {
      setCatalogInformation((oldValue) => ({ ...oldValue, page: 1 }));
      setSelectedCategory({ name, id });
    };
    if (children && children.length > 0) {
      return (
        <SubMenu
          title={name}
          key={id}
          popupClassName={children.length > 8 && "Submenu-class"}
        >
          <Item key={id} onClick={onClick}>
            {t("PRODUCTS-TAB-CATALOG.All")}
          </Item>
          <Scrollbars
            style={{ height: children.length * 32, width: 200, maxHeight: 380 }}
            renderThumbHorizontal={() => <div style={{ display: "none" }} />}
          >
            {children.map((subCategories) => renderCategories(subCategories))}
          </Scrollbars>
        </SubMenu>
      );
    } else {
      return (
        <Item key={id} onClick={onClick}>
          {name}
        </Item>
      );
    }
  };

  const filterCatalogBySearch = (text) => {
    if (text) {
      setLoadingSearch(true);
      setSelectedCategory();
      neomodeService
        .getCatalogNeomode(false, 1, itemsPerPage, text)
        .then((resp) => {
          if (resp.catalog?.length) {
            setCatalogSearch(resp.catalog);
            setCatalogSearchInformation({
              page: 1,
              moreItems: resp.moreItems,
            });
          } else message.info(t("PRODUCTS-TAB-CATALOG.NoItensFound"));
        })
        .catch(() =>
          message.error(t("PRODUCTS-TAB-VTEX-CATALOG.SearchCodeError"))
        )
        .finally(() => setLoadingSearch(false));
    } else setCatalogSearch([]);
  };

  const resetAll = () => {
    setSearchText("");
    setCatalogSearchInformation({ moreItems: false, page: 1 });
    setCatalogSearch(true);
  };

  return (
    <Modal
      visible={open}
      okText={t("PRODUCTS-TAB-VTEX-CATALOG.Add")}
      okButtonProps={{ disabled: selectedRows.length === 0 }}
      onOk={() => onFinish(selectedRows)}
      cancelText={t("PRODUCTS-TAB-VTEX-CATALOG.back")}
      onCancel={() => onClose()}
      width={"100%"}
      bodyStyle={{ padding: 0 }}
      title={
        <s.OverlayContainer>
          {t("NEOMODE-CATALOG.CatalogTitle")}
          <div style={{ display: "flex" }}>
            <Dropdown
              size="small"
              overlay={() => (
                <Menu className="Menu-Class">
                  <Item onClick={() => setSelectedCategory(false)}>
                    {t("PRODUCTS-TAB-CATALOG.All")}
                  </Item>
                  {categories.map((category) => renderCategories(category))}
                </Menu>
              )}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  cursor: "pointer",
                }}
              >
                {selectedCategory?.name
                  ? selectedCategory.name
                  : t("PRODUCTS-TAB-CATALOG.Categories")}{" "}
                <DownOutlined />
              </label>
            </Dropdown>
          </div>
        </s.OverlayContainer>
      }
    >
      {(catalogSearch?.length > 0 ? catalogSearch : catalog).length === 0 ? (
        <div style={{ textAlign: "center", margin: "50px" }}>
          {loading ? (
            <LoadingOutlined style={{ color: "gray", fontSize: "20px" }} />
          ) : selectedCategory ? (
            t("PRODUCTS-TAB-CATALOG.ProductCategorieNotFound")
          ) : (
            t("NEOMODE-CATALOG.NoProducts")
          )}
        </div>
      ) : loading ? (
        <div style={{ textAlign: "center", margin: "50px" }}>
          <LoadingOutlined style={{ color: "gray", fontSize: "20px" }} />
        </div>
      ) : (
        open && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <s.SearchContainer>
              <label>
                {t("PRODUCTS-TAB-CATALOG.SearchFor") + " "}
                {t("PRODUCTS-TAB-VTEX-CATALOG.Word").toLowerCase()}
              </label>
              <Input.Search
                placeholder={`${t("PRODUCTS-TAB-VTEX-CATALOG.TypeTheWord")} ${t(
                  "PRODUCTS-TAB-VTEX-CATALOG.OfTheProduct"
                )}`}
                onSearch={(v) => filterCatalogBySearch(v)}
                loading={loadingSearch}
                disabled={loading || loadingSearch}
                style={{ width: "350px", margin: "0 20px 0 0px" }}
                value={searchText}
                onChange={({ target: { value: v } }) =>
                  v?.trim()?.length > 0 ? setSearchText(v.trim()) : resetAll()
                }
              />
            </s.SearchContainer>
            <Table
              loading={loading}
              rowSelection={{
                type: `checkbox`,
                ...rowSelection,
              }}
              style={{ width: "100%" }}
              columns={tableColumns}
              rowKey="produtoId"
              pagination={false}
              dataSource={catalogSearch?.length ? catalogSearch : catalog}
            />

            <Button
              onClick={() => getProducts(true)}
              type="default"
              className="styleBtn"
              style={{ margin: "20px auto" }}
              loading={loadingNewProducts}
              disabled={
                loadingNewProducts ||
                loadingSearch ||
                loading ||
                !(catalogSearch?.length
                  ? catalogSearchInformation.moreItems
                  : catalogInformation.moreItems)
              }
            >
              {(
                catalogSearch?.length
                  ? catalogSearchInformation.moreItems
                  : catalogInformation.moreItems
              )
                ? t("PRODUCTS-TAB-VTEX-CATALOG.LoadMore")
                : t("PRODUCTS-TAB-VTEX-CATALOG.NoItensToLoad")}
            </Button>
          </div>
        )
      )}
    </Modal>
  );
};

export default NeomodeCatalog;
