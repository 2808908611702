import React, { useContext, useState } from "react";
import { Button, message, Input, Modal } from "antd";

import { useTranslation } from "react-i18next";
import { useNeomode, UserContext } from "../../context";
import { neomodeService } from "../../services";
import { formatCash } from "../../utils";

const NeomodeResume = ({
  visible,
  liveId,
  closeModal,
  goBackStep,
  cartTotal,
  observacao,
}) => {
  const { t } = useTranslation();
  const { user: user4Show } = useContext(UserContext);
  const {
    shippingSelected,
    paymentMethodSelected,
    installment,
    addressSelected,
    setInstallment,
  } = useNeomode();

  const [stepResume, setStepResume] = useState("resume"); //resume | verify-cvv
  const [finishing, setFinishing] = useState(false);
  const [cvv, setCvv] = useState("");
  const [enableCoupon, setEnableCoupon] = useState(false); //botão
  const [labelCoupon, setLabelCoupon] = useState("");
  const [loadingCupom, setLoadingCupom] = useState(false);
  const [voucherAdd, setVoucherAdd] = useState(false);

  const { label, fullAddress } = addressSelected;
  const { amount, deliveryTime, name } = shippingSelected;
  const { type, info } = paymentMethodSelected;
  const {
    installments,
    amount: amountInstallment,
    percentTaxRate,
    totalAmount,
  } = installment;

  const handleFinish = () => {
    setFinishing(true);
    neomodeService
      .finishOrder(
        liveId,
        user4Show.codigo,
        paymentMethodSelected.id,
        installments,
        cvv,
        observacao
      )
      .then(() =>
        Modal.success({
          content: t("NEOMODE.OrderFinishedSuccess"),
          afterClose: () => closeModal(),
        })
      )
      .catch(() =>
        Modal.error({
          content: t("NEOMODE.OrderFinishError"),
        })
      )
      .finally(() => setFinishing(false));
  };

  const renderModalVerifyCvv = () => (
    <Modal
      visible={stepResume === "verify-cvv"}
      onCancel={() => !finishing && setStepResume("resume")}
      title="CVV"
      okText={finishing ? t("NEOMODE.Finishing") : t("NEOMODE.FinishShop")}
      cancelText="Voltar"
      okButtonProps={{ loading: finishing, disabled: !cvv }}
      cancelButtonProps={{ style: { display: finishing && "none" } }}
      onOk={handleFinish}
    >
      <label>{t("NEOMODE.CVVCode")}</label>
      <Input
        type="number"
        placeholder={"CVV"}
        value={cvv}
        onChange={({ target: t }) => t.value.length <= 4 && setCvv(t.value)}
        disabled={finishing}
      />
    </Modal>
  );

  const handleEnableCoupon = () => {
    setEnableCoupon(!enableCoupon);
  };

  function handleVoucher(removeVoucher) {
    setLoadingCupom(true);
    neomodeService
      .changeVoucher(
        liveId,
        user4Show.codigo,

        removeVoucher ? false : labelCoupon,
        paymentMethodSelected.id,
        installments
      )
      .then((data) => {
        setInstallment((oldValues) => ({
          ...oldValues,
          amount: data.amountInstallment,
          totalAmount: data.totalAmount,
        }));
        if (removeVoucher) {
          setLabelCoupon("");
          setEnableCoupon(false);
          setVoucherAdd(false);
          message.success(t("NEOMODE.CouponRemoved"));
        } else {
          setVoucherAdd(true);
          message.success(t("NEOMODE.SuccessCoupon"));
        }
      })
      .catch(() => {
        message.error(t("NEOMODE.InvalidCoupon"));
      })
      .finally(() => setLoadingCupom(false));
  }

  if (!visible) return <></>;
  else
    return (
      <div>
        {renderModalVerifyCvv()}
        <label style={{ fontSize: "12px", color: "gray" }}>
          {t("NEOMODE.Shipping")}
        </label>
        <br />
        {amount > 0 && `${formatCash(amount)} - `}
        {name}
        {deliveryTime &&
          ` ${t("NEOMODE.ShippingIn")} ${deliveryTime} ${t("NEOMODE.Days")}`}
        <br />
        {label && fullAddress && (
          <>
            <label style={{ fontSize: "12px", color: "gray" }}>
              {t("NEOMODE.Address")}: {label}
            </label>
            <br />
            {fullAddress}
            <br />
          </>
        )}
        <label style={{ fontSize: "12px", color: "gray" }}>
          {t("NEOMODE.Payment")}
        </label>

        <br />
        {type === 1 ? (
          <>
            {info}
            <br />
            {installments} x {formatCash(amountInstallment)} ={" "}
            {formatCash(totalAmount)}
          </>
        ) : (
          <>
            {cartTotal}
            {amount > 0 && ` + ${formatCash(amount)}`} - {info}
          </>
        )}
        <br />

        <div
          style={{
            display: "flex",
            maxWidth: "300px",
            marginTop: "16px",
          }}
        >
          {!enableCoupon && !labelCoupon && (
            <Button
              style={{
                "border-width": "0px 0px 1px 0px",
                borderColor: "gray",
                background: "none",
                outline: "none",
                color: "gray",
              }}
              disabled={loadingCupom}
              onClick={handleEnableCoupon}
            >
              {t("NEOMODE.Coupon")}
            </Button>
          )}

          {enableCoupon && (
            <>
              <Input
                type="text"
                placeholder={t("NEOMODE.Coupon")}
                size="middle"
                disabled={loadingCupom}
                value={labelCoupon}
                onChange={(e) => setLabelCoupon(e.target.value)}
              />
              <Button
                type="primary"
                style={{ marginRight: 2, marginLeft: 2 }}
                loading={loadingCupom}
                disabled={!labelCoupon?.length}
                onClick={() => handleVoucher()}
              >
                {t("NEOMODE.Apply")}
              </Button>

              {!loadingCupom && voucherAdd && (
                <Button onClick={() => handleVoucher(true)}>
                  {t("NEOMODE.Remove")}
                </Button>
              )}
            </>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 24,
          }}
        >
          {!finishing && (
            <Button
              onClick={goBackStep}
              disabled={loadingCupom}
              style={{ marginRight: 24 }}
            >
              {t("NEOMODE.Back")}
            </Button>
          )}
          <Button
            loading={finishing}
            disabled={loadingCupom}
            onClick={() =>
              type === 1 ? setStepResume("verify-cvv") : handleFinish()
            }
          >
            {finishing ? t("NEOMODE.Finishing") : t("NEOMODE.Finish")}
          </Button>
        </div>
      </div>
    );
};

export default NeomodeResume;
