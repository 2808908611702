import React, { useCallback, useEffect, useState } from "react";
import { Button, Switch, Card, Modal, Input } from "antd";
import { useTranslation } from "react-i18next";
import { SolutionOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import AudienceModal from "./audience-modal";
import { managerService } from "../../../../../services";

const AudienceCard = ({
  liveId,
  listaAudiencia,
  setListaAudiencia,
  tabelaPreco,
  setTabelaPreco = () => {},
  awinAdvertiserId,
  setAwinAdvertiserId = () => {},
}) => {
  const { t } = useTranslation();
  const [awinId, setAwinId] = useState(false);
  const [audienceList, setAudienceList] = useState(false);
  const [priceTable, setPriceTable] = useState(false);
  const [audienceModalIsOpen, setAudienceModalIsOpen] = useState(false);
  const handleGetAudienceList = useCallback(
    (id) =>
      managerService.getAudienceList(id).then((list) => setAudienceList(list)),
    [setAudienceList]
  );
  const [priceTableWarning, setPriceTableWarning] = useState(false);

  useEffect(() => {
    if (liveId && listaAudiencia && listaAudiencia === true)
      handleGetAudienceList(liveId);
  }, [liveId, listaAudiencia]);

  useEffect(() => {
    if (audienceList !== listaAudiencia) setListaAudiencia(audienceList);
  }, [audienceList]);

  useEffect(() => setTabelaPreco(priceTable), [priceTable]);

  useEffect(() => setAwinAdvertiserId(awinId), [awinId]);

  useEffect(() => {
    if (awinAdvertiserId && awinId === false) setAwinId(awinAdvertiserId);
  }, [awinAdvertiserId]);

  useEffect(() => setPriceTable(tabelaPreco), [tabelaPreco]);

  const openWarningPriceTable = () => {
    if (!priceTableWarning && liveId) {
      Modal.warning({
        title: t("LIVE-TAB-AUDIENCE-CARD.Warning"),
        content: <>{t("LIVE-TAB-AUDIENCE-CARD.WarningContent")}</>,
      });
      setPriceTableWarning(true);
    }
  };

  return (
    <Card
      title={
        <>
          <SolutionOutlined style={{ marginRight: "10px" }} />
          {t("LIVE-TAB-AUDIENCE-CARD.ProductsAudience")}
        </>
      }
      style={{ margin: "5px", width: "340px" }}
      className="shadow"
    >
      <label>{t("LIVE-TAB-AUDIENCE-CARD.AwinTracking")}</label>
      <legend style={{ fontSize: "12px", color: "gray" }}>
        {t("LIVE-TAB-AUDIENCE-CARD.AwinTrackingDescriptionOne")}{" "}
        <strong>{t("LIVE-TAB-AUDIENCE-CARD.AdvertiserID")}</strong>{" "}
        {t("LIVE-TAB-AUDIENCE-CARD.AwinTrackingDescriptionTwo")}
      </legend>
      <div>
        <Switch
          checked={awinId}
          onChange={() => setAwinId(awinId ? false : true)}
          style={{ width: 30 }}
        />
        {awinId && (
          <Input
            placeholder={t("LIVE-TAB-AUDIENCE-CARD.AdvertiserID")}
            value={awinId === true ? "" : awinId}
            onChange={({ target: { value: v } }) => setAwinId(v)}
            style={{ margin: "10px 0" }}
          />
        )}
      </div>
      <label>{t("LIVE-TAB-AUDIENCE-CARD.AudienceList")}</label>
      <legend style={{ fontSize: "12px", color: "gray" }}>
        {t("LIVE-TAB-AUDIENCE-CARD.AudienceListDescription")}
      </legend>
      <div style={{ display: "flex", alignItems: "center", height: "30px" }}>
        <Switch
          checked={audienceList ? true : false}
          onChange={() => setAudienceList(audienceList ? false : [])}
          style={{ width: 30 }}
        />
        {audienceList && (
          <>
            <Button
              size="small"
              style={{ marginLeft: "10px" }}
              onClick={() => setAudienceModalIsOpen(true)}
            >
              <UsergroupAddOutlined />
              {t("LIVE-TAB-AUDIENCE-CARD.AudienceList")}
            </Button>
            <AudienceModal
              visible={audienceModalIsOpen}
              liveId={liveId}
              onOk={(newList) => {
                setAudienceList(newList);
                setAudienceModalIsOpen(false);
              }}
              audienceList={audienceList}
              onCancel={() => setAudienceModalIsOpen(false)}
            />
          </>
        )}
      </div>
      {audienceList && (
        <>
          <div style={{ marginTop: "15px" }}>
            {t("LIVE-TAB-AUDIENCE-CARD.PriceTable")}
          </div>
          <legend style={{ fontSize: "12px", color: "gray" }}>
            {t("LIVE-TAB-AUDIENCE-CARD.PriceTableDescription")}
          </legend>
          <Switch
            checked={priceTable}
            onChange={() => {
              openWarningPriceTable();
              setPriceTable(!priceTable);
            }}
            style={{ width: 30 }}
          />
        </>
      )}
    </Card>
  );
};

export default AudienceCard;
