import React, { useState, useContext, useEffect, useCallback } from "react";
import { Button, Popover } from "antd";
import { useTranslation } from "react-i18next";
import { ThemeContext, UserContext } from "../../context";
import Header from "./header/header";
import LoginForm from "./login-form/login-form";
import "./live.css";
import * as s from "./styles-live";
import moment from "moment";
import { validateEmail } from "../../utils";
import { customerService } from "../../services";

const Timer = ({ live, setStarted, logged, setLogged }) => {
  const { t } = useTranslation();
  const { corPrimaria, logo, showPoweredBy } = useContext(ThemeContext);
  const { openedFrom, setOpenedFrom, user, setUser } = useContext(UserContext);

  const [visible, setVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState(live.timeToStart);
  const [message, setMessage] = useState(null);
  const [logging, setLogging] = useState(false);
  const [codigo, setCodigo] = useState();

  const mimDigits = (value) => {
    return value.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  };

  const renderTimeLeft = () => {
    if (timeLeft >= 0) {
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
      const seconds = Math.floor((timeLeft / 1000) % 60);

      return (
        <s.ContainerTimeLeft>
          <s.TimeColumn disabled={days === 0}>
            <h1>{mimDigits(days)}</h1>
            <p>{t("LIVE.Days")}</p>
          </s.TimeColumn>
          <div className="border" />
          <s.TimeColumn disabled={hours === 0 && days === 0}>
            <h1>{mimDigits(hours)}</h1>
            <p>{t("LIVE.Hours")}</p>
          </s.TimeColumn>
          <div className="border" />
          <s.TimeColumn disabled={minutes === 0 && hours === 0}>
            <h1>{mimDigits(minutes)}</h1>
            <p>{t("LIVE.Minutes")}</p>
          </s.TimeColumn>
          <div className="border" />
          <s.TimeColumn disabled={seconds === 0 && minutes === 0}>
            <h1>{mimDigits(seconds)}</h1>
            <p>{t("LIVE.Seconds")}</p>
          </s.TimeColumn>
        </s.ContainerTimeLeft>
      );
    } else {
      onFinish();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft <= 1000) {
          clearInterval(interval);
          onFinish();
          return 0;
        }
        return prevTimeLeft - 1000;
      });
    }, 1000);
    return () => clearInterval(interval);
  });

  const onFinish = () => {
    if (live.listaAudiencia) {
      if (!logged) return;
      else setStarted(true);
    } else {
      if (live.autoStart !== false) setStarted(true);
      else setOpenedFrom(true);
    }
  };

  const renderContent = () => (
    <LoginForm
      live={live}
      visible={visible}
      setVisible={setVisible}
      fullWidth
    />
  );

  const date = new Date(live.dataInicio);
  const startTime = moment(date).format("HH:mm");
  const liveDate = moment(date).format("DD/MM/YYYY");

  const isMobile = window.innerWidth < 955;

  const handleLoginAudience = useCallback(
    (userCodigo) => {
      let codigoIsEmail = validateEmail(userCodigo);
      setLogging(true);
      customerService
        .checkAudienceCustomer(live.liveId, userCodigo)
        .then(({ idTabelaPreco = false }) => {
          let userData = {
            ...user,
            idTabelaPreco,
            codigo: !codigoIsEmail && userCodigo,
            email: codigoIsEmail && userCodigo,
            codigoIsEmail,
          };
          setLogged(true);
          setUser(userData);
          localStorage.setItem("customer", JSON.stringify(userData));

          setLogging(false);
          setMessage("");
        })
        .catch(() => {
          setMessage(t("LIVE.InvalidCode"));
          setLogging(false);
        });
    },
    [live, user, setLogged, setUser, setLogging]
  );

  useEffect(() => {
    if (!logged && user && !user.anonimo && live?.listaAudiencia) {
      let userCodigoOrEmail =
        user.codigoIsEmail && user.email ? user.email : user.codigo;
      setCodigo(userCodigoOrEmail);
      handleLoginAudience(userCodigoOrEmail);
    }
  }, [user]);

  return (
    <>
      {!isMobile ? (
        <Header live={live} />
      ) : (
        <s.TopBottom>
          {logo && (
            <img
              alt="header-contractor-logo"
              src={logo}
              style={{ maxWidth: "25vw", maxHeight: "65px" }}
            />
          )}
          {logo && showPoweredBy ? (
            <img
              alt="header-logo"
              src={"/assets/images/poweredby.svg"}
              className="header-logo"
              style={{ maxHeight: "15px", marginLeft: "5px" }}
            />
          ) : (
            !logo && (
              <img
                alt="header-logo"
                src={"/assets/images/logo.svg"}
                className="header-logo"
                style={{ maxWidth: "100px", marginLeft: "5px" }}
              />
            )
          )}
        </s.TopBottom>
      )}

      <s.Timer
        className="fade-in"
        corPrimaria={corPrimaria}
        descLength={live?.descricao.length}
      >
        <s.ElementsContainer>
          <s.InfosContainer>
            {live?.thumbnail && (
              <img src={live?.thumbnail} alt={live?.nome} className="shadow" />
            )}

            <div>
              <div className="welcome">
                {t("LIVE.Welcome")}
                <img
                  src="/assets/images/emoji-welcome.png"
                  alt={t("LIVE.Welcome")}
                />
              </div>
              <h1>{live?.nome}</h1>
              <p title={live?.descricao} className="description">
                {live?.descricao}
              </p>

              <s.LiveDataContainer>
                <p>{t("LIVE.LiveData")}</p>

                <div className="div-row">
                  <div className="div-column">
                    <p>{t("LIVE.Day")}</p>
                    <span>{liveDate}</span>
                  </div>
                  <div className="div-column">
                    <p>{t("LIVE.Start")}</p>
                    <span>{startTime}</span>
                  </div>
                </div>
              </s.LiveDataContainer>
            </div>
          </s.InfosContainer>

          <s.TimerContainer>
            <div className="timer-wrapper">
              <label>
                {timeLeft === 0
                  ? t("LIVE.LiveStarted")
                  : t("LIVE.WaitStartLive")}
              </label>

              <s.CountDownContainer>{renderTimeLeft()}</s.CountDownContainer>
            </div>

            {live && live.listaAudiencia && (
              <s.ExclusiveLiveContainer>
                <div className="text-exclusive">{t("LIVE.ExclusiveLive")}</div>
                <span>{t("LIVE.ExclusiveLiveDesc")}</span>

                <div className="validate-code">
                  <div className="div-icon">
                    <img src={"/assets/icons/lock.svg"} alt="" />
                    <span>{t("LIVE.ValidateAccessCode")}</span>
                  </div>

                  <div className="div-input">
                    <label for="code">{t("LIVE.TypeTheCode")}</label>
                    <div className="div-input-button">
                      <input
                        id="code"
                        disabled={logging}
                        value={codigo}
                        onChange={({ target: t }) => setCodigo(t.value)}
                        style={message ? { borderColor: "red" } : null}
                      />
                      <Button
                        onClick={() => handleLoginAudience(codigo)}
                        style={{
                          background: "#FF981C",
                          height: "2.5rem",
                          borderRadius: "4px",
                        }}
                      >
                        {logging ? (
                          <span className="text-button">
                            {t("LIVE.Verifying")}
                          </span>
                        ) : (
                          <span className="text-button">
                            {t("LIVE.Verify")}
                          </span>
                        )}
                      </Button>
                    </div>
                    {message && (
                      <span className="error-message">{message}</span>
                    )}
                  </div>
                </div>
                {!isMobile ? (
                  <s.MessageVerification
                    style={
                      logged
                        ? { visibility: "visible" }
                        : { visibility: "hidden" }
                    }
                  >
                    <img src={"/assets/icons/success.svg"} alt="" />
                    <div>
                      <p>{t("LIVE.ValidadedCode")}</p>
                      <span>{t("LIVE.EndOfCountdown")}</span>
                    </div>
                  </s.MessageVerification>
                ) : (
                  <s.MessageVerification>
                    <img src={"/assets/icons/success.svg"} alt="" />
                    <div>
                      <p>{t("LIVE.ValidadedCode")}</p>
                      <span>{t("LIVE.EndOfCountdown")}</span>
                    </div>
                  </s.MessageVerification>
                )}
              </s.ExclusiveLiveContainer>
            )}
          </s.TimerContainer>
        </s.ElementsContainer>

        {openedFrom && <p>{t("LIVE.WaitingStartLive")}</p>}
        <div style={{ width: "100%", display: "flex" }}>
          <a
            href={window.location.origin + "/termos-de-uso.pdf"}
            target="_blank"
            style={{ margin: "auto auto 30px auto" }}
            rel="noreferrer"
          >
            {t("APP.TermsOfUse")}
          </a>
        </div>
      </s.Timer>

      {isMobile && (
        <s.TopBottom>
          <Popover
            placement="bottom"
            content={renderContent()}
            trigger="click"
            onOpenChange={(v) => setVisible(v)}
            open={visible}
          >
            <Button
              style={{
                background: "#fff",
                color: corPrimaria,
              }}
              size={"large"}
              id="btn-login-customer"
            >
              {t("LIVE.Enter")}
            </Button>
          </Popover>
        </s.TopBottom>
      )}
    </>
  );
};

export default Timer;
