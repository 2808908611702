import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input, message } from "antd";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { customerService, firestore } from "../../../services";
import * as s from "./styles-chat";
import { Scrollbars } from "react-custom-scrollbars";
import { ThemeContext, UserContext } from "../../../context";
import { createLinkElementInsideText, stringToColor } from "../../../utils";

const { Search } = Input;
const Chat = ({ live }) => {
  const { t } = useTranslation();
  const isKeyboardOpen = useDetectKeyboardOpen();
  const { corPrimaria, corSecundaria } = useContext(ThemeContext);
  const [chat, setChat] = useState([]); //mensagens
  const [msg, setMsg] = useState(""); //mensagem
  const { user, setOpenedFrom, openedFrom } = useContext(UserContext); //dados do usuário
  //Control
  const [loading, setLoading] = useState(true);
  const [chatRef, setChatRef] = useState(); //ref do scroll
  const [autoScroll, setAutoScroll] = useState(true); //scroll automático ligado / desligado
  const [firstScroll, setFirstScroll] = useState(true); // influencia no timeout do scroll no momento que o usuario entra na live

  useEffect(() => {
    let subscribe = firestore
      .collection("lives")
      .doc(live.liveId)
      .collection("chat")
      .doc(live.salaId)
      .collection("mensagens")
      .orderBy("timeEnvio", "desc")
      .limit(200)
      .onSnapshot((snapshot) => {
        setLoading(false);
        setChat(
          snapshot.docs
            .map((doc) => ({
              ...doc.data(),
              docId: doc.id,
            }))
            .sort((a, b) => a.timeEnvio - b.timeEnvio)
        );
      });
    return () => subscribe();
  }, []);

  useEffect(() => {
    if (chat && autoScroll && chat.length >= 9 && chatRef) {
      if (firstScroll) setFirstScroll(false);
      let time = firstScroll ? 300 : 0;
      setTimeout(() => chatRef.scrollToBottom(), time);
    }
  }, [chat]);

  useEffect(() => {
    if (chatRef) chatRef.scrollToBottom();
  }, [loading]);

  const handleUpdate = async ({ scrollTop, scrollHeight, clientHeight }) => {
    // adicionado -1 pois a subtração fica 1 a menos do esperado quando o usuario está scrollando
    if (scrollHeight - parseInt(scrollTop) - 1 <= clientHeight)
      setAutoScroll(true);
    else if (chat.length >= 10) setAutoScroll(false);
  };

  const sendMsg = (mensagem) => {
    setMsg("");
    customerService
      .sendMessage(live.liveId, live.salaId, {
        mensagem,
        customer: { ...user, codigo: user.codigo || user.hash },
      })
      .then(() => {
        chatRef.scrollToBottom();
        document.getElementById("input-chat-message").focus();
      });
  };

  const renderMessage = (msg, docId) => {
    setTimeout(() => {
      let element = document.getElementById(docId);
      if (msg && element?.childNodes.length === 0) {
        if (!msg.includes("http:") && !msg.includes("https:")) {
          element.innerHTML = msg;
        } else {
          let allWords = msg.trim().replace(/\n/g, " ").split(" "); // separa todas as palavras
          element.appendChild(
            createLinkElementInsideText(allWords, false, t("UTILS.ProductLink"))
          );
        }
      }
    }, 300);
  };

  return (
    <s.ChatContainer
      id="container-chat"
      className="shadow fade-in"
      mobileKeyboardIsOpen={window.innerWidth < 955 && isKeyboardOpen}
    >
      <s.ChatHeader
        mobileKeyboardIsOpen={window.innerWidth < 955 && isKeyboardOpen}
      >
        {t("ROOM-TAB.Chat")}
      </s.ChatHeader>
      {live.fixedMessageChat !== null && (
        <s.ChatFixedMessage
          corPrimaria={corPrimaria}
          style={{
            display: `${!live.fixedMessageChat ? "none" : "flex"}`,
          }}
        >
          {live?.fixedMessageChat &&
            live.fixedMessageChat
              .split(/(https?:\/\/\S+)/)
              .map((word, index) => {
                if (word.match(/^https?:\/\//)) {
                  return (
                    <a
                      href={word.trim()}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                      style={{ margin: "0 5px" }}
                    >
                      {word.trim()}
                    </a>
                  );
                } else {
                  return <React.Fragment key={index}>{word}</React.Fragment>;
                }
              })}
        </s.ChatFixedMessage>
      )}

      <Scrollbars
        style={{
          background: "white",
          height: `calc(100% - ${window.innerWidth < 955 ? 8 : 25}px)`,
        }}
        ref={(c) => setChatRef(c)}
        onUpdate={handleUpdate}
        renderThumbHorizontal={() => <div />}
        id="scrollbars-chat-horizontal"
      >
        {!loading &&
          chat.map((msg) => {
            return (
              <>
                {live.fixedMessageChat !== null ? (
                  <div
                    style={{
                      top: `${
                        live?.fixedMessageChat?.length > 161 ? "6rem" : "3rem"
                      }`,
                      position: "relative",
                    }}
                  >
                    <s.Msg
                      key={msg.docId}
                      // rep={zoeira.includes(msg.email)}
                      corPrimaria={corPrimaria}
                      corSecundaria={corSecundaria}
                      colorByName={stringToColor(msg.name.toLowerCase())}
                    >
                      <div>
                        <label className="msg-name">
                          {msg.name.toLowerCase()}
                        </label>
                        <label id={msg.docId} className="msg-content">
                          {renderMessage(msg.mensagem, msg.docId)}
                        </label>
                      </div>
                    </s.Msg>
                  </div>
                ) : (
                  <s.Msg
                    key={msg.docId}
                    // rep={zoeira.includes(msg.email)}
                    corPrimaria={corPrimaria}
                    corSecundaria={corSecundaria}
                    colorByName={stringToColor(msg.name.toLowerCase())}
                  >
                    <div>
                      <label className="msg-name">
                        {msg.name.toLowerCase()}
                      </label>
                      <label id={msg.docId} className="msg-content">
                        {renderMessage(msg.mensagem, msg.docId)}
                      </label>
                    </div>
                  </s.Msg>
                )}
              </>
            );
          })}
      </Scrollbars>

      <Search
        placeholder={
          live?.chat === false
            ? t("CHAT.ChatBloqued")
            : !live?.salaId
            ? t("CHAT.LiveWithoutChat")
            : user?.name
            ? `${t("CHAT.Comment")}`
            : t("CHAT.LoginToComment")
        }
        size={
          window.innerWidth >= 955
            ? "large"
            : isKeyboardOpen
            ? "large"
            : "middle"
        }
        enterButton={t("CHAT.Send")}
        onSearch={(v) => user?.name && sendMsg(v)}
        value={msg}
        onChange={({ target: t }) => user?.name && setMsg(t.value)}
        disabled={live.chat === false || !live.salaId}
        id="input-chat-message"
        onClick={() => {
          if (!user?.name) {
            let thisInput = document.getElementById("input-chat-message");
            if (thisInput) thisInput.blur();
            if (!openedFrom) {
              document.getElementById("btn-login-customer").click();
              setOpenedFrom("chat");
              message.info(t("CHAT.FillNameToInteract"), 3);
              if (document.getElementById("input-customer-name")) {
                document.getElementById("input-customer-name").focus();
              }
            }
          }
        }}
        className="form-send-msg-chat animated-linear"
      />
    </s.ChatContainer>
  );
};

export default Chat;
