import React, { createContext, useState, useContext } from "react";

const USER_INITIAL = {
  email: "",
  fullName: "",
  cpf: "",
  phoneNumber: "",
  password: "",
  dateOfBirth: "",
};

const NeomodeContext = createContext({
  user: USER_INITIAL,
  setUser: () => {},
  userLogged: false,
  setUserLogged: () => {},
  sellerSelected: "",
  setSellerSelected: () => {},
  shippingSelected: "",
  setShippingSelected: () => {},
  addressSelected: "",
  setAddressSelected: () => {},
  cpfPickUp: "",
  setCpfPickUp: () => {},
  paymentMethodSelected: "",
  setPaymentMethodSelected: () => {},
  installment: "",
  setInstallment: () => {},
});

const NeomodeProvider = ({ children }) => {
  const [user, setUser] = useState(USER_INITIAL);
  const [userLogged, setUserLogged] = useState(false);
  const [sellerSelected, setSellerSelected] = useState(false); // id do seller escolhido
  const [shippingSelected, setShippingSelected] = useState(false);
  const [addressSelected, setAddressSelected] = useState(false);
  const [cpfPickUp, setCpfPickUp] = useState(false);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(false);
  const [installment, setInstallment] = useState(false);

  return (
    <NeomodeContext.Provider
      value={{
        user,
        setUser,
        userLogged,
        setUserLogged,
        sellerSelected,
        setSellerSelected,
        shippingSelected,
        setShippingSelected,
        cpfPickUp,
        setCpfPickUp,
        addressSelected,
        setAddressSelected,
        paymentMethodSelected,
        setPaymentMethodSelected,
        installment,
        setInstallment,
      }}
    >
      {children}
    </NeomodeContext.Provider>
  );
};

const useNeomode = () => useContext(NeomodeContext);

export { NeomodeProvider, useNeomode };
