import firebase from "firebase/app";
import "firebase/database";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

const INITIAL_STATE = [];
function users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD":
      return [...state, action.user];
    case "REMOVE":
      return [...state.filter((user) => user.id !== action.user.id)];
    case "CHANGE":
      return [
        ...state.map((user) =>
          user.id !== action.user.id ? user : action.user
        ),
      ];
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
}

const listenUsersAdded = (liveId) => {
  firebase
    .database()
    .ref(`${liveId}/online`)
    .on("child_added", (data) => {
      if (data?.val()) {
        store.dispatch({
          type: "ADD",
          user: {
            id: data.key,
            ...data.val(),
          },
        });
      }
    });
};

const listenUsersChanged = (liveId) => {
  firebase
    .database()
    .ref(`${liveId}/online`)
    .on("child_changed", (data) => {
      if (data?.val()) {
        store.dispatch({
          type: "CHANGE",
          user: {
            id: data.key,
            ...data.val(),
          },
        });
      }
    });
};

const listenUsersRemoved = (liveId) => {
  firebase
    .database()
    .ref(`${liveId}/online`)
    .on("child_removed", (data) => {
      if (data?.val())
        store.dispatch({
          type: "REMOVE",
          user: { id: data.key, ...data.val() },
        });
    });
};

const initializeListenUsers = (liveId) => {
  listenUsersAdded(liveId);
  listenUsersChanged(liveId);
  listenUsersRemoved(liveId);
  return (dispatch) => dispatch({ type: "INITIALIZE" });
};
const clearState = () => {
  return (dispatch) => dispatch({ type: "RESET" });
};

export const usersActions = {
  initializeListenUsers,
  clearState,
};

const reducers = combineReducers({
  users,
});

export const store = createStore(
  reducers,
  compose(applyMiddleware(thunkMiddleware))
);
