import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Skeleton, notification, message, Modal } from "antd";
import { useTranslation } from "react-i18next";
import {
  RightOutlined,
  LeftOutlined,
  CloseSquareOutlined,
  CopyOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import Calendar from "react-calendar";
import moment from "moment";
import "moment/locale/pt-br";
import queryString from "query-string";

import { handleChangeTime, convertImgToBase64URL } from "../../../../utils";

import { managerService, userService } from "../../../../services";
import { UserContext } from "../../../../context";
import * as s from "./styles";

const { TextArea } = Input;

const LandingPage = ({ location: { search } }) => {
  const { t } = useTranslation();
  const { setUser, user } = useContext(UserContext);

  const [brandName, setBrandName] = useState("");
  const [liveName, setLiveName] = useState("");
  const [liveUrl, setLiveUrl] = useState("");
  const [aboutLive, setAboutLive] = useState("");
  const [liveTime, setliveTime] = useState("");
  const [dateValue, onChangeDateValue] = useState(new Date());
  const [minDateCalendar, setMinDateCalendar] = useState(new Date());

  const [colorPrimary, setColorPrimary] = useState(
    user?.whiteLabel?.corPrimaria ? user?.whiteLabel.corPrimaria : "#6a3189"
  );
  const [colorSecundary, setColorSecundary] = useState(
    user?.whiteLabel?.corSecundaria ? user?.whiteLabel.corSecundaria : "#ffc05c"
  );
  const [colorButton, setColorButton] = useState(
    user?.whiteLabel?.corPrimaria ? user?.whiteLabel.corPrimaria : "#6a3189"
  );
  const [textColor, setTextColor] = useState("#FFFFFF");

  const [cropImage, setCropImage] = useState(false); //modal para formatar thumbnail
  const [thumb, setThumb] = useState();
  const [errorThumb, setErrorThumb] = useState(false);
  const [nameThumbnail, setNameThumbnail] = useState();

  const [timeStart, setTimeStart] = useState("");
  const [invalidValues, setInvalidValues] = useState([]);
  const [landingPage, setLandingPage] = useState();
  const [leadId, setLeadId] = useState();

  const [saving, setSaving] = useState(false); //salvando dados

  const [preview, setPreview] = useState(false);
  const [link, setLink] = useState(""); //link amigável para live

  const [url, setUrl] = useState(
    window.location.origin + "/live/" + user.nameParamLive
  );

  const [loading, setLoading] = useState(
    setTimeout(() => {
      setLoading(false);
    }, 2000)
  );

  const regex = /^[0-2][0-9]:[0-5][0-9]:[0-9]{2}$/;

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      var size = e.target.files[0].size;
      if (size < 800000) {
        setThumb(URL.createObjectURL(e.target.files[0]));
        setNameThumbnail(e.target.files[0].name);
        message.success(t("LANDING-PAGE.infosLive.imageAllowed"));
      } else {
        message.error({
          content: t("LANDING-PAGE.infosLive.imageNotAllowed"),
          duration: 5,
        });
        size.value = "";
      }
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (dateValue && dateValue[0] && timeStart) {
      checkInvalidValues("timeLive");
    }
  }, [dateValue]);

  useEffect(() => {
    document.title = `Cadastrar Leads | 4Show`;
    return () => {
      document.title = "4Show";
    };
  }, [setUser]);

  useEffect(() => {
    if (search) {
      let values = queryString.parse(search);
      if (values.lp)
        managerService.getLead(values.lp).then((data) => {
          setLandingPage(data);
          setLeadId(values.lp);
        });
    }
  }, [search]);

  useEffect(() => {
    if (landingPage) {
      setBrandName(landingPage.nomeEmpresa);
      setLiveName(landingPage.nomeLive);
      setAboutLive(landingPage.sobreLive);
      setLiveUrl(landingPage.liveUrl);
      setColorPrimary(landingPage.corPrimaria);
      setColorSecundary(landingPage.corSecundaria);
      setColorButton(landingPage.corBotao);
      setTextColor(landingPage.corTexto);
      setThumb(landingPage.imagemPrincipal);
      // setMinDateCalendar(new Date());
      onChangeDateValue(new Date(landingPage.timestamp));
      setTimeStart(moment(landingPage.timestamp).format("HH:mm:ss"));
    }
  }, [landingPage]);

  const checkInvalidValues = (type) => {
    if (type === "brandName" && !brandName)
      setInvalidValues([...invalidValues, type]);
    else if (type === "liveName" && !liveName) {
      setInvalidValues([...invalidValues, type]);
    } else if (type === "aboutLive" && !aboutLive) {
      setInvalidValues([...invalidValues, type]);
    } else if (
      type === "timeLive" &&
      !timeStart &&
      new Date(
        moment(dateValue).format().substring(0, 10) + "T" + timeStart
      ).getTime()
    ) {
      setInvalidValues([...invalidValues, type]);
    } else if (type === "timeLive" && !timeStart) {
      setInvalidValues([...invalidValues, type]);
    } else if (type === "timeLive" && !regex.test(timeStart)) {
      setInvalidValues([...invalidValues, type]);
    }
  };

  // const openNotification = (success, landingPage) => {
  //   if (success)
  //     notification.success({
  //       key: landingPage,
  //       message: t("ROOM-TAB.UploadSuccess"),
  //       description: `${t("ROOM-TAB.IntroductionSuccessDescriptionOne")} ${
  //         landingPage ? `${t("ROOM-TAB.OfIntroduction")} ` : ""
  //       }${t("ROOM-TAB.IntroductionSuccessDescriptionTwo")}`,
  //       onClick: () => notification.close(landingPage),
  //       duration: 10,
  //     });
  //   else
  //     notification.error({
  //       key: landingPage,
  //       message: t("ROOM-TAB.UploadError"),
  //       description: `${t("ROOM-TAB.IntroductionErrorDescriptionOne")} ${
  //         landingPage ? ` $t("ROOM-TAB.OfIntroduction")} ` : ""
  //       }. ${t("ROOM-TAB.IntroductionErrorDescriptionTwo")}`,
  //       onClick: () => notification.close(landingPage),
  //       duration: 10,
  //     });
  // };

  // const checkFinishIsValidation = () => {
  //   let isDisabled = false;
  //   if(!thumbLogo || !brandName || !aboutBrand || !liveName ||
  //     !aboutLive || !dateValue || !minDateCalendar
  //     || !thumb) isDisabled = true

  //   return isDisabled
  // }

  const onFinishCreatedLP = async () => {
    setSaving(true);

    await userService.getUser().then((data) => {
      setUser(data.user);
    });

    let data = {
      corPrimaria: colorPrimary,
      corSecundaria: colorSecundary,
      corBotao: colorButton,
      corTexto: textColor,
      nomeEmpresa: brandName,
      nomeLive: liveName,
      sobreLive: aboutLive,
      timestamp: new Date(
        moment(dateValue).format().substring(0, 10) + "T" + timeStart
      ).getTime(),
      leadUrl: user.nameParamLive,
      liveUrl,
    };

    await convertImgToBase64URL(thumb, (base64) => {
      data.imagemPrincipal = base64 ? base64.split("base64,")[1] : "";
      data.nomeImagemP = nameThumbnail;

      if (leadId) {
        return managerService
          .editLead(leadId, data)
          .then((data) => {
            setSaving(false);
            if (data.message === "Lead criado com sucesso!") {
              notification.success({
                key: "aviso",
                message: t("LANDING-PAGE.infosLive.EditLPSuccess"),
                description: t(
                  "LANDING-PAGE.infosLive.EditLPSuccessDescription"
                ),
                onClick: () => notification.close("aviso"),
                duration: 10,
              });
            }
            // history.push("/dashboard-landing-page");
          })
          .catch((e) => {
            if (
              !brandName ||
              !liveName ||
              !aboutLive ||
              !dateValue ||
              !minDateCalendar ||
              !thumb
            ) {
              notification.error({
                key: "aviso",
                message: t("LIVE-TAB.GeneralError"),
                description: t("LANDING-PAGE.infosLive.EditLPErrorLead"),
                onClick: () => notification.close("aviso"),
                duration: 10,
              });
            } else {
              notification.error({
                key: "aviso",
                message: t("LIVE-TAB.GeneralError"),
                description: t("LANDING-PAGE.infosLive.EditLPErrorLead"),
                onClick: () => notification.close("aviso"),
                duration: 10,
              });
            }
            setSaving(false);
          });
      } else {
        return managerService
          .createLead(data)
          .then((data) => {
            setSaving(false);
            if (data.message === "Lead criado com sucesso!") {
              notification.success({
                key: "aviso",
                message: t("LANDING-PAGE.infosLive.CreateLPSuccess"),
                description: t("LANDING-PAGE.infosLive.CreateLPSuccess"),
                onClick: () => notification.close("aviso"),
                duration: 10,
              });
            }
            if (data.id) setLeadId(data.id);
            // history.push("/dashboard-landing-page");
          })
          .catch((e) => {
            if (
              !brandName ||
              !liveName ||
              !aboutLive ||
              !dateValue ||
              !minDateCalendar ||
              !thumb
            ) {
              notification.error({
                key: "aviso",
                message: t("LIVE-TAB.GeneralError"),
                description: t(
                  "LANDING-PAGE.infosLive.CreateLPErrorDescription"
                ),
                onClick: () => notification.close("aviso"),
                duration: 10,
              });
            } else {
              notification.error({
                key: "aviso",
                message: t("LIVE-TAB.GeneralError"),
                description: t("LANDING-PAGE.infosLive.CreateLPErrorLead"),
                onClick: () => notification.close("aviso"),
                duration: 10,
              });
            }
            setSaving(false);
          });
      }
    });
  };

  return (
    <div className="content" style={{ display: "block" }}>
      <s.Block>
        <s.ContentAddBrand>
          <Input
            type="color"
            value={colorPrimary}
            onChange={({ target: t }) => setColorPrimary(t.value)}
            style={{ width: "75px", height: "75px" }}
          />
          <s.ContentButton>
            <h3>{t("LANDING-PAGE.primaryColor")}</h3>
          </s.ContentButton>
        </s.ContentAddBrand>
        <s.ContentAddBrand>
          <Input
            type="color"
            value={colorSecundary}
            onChange={({ target: t }) => setColorSecundary(t.value)}
            style={{ width: "75px", height: "75px" }}
          />
          <s.ContentButton>
            <h3>{t("LANDING-PAGE.secundaryColor")}</h3>
          </s.ContentButton>
        </s.ContentAddBrand>
        <s.ContentAddBrand>
          <Input
            type="color"
            value={colorButton}
            onChange={({ target: t }) => setColorButton(t.value)}
            style={{ width: "75px", height: "75px" }}
          />
          <s.ContentButton>
            <h3>{t("LANDING-PAGE.buttonColor")}</h3>
          </s.ContentButton>
        </s.ContentAddBrand>
        <s.ContentAddBrand>
          <Input
            type="color"
            value={textColor}
            onChange={({ target: t }) => setTextColor(t.value)}
            style={{ width: "75px", height: "75px" }}
          />
          <s.ContentButton>
            <h3>{t("LANDING-PAGE.fontColor")}</h3>
          </s.ContentButton>
        </s.ContentAddBrand>
      </s.Block>

      <s.BlockInfos>
        <s.CadastroContent>
          <section style={{ padding: "40px" }}>
            <div style={{ marginBottom: "20px" }}>
              <label>{t("LANDING-PAGE.infosLive.brand")} *</label>
              <Input
                value={brandName}
                onChange={({ target: t }) => {
                  if (invalidValues.includes("brandName"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "brandName")
                    );
                  setBrandName(t.value);
                }}
                onBlur={() => checkInvalidValues("brandName")}
                style={{
                  border: invalidValues.includes("nome") && "1px solid red",
                }}
              />
              {invalidValues.includes("brandName") && (
                <s.InvalidLabel className="fade-in">
                  {t("LANDING-PAGE.validations.brandName")}
                </s.InvalidLabel>
              )}
            </div>
            <div style={{ marginBottom: "20px" }}>
              <label>{t("LANDING-PAGE.infosLive.liveName")} *</label>
              <Input
                value={liveName}
                onChange={({ target: t }) => {
                  if (invalidValues.includes("liveName"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "liveName")
                    );
                  setLiveName(t.value);
                }}
                onBlur={() => checkInvalidValues("liveName")}
                style={{
                  border: invalidValues.includes("liveName") && "1px solid red",
                }}
              />
              {invalidValues.includes("liveName") && (
                <s.InvalidLabel className="fade-in">
                  {t("LANDING-PAGE.validations.liveName")}
                </s.InvalidLabel>
              )}
            </div>
            <div style={{ marginBottom: "20px" }}>
              <label>{t("LANDING-PAGE.infosLive.aboutLive")} *</label>
              <TextArea
                style={{
                  maxHeight: "86px",
                  minHeight: "86px",
                  border:
                    invalidValues.includes("descricao") && "1px solid red",
                }}
                value={aboutLive}
                onBlur={() => checkInvalidValues("aboutLive")}
                onChange={({ target: t }) => {
                  if (invalidValues.includes("aboutLive"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "aboutLive")
                    );
                  setAboutLive(t.value);
                }}
              />
              {invalidValues.includes("aboutLive") && (
                <s.InvalidLabel className="fade-in">
                  {t("LANDING-PAGE.validations.aboutLive")}
                </s.InvalidLabel>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  color: liveTime && liveTime.length < 8 ? "red" : "black",
                }}
              >
                {t("LIVE-TAB.StartTime")} *
              </label>
              <Input
                onChange={({ target: t }) => {
                  if (invalidValues.includes("timeLive"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "timeLive")
                    );
                  handleChangeTime(t.value, timeStart, setTimeStart);
                }}
                placeholder="00:00:00"
                value={timeStart}
                maxLength={8}
                onBlur={() => checkInvalidValues("timeLive")}
                style={{
                  border: invalidValues.includes("timeLive") && "1px solid red",
                  width: "120px",
                }}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <label>{t("LANDING-PAGE.infosLive.liveUrl")}</label>
              <Input
                value={liveUrl}
                onChange={({ target: t }) => {
                  setLiveUrl(t.value);
                }}
              />
            </div>
          </section>
        </s.CadastroContent>
        <s.CadastroContent>
          <section>
            <Calendar
              onChange={(v) => {
                if (invalidValues.includes("timeLive"))
                  setInvalidValues((list) =>
                    list.filter((value) => value !== "timeLive")
                  );
                onChangeDateValue(v);
              }}
              value={dateValue}
              // selectRange={true}
              minDate={minDateCalendar}
              calendarType={"US"}
              minDetail={"month"}
              maxDetail={"month"}
              navigationLabel={({ label }) => (
                <label style={{ color: "#828282" }}>
                  {label.split(" ")[0]}
                </label>
              )}
              nextLabel={<RightOutlined />}
              prevLabel={<LeftOutlined />}
            />
          </section>
        </s.CadastroContent>
      </s.BlockInfos>

      <s.ImgsBlock>
        <s.ImageContent>
          <input
            id="thumbnail-lp"
            type="file"
            accept="image/*"
            onChange={onSelectFile}
            style={{ display: "none" }}
          />

          <div
            onClick={() => setCropImage(true)}
            name="thumbnail"
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {thumb && !errorThumb ? (
              window.innerWidth <= 1600 ? (
                <img
                  alt="thumbnail"
                  src={thumb}
                  style={s.imgStyle1600}
                  onError={() => setErrorThumb(true)}
                />
              ) : window.innerWidth <= 1300 ? (
                <img
                  alt="thumbnail"
                  src={thumb}
                  style={s.imgStyle1000}
                  onError={() => setErrorThumb(true)}
                />
              ) : (
                <img
                  alt="thumbnail"
                  src={thumb}
                  style={s.imgStyle}
                  onError={() => setErrorThumb(true)}
                />
              )
            ) : window.innerWidth <= 1600 ? (
              <Skeleton.Image
                style={{
                  ...s.imgStyle1600,
                  border: "1px solid #d9d9d9",
                }}
                active
              ></Skeleton.Image>
            ) : window.innerWidth <= 1300 ? (
              <Skeleton.Image
                style={{
                  ...s.imgStyle1000,
                  border: "1px solid #d9d9d9",
                }}
                active
              ></Skeleton.Image>
            ) : (
              <Skeleton.Image
                style={{
                  ...s.imgStyle,
                  border: "1px solid #d9d9d9",
                }}
                active
              ></Skeleton.Image>
            )}
          </div>

          <Button
            type="primary"
            style={{ margin: "25px 25px 25px 0px ", position: "absolute" }}
            onClick={() => document.getElementById(`thumbnail-lp`).click()}
          >
            {!thumb ? t("CROP-IMAGE.Upload") : t("CROP-IMAGE.ChangeImage")}
          </Button>
        </s.ImageContent>
        {preview && (
          <Modal
            open={preview}
            onCancel={() => setPreview()}
            closeIcon={
              <div style={{ marginTop: "-18px" }}>
                <CloseSquareOutlined
                  style={{
                    background: "red",
                    fontSize: "32px",
                    marginLeft: "86px",
                  }}
                />
              </div>
            }
            footer={null}
            width={"85%"}
            bodyStyle={{ height: "630px", padding: 0, marginTop: "-70px" }}
          >
            <iframe
              src={window.location.origin + "/live/" + user.nameParamLive}
              title="preview-LP"
              style={{ border: 0 }}
              height="630px"
              width="101%"
            ></iframe>
          </Modal>
        )}
        <s.CadastroContent>
          <s.ButtonContent>
            <strong>{t("ROOM-TAB.Warning")}!</strong>
            <p>{t("LANDING-PAGE.LandingPageWarning")}</p>
            <Button
              type="secundary"
              style={{ marginBottom: "10px" }}
              onClick={() => setPreview(true)}
              disabled={!leadId}
            >
              {t("LANDING-PAGE.infosLive.preview")}
            </Button>
            <p style={{ marginBottom: "-16px" }}>
              {t("LANDING-PAGE.RedirectLP")}
            </p>

            <Button
              type="default"
              style={{ marginBottom: "10px" }}
              icon={<ArrowUpOutlined />}
              href={link + url}
              target="_blank"
              disabled={!leadId}
            >
              {`${link + url}`}
            </Button>
            <p style={{ marginBottom: "-34px" }}>{t("LANDING-PAGE.LinkLP")}</p>
            <Button
              type="secundary"
              onClick={() => {
                navigator.clipboard
                  .writeText(url + link)
                  .then(() => message.success(t("PUBLISH-TAB.LinkCopied")));
              }}
              style={{ marginTop: "20px" }}
              disabled={!leadId}
            >
              <CopyOutlined style={{ marginRight: "10px" }} />
              <label id="url-live">{url + link}</label>
            </Button>
          </s.ButtonContent>
        </s.CadastroContent>
      </s.ImgsBlock>
      <div
        style={{
          textAlign: "center",
          marginTop: "1.1rem",
          marginBottom: "1.1rem",
        }}
      >
        <Button
          type="primary"
          onClick={onFinishCreatedLP}
          loading={saving}
          // disabled={checkFinishIsValidation()}
        >
          {leadId
            ? t("LANDING-PAGE.infosLive.editLP")
            : t("LANDING-PAGE.infosLive.createLP")}
        </Button>
      </div>
    </div>
  );
};

export default withRouter(LandingPage);
