import { useCallback, useEffect, useState } from "react";

const useTheme = () => {
  const [logo, setLogo] = useState(false);
  const [corPrimaria, setCorPrimaria] = useState("rgb(106,49,137)");
  const [corSecundaria, setCorSecundaria] = useState("rgb(255,192,92)");
  const [showPoweredBy, setShowPoweredBy] = useState(true);
  const [verticalChatHeight, setVerticalChatHeight] = useState(170);
  var root = document.querySelector(":root");

  const hexToRgb = (hex) =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16))
      .join();

  const handleColors = useCallback(() => {
    root.style.setProperty("--corPrimaria", corPrimaria);
    root.style.setProperty("--corSecundaria", corSecundaria);
    root.style.setProperty("--antd-wave-shadow-color", corSecundaria);
    root.style.setProperty(
      "--corSecundariaLight",
      `rgba(${hexToRgb(corSecundaria)},0.2)`
    );
    root.style.setProperty(
      "--corSecundariaLight2",
      `rgba(${hexToRgb(corSecundaria)},0.5)`
    );
  }, [corPrimaria, corSecundaria, root]);

  useEffect(() => {
    handleColors();
  }, [handleColors]);

  return {
    logo,
    setLogo,
    corPrimaria,
    setCorPrimaria,
    corSecundaria,
    setCorSecundaria,
    showPoweredBy,
    setShowPoweredBy,
    verticalChatHeight,
    setVerticalChatHeight,
  };
};

export default useTheme;
