import React, { createContext } from "react";
import useShowProduct from "./hooks/use-show-product";

const ShowProductContext = createContext({
  show: false,
  setShow: () => {},
  show2: false,
  setShow2: () => {},
});

const ShowProductProvider = ({ children }) => {
  const { show, setShow, show2, setShow2 } = useShowProduct();

  return (
    <ShowProductContext.Provider
      value={{
        show,
        setShow,
        show2,
        setShow2,
      }}
    >
      {children}
    </ShowProductContext.Provider>
  );
};

export { ShowProductContext, ShowProductProvider };
