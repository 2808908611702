import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "antd";
import * as s from "./styles-cart";
import { formatCash } from "../../../utils";

const ModalFreeShipping = ({
  visible,
  finish,
  onCancel,
  loading,
  unidadeMonetaria,
  minValueFreeShipping,
  orderCompleted,
  setOrderCompleted,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={
        orderCompleted
          ? t("MODAL-FREE-SHIPPING.SendYourRequest")
          : t("MODAL-FREE-SHIPPING.FreeShipping")
      }
      visible={visible}
      width={"450px"}
      footer={null}
      onCancel={() => {
        if (orderCompleted) setOrderCompleted();
        onCancel();
      }}
    >
      <div>
        {orderCompleted ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>{t("MODAL-FREE-SHIPPING.ThankYouMessage")}</label>
            <Button
              type="primary"
              style={{ width: 80, margin: "10px auto 0 auto" }}
              onClick={() => {
                setOrderCompleted();
                onCancel();
              }}
            >
              Ok
            </Button>
          </div>
        ) : (
          <>
            <label>
              {t("MODAL-FREE-SHIPPING.FreeShippingRequirementOne")}{" "}
              {minValueFreeShipping &&
                formatCash(
                  minValueFreeShipping / 100,
                  false,
                  unidadeMonetaria
                )}{" "}
              {t("MODAL-FREE-SHIPPING.FreeShippingRequirementTwo")}
            </label>
            <s.FreeShippingModalButtonContainer>
              <Button
                type="seconday"
                style={{ maxWidth: 202, marginTop: 10 }}
                onClick={onCancel}
              >
                {t("MODAL-FREE-SHIPPING.KeepBuying")}
              </Button>
              <Button
                type="primary"
                style={{ maxWidth: 202, marginTop: 10 }}
                onClick={finish}
                loading={loading}
              >
                {t("MODAL-FREE-SHIPPING.EndOrder")}
              </Button>
            </s.FreeShippingModalButtonContainer>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalFreeShipping;
