import React, { createContext } from "react";
import useViewLandingPage from "./hooks/use-view-landing-page";

const ViewLandingPageContext = createContext({
  landingPage: false,
  setLandingPage: () => {},
});

const ViewLandingPageProvider = ({ children }) => {
  const { landingPage, setLandingPage } = useViewLandingPage();

  return (
    <ViewLandingPageContext.Provider
      value={{
        landingPage,
        setLandingPage,
      }}
    >
      {children}
    </ViewLandingPageContext.Provider>
  );
};

export { ViewLandingPageContext, ViewLandingPageProvider };
