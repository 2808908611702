import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const InfoLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    color: #ccc;
    font-size: 12px;
    margin: 0 8px;
    text-transform: uppercase;
  }
  .before {
    flex: 1;
    height: 1px;
    background: #ccc;
  }
  .after {
    flex: 1;
    height: 1px;
    background: #ccc;
  }
`;
