import { axiosApp } from ".";
//-------------------------------------------------------------
//---------------------------Cart------------------------------
//-------------------------------------------------------------
const addFavorite = (live, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`/product/favoritos/adicionar/${live}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const deleteFavorite = (live, prodId, customer) =>
  new Promise((resolve, reject) => {
    axiosApp
      .delete(`/product/favoritos/deletar/${live}/${prodId}`, {
        data: customer,
      })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const editFavorite = (live, favoriteId, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .put(`/product/favoritos/alterar/${live}/${favoriteId}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const finishOrder = (live, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`/product/pedido/fechar/${live}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const finishOrderVtex = (live, customer) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`/vtex/addToCart`, {
        live,
        customer,
      })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const finishOrderMagento = (body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`/magento/create-cart`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });

const verifyQuantityProduct = (params) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`/product/quantity-ordered`, { params })
      .then(({ data }) => resolve(data))
      .catch((e) => {
        reject(e.response.data);
      });
  });
export const productService = {
  //cart
  addFavorite,
  deleteFavorite,
  editFavorite,
  finishOrder,
  finishOrderVtex,
  finishOrderMagento,
  verifyQuantityProduct,
};
