import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  @media (max-width: 955px) {
    height: 50px;
    padding-left: 10px;
  }
  background: ${({ corPrimaria: c }) => c};

  #btn-login-customer {
    color: white;
    padding: 0;
    box-shadow: none;
    height: 45px;
    margin-right: 20px;
    margin-left: 20;
    @media (max-width: 955px) {
      margin: 0 5px 0 0 !important;
      background: transparent !important;
      border-color: transparent !important;
    }
  }

  .header-logo {
    height: 60%;
  }

  .ant-select.ant-select-single.ant-select-show-arrow {
    margin-right: auto !important;
    margin-left: 14px !important;
  }

  .ant-select-selector {
    display: flex !important;
    background-color: transparent !important;
    border: none !important;
    color: white !important;
    transition: 1s !important;
    align-items: center !important;
    justify-content: center !important;

    border-right-width: 0px !important;
    border: 0px !important;
  }

  .ant-select-selection-item {
    label {
      display: none;
    }

    img {
      margin-right: 0;
    }
  }

  .ant-select-arrow {
    color: white !important;
  }
`;
export const UserVertical = styled.div`
  z-index: 4;
  position: fixed;
  bottom: 40px;
  color: white;
  right: 0px;
  font-size: 18px;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent !important;
    border: none !important;
  }

  .ant-select-selection-item {
    label {
      display: none;
    }

    img {
      margin-right: 0;
    }
  }

  .ant-select.ant-select-single.ant-select-show-arrow {
    margin-right: auto !important;
    margin-left: 14px !important;
  }

  .ant-select-selector {
    display: flex !important;
    background-color: transparent !important;
    border: none !important;
    color: white !important;
    transition: 1s !important;
    align-items: center !important;
    justify-content: center !important;

    border-right-width: 0px !important;
    border: 0px !important;
  }
  .ant-select-arrow {
    color: white !important;
  }
`;

export const DropdownImage = styled.img`
  width: 30px;
  margin-right: 10px;
`;

//---------------------------------------------------------------
//                     Novo Header Vertical
//---------------------------------------------------------------
export const VerticalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background: var(--corPrimaria);
  padding: 10px 15px;
  /* MAGIC HAPPENS HERE */
  transform: translateZ(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  -webkit-transform: translateZ(0);
  -webkit-font-smoothing: antialiased; /* seems to do the same in Safari */
  @media (max-width: 955px) {
    padding-right: 0px;
  }

  #btn-login-customer {
    color: white;
    padding: 0;
    box-shadow: none;
    height: 45px;
    margin-right: ${({ user }) => (user ? 20 : 45)}px;
    margin-left: 20px;
  }

  .user-interaction {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 30%;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent !important;
    border-color: transparent !important;
  }

  .ant-select-arrow {
    color: #fff !important;
    left: 45px !important;
  }
`;
