import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as s from "./styled-products-tab";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Input,
  Skeleton,
  Card,
  notification,
  message,
  Modal,
  Popover,
  Checkbox,
} from "antd";
import {
  PlaySquareOutlined,
  UnorderedListOutlined,
  RightOutlined,
  LeftOutlined,
  DeleteOutlined,
  WarningOutlined,
  VideoCameraOutlined,
  DollarOutlined,
  FormOutlined,
} from "@ant-design/icons";
import "moment/locale/pt-br";
import ModalProduct from "./modal-product";
import { Scrollbars } from "react-custom-scrollbars";
import ModalVariation from "./modal-variation";
import ModalUploadCsv from "./modal-upload-csv";
import { managerService } from "../../../../services";
import {
  formatCash,
  formatTime,
  handleChangeTime,
  sourceImage,
  preventInvalidNumberOnKeyDown,
  preventInvalidNumberOnPaste,
} from "../../../../utils";
import Compress from "compress.js";
import ModalProductSplit from "./modal-product-split";
import ModalSplitGeral from "./modal-split-geral";
import VtexCatalog from "./vtex-catalog";
import MagentoCatalog from "./magento-catalog";
import NeomodeCatalog from "./neomode-catalog";
import { PaymentContext, ThemeContext } from "../../../../context";
import { TabsVideo } from "../../../../components";
import PriceTableModal from "./price-table-modal";
import ModalUploadXml from "./modal-upload-xml";
import XmlCatalog from "./xml-catalog";

const compress = new Compress();
const ProductsTab = ({
  next,
  live,
  aoVivo,
  liveWithAssinatura,
  liveWithTabelaPreco = false,
}) => {
  const { t } = useTranslation();
  const { corSecundaria } = useContext(ThemeContext);
  const { services } = useContext(PaymentContext);
  const { pagarme = [] } = services;
  const [products, setProducts] = useState([]); //produtos
  const [variations, setVariations] = useState({}); //variações
  const [productsChanged, setProductsChanged] = useState([]); //produtos alterados
  const [openModal, setOpenModal] = useState(false); //modal dos produtos aberta / fechada
  const [openUploadProduct, setOpenUploadProduct] = useState(false); //modal de upload de produtos por csv
  const [openModalXml, setOpenModalXml] = useState(false); //modal de upload de produtos por xml
  const [productsXml, setProductsXml] = useState([]);
  const [prodSelected, setProdSelected] = useState(false); //índice do produto principal selecionado
  const [render, setRender] = useState(false); //para renderizar novamente
  const [video, setVideo] = useState(false); //url do vídeo principal
  const [hasProductWithAssinatura, setHasProductWithAssinatura] =
    useState(false);
  //Control
  const [deleteProductModal, setDeleteProductModal] = useState(false);
  const [deletingProduct, setDeletingProduct] = useState(false);
  const [splitModal, setSplitModal] = useState(false); //modal com split geral ou do produto
  const [splitGeral, setSplitGeral] = useState([]); //split geral
  const [variationCatalogIndex, setVariationCatalogIndex] = useState(false); //atualizando catálogo vtex
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [savingProductsList, setSavingProductsList] = useState([]);
  const [wrongProducts, setWrongProducts] = useState(false);
  const [openCatalog, setOpenCatalog] = useState(false); //modal com catálogo da vtex 1=principal 2=variacao
  const [openCatalogXml, setOpenCatalogXml] = useState(false); //modal com catálogo da xml
  const [openPriceTableModal, setOpenPriceTableModal] = useState(false); //modal com tabela de preços
  const [tabelaPreco, setTabelaPreco] = useState(false); //tabela de preços
  const [controlAllStock, setControlAllStock] = useState(false); //controlar estoque de todos os produtos
  const [showAllStock, setShowAllStock] = useState(false); //mostrar estoque de todos os produtos
  const [videoDuration, setVideoDuration] = useState(false);
  const [invalidValues, setInvalidValues] = useState([]);

  const liveWithVtex = live?.catalog === "vtex";
  const liveWithMagento = live?.catalog === "magento";
  const liveWithNeomode = live?.catalog === "neomode";
  const liveCheckoutExterno = live?.checkout === "externo";

  //   const liveWithVtex =
  //   live?.condicoes?.length === 1 && live?.condicoes[0] === "VTEX";
  // const liveWithMagento =
  //   live?.condicoes?.length === 1 && live?.condicoes[0] === "MAGENTO";
  // const liveWithNeomode =
  //   live?.condicoes?.length === 1 && live?.condicoes[0] === "NEOMODE";

  let liveWithPagarme = live?.paymentService === "pagarme";
  let liveWithCorreios = live?.formaEntrega?.correios?.cepOrigem;

  const liveCheckoutInterno =
    (!live?.checkout || live?.checkout === "interno") &&
    (live?.catalog === "vtex" ||
      live?.catalog === "magento" ||
      live?.catalog === "neomode");
  //função que pega os dados e formata

  useEffect(() => {
    if (document.getElementById("main-video")) {
      setVideoDuration(
        formatTime(document.getElementById("main-video").duration)
      );
    } else {
      managerService.getRoom(live.docId).then(({ video: v }) => {
        if (v) {
          let vid = document.createElement("video");
          vid.src = v;
          vid.preload = "metadata";
          vid.onloadedmetadata = function () {
            setVideoDuration(formatTime(vid.duration));
          };
        } else {
          notification.error({
            key: "aviso",
            message: t("PRODUCT-TAB.NoMainVideo"),
            description: t("PRODUCT-TAB.NoMainVideoDescription"),
            onClick: () => notification.close("aviso"),
            duration: 10,
          });
        }
      });
    }
  }, [live.video, live.docId]);

  const formatProductFromDatabase = useCallback(
    (product, fromCatchRequest) => {
      let productFormated = {
        ...product,
        thumbnail: sourceImage(
          fromCatchRequest ? product.image : product.thumbnail
        ),
        preco:
          product.preco && !fromCatchRequest
            ? parseFloat(product.preco / 100).toFixed(2)
            : product.preco,
        precoOriginal:
          product.precoOriginal && !fromCatchRequest
            ? parseFloat(product.precoOriginal / 100).toFixed(2)
            : product.precoOriginal,
      };
      if (
        (liveWithPagarme || liveCheckoutInterno) &&
        productFormated.splitRules
      ) {
        productFormated.splitRules = product.splitRules;
        if (product.useSplitGeral) setSplitGeral(product.splitRules);
      }
      return productFormated;
    },
    [liveWithPagarme, liveCheckoutInterno]
  );

  const getData = () => {
    setVideo(live.video || localStorage.getItem(`${live.liveId}_url_video`));
    managerService
      .getProducts(live.docId)
      .then((d) => {
        if (d.length > 0) {
          let data = d.map((p) => formatProductFromDatabase(p));
          let principals = [];
          data.forEach((p) => {
            if (p.principal) {
              if (p.produtoAssinatura) setHasProductWithAssinatura(true);
              principals.push(p);
            }
          });
          setProducts(principals);
          let dataVariations = data.filter((p) => !p.principal);
          let newVariations = {};
          dataVariations.forEach((v) => {
            newVariations[v.id] = newVariations[v.id]
              ? [...newVariations[v.id], { ...v }]
              : [{ ...v }];
          });
          setVariations(newVariations);
        } else setProducts([]);
        setLoading(false);
        setSaving(false);
        setOpenModal(false);
      })
      .catch(() => {
        setLoading(false);
        setOpenModal(false);
        setSaving(false);
      });
  };

  //função para atualizar os produtos sempre que sair ou entrar na página
  useEffect(() => {
    getData();
    return () => getData();
  }, []);

  const checkIfLiveIsTabelaPreco = () =>
    liveWithTabelaPreco && tabelaPreco?.length > 0;

  useEffect(() => {
    if (checkIfLiveIsTabelaPreco() && products?.length > 0) {
      updatePriceTable(products, variations);
      setWrongProducts(false);
    }
  }, [tabelaPreco]);

  const addTabelaPrecoToProduct = (data) => {
    let product = data;
    let productTabelaPreco = tabelaPreco.filter(
      (tabela) => String(tabela.sku) === String(product.codigo)
    );
    if (productTabelaPreco.length > 0) {
      productTabelaPreco = productTabelaPreco.map(
        ({ tabela_preco, preco, precoOriginal }) => ({
          tabelaPreco: tabela_preco,
          valor: parseFloat(preco),
          valorOriginal: parseFloat(precoOriginal),
        })
      );
      product.tabelaPreco = productTabelaPreco;
    }
    return product;
  };

  //altera um dado de um produto
  const onChangeProducts = (i, field, value) => {
    let newData = products;
    if (field === "produtoAssinatura") setHasProductWithAssinatura(value);
    if (!newData[i]) {
      if (newData.length > 0) newData[i] = { id: newData[i - 1].id + 1 };
      else newData[i] = { id: 1 };
    }
    newData[i][field] = value;
    if (controlAllStock && !newData[i].controlStock)
      newData[i]["controlStock"] = true;
    if (checkIfLiveIsTabelaPreco()) {
      let productWithTabelaPreco = addTabelaPrecoToProduct(newData[i]);
      newData[i] = productWithTabelaPreco;
    }
    setProductsChanged([...productsChanged, i]);
    setProducts(
      newData.filter(
        (p) =>
          p.codigo ||
          p.tempoInicio ||
          p.tempoFim ||
          p.preco ||
          p.precoOriginal ||
          p.thumbnail
      )
    );
    setRender(!render);
    setWrongProducts(false);
  };

  //função para checar os produtos antes de salvar
  const checkErrorProduct = (p, i) => {
    return new Promise((resolve, reject) => {
      let cardsStartTimes = products
        .map(
          (prod) =>
            prod.tempoInicio &&
            prod.tempoInicio !== "" &&
            prod.id !== p.id && {
              id: prod.id,
              time: prod.tempoInicio,
            }
        )
        .filter((e) => e);
      let cardsEndTimes = products
        .map(
          (prod) =>
            prod.tempoFim &&
            prod.tempoFim !== "" &&
            prod.id !== p.id && {
              id: prod.id,
              time: prod.tempoFim,
            }
        )
        .filter((e) => e);
      let resp = false;
      let moreThanOne = false;
      if (
        !live.aoVivo &&
        !live.realtime &&
        p?.showOptions?.when === "after-appearing-on-video" &&
        (!p.tempoInicio || !p.tempoFim)
      )
        resp = t("PRODUCT-TAB.CheckErrorFulfillTimeProduct");
      if (!p.thumbnail) resp = `${t("PRODUCT-TAB.CheckErrorImage")} ${i + 1}!`;
      if (!p.descricao)
        resp = `${t("PRODUCT-TAB.CheckErrorDescription")} ${i + 1}!`;
      if (!p.codigo) resp = `${t("PRODUCT-TAB.CheckErrorCodeSKU")} ${i + 1}!`;
      if (
        (p.tempoInicio && p.tempoInicio !== "") ||
        (p.tempoFim && p.tempoFim !== "")
      ) {
        if (p.tempoInicio && (!p.tempoFim || p.tempoFim === ""))
          resp = `${t("PRODUCT-TAB.TheProduct")} ${i + 1} ${t(
            "PRODUCT-TAB.CheckErrorEntryNoExitTime"
          )}!`;
        if (p.tempoFim && (!p.tempoInicio || p.tempoInicio === ""))
          resp = `${t("PRODUCT-TAB.TheProduct")} ${i + 1} ${t(
            "PRODUCT-TAB.CheckErrorNoEntryExitTime"
          )}!`;
        if (p.tempoInicio === p.tempoFim)
          resp = `${t("PRODUCT-TAB.TheProduct")} ${i + 1} ${t(
            "PRODUCT-TAB.CheckErrorSameTime"
          )}!`;
        if (p.tempoInicio > p.tempoFim)
          resp = `${t("PRODUCT-TAB.TheProduct")} ${i + 1} ${t(
            "PRODUCT-TAB.CheckErrorInvalidExitTime"
          )}!`;
        if (p.tempoInicio && p.tempoInicio.length < 8)
          resp = `${t("PRODUCT-TAB.TheProduct")} ${i + 1} ${t(
            "PRODUCT-TAB.CheckErrorInvalidEntryTime"
          )}!`;
        if (p.tempoFim && p.tempoFim.length < 8)
          resp = `${t("PRODUCT-TAB.TheProduct")} ${i + 1} ${t(
            "PRODUCT-TAB.CheckErrorInvalidExitTime"
          )}!`;
        [...cardsStartTimes, ...cardsEndTimes].forEach((card) => {
          if (card.time === (p.tempoInicio || p.tempoFim)) {
            resp = `${t("PRODUCT-TAB.TheProducts")} ${i + 1} e ${card.id} ${t(
              "PRODUCT-TAB.CheckErrorSameTime"
            )}!`;
            moreThanOne = card.id + 1;
          }
        });
      }
      if (liveWithCorreios && !p.produtoAssinatura) {
        if (!p.medidas)
          resp = `${t("PRODUCT-TAB.TheProduct")} ${i + 1} ${t(
            "PRODUCT-TAB.CheckErrorNoMeasures"
          )}`;
        else {
          let { peso, altura, largura, comprimento } = p.medidas;
          if (!peso || peso === "" || peso === 0)
            resp = `${t("PRODUCT-TAB.TheProduct")} ${i + 1} ${t(
              "PRODUCT-TAB.CheckErrorInvalidMeasureWeight"
            )}!`;
          if (!comprimento || comprimento === "" || comprimento === 0)
            resp = `${t("PRODUCT-TAB.TheProduct")} ${i + 1} ${t(
              "PRODUCT-TAB.CheckErrorInvalidMeasureLength"
            )}!`;
          if (!largura || largura === "" || largura === 0)
            resp = `${t("PRODUCT-TAB.TheProduct")} ${i + 1} ${t(
              "PRODUCT-TAB.CheckErrorInvalidMeasureWidth"
            )}!`;
          if (!altura || altura === "" || altura === 0)
            resp = `${t("PRODUCT-TAB.TheProduct")} ${i + 1} ${t(
              "PRODUCT-TAB.CheckErrorInvalidMeasureHeight"
            )}!`;
        }
      }
      if (liveWithTabelaPreco) {
        if (!p.tabelaPreco || (p.tabelaPreco && p.tabelaPreco.length === 0))
          resp = `${t("PRODUCT-TAB.TheProduct")} ${i + 1} ${t(
            "PRODUCT-TAB.CheckErrorNoPriceTable"
          )}`;
      } else {
        if (!p.preco) resp = `${t("PRODUCT-TAB.CheckErrorNoPrice")} ${i + 1}!`;
      }

      if (resp) {
        if (!moreThanOne) setWrongProducts([i + 1]);
        else setWrongProducts([i + 1, moreThanOne - 1]);
      }
      if (resp) resolve(resp);
      else reject(resp);
    });
  };

  const feedbackSaveProducts = useCallback((success, errorBackend) => {
    if (success) {
      notification.success({
        key: "aviso",
        message: t("PRODUCT-TAB.SaveProductSuccess"),
        description: t("PRODUCT-TAB.SaveProductSuccessDescription"),
        onClick: () => notification.close("aviso"),
        duration: 10,
      });
    } else {
      setSaving(false);
      notification.error({
        key: "aviso",
        message: t("PRODUCT-TAB.SaveProductError"),
        description:
          errorBackend || t("PRODUCT-TAB.SaveProductErrorDescription"),
        onClick: () => notification.close("aviso"),
        duration: 10,
      });
    }
  }, []);

  const atualizeLocalProductFromNewProduct = useCallback(
    (newProduct) => {
      if (newProduct.principal) {
        let newProducts = products.map((product) => {
          if (product.id === newProduct.id)
            return formatProductFromDatabase(newProduct);
          else return product;
        });
        setProducts(newProducts);
      } else {
        let newVariations = variations;
        let arrayOfTheVariations = variations[newProduct.id];
        arrayOfTheVariations = arrayOfTheVariations.map((variation) => {
          if (variation.codigo === newProduct.codigo)
            return formatProductFromDatabase(newProduct);
          else return variation;
        });
        newVariations[newProduct.id] = arrayOfTheVariations;
        setVariations(newVariations);
      }
    },
    [products, variations, formatProductFromDatabase]
  );

  const handleSaveProduct = async (product, index) => {
    checkErrorProduct(product, index)
      .then((error) =>
        notification.error({
          key: "aviso",
          message: t("PRODUCT-TAB.FieldsRequired"),
          description: error,
          onClick: () => notification.close("aviso"),
          duration: 10,
        })
      )
      .catch(async () => {
        setSavingProductsList([...savingProductsList, product.id]);
        let thisProductVariations = variations && variations[product.id];
        if (thisProductVariations) {
          thisProductVariations = thisProductVariations.map((variation) => {
            let variationData = {
              ...variation,
              principal: false,
              id: product.id,
              preco: parseInt(
                parseFloat(variation.preco).toFixed(2).replace(".", "")
              ),
              precoOriginal: parseInt(
                parseFloat(variation.precoOriginal).toFixed(2).replace(".", "")
              ),
            };
            if (
              variation.imageName &&
              variation.thumbnail.includes("base64,")
            ) {
              variationData.image = variation.thumbnail.split("base64,")[1];
              delete variationData.thumbnail;
            }
            return variationData;
          });
        }
        if (product.imageName && product.thumbnail.includes("base64,")) {
          product.image = product.thumbnail.split("base64,")[1];
          delete product.thumbnail;
        }
        if (liveWithPagarme || liveCheckoutInterno) {
          let splitRules = splitGeral;
          let useSplitGeral = true;
          if (product.useSplitGeral === false && product.splitRules) {
            splitRules = product.splitRules;
            useSplitGeral = false;
          }

          product.splitRules = splitRules;
          product.useSplitGeral = useSplitGeral;
          if (thisProductVariations)
            thisProductVariations = thisProductVariations.map((variation) => ({
              ...variation,
              splitRules,
              useSplitGeral,
            }));
        }

        product.preco = parseInt(
          parseFloat(product.preco).toFixed(2).replace(".", "")
        );

        product.precoOriginal = parseInt(
          parseFloat(product.precoOriginal).toFixed(2).replace(".", "")
        );
        product.quantidadeMaximaVendaProduto = parseInt(
          product.quantidadeMaximaVendaProduto
        );
        product.principal = true;
        product.tempoInicio = product.tempoInicio ? product.tempoInicio : "";
        product.tempoFim = product.tempoFim ? product.tempoFim : "";
        if (product.controlStock) {
          product.esgotado =
            product.stock.reduce((a, b) => a + b, 0) === 0 ? true : false;
        }
        let listProductsNeedSave = [product];

        if (thisProductVariations)
          listProductsNeedSave = [
            ...listProductsNeedSave,
            ...thisProductVariations,
          ];
        await Promise.all(
          listProductsNeedSave.map((productNeedSave) =>
            managerService
              .saveProduct(live.docId, productNeedSave)
              .then(({ produto }) =>
                atualizeLocalProductFromNewProduct(produto)
              )
          )
        )
          .then(() => {
            setProductsChanged(productsChanged.filter((x) => x !== index));
            feedbackSaveProducts(true);
          })
          .catch((e) => {
            atualizeLocalProductFromNewProduct(
              formatProductFromDatabase(product, true)
            );
            feedbackSaveProducts(false, e);
          });
        setSavingProductsList(
          savingProductsList.filter((savingId) => savingId !== product.id)
        );
      });
  };

  const deleteProduct = async () => {
    setDeletingProduct(true);
    setOpenModal(false);
    let del = deleteProductModal;
    if (del.docId) {
      let delVariations = [];
      if (variations && variations[del.id]?.length > 0)
        delVariations = variations[del.id].filter((v) => v.docId);
      if (delVariations.length > 0)
        Promise.all(
          delVariations.map(
            async (v, i) =>
              await managerService.deleteProduct(live.docId, v.docId)
          )
        )
          .then(() => deletePrincipalProduct(del.docId, del.id))
          .catch(() => {
            notification.error({
              key: "aviso-del-prod-var",
              message: t("PRODUCT-TAB.DeleteError"),
              description: t("PRODUCT-TAB.DeleteVariationErrorDescription"),
              onClick: () => notification.close("aviso-del-prod-var"),
              duration: 10,
            });
          });
      else deletePrincipalProduct(del.docId, del.id);
    } else deleteProductsFromState(del.id, del.id);
  };

  const deletePrincipalProduct = (productDocId, id) => {
    managerService
      .deleteProduct(live.docId, productDocId)
      .then(() => {
        notification.success({
          key: "aviso-del-prod",
          message: t("PRODUCT-TAB.DeleteSuccess"),
          onClick: () => notification.close("aviso-del-prod"),
          duration: 10,
        });
        deleteProductsFromState(id);
      })
      .catch(() => {
        notification.error({
          key: "aviso-del-prod-var",
          message: t("PRODUCT-TAB.DeleteError"),
          description: t("PRODUCT-TAB.DeleteErrorDescription"),
          onClick: () => notification.close("aviso-del-prod-var"),
          duration: 10,
        });
      });
  };

  const deleteProductsFromState = useCallback(
    (id) => {
      let newVariations = variations;
      if (newVariations[id]) delete newVariations[id];
      let newProducts = products.filter((product) => product.id !== id);
      setProducts(newProducts);
      setVariations(newVariations);
      setDeleteProductModal(false);
      setDeletingProduct(false);
    },
    [products, variations]
  );

  const handleAddVtexProductsToProducts = (vtexProducts) => {
    //add produto principal
    if (openCatalog === 1) {
      let newProducts = [...products];
      let lastId = newProducts[newProducts.length - 1]?.id || 0;
      vtexProducts.forEach((row, i) => {
        let productToAdd = {
          vtexData: row,
          codigo: row.skus[0],
          descricao: row.produtoNome,
          id: lastId + 1,
          preco: parseFloat(row.preco).toFixed(2),
          precoOriginal: parseFloat(row.precoOriginal).toFixed(2),
          principal: true,
          skus: row.skus,
          tamanhos: row.tamanhos,
          thumbnail: row.imagemUrl,
          tipoGrade: "aberta",
          sellers: row.sellers,
        };
        if (checkIfLiveIsTabelaPreco()) {
          productToAdd = addTabelaPrecoToProduct(productToAdd);
        }
        newProducts.push(productToAdd);
        lastId++;
      });
      setProducts(newProducts);
      setOpenCatalog(false);
      message.success(t("PRODUCT-TAB.ProductAdded"));
    }
    //add variacao ao porduto principal
    else if (openCatalog === 2) {
      let newVariations = variations;
      let productId =
        products && products[variationCatalogIndex]?.id
          ? products[variationCatalogIndex].id
          : 1;
      let dataVariations = [];
      vtexProducts.forEach((row) => {
        let variationToAdd = {
          vtexData: row,
          codigo: row.skus[0],
          descricao: row.produtoNome,
          id: productId,
          preco: parseFloat(row.preco).toFixed(2),
          precoOriginal: parseFloat(row.precoOriginal).toFixed(2),
          principal: false,
          skus: row.skus,
          tamanhos: row.tamanhos,
          thumbnail: row.imagemUrl,
          tipoGrade: "aberta",
          new: true,
        };
        if (checkIfLiveIsTabelaPreco()) {
          variationToAdd = addTabelaPrecoToProduct(variationToAdd);
        }
        dataVariations.push(variationToAdd);
      });
      newVariations[productId] = dataVariations;
      setVariations(newVariations);
      setOpenCatalog(false);
      message.success(t("PRODUCT-TAB.VariationsAdded"));
    }
  };

  const handleAddMagentoProductsToProducts = (magentoProducts) => {
    //add produto principal
    if (openCatalog === 1) {
      let newProducts = [...products];
      let lastId = newProducts[newProducts.length - 1]?.id || 0;
      magentoProducts.forEach((row, i) => {
        let productToAdd = {
          magentoData: row,
          codigo: row.skus[0],
          descricao: row.produtoNome,
          id: lastId + 1,
          preco: parseFloat(row.preco).toFixed(2),
          precoOriginal: parseFloat(row.precoOriginal).toFixed(2),
          principal: true,
          skus: row.skus,
          tamanhos: row.tamanhos,
          thumbnail: row.imagemUrl,
          tipoGrade: "aberta",
        };
        if (checkIfLiveIsTabelaPreco()) {
          productToAdd = addTabelaPrecoToProduct(productToAdd);
        }
        newProducts.push(productToAdd);
        lastId++;
      });
      setProducts(newProducts);
      setOpenCatalog(false);
      message.success(t("PRODUCT-TAB.ProductAdded"));
    }
    //add variacao ao porduto principal
    else if (openCatalog === 2) {
      let newVariations = variations;
      let productId =
        products && products[variationCatalogIndex]?.id
          ? products[variationCatalogIndex].id
          : 1;
      let dataVariations = [];
      magentoProducts.forEach((row) => {
        let variationToAdd = {
          magentoData: row,
          codigo: row.skus[0],
          descricao: row.produtoNome,
          id: productId,
          preco: parseFloat(row.preco).toFixed(2),
          precoOriginal: parseFloat(row.precoOriginal).toFixed(2),
          principal: false,
          skus: row.skus,
          tamanhos: row.tamanhos,
          thumbnail: row.imagemUrl,
          tipoGrade: "aberta",
          new: true,
        };
        if (checkIfLiveIsTabelaPreco()) {
          variationToAdd = addTabelaPrecoToProduct(variationToAdd);
        }
        dataVariations.push(variationToAdd);
      });
      newVariations[productId] = dataVariations;
      setVariations(newVariations);
      setOpenCatalog(false);
      message.success(t("PRODUCT-TAB.VariationsAdded"));
    }
  };

  const handleAddNeomodeProductsToProducts = (neomodeProducts) => {
    //add produto principal
    if (openCatalog === 1) {
      let newProducts = [...products];
      let lastId = newProducts[newProducts.length - 1]?.id || 0;
      neomodeProducts.forEach((row, i) => {
        let productToAdd = {
          neomodeData: row,
          codigo: row.skus[0],
          descricao: row.produtoNome,
          id: lastId + 1,
          preco: parseFloat(row.preco).toFixed(2),
          precoOriginal: parseFloat(row.precoOriginal).toFixed(2),
          principal: true,
          skus: row.skus,
          tamanhos: row.tamanhos,
          thumbnail: row.imagemUrl,
          tipoGrade: "aberta",
        };
        if (checkIfLiveIsTabelaPreco()) {
          newProducts = addTabelaPrecoToProduct(newProducts);
        }
        newProducts.push(productToAdd);
        lastId++;
      });
      setProducts(newProducts);
      setOpenCatalog(false);
      message.success(t("PRODUCT-TAB.ProductAdded"));
    }
    //add variacao ao porduto principal
    else if (openCatalog === 2) {
      let newVariations = variations;
      let productId =
        products && products[variationCatalogIndex]?.id
          ? products[variationCatalogIndex].id
          : 1;
      let dataVariations = [];
      neomodeProducts.forEach((row) => {
        let variationToAdd = {
          neomodeData: row,
          codigo: row.skus[0],
          descricao: row.produtoNome,
          id: productId,
          preco: parseFloat(row.preco).toFixed(2),
          precoOriginal: parseFloat(row.precoOriginal).toFixed(2),
          principal: false,
          skus: row.skus,
          tamanhos: row.tamanhos,
          thumbnail: row.imagemUrl,
          tipoGrade: "aberta",
          new: true,
        };
        if (checkIfLiveIsTabelaPreco()) {
          variationToAdd = addTabelaPrecoToProduct(variationToAdd);
        }
        dataVariations.push(variationToAdd);
      });
      newVariations[productId] = dataVariations;
      setVariations(newVariations);
      setOpenCatalog(false);
      message.success(t("PRODUCT-TAB.VariationsAdded"));
    }
  };
  const handleAddXmlProductsToProducts = (xmlProducts) => {
    //add produto principal
    if (openCatalogXml) {
      let newProducts = [...products];
      let lastId = newProducts[newProducts.length - 1]?.id || 0;
      xmlProducts.forEach((row, i) => {
        let productToAdd = {
          ...row,
          id: lastId + 1,
        };

        newProducts.push(productToAdd);
        lastId++;
      });
      setProducts(newProducts);
      setOpenCatalogXml(false);
      message.success(t("PRODUCT-TAB.ProductAdded"));
    }
  };

  const handleNewDataCsv = (updatedProducts, updatedVariations) => {
    let newProducts = updatedProducts.filter(
      (p) =>
        p.codigo ||
        p.tempoInicio ||
        p.tempoFim ||
        p.preco ||
        p.precoOriginal ||
        p.thumbnail
    );

    if (!liveWithTabelaPreco) setProducts(newProducts);
    if (!liveWithTabelaPreco) setVariations({ ...updatedVariations });

    if (checkIfLiveIsTabelaPreco())
      updatePriceTable(newProducts, updatedVariations);
    else if (liveWithTabelaPreco && !tabelaPreco) setProducts(newProducts);

    let tmpChanged = [];
    updatedProducts.map((_p, i) => tmpChanged.push(i));
    setProductsChanged(tmpChanged);

    if (liveWithTabelaPreco && !tabelaPreco)
      setVariations({ ...updatedVariations });
    else if (
      liveWithTabelaPreco &&
      !tabelaPreco &&
      Object.entries(updatedVariations).length > 0
    )
      setVariations({ ...updatedVariations });
  };

  const handleNewDataXml = (updatedProducts) => {
    updatedProducts.filter(
      (p) =>
        p.codigo ||
        p.tempoInicio ||
        p.tempoFim ||
        p.preco ||
        p.precoOriginal ||
        p.thumbnail
    );
    setProductsXml(updatedProducts);
  };

  const updatePriceTable = (productsToUpdate, variationsToUpdate) => {
    let tmp = [...productsToUpdate];
    tmp.forEach((data, i) => {
      let productTabelaPreco = tabelaPreco.filter(
        (tabela) => String(tabela.sku) === String(data.codigo)
      );
      if (productTabelaPreco.length > 0) {
        productTabelaPreco = productTabelaPreco.map(
          ({ tabela_preco, preco }) => ({
            tabelaPreco: tabela_preco,
            valor: parseFloat(preco),
          })
        );
        tmp[i].tabelaPreco = productTabelaPreco;
      }
    });
    setProducts(tmp);

    updateVariationsPriceTable(variationsToUpdate);
  };

  const updateVariationsPriceTable = (variationsToUpdate) => {
    if (typeof variationsToUpdate === "object") {
      let tmp = { ...variationsToUpdate };
      Object.entries(tmp).forEach((variation, idx) => {
        if (variation[1]) {
          if (variation[1].length > 1) {
            variation[1].forEach((_vari, idx) => {
              let productTabelaPreco = tabelaPreco.filter(
                (tabela) =>
                  String(tabela.sku) === String(variation[1][idx].codigo)
              );
              if (productTabelaPreco.length > 0) {
                productTabelaPreco = productTabelaPreco.map(
                  ({ tabela_preco, preco }) => ({
                    tabelaPreco: tabela_preco,
                    valor: parseFloat(preco),
                  })
                );
                variation[1][idx].tabelaPreco = productTabelaPreco;
              }
              productTabelaPreco = [];
            });
          } else if (variation[1][0]) {
            let productTabelaPreco = tabelaPreco.filter(
              (tabela) => String(tabela.sku) === String(variation[1][0].codigo)
            );
            if (productTabelaPreco.length > 0) {
              productTabelaPreco = productTabelaPreco.map(
                ({ tabela_preco, preco }) => ({
                  tabelaPreco: tabela_preco,
                  valor: parseFloat(preco),
                })
              );
              variation[1][0].tabelaPreco = productTabelaPreco;
            }
          }
        }
      });
      setVariations(tmp);
    } else {
      if (variationsToUpdate?.length > 0) {
        let tmp = [...variationsToUpdate];
        tmp.forEach((variacoes, idx) => {
          let productTabelaPreco = tabelaPreco.filter(
            (tabela) => String(tabela.sku) === String(variacoes[idx].codigo)
          );
          if (productTabelaPreco.length > 0) {
            productTabelaPreco = productTabelaPreco.map(
              ({ tabela_preco, preco }) => ({
                tabelaPreco: tabela_preco,
                valor: parseFloat(preco),
              })
            );
            variacoes[idx].tabelaPreco = productTabelaPreco;
          }
        });
        setVariations(tmp);
      }
    }
  };

  const handleControlAllStock = (confirm) => {
    if (confirm) {
      let tmpProd = [...products];
      let tmpVariation = { ...variations };
      tmpProd.forEach((prod, idx) => {
        if (prod.tipoGrade)
          tmpProd[idx] = { ...tmpProd[idx], controlStock: true };
      });

      Object.entries(tmpVariation).forEach((variation, idx) => {
        if (variation)
          variation[idx] = { ...variation[idx], controlStock: true };
      });

      setProducts(tmpProd);
      setVariations(tmpVariation);
    }
  };

  const checkInvalidValues = (type, index) => {
    if (type === "codigo" && !products[index]?.codigo)
      setInvalidValues([...invalidValues, `${type}-${index}`]);
    else if (
      type === "preco" &&
      !products[index]?.preco &&
      products[index]?.preco !== 0
    )
      setInvalidValues([...invalidValues, `${type}-${index}`]);
  };

  const putShowAllStock = (showAllStock) => {
    managerService
      .showStockOnLive(live.docId, { showStockOnLive: showAllStock })
      .then((message) => {
        setShowAllStock(showAllStock);
      })
      .catch((e) => {});
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ textAlign: "center", color: "gray" }}>
        {t("PRODUCT-TAB.FulfillInformation")}{" "}
        {live?.docId ? t("PRODUCT-TAB.Edit") : t("PRODUCT-TAB.Create")}{" "}
        {t("PRODUCT-TAB.YourLive")}
        <br />
        {t("PRODUCT-TAB.FieldsWith")} <b> * </b> {t("PRODUCT-TAB.AreRequired")}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "center",
        }}
        className="fade-in"
      >
        {openModal && (
          <ModalProduct
            liveWithCorreios={liveWithCorreios}
            open={openModal}
            prod={products[prodSelected]}
            liveWithVtex={liveWithVtex}
            liveWithMagento={liveWithMagento}
            liveWithNeomode={liveWithNeomode}
            liveCheckoutExterno={liveCheckoutExterno}
            liveWithAssinatura={liveWithAssinatura}
            liveWithTabelaPreco={liveWithTabelaPreco}
            alreadyHasAssinatura={hasProductWithAssinatura}
            onClose={() => {
              setOpenModal(false);
              setProdSelected(false);
            }}
            disabled={
              (liveWithVtex && live?.catalog === "vtex") ||
              (liveWithMagento && live?.catalog === "magento") ||
              (liveWithNeomode && live?.catalog === "neomode")
            }
            deleteProd={() => deleteProduct()}
            catalog={live?.catalog}
            onFinish={(data) => {
              Object.entries(data).map(([key, value]) =>
                onChangeProducts(prodSelected, key, value)
              );
              setOpenModal(false);
              setProdSelected(false);
            }}
            videoDuration={videoDuration}
            aoVivo={live.aoVivo}
            realtime={live.realtime}
            externalCheckout={live.checkout === "externo"}
          />
        )}
        <ModalUploadXml
          open={openModalXml}
          onCancel={setOpenModalXml}
          handleUpdateXml={handleNewDataXml}
        />

        <XmlCatalog
          open={openCatalogXml}
          onCancel={setOpenCatalogXml}
          onFinish={handleAddXmlProductsToProducts}
          products={products}
          catalog={productsXml}
          onClose={() => setOpenCatalogXml(false)}
          unidadeMonetaria={live?.unidadeMonetaria}
        />
        {openUploadProduct && (
          <ModalUploadCsv
            liveId={live.docId}
            open={openUploadProduct}
            setOpen={setOpenUploadProduct}
            handleUploadedCsv={handleNewDataCsv}
            alreadyHasProducts={
              products?.length > 0 && products.filter((p) => p.docId).length > 0
            }
            clearProducts={() => {
              setVariations({});
              setProducts([]);
              setProductsChanged([]);
            }}
          />
        )}
        {deleteProductModal && (
          <Modal
            title={t("PRODUCT-TAB.DeleteProductVariation")}
            visible={deleteProductModal}
            onOk={() => deleteProduct()}
            onCancel={() => !deletingProduct && setDeleteProductModal(false)}
            okText={t("PRODUCT-TAB.Delete")}
            cancelText={t("PRODUCT-TAB.Back")}
            cancelButtonProps={{ disabled: deletingProduct }}
            okButtonProps={{ danger: true, loading: deletingProduct }}
          >
            {t("PRODUCT-TAB.DeleteConfirm")}
          </Modal>
        )}
        {(liveWithVtex || live?.catalog === "vtex") && (
          <VtexCatalog
            open={openCatalog}
            products={products}
            onFinish={handleAddVtexProductsToProducts}
            onClose={() => setOpenCatalog(false)}
            unidadeMonetaria={live?.unidadeMonetaria}
          />
        )}
        {(liveWithMagento || live?.catalog === "magento") && (
          <MagentoCatalog
            open={openCatalog}
            products={products}
            onFinish={handleAddMagentoProductsToProducts}
            onClose={() => setOpenCatalog(false)}
            unidadeMonetaria={live?.unidadeMonetaria}
          />
        )}
        {(liveWithNeomode || live?.catalog === "neomode") && (
          <NeomodeCatalog
            open={openCatalog}
            products={products}
            onFinish={handleAddNeomodeProductsToProducts}
            onClose={() => setOpenCatalog(false)}
            unidadeMonetaria={live?.unidadeMonetaria}
          />
        )}
        {liveWithTabelaPreco && (
          <PriceTableModal
            visible={openPriceTableModal}
            onOk={(newTabelaPreco) => {
              setTabelaPreco(newTabelaPreco);
              setOpenPriceTableModal(false);
            }}
            onCancel={() => setOpenPriceTableModal(false)}
            tabelaPreco={tabelaPreco}
            unidadeMonetaria={live?.unidadeMonetaria}
          />
        )}
        {!aoVivo && (
          <Card
            title={
              <>
                <PlaySquareOutlined style={{ marginRight: "10px" }} />
                {t("PRODUCT-TAB.MainVideo")}
              </>
            }
            style={{
              width: video ? "744px" : "400px",
              margin: "5px",
            }}
            className="shadow"
          >
            {video && (
              <TabsVideo
                url={video}
                aoVivo={aoVivo}
                videoId={"video_products"}
                style={{ width: "100%", maxHeight: "425px" }}
              />
            )}
          </Card>
        )}
        {splitModal ? (
          typeof splitModal === "object" ? (
            <ModalProductSplit
              visible={splitModal}
              onCancel={() => setSplitModal(false)}
              splitGeral={splitGeral}
              unidadeMonetaria={live?.unidadeMonetaria}
              onOk={(newSplit, useSplitGeral) => {
                setProducts(
                  products.map((p) => ({
                    ...p,
                    splitRules:
                      p === splitModal ? newSplit : p.splitRules || [],
                    useSplitGeral:
                      p === splitModal
                        ? useSplitGeral
                        : p.useSplitGeral || false,
                  }))
                );
                setSplitModal(false);
              }}
            />
          ) : (
            <ModalSplitGeral
              visible={splitModal}
              splitGeral={splitGeral}
              onOk={(newSplitGeral) => {
                setSplitGeral(newSplitGeral);
                setSplitModal(false);
              }}
              onCancel={() => setSplitModal(false)}
            />
          )
        ) : (
          <></>
        )}
        <Card
          title={
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <UnorderedListOutlined style={{ marginRight: "10px" }} />
                  {t("PRODUCT-TAB.ProductList")}
                  {liveWithVtex ||
                    (live?.catalog === "vtex" && (
                      <p style={{ fontSize: "11px", margin: 0 }}>
                        {t("PRODUCT-TAB.OpenCatalogDescription")}
                      </p>
                    ))}
                </div>
                {(liveWithVtex ||
                  liveWithMagento ||
                  liveWithNeomode ||
                  ["vtex", "magento", "neomode"].includes(live.catalog)) && (
                  <Button type="primary" onClick={() => setOpenCatalog(1)}>
                    {t("PRODUCT-TAB.OpenCatalog")}
                  </Button>
                )}
                {live.checkout === "externo" && (
                  <Button
                    type="primary"
                    onClick={() => setOpenCatalogXml(true)}
                  >
                    {t("PRODUCT-TAB.OpenCatalog")}
                  </Button>
                )}
                {liveWithTabelaPreco && (
                  <Button
                    type="primary"
                    onClick={() => setOpenPriceTableModal(!openPriceTableModal)}
                  >
                    {t("PRODUCT-TAB.PriceTable")}
                  </Button>
                )}
                {liveWithPagarme && (
                  <Button type="primary" onClick={() => setSplitModal(true)}>
                    <DollarOutlined />
                    {t("PRODUCT-TAB.GeneralSplit")}
                  </Button>
                )}
              </div>
              {!liveWithVtex &&
                !liveWithMagento &&
                !liveWithNeomode &&
                !["vtex", "magento", "neomode"].includes(live.catalog) &&
                !liveWithTabelaPreco && (
                  <div>
                    <Checkbox
                      style={{ margin: "0 10px 0 0", fontWeight: "normal" }}
                      checked={controlAllStock}
                      onChange={() => {
                        handleControlAllStock(!controlAllStock);
                        setControlAllStock(!controlAllStock);
                      }}
                    >
                      {t("PRODUCT-TAB.ControlAllProductsStock")}
                    </Checkbox>
                    {controlAllStock && (
                      <Checkbox
                        style={{ margin: "0 10px 0 0", fontWeight: "normal" }}
                        checked={showAllStock}
                        onChange={() => {
                          if (controlAllStock === true)
                            putShowAllStock(!showAllStock);
                        }}
                      >
                        {t("PRODUCT-TAB.ShowAllProductsStock")}
                      </Checkbox>
                    )}
                  </div>
                )}
            </>
          }
          bodyStyle={{ padding: "0px" }}
          style={{ margin: "5px" }}
          className="shadow"
        >
          <Scrollbars
            style={{ width: 744, height: 462 }}
            renderThumbHorizontal={() => <div style={{ display: "none" }} />}
          >
            <div style={{ margin: "0 24px" }}>
              {[
                ...products,
                !liveWithVtex && !liveWithNeomode && !liveWithMagento && {},
              ].map((item, i) => (
                <div key={i}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: Object.entries(item).length > 0 ? "40px" : "20px",
                      borderTop: "1px solid #f0f0f0",
                    }}
                  >
                    {Object.entries(item).length > 0 && (
                      <>
                        {wrongProducts && wrongProducts.includes(i + 1) && (
                          <s.WarningContainer className="fade-in" error>
                            <WarningOutlined />
                          </s.WarningContainer>
                        )}
                        <label>
                          {t("PRODUCT-TAB.Product")} {i + 1}
                          {item.produtoAssinatura &&
                            ` / ${t("PRODUCT-TAB.Subscription")}`}
                        </label>
                        {productsChanged.includes(i) && (
                          <div
                            className="fade-in"
                            style={{ display: "flex", marginLeft: 10 }}
                          >
                            <s.WarningContainer>
                              <WarningOutlined />
                            </s.WarningContainer>
                            <i>{t("PRODUCT-TAB.UnsavedChanges")}</i>
                          </div>
                        )}
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => handleSaveProduct(item, i)}
                          style={{ margin: "0 10px 0 auto" }}
                          loading={savingProductsList?.includes(item.id)}
                        >
                          {t("PRODUCT-TAB.Save")}
                        </Button>
                        <Button
                          danger
                          onClick={() => setDeleteProductModal(item)}
                          type="primary"
                          size="small"
                          disabled={savingProductsList?.includes(item.id)}
                        >
                          <DeleteOutlined />
                        </Button>
                      </>
                    )}
                  </div>
                  <s.Prod>
                    <article style={{ maxWidth: "145px" }}>
                      <div style={{ marginBottom: 12 }}>
                        <label>{t("PRODUCT-TAB.CodeSKU")} *</label>
                        <Input
                          placeholder={t("PRODUCT-TAB.CodeSKUPlaceholder")}
                          onBlur={() => checkInvalidValues("codigo", i)}
                          onChange={({ target: t }) => {
                            if (invalidValues.includes(`codigo-${i}`))
                              setInvalidValues(
                                invalidValues.filter((x) => x !== `codigo-${i}`)
                              );
                            onChangeProducts(i, "codigo", t.value);
                          }}
                          style={{
                            border:
                              invalidValues.includes(`codigo-${i}`) &&
                              "1px solid red",
                          }}
                          value={item.codigo}
                          disabled={
                            (liveWithVtex && live?.catalog === "vtex") ||
                            (liveWithMagento && live?.catalog === "magento") ||
                            (liveWithNeomode && live?.catalog === "neomode") ||
                            savingProductsList?.includes(item.id)
                          }
                        />
                        {invalidValues.includes(`codigo-${i}`) && (
                          <s.InvalidLabel className="fade-in">
                            {t("PRODUCT-TAB.CodeSKURequired")}
                          </s.InvalidLabel>
                        )}
                      </div>
                      <div style={{ marginBottom: 12 }}>
                        <label
                          style={{
                            color:
                              item.tempoInicio && item.tempoInicio.length < 8
                                ? "red"
                                : "black",
                          }}
                        >
                          {t("PRODUCT-TAB.EntryTime")}
                        </label>
                        <Input
                          placeholder="00:00:00"
                          onChange={({ target: t }) =>
                            handleChangeTime(
                              t.value,
                              item.tempoInicio || "",
                              (v) => onChangeProducts(i, "tempoInicio", v)
                            )
                          }
                          disabled={savingProductsList?.includes(item.id)}
                          value={item.tempoInicio || ""}
                          addonAfter={
                            <VideoCameraOutlined
                              title={t("PRODUCT-TAB.UseVideoTime")}
                              onClick={() => {
                                let e =
                                  document.getElementById("video_products");
                                if (e && e.currentTime) {
                                  let v = new Date(e.currentTime * 1000)
                                    .toISOString()
                                    .substring(11, 19);
                                  onChangeProducts(i, "tempoInicio", v);
                                }
                              }}
                            />
                          }
                        />
                      </div>
                      <div>
                        <label
                          style={{
                            color:
                              item.tempoFim && item.tempoFim.length < 8
                                ? "red"
                                : "black",
                          }}
                        >
                          {t("PRODUCT-TAB.ExitTime")}
                        </label>
                        <Input
                          placeholder="00:00:00"
                          onChange={({ target: t }) =>
                            handleChangeTime(
                              t.value,
                              item.tempoFim || "",
                              (v) => onChangeProducts(i, "tempoFim", v)
                            )
                          }
                          disabled={savingProductsList?.includes(item.id)}
                          value={item.tempoFim || ""}
                          addonAfter={
                            <VideoCameraOutlined
                              title={t("PRODUCT-TAB.UseVideoTime")}
                              onClick={() => {
                                let e =
                                  document.getElementById("video_products");
                                if (e && e.currentTime) {
                                  let v = new Date(e.currentTime * 1000)
                                    .toISOString()
                                    .substring(11, 19);
                                  onChangeProducts(i, "tempoFim", v);
                                }
                              }}
                            />
                          }
                        />
                      </div>
                    </article>
                    <article style={{ height: "186px" }}>
                      <label>{t("PRODUCT-TAB.Image")} (200x180) *</label>
                      <DropInput
                        i={i}
                        onChangeProducts={(base64, imageName) => {
                          onChangeProducts(i, "thumbnail", base64);
                          onChangeProducts(i, "imageName", imageName);
                          setRender(!render);
                        }}
                        thumbnail={sourceImage(item.thumbnail)}
                        disabled={
                          (liveWithVtex && live?.catalog === "vtex") ||
                          (liveWithMagento && live?.catalog === "magento") ||
                          (liveWithNeomode && live?.catalog === "neomode") ||
                          savingProductsList?.includes(item.id)
                        }
                      />
                      <div style={{ display: "flex" }}>
                        <Button
                          disabled={
                            (loading && live.checkout !== "externo") ||
                            saving ||
                            (live.checkout !== "externo" &&
                              Object.entries(item).length === 0) ||
                            savingProductsList?.includes(item.id)
                          }
                          type="primary"
                          onClick={() => {
                            setOpenModal(true);
                            setProdSelected(i);
                          }}
                          style={{
                            width: !liveWithPagarme && "100%",
                            marginTop: 10,
                          }}
                        >
                          {t("PRODUCT-TAB.Product")}
                          <FormOutlined />
                        </Button>
                        {liveWithPagarme && (
                          <Button
                            style={{ padding: "4px 11px", marginTop: 10 }}
                            onClick={() => setSplitModal(item)}
                            disabled={savingProductsList?.includes(item.id)}
                          >
                            <DollarOutlined />
                          </Button>
                        )}
                      </div>
                    </article>
                    <article style={{ width: "100px" }}>
                      <div style={{ display: "grid" }}>
                        <label>{t("PRODUCT-TAB.Card")}</label>
                        {item.thumbnail ? (
                          <s.Card
                            className="shadow2"
                            corSecundaria={corSecundaria}
                          >
                            <img
                              alt="thumbnail-card"
                              src={sourceImage(item.thumbnail)}
                              id={item.thumbnail}
                            />
                            <section>
                              <img
                                src={"/assets/icons/shoppingFilled.svg"}
                                alt="shopping-icon-card"
                              />
                            </section>
                          </s.Card>
                        ) : (
                          <Skeleton.Image
                            style={{
                              ...s.imgProdStyle,
                              border: "1px solid #d9d9d9",
                              width: "100px",
                              height: "80px",
                            }}
                            active
                          ></Skeleton.Image>
                        )}
                      </div>
                      <div style={{ marginTop: 30 }}>
                        {/* {!liveWithTabelaPreco ? (
                          <>
                            <label>{t("PRODUCT-TAB.Price")} *</label>
                            <Input
                              type="number"
                              min={0}
                              onPaste={(e) => preventInvalidNumberOnPaste(e)}
                              onKeyDown={(e) =>
                                preventInvalidNumberOnKeyDown(e)
                              }
                              onBlur={() => checkInvalidValues("preco", i)}
                              placeholder="00,00"
                              onChange={({ target: t }) => {
                                if (invalidValues.includes(`preco-${i}`))
                                  setInvalidValues(
                                    invalidValues.filter(
                                      (x) => x !== `preco-${i}`
                                    )
                                  );
                                if (t.value >= 0 && !isNaN(t.value)) {
                                  onChangeProducts(
                                    i,
                                    "preco",
                                    parseFloat(t.value)
                                  );
                                }
                              }}
                              style={{
                                border:
                                  invalidValues.includes(`preco-${i}`) &&
                                  "1px solid red",
                              }}
                              value={item.preco}
                              disabled={
                                (liveWithVtex && live?.catalog === "vtex") ||
                                (liveWithMagento &&
                                  live?.catalog === "magento") ||
                                savingProductsList?.includes(item.id)
                              }
                            />
                            {invalidValues.includes(`preco-${i}`) && (
                              <s.InvalidLabel className="fade-in">
                                {t("PRODUCT-TAB.PriceRequired")}
                              </s.InvalidLabel>
                            )}
                          </>
                        ) : (
                          <>
                            Preço Original
                            <label>{t("PRODUCT-TAB.PriceOriginal")} *</label>
                            <Input
                              type="number"
                              min={0}
                              onPaste={(e) => preventInvalidNumberOnPaste(e)}
                              onKeyDown={(e) =>
                                preventInvalidNumberOnKeyDown(e)
                              }
                              onBlur={() => checkInvalidValues("preco", i)}
                              placeholder="00,00"
                              onChange={({ target: t }) => {
                                if (invalidValues.includes(`preco-${i}`))
                                  setInvalidValues(
                                    invalidValues.filter(
                                      (x) => x !== `preco-${i}`
                                    )
                                  );
                                if (t.value >= 0 && !isNaN(t.value)) {
                                  onChangeProducts(
                                    i,
                                    "preco",
                                    parseFloat(t.value)
                                  );
                                }
                              }}
                              style={{
                                border:
                                  invalidValues.includes(`preco-${i}`) &&
                                  "1px solid red",
                              }}
                              value={item.preco}
                              disabled={
                                (liveWithVtex && live?.catalog === "vtex") ||
                                (liveWithMagento &&
                                  live?.catalog === "magento") ||
                                savingProductsList?.includes(item.id)
                              }
                            />
                            {invalidValues.includes(`preco-${i}`) && (
                              <s.InvalidLabel className="fade-in">
                                {t("PRODUCT-TAB.PriceRequired")}
                              </s.InvalidLabel>
                            )}

                            <label>{t("PRODUCT-TAB.Price")} *</label>
                            <Input
                              type="number"
                              min={0}
                              onPaste={(e) => preventInvalidNumberOnPaste(e)}
                              onKeyDown={(e) =>
                                preventInvalidNumberOnKeyDown(e)
                              }
                              onBlur={() => checkInvalidValues("preco", i)}
                              placeholder="00,00"
                              onChange={({ target: t }) => {
                                if (invalidValues.includes(`preco-${i}`))
                                  setInvalidValues(
                                    invalidValues.filter(
                                      (x) => x !== `preco-${i}`
                                    )
                                  );
                                if (t.value >= 0 && !isNaN(t.value)) {
                                  onChangeProducts(
                                    i,
                                    "preco",
                                    parseFloat(t.value)
                                  );
                                }
                              }}
                              style={{
                                border:
                                  invalidValues.includes(`preco-${i}`) &&
                                  "1px solid red",
                              }}
                              value={item.preco}
                              disabled={
                                (liveWithVtex && live?.catalog === "vtex") ||
                                (liveWithMagento &&
                                  live?.catalog === "magento") ||
                                savingProductsList?.includes(item.id)
                              }
                            />
                            {invalidValues.includes(`preco-${i}`) && (
                              <s.InvalidLabel className="fade-in">
                                {t("PRODUCT-TAB.PriceRequired")}
                              </s.InvalidLabel>
                            )}
                          </>
                        )} */}

                        {!liveWithTabelaPreco ? (
                          <>
                            {/* Preço Original */}
                            <label>{t("PRODUCT-TAB.PriceOriginal")}</label>
                            <Input
                              type="number"
                              min={0}
                              onPaste={(e) => preventInvalidNumberOnPaste(e)}
                              onKeyDown={(e) =>
                                preventInvalidNumberOnKeyDown(e)
                              }
                              // onBlur={() => checkInvalidValues("preco", i)}
                              placeholder="00,00"
                              onChange={({ target: t }) => {
                                // if (invalidValues.includes(`preco-${i}`))
                                //   setInvalidValues(
                                //     invalidValues.filter(
                                //       (x) => x !== `preco-${i}`
                                //     )
                                //   );
                                if (t.value >= 0 && !isNaN(t.value)) {
                                  onChangeProducts(
                                    i,
                                    "precoOriginal",
                                    parseFloat(t.value)
                                  );
                                }
                              }}
                              // style={{
                              //   border:
                              //     invalidValues.includes(`preco-${i}`) &&
                              //     "1px solid red",
                              // }}
                              value={item.precoOriginal}
                              disabled={
                                (liveWithVtex && live?.catalog === "vtex") ||
                                (liveWithMagento &&
                                  live?.catalog === "magento") ||
                                savingProductsList?.includes(item.id)
                              }
                            />
                            {/* {invalidValues.includes(`preco-${i}`) && (
                              <s.InvalidLabel className="fade-in">
                                {t("PRODUCT-TAB.PriceRequired")}
                                <br />
                              </s.InvalidLabel>
                            )} */}
                            <label>{t("PRODUCT-TAB.Price")} *</label>
                            <Input
                              type="number"
                              min={0}
                              onPaste={(e) => preventInvalidNumberOnPaste(e)}
                              onKeyDown={(e) =>
                                preventInvalidNumberOnKeyDown(e)
                              }
                              onBlur={() => checkInvalidValues("preco", i)}
                              placeholder="00,00"
                              onChange={({ target: t }) => {
                                if (invalidValues.includes(`preco-${i}`))
                                  setInvalidValues(
                                    invalidValues.filter(
                                      (x) => x !== `preco-${i}`
                                    )
                                  );
                                if (t.value >= 0 && !isNaN(t.value)) {
                                  onChangeProducts(
                                    i,
                                    "preco",
                                    parseFloat(t.value)
                                  );
                                }
                              }}
                              style={{
                                border:
                                  invalidValues.includes(`preco-${i}`) &&
                                  "1px solid red",
                              }}
                              value={item.preco}
                              disabled={
                                (liveWithVtex && live?.catalog === "vtex") ||
                                (liveWithNeomode &&
                                  live?.catalog === "neomode") ||
                                (liveWithMagento &&
                                  live?.catalog === "magento") ||
                                savingProductsList?.includes(item.id)
                              }
                            />
                            {invalidValues.includes(`preco-${i}`) && (
                              <s.InvalidLabel className="fade-in">
                                {t("PRODUCT-TAB.PriceRequired")}
                              </s.InvalidLabel>
                            )}
                          </>
                        ) : (
                          <>
                            {item.tabelaPreco && item.tabelaPreco.length > 0 ? (
                              <Popover
                                content={
                                  <table style={{ width: "100%" }}>
                                    <thead>
                                      <tr>
                                        <th>{t("PRODUCT-TAB.Table")}</th>
                                        <th style={{ textAlign: "right" }}>
                                          {t("PRODUCT-TAB.Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item.tabelaPreco.map(
                                        ({ valor, tabelaPreco: tabela }) => (
                                          <tr key={item.id + tabela}>
                                            <th>{tabela}</th>
                                            <th style={{ textAlign: "right" }}>
                                              {formatCash(
                                                valor / 100,
                                                false,
                                                live?.unidadeMonetaria
                                              )}
                                            </th>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                }
                                title={t("PRODUCT-TAB.Prices")}
                                trigger="hover"
                              >
                                <Button>{t("PRODUCT-TAB.CheckPrices")}</Button>
                              </Popover>
                            ) : (
                              <Button disabled>
                                {t("PRODUCT-TAB.NoPrices")}
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </article>
                    <div
                      style={{
                        borderRight: "1px solid #f0f0f0",
                        margin: "0 49px 0 19px",
                      }}
                    />
                    {!loading && (
                      <Variations
                        live={live}
                        liveWithTabelaPreco={liveWithTabelaPreco}
                        setOpenCatalog={(v) => {
                          setOpenCatalog(v);
                          setVariationCatalogIndex(i);
                        }}
                        controlAllStock={controlAllStock}
                        prod={item}
                        prodIndex={i}
                        liveWithVtex={liveWithVtex && live?.catalog === "vtex"}
                        liveWithNeomode={
                          liveWithNeomode && live?.catalog === "neomode"
                        }
                        liveWithMagento={
                          liveWithMagento && live?.catalog === "magento"
                        }
                        liveWithCorreios={liveWithCorreios}
                        catalog={live?.catalog}
                        tabelaPreco={tabelaPreco}
                        setProdVariations={(v) =>
                          setVariations({ ...variations, [item.id]: v })
                        }
                        variations={
                          variations && variations[item.id]
                            ? variations[item.id]
                            : []
                        }
                        disabled={
                          saving ||
                          Object.entries(item).length === 0 ||
                          savingProductsList?.includes(item.id)
                        }
                        productsChanged={productsChanged}
                        setProductsChanged={setProductsChanged}
                      />
                    )}
                  </s.Prod>
                </div>
              ))}
            </div>
            {!liveWithVtex && live?.catalog !== "vtex" && (
              <s.UploadButtonContainer>
                {!live.checkout === "externo" && (
                  <Button
                    type="primary"
                    onClick={() => setOpenUploadProduct(true)}
                    style={{ width: "45%", marginBottom: 20 }}
                  >
                    {t("PRODUCT-TAB.UploadCSVFile")}
                  </Button>
                )}
              </s.UploadButtonContainer>
            )}
            <s.UploadButtonContainer>
              {live.checkout === "externo" && (
                <Button
                  type="primary"
                  onClick={() => setOpenModalXml(true)}
                  style={{ width: "45%" }}
                >
                  {t("PRODUCT-TAB.UploadXMLFile")}
                </Button>
              )}
            </s.UploadButtonContainer>
          </Scrollbars>
        </Card>
      </div>
      <Button
        disabled={loading || saving}
        type="primary"
        htmlType="submit"
        onClick={() => next()}
        loading={loading || saving}
        style={{ margin: "20px 0" }}
      >
        {t("PRODUCT-TAB.Next")}
      </Button>
    </div>
  );
};
const DropInput = ({ i, onChangeProducts, thumbnail, disabled }) => {
  //upload / dropzone da imagem do produto (está aqui pq se colcoar no código buga)
  const { getRootProps, getInputProps, isDragActive } = useDropzone();

  return (
    <div
      key={`prod-${i}`}
      id={`prod-${i}`}
      {...getRootProps({
        onChange: async ({ target: t }) =>
          await compress
            .compress([...t.files], {
              size: 2,
              quality: 1,
              maxWidth: 200,
              maxHeight: 180,
              resize: true,
            })
            .then((d) => onChangeProducts(d[0].prefix + d[0].data, d[0].alt)),
      })}
      name="thumbnail"
      style={{
        ...s.imgProdStyle,
        border: "none",
        opacity: isDragActive ? 0.6 : 1,
        marginBottom: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <input
        {...getInputProps()}
        accept="image/*"
        multiple={false}
        id={`input-dropzone-${i}`}
        disabled={disabled}
      />
      {thumbnail ? (
        <img
          style={s.imgProdStyle}
          alt="thumbnail-prod"
          src={sourceImage(thumbnail)}
        />
      ) : (
        <Skeleton.Image
          style={{
            ...s.imgProdStyle,
            border: "1px solid #d9d9d9",
            width: "140px",
            height: "120px",
          }}
          active
        ></Skeleton.Image>
      )}
    </div>
  );
};
const Variations = ({
  setProdVariations,
  variations: oldVariations,
  live,
  disabled,
  liveWithVtex,
  liveWithMagento,
  liveWithNeomode,
  liveWithCorreios,
  setOpenCatalog,
  prod,
  liveWithTabelaPreco,
  controlAllStock,
  tabelaPreco,
  catalog,
  productsChanged,
  setProductsChanged,
  prodIndex,
}) => {
  const { t } = useTranslation();
  const [variations, setVariations] = useState(oldVariations || []); //variações dos produtos
  const [variationsIndex, setVariationsIndex] = useState(0); //índice da variação sendo exibida por produto
  const [openModalVariation, setOpenModalVariation] = useState(false); //modal das variações aberta / fechada
  const [edit, setEdit] = useState(false); //se foi selecionada uma variação para editar

  const newVariationFromProd = {
    medidas: prod.medidas || {},
    tamanhos: prod.tamanhos || [],
    preco: prod.preco || "",
    tipoGrade: prod.tipoGrade || "aberta",
    grades: prod.grades || [],
  };

  useEffect(() => {
    setVariations(oldVariations);
  }, [oldVariations]);

  const changeVariations = (v) => {
    setVariations(v);
    setProdVariations(v);

    if (!productsChanged.includes(prodIndex))
      setProductsChanged([...productsChanged, prodIndex]);
  };

  return (
    <>
      {openModalVariation && (
        <ModalVariation
          newVariation={!edit}
          open={openModalVariation}
          tabelaPreco={tabelaPreco}
          liveWithTabelaPreco={liveWithTabelaPreco}
          controlAllStock={controlAllStock}
          variation={edit ? variations[variationsIndex] : newVariationFromProd}
          onClose={() => setOpenModalVariation(false)}
          unidadeMonetaria={live?.unidadeMonetaria}
          onFinish={(data) => {
            if (edit) {
              let newVariations = variations;
              newVariations[variationsIndex] = data;
              changeVariations(newVariations);
              setEdit(false);
            } else changeVariations([...variations, data]);
            setOpenModalVariation(false);
          }}
          liveWithVtex={liveWithVtex}
          liveWithMagento={liveWithMagento}
          liveWithNeomode={liveWithNeomode}
          catalog={live?.catalog}
          liveWithCorreios={liveWithCorreios}
          deleteVariation={() => {
            let del = variations[variationsIndex];
            let remove = () => {
              setOpenModalVariation(false);
              notification.success({
                key: "aviso-var",
                message: t("PRODUCT-TAB.VariationDeleted"),
                onClick: () => notification.close("aviso-var"),
                duration: 10,
              });
              setVariationsIndex(0);
              changeVariations(variations.filter((v) => v !== del));
            };
            if (del.docId)
              managerService
                .deleteProduct(live.docId, del.docId)
                .then(() => remove())
                .catch(() => {
                  notification.error({
                    key: "aviso-var",
                    message: t("PRODUCT-TAB.DeleteVariationError"),
                    description: t(
                      "PRODUCT-TAB.DeleteErrorVariationDescription"
                    ),
                    onClick: () => notification.close("aviso-var"),
                    duration: 10,
                  });
                });
            else remove();
          }}
        />
      )}
      <article style={{ position: "relative", marginRight: "30px" }}>
        <Button
          style={{ position: "absolute", left: -30, top: 44 }}
          disabled={!variations[variationsIndex - 1]}
          onClick={() => setVariationsIndex(variationsIndex - 1)}
        >
          <LeftOutlined />
        </Button>
        <Button
          style={{ position: "absolute", right: -30, top: 44 }}
          disabled={!variations[variationsIndex + 1]}
          onClick={() => setVariationsIndex(variationsIndex + 1)}
        >
          <RightOutlined />
        </Button>
        <div style={{ display: "grid", margin: "0 auto" }}>
          {variations[variationsIndex] &&
          variations[variationsIndex].thumbnail &&
          !disabled ? (
            <img
              style={{ ...s.imgProdStyle, cursor: "pointer" }}
              title={t("PRODUCT-TAB.AlterVariation")}
              alt={`variation-${variationsIndex}`}
              src={sourceImage(variations[variationsIndex].thumbnail)}
              onClick={() => {
                setEdit(true);
                setOpenModalVariation(true);
              }}
            />
          ) : (
            <Skeleton.Image
              style={{
                ...s.imgProdStyle,
                margin: "0 auto",
                border: "1px solid #d9d9d9",
                width: "140px",
                height: "120px",
              }}
              active
            ></Skeleton.Image>
          )}
        </div>
        <s.Dots>
          {!disabled &&
            variations.map((n, i) =>
              i === variationsIndex ? (
                <span key={`var-dot-${i}`} />
              ) : (
                <div key={`var-dot-${i}`} />
              )
            )}
        </s.Dots>
        <Button
          type="primary"
          onClick={() => {
            if (
              liveWithVtex ||
              liveWithMagento ||
              liveWithNeomode ||
              ["vtex", "magento", "neomode"].includes(catalog)
            )
              setOpenCatalog(2);
            else {
              setEdit(false);
              setOpenModalVariation(true);
            }
          }}
          disabled={disabled}
          style={{ marginTop: variations[variationsIndex] ? 16 : 25 }}
        >
          {t("PRODUCT-TAB.NewVariation")}
        </Button>
      </article>
    </>
  );
};

export default ProductsTab;
