import React from "react";
import CaptureLead from "./desktop/capture-lead";

const ViewCaptureLead = () => {
  
  return (
    <>
      <CaptureLead/>
    </>
  );
};

export default ViewCaptureLead;
