import styled from "styled-components";

export const SwitchDevice = styled.div`
  .mobile-content {
    display: none;
  }
  @media (max-width: 955px) {
    .desktop-content {
      display: none;
    }
    .mobile-content {
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      .product-header {
        .status {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 120px;
          height: 20px;
          background: var(--corPrimaria);
          border-radius: 8px;
          color: white;
          font-weight: bold;
          font-size: 12px;
        }
        display: flex;
        flex-direction: row;
        .product-imagem {
          width: 120px;
          border-radius: 8px;
        }
        > section {
          margin-top: 10px;
          margin-left: 10px;
          width: 100%;
          > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            > label {
              font-size: 18px;
              font-weight: bold;
            }
          }
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      .input-container {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        > div {
          flex: 1;
          .custom-select {
            position: relative;
            #native-select {
              position: absolute;
              z-index: 2;
              height: 100%;
              width: 100%;
              opacity: 0;
            }
          }
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        .price {
          display: flex;
          flex-direction: column;
          > span {
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 350px;
  height: 100%;
  padding: 20px;
  box-shadow: -13px 0px 35px rgba(0, 0, 0, 0.25);
  background-color: white;
  z-index: 11;
  transition: 0.25s;
  position: absolute;

  .line {
    width: 100%;
    height: 1px;
    background: #d9d9d9;
    margin: 20px 0;
  }
`;

export const Image = styled.img`
  max-height: 250px;
  border-radius: 10px;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 40px);
  height: 40px;
  background: var(--corPrimaria);
  border-radius: 8px;
  margin: 0 20px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  opacity: 0.8;
  text-transform: uppercase;
`;

export const Description = styled.label`
  font-size: 18px;
  color: grey;
  margin: 20px 0;
  display: flex;
  text-align: center;
`;

export const PriceWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  label {
    font-size: 25px;
    color: black;
    font-weight: bold;
  }
`;

export const MeasuresWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Total = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  span {
    font-size: 25px;
    font-weight: bold;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const CustomSelectContainerOldMethod = styled.div`
  position: relative;
  #native-select {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
`;

export const PrimaryButtonStyle = {
  width: "100%",
  marginBottom: 10,
};

export const SecondaryButtonStyle = {
  width: "100%",
};
