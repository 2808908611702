import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useLayoutEffect,
} from "react";
import { useTranslation } from "react-i18next";
import * as s from "./styles-cart";
import { formatCash } from "../../../utils";
import { Select, Input, Button, Modal, Popover, message } from "antd";
import { GradesContext, ThemeContext } from "../../../context";
import Scrollbars from "react-custom-scrollbars";

const { Option } = Select;
const GradeFechada = ({
  grades,
  grade,
  preco,
  changeProducts,
  disabled,
  gradeId,
  quantiGrade: quanti,
  productsOutOffStock,
  produto,
  unidadeMonetaria,
  layoutCheckout,
  produtoCarrinho,
  setProdDelete,
  modalSizeProduct,
  setModalSizeProduct,
  vertical,
}) => {
  const { t } = useTranslation();
  const { corSecundaria } = useContext(ThemeContext);
  const { userGrades } = useContext(GradesContext); //grades do admin da live
  const [selected, setSelected] = useState(gradeId); //grade selecionada
  const [quantities, setQuantities] = useState(grade); //quantidades por tamanho
  const [quantiGrade, setQuantiGrade] = useState(quanti); //quantidade da grade
  const [render, setRender] = useState(false); //alterado para renderizar novamente
  const [sizeModal, setSizeModal] = useState(false); //modal com inputs no mobile
  const [changes, setChanges] = useState(false); //se houveram mudanças
  const [measuresOutOffStock, setMeasuresOutOffStock] = useState([]);
  const [selectedGrid, setSelectedGrid] = useState([]);
  const [loading, setLoading] = useState(false);
  const [windowMeasures, setWindowMeasures] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeWindow);
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);
  const resizeWindow = () => {
    setWindowMeasures({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    if (selected && quantiGrade > 0) {
      let gridInfo = [];
      userGrades
        .filter((e) => e.gradeId === selected)
        .map((g) => {
          g.grade.map((grid) => {
            gridInfo.push({
              tamanho: grid.tamanho,
              quantidade: grid.quantidade * quantiGrade,
            });
          });
        });
      setSelectedGrid(gridInfo);
    }
  }, [selected, quantiGrade]);

  const handleChanges = useCallback(() => {
    if (changes) {
      if (!isNaN(quantiGrade)) {
        let newQuantities = userGrades.filter((e) => e.gradeId === selected);
        if (newQuantities && newQuantities.length > 0) {
          newQuantities = newQuantities[0].grade.map((g) => ({
            ...g,
            quantidade: parseInt(g.quantidade) * parseInt(quantiGrade),
          }));
          setQuantities(newQuantities);
          setRender(!render);
          changeProducts(newQuantities, selected, quantiGrade);
          showMeasureWarning(newQuantities);
        }
      } else setQuantiGrade(0);
      setLoading(false);
      setChanges(false);
    }
  }, [changes, changeProducts, quantiGrade, render, selected, userGrades]);

  const isTOutOffStock = (t, qtd, idx) => {
    let indexInMeasures = produto.tamanhos.indexOf(t);
    return (
      measuresOutOffStock.includes(t) || produto.stock[indexInMeasures] < qtd
    );
  };

  const showMeasureWarning = (qtds) => {
    if (produto.controlStock) {
      let outStockArray = [];
      qtds.map(({ quantidade: qtd, tamanho: t }, idx) => {
        if (isTOutOffStock(t, qtd, idx)) outStockArray.push(t);
      });

      if (outStockArray.length > 0) {
        message.warning({
          content: `${t("CLOSED-GRID.ProductGridWarning1")} ${
            produto.descricao
          } ${t(
            "CLOSED-GRID.ProductGridWarning2"
          )}: ${outStockArray.toString()}`,
          duration: 8,
        });
      }
    }
  };

  useEffect(() => {
    handleChanges();
  }, [changes, handleChanges]);

  useEffect(() => {
    if (productsOutOffStock.length > 0) {
      let tmp = [];
      productsOutOffStock.map((prods) => (tmp = [...tmp, ...prods.tamanhos]));
      setMeasuresOutOffStock(tmp);
    }
  }, [productsOutOffStock]);

  useEffect(() => {
    if (quantities.length > 0) showMeasureWarning(quantities);
  }, []);

  const renderSizeInputs = (mobile) => (
    <>
      <s.SizesMobile>
        <div>
          <p>{t("CLOSED-GRID.Grid")}</p>
          <Select
            placeholder={t("CLOSED-GRID.Grids")}
            onChange={(s) => {
              setSelected(s);
              setChanges(changes === 1 ? 2 : 1);
            }}
            style={{ width: mobile ? 130 : 120 }}
            value={selected}
            disabled={disabled}
          >
            {userGrades
              .filter((e) => grades.includes(e.gradeId))
              .map((g) => (
                <Option value={g.gradeId} key={g.gradeId}>
                  {g.nome || t("CLOSED-GRID.NoName")}
                </Option>
              ))}
          </Select>
        </div>
        <div>
          <p>{t("CLOSED-GRID.Quantity")}</p>
          <Input.Group
            compact
            style={{
              width: mobile ? 130 : 120,
              display: "flex",
              marginTop: "auto",
            }}
            className="my-input-number"
          >
            <Button
              onClick={() => {
                if (quantiGrade > 1) {
                  setQuantiGrade(quantiGrade - 1);
                  setChanges(changes === 1 ? 2 : 1);
                }
              }}
              disabled={disabled || quantiGrade === 0}
            >
              -
            </Button>
            <Input
              type="number"
              value={quantiGrade}
              onChange={({ target: { value: v } }) => {
                if (v >= 0) {
                  setQuantiGrade(parseInt(v));
                  setChanges(changes === 1 ? 2 : 1);
                }
              }}
              disabled={disabled}
            />
            <Button
              onClick={() => {
                setQuantiGrade(quantiGrade + 1);
                setChanges(changes === 1 ? 2 : 1);
              }}
              disabled={disabled}
            >
              +
            </Button>
          </Input.Group>
        </div>
      </s.SizesMobile>

      {mobile && renderResume(true)}
    </>
  );

  const renderEditGridModal = () => {
    return (
      <>
        <s.SizesMobileClosedGrid>
          <div className="sizesNQuantities">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p>
                {t("CLOSED-GRID.Size")} ({t("CLOSED-GRID.Grid")})
              </p>
              <Select
                placeholder={t("CLOSED-GRID.Grids")}
                onChange={(s) => {
                  setSelected(s);
                }}
                style={{ width: 100, height: 24 }}
                value={selected}
                disabled={disabled}
              >
                {userGrades
                  .filter((e) => grades.includes(e.gradeId))
                  .map((g) => {
                    let allSizes = g.grade.map((x) => x.tamanho);
                    return (
                      <Option value={g.gradeId} key={g.gradeId}>
                        {allSizes.toString()}
                      </Option>
                    );
                  })}
              </Select>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "30px",
              }}
            >
              <p>{t("CLOSED-GRID.Quantity")}</p>
              <Input.Group
                compact
                style={{
                  width: 110,
                  height: 24,
                  display: "flex",
                  marginTop: "auto",
                  border: "1px solid lightgrey",
                  borderRadius: 2,
                }}
                className="my-input-number"
              >
                <Button
                  className="custom-button"
                  onClick={() => {
                    if (quantiGrade > 1) {
                      setQuantiGrade(quantiGrade - 1);
                    }
                  }}
                  disabled={disabled || quantiGrade === 0}
                >
                  -
                </Button>
                <Input
                  type="number"
                  value={quantiGrade}
                  onChange={({ target: { value: v } }) => {
                    if (v >= 0) setQuantiGrade(parseInt(v));
                  }}
                  disabled={disabled}
                />
                <Button
                  className="custom-button"
                  onClick={() => {
                    setQuantiGrade(quantiGrade + 1);
                  }}
                  disabled={disabled}
                >
                  +
                </Button>
              </Input.Group>
            </div>
          </div>

          <div className="table-container">
            <Scrollbars
              className="table-scrollbars"
              style={{
                width: "100%",
                height: selectedGrid.length * 35,
                maxHeight: 150,
              }}
            >
              <table
                style={{
                  width: 280,
                  fontSize: 14,
                }}
              >
                <tbody>
                  <tr>
                    <th>{t("CLOSED-GRID.Size")}</th>
                    <th>{t("CLOSED-GRID.Quantity")}</th>
                    <th>{t("CLOSED-GRID.Total")}</th>
                  </tr>
                  {selectedGrid.map(({ quantidade: qtd, tamanho: t }, idx) => {
                    if (qtd > 0)
                      return (
                        <tr key={t}>
                          <td
                            style={{
                              color:
                                produto.controlStock &&
                                isTOutOffStock(t, qtd, idx) &&
                                "red",
                            }}
                          >
                            {t}
                          </td>
                          <td
                            style={{
                              color:
                                produto.controlStock &&
                                isTOutOffStock(t, qtd, idx) &&
                                "red",
                            }}
                          >
                            {qtd}
                          </td>
                          <td
                            style={{
                              color:
                                produto.controlStock &&
                                isTOutOffStock(t, qtd, idx) &&
                                "red",
                            }}
                          >
                            {formatCash(qtd * preco, false, unidadeMonetaria)}
                          </td>
                        </tr>
                      );

                    return null;
                  })}
                </tbody>
              </table>
            </Scrollbars>
          </div>
          <div className="value-total-modal">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="title-col">{t("CLOSED-GRID.Total")}</span>
              <strong>
                {formatCash(
                  selectedGrid
                    .map((p) => parseInt(p.quantidade))
                    .reduce((a, b) => a + b, 0) * preco,
                  false,
                  unidadeMonetaria
                )}
              </strong>
            </div>

            <Button
              type="primary"
              loading={loading}
              disabled={loading}
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  setChanges(changes === 1 ? 2 : 1);
                }, 800);
              }}
              style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                background: loading && corSecundaria,
              }}
            >
              {loading ? t("CLOSED-GRID.Saving") : t("CLOSED-GRID.Save")}
            </Button>
          </div>
        </s.SizesMobileClosedGrid>
      </>
    );
  };

  const renderResume = (mobile) => (
    <table
      style={{
        width: mobile ? "100%" : "280px",
        fontSize: mobile ? "12px" : "14px",
      }}
    >
      <tbody>
        <tr>
          <th>{t("CLOSED-GRID.Size")}</th>
          <th>{t("CLOSED-GRID.Qtd")}</th>
          <th>{t("CLOSED-GRID.Total")}</th>
        </tr>
        {quantities.map(({ quantidade: qtd, tamanho: t }, idx) => {
          if (qtd > 0)
            return (
              <tr key={t}>
                <td
                  style={{
                    color:
                      produto.controlStock &&
                      isTOutOffStock(t, qtd, idx) &&
                      "red",
                  }}
                >
                  {t}
                </td>
                <td
                  style={{
                    color:
                      produto.controlStock &&
                      isTOutOffStock(t, qtd, idx) &&
                      "red",
                  }}
                >
                  {qtd}
                </td>
                <td
                  style={{
                    color:
                      produto.controlStock &&
                      isTOutOffStock(t, qtd, idx) &&
                      "red",
                  }}
                >
                  {formatCash(qtd * preco, false, unidadeMonetaria)}
                </td>
              </tr>
            );

          return null;
        })}
        <tr
          style={{
            borderTop: "1px solid",
            height: "40px",
            background: "#f4f4f4",
          }}
        >
          <th>{t("CLOSED-GRID.Total")}</th>
          <th>
            {quantities
              .map((p) => parseInt(p.quantidade))
              .reduce((a, b) => a + b, 0)}
          </th>
          <th>
            {formatCash(
              quantities
                .map((p) => parseInt(p.quantidade))
                .reduce((a, b) => a + b, 0) * preco,
              false,
              unidadeMonetaria
            )}
          </th>
        </tr>
      </tbody>
    </table>
  );

  return (
    <>
      <Modal
        title={t("CLOSED-GRID.Sizes")}
        visible={sizeModal}
        onCancel={() => setSizeModal(false)}
        style={{ maxWidth: "300px" }}
        width={"80vw"}
        footer={false}
        bodyStyle={{ padding: 10 }}
      >
        {renderSizeInputs(true)}
      </Modal>
      <Modal
        title={t("CLOSED-GRID.Sizes")}
        visible={modalSizeProduct}
        onCancel={() => setModalSizeProduct(false)}
        style={{
          maxWidth: "400px",
          top:
            !vertical && windowMeasures.height <= 736
              ? "33vh"
              : !vertical
              ? "30vh"
              : windowMeasures.width < 955 && "45vh",
        }}
        width={
          windowMeasures.width === 320 && windowMeasures.height === 568
            ? "90vw"
            : "80vw"
        }
        footer={false}
        bodyStyle={{ padding: 10 }}
        maskStyle={{
          top:
            !vertical && windowMeasures.height <= 736
              ? "31vh"
              : !vertical &&
                windowMeasures.width === 375 &&
                windowMeasures.height === 812
              ? "26vh"
              : !vertical && windowMeasures.width < 955 && "28vh",
          background: "rgba(0, 0, 0, 0,5)",
        }}
      >
        {renderEditGridModal()}
      </Modal>
      <div className="qtd-size-col">{renderSizeInputs()}</div>
      <div className="resume-col">
        <p className="title-col">{t("CLOSED-GRID.GridResume")}</p>
        {windowMeasures.width > 955 && (
          <>
            <Scrollbars style={{ height: "45px" }} autoHide>
              <div className="resume-content">
                {quantities
                  .filter((q) => q.quantidade)
                  .map(({ quantidade: qtd, tamanho: t }, idx) => (
                    <div key={t} className="resume-size">
                      <span
                        className={
                          produto.controlStock && isTOutOffStock(t, qtd, idx)
                            ? "unavailable"
                            : ""
                        }
                      >
                        {t}
                      </span>
                      <span
                        className={
                          produto.controlStock && isTOutOffStock(t, qtd, idx)
                            ? "unavailable"
                            : ""
                        }
                      >
                        {qtd}
                      </span>
                    </div>
                  ))}
                <div className="resume-size">
                  <span
                    style={{
                      fontWeight: "bold",
                      marginTop: "20px",
                      border: "none",
                    }}
                  >
                    (
                    {quantities
                      .map((p) => parseInt(p.quantidade))
                      .reduce((a, b) => a + b, 0)}
                    )
                  </span>
                </div>
              </div>
            </Scrollbars>
            <div className="resume-details">
              {quantities
                .map((q) => parseInt(q.quantidade) > 0)
                .filter((e) => e)
                .join(", ") && (
                <Popover
                  placement="topRight"
                  content={renderResume()}
                  trigger="click"
                >
                  <Button size="small">{t("CLOSED-GRID.Details")}</Button>
                </Popover>
              )}
            </div>
          </>
        )}
        <div className="total-col">
          {windowMeasures.width < 955 ? (
            <div className="vertical-buttons">
              <div onClick={() => setProdDelete(produtoCarrinho)}>
                <img
                  src={"/assets/icons/deleteIcon.svg"}
                  alt="excluir-produto"
                />
              </div>
            </div>
          ) : (
            <Button type="primary" onClick={() => setModalSizeProduct(true)}>
              {t("CLOSED-GRID.Sizes")}
            </Button>
          )}
          <div className="value-total">
            <span className="title-col">{t("CLOSED-GRID.Total")}:</span>
            <strong>
              {formatCash(
                quantities
                  .map((p) => parseInt(p.quantidade))
                  .reduce((a, b) => a + b, 0) * preco,
                false,
                unidadeMonetaria
              )}
            </strong>
          </div>
        </div>
      </div>
    </>
  );
};
export default GradeFechada;
