import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { axiosApp } from ".";
//-------------------------------------------------------------
//---------------------------Live------------------------------
//-------------------------------------------------------------
const createLive = (body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`manager/criarLive`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const editLive = (id, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .put(`manager/editarLive/${id}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const deleteLive = (id) =>
  new Promise((resolve, reject) => {
    axiosApp
      .delete(`manager/deleteLive/${id}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e?.response?.data?.message || false));
  });
const editThumbnail = (id, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .put(`/manager/editarThumbail/${id}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const getTransmissionStartTime = (id) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`/manager/stream-time/${id}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//---------------------------Room------------------------------
//-------------------------------------------------------------
const createRoom = (id, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`manager/cadastrarSalas/${id}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const getRoom = (id) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`manager/buscarSalas/${id}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const editRoom = (id, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .put(`manager/editarSalas/${id}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//---------------------------Users Online------------------------------
//-------------------------------------------------------------
const manageViewersOnLive = (liveId, body) => {
  return new Promise((resolve, reject) => {
    axiosApp
      .put(`manager/viewersOnLive/${liveId}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
};
//-------------------------------------------------------------
//-------------------------Products----------------------------
//-------------------------------------------------------------
const saveProduct = (id, product) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`manager/cadastrarProduto/${id}`, product)
      .then(({ data }) => resolve(data))
      .catch((e) =>
        reject(
          e && e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : false
        )
      );
  });
const getProducts = (id) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`manager/produtos/${id}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const deleteProduct = (id, docId) =>
  new Promise((resolve, reject) => {
    axiosApp
      .delete(`manager/deletarProduto/${id}`, { data: { docId } })
      .then(({ data }) => resolve(data))
      .catch((e) =>
        reject(
          e && e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : false
        )
      );
  });
const deleteAllProducts = (liveId) =>
  new Promise((resolve, reject) => {
    axiosApp
      .delete(`manager/produtos/${liveId}`)
      .then(({ data }) => resolve(data))
      .catch((e) =>
        reject(
          e && e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : false
        )
      );
  });
const saveProductTime = (tempoInicio, tempoFim, live, product) => {
  return new Promise((resolve, reject) => {
    let body = { tempoInicio, tempoFim };
    axiosApp
      .put(`manager/timeOnProduct/${live}/${product}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
};
const showStockOnLive = (live, body) => {
  return new Promise((resolve, reject) => {
    axiosApp
      .put(`manager/showStockOnLive/${live}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
};
//-------------------------------------------------------------
//-----------------------------Url-----------------------------
//-------------------------------------------------------------
const testUrl = (name) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`manager/friendlyUrl/generate`, { name })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const saveUrl = (id, friendlyUrl) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`manager/friendlyUrl/save/${id}`, { friendlyUrl })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//----------------------------Active---------------------------
//-------------------------------------------------------------
const deactivateLive = (id) =>
  new Promise((resolve, reject) => {
    axiosApp
      .put(`manager/desativarLive/${id}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//----------------------------Reports--------------------------
//-------------------------------------------------------------
const userReport = (live, format) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`report/customers/${live}`, {
        params: { format },
        responseType: format === "excel" ? "arraybuffer" : "text",
      })
      .then(({ data }) => resolve(data))
      .catch((e) => {
        let error = e.response.data;
        if (format === "excel") {
          let decoder = new TextDecoder("utf-8");
          error = JSON.parse(decoder.decode(error));
        }
        reject(error?.message ? error.message : false);
      });
  });
const cartReport = (live, format) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`report/favoritos/${live}`, {
        params: { format },
        responseType: format === "excel" ? "arraybuffer" : "text",
      })
      .then(({ data }) => resolve(data))
      .catch((e) => {
        let error = e.response.data;
        if (format === "excel") {
          let decoder = new TextDecoder("utf-8");
          error = JSON.parse(decoder.decode(error));
        }
        reject(error?.message ? error.message : false);
      });
  });
const orderReport = (live, format) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`report/pedidos/${live}`, {
        params: { format },
        responseType: format === "excel" ? "arraybuffer" : "text",
      })
      .then(({ data }) => resolve(data))
      .catch((e) => {
        let error = e.response.data;
        if (format === "excel") {
          let decoder = new TextDecoder("utf-8");
          error = JSON.parse(decoder.decode(error));
        }
        reject(error?.message ? error.message : false);
      });
  });

const chatReport = (live, format) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`report/chat/${live}`, {
        params: { format },
        responseType: format === "excel" ? "arraybuffer" : "text",
      })
      .then(({ data }) => resolve(data))
      .catch((e) => {
        let error = e.response.data;
        if (format === "excel") {
          let decoder = new TextDecoder("utf-8");
          error = JSON.parse(decoder.decode(error));
        }
        reject(error?.message ? error.message : false);
      });
  });

//-------------------------------------------------------------
//--------------------------Transmition------------------------
//-------------------------------------------------------------
const getTransmitionConfigs = (live) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`manager/stream/${live}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//----------------------------Payment--------------------------
//-------------------------------------------------------------
const getBanksList = () =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`manager/banksList`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//-----------------------------VTEX----------------------------
//-------------------------------------------------------------
const updateCredentials = (credentials) =>
  new Promise((resolve, reject) => {
    axiosApp
      .put(`/vtex/updateCredentials`, credentials)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const getCatalog = (lastItem = null, category = null) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`/vtex/catalog`, { params: { lastItem, category } })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const getCategories = () =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`/vtex/categories`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const searchCatalog = (searchField, searchValue, lastItem) =>
  new Promise((resolve, reject) => {
    let searchMoreItems = lastItem ? `&lastItem=${lastItem}` : "";
    axiosApp
      .get(`/vtex/search?${searchField}=${searchValue}${searchMoreItems}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//-----------------------------THEME---------------------------
//-------------------------------------------------------------
const saveTheme = (body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .put(`contractor/whiteLabel`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const saveThumbTheme = (body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .put(`contractor/logoWhiteLabel`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const deleteThumbTheme = () =>
  new Promise((resolve, reject) => {
    axiosApp
      .delete(`contractor/logoWhiteLabel`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//---------------------------AUDIENCE--------------------------
//-------------------------------------------------------------
const getAudienceList = (liveId) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`manager/listaAudiencia/${liveId}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const saveAudienceList = (liveId, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`manager/listaAudiencia/${liveId}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//---------------------------CONTRACTOR EMAIL--------------------------
//-------------------------------------------------------------
const getContractorEmailList = (liveId) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`manager/listaEmailContractor/${liveId}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const updateContractorEmailList = (liveId, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`manager/listaEmailContractor/${liveId}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const deleteContractorEmailList = (liveId) =>
  new Promise((resolve, reject) => {
    axiosApp
      .delete(`manager/listaEmailContractor/${liveId}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
//-------------------------------------------------------------
//---------------------------E-MAIL----------------------------
//-------------------------------------------------------------
const getEmailServices = () => {
  return new Promise((resolve, reject) => {
    axiosApp
      .get(`manager/servicesEmailWhiteLabel`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
};
const testEmailServer = (body) => {
  return new Promise((resolve, reject) => {
    axiosApp
      .post(`manager/testEmailWhiteLabel`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
};
const saveEmailTrigger = (liveId, body) => {
  return new Promise((resolve, reject) => {
    axiosApp
      .put(`manager/emailWhiteLabel/${liveId}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
};
//-------------------------------------------------------------
//---------------------------MAGENTO---------------------------
//-------------------------------------------------------------
const updateCredentialsMagento = (credentials) =>
  new Promise((resolve, reject) =>
    axiosApp
      .put(`/magento/update-credentials`, credentials)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e))
  );
const getCatalogMagento = (lastItem) =>
  new Promise((resolve, reject) =>
    axiosApp
      .get(`/magento/catalog`, { params: lastItem ? { lastItem } : false })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e))
  );

//-------------------------------------------------------------
//---------------------------Landing Page (LEADS) -------------
//-------------------------------------------------------------
const createLead = (body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`manager/lead`, body)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => reject(e.response.data));
  });

const editLead = (id, body) =>
  new Promise((resolve, reject) => {
    axiosApp
      .put(`manager/lead/${id}`, body)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const getLead = (id) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`manager/lead/${id}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
const deleteLead = (id) =>
  new Promise((resolve, reject) => {
    axiosApp
      .delete(`manager/lead/${id}`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e?.response?.data?.message || false));
  });
const getAllLeads = () =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`manager/leads`)
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });

export const managerService = {
  //live
  createLive,
  editLive,
  deleteLive,
  editThumbnail,
  getTransmissionStartTime,
  //room
  createRoom,
  getRoom,
  editRoom,
  //users online
  manageViewersOnLive,
  //products
  saveProduct,
  getProducts,
  deleteProduct,
  deleteAllProducts,
  saveProductTime,
  showStockOnLive,
  //url
  testUrl,
  saveUrl,
  //active
  deactivateLive,
  //reports
  userReport,
  cartReport,
  orderReport,
  chatReport,
  //transmition
  getTransmitionConfigs,
  //payment
  getBanksList,
  //VTEX
  updateCredentials,
  getCatalog,
  getCategories,
  searchCatalog,
  //theme
  saveTheme,
  saveThumbTheme,
  deleteThumbTheme,
  //audience
  getAudienceList,
  saveAudienceList,
  //contractor email
  getContractorEmailList,
  updateContractorEmailList,
  deleteContractorEmailList,
  //email
  getEmailServices,
  testEmailServer,
  saveEmailTrigger,
  //Magento,
  updateCredentialsMagento,
  getCatalogMagento,
  //lead
  createLead,
  editLead,
  getLead,
  deleteLead,
  getAllLeads,
};
