import React, { useState, useEffect } from "react";
import { Card, Skeleton, Modal } from "antd";
import { useTranslation } from "react-i18next";
import {
  EditOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { managerService } from "../../../../services";
import ReportsModal from "./modal-report";

const { Meta } = Card;

const Dashboard = ({ landingPage, history }) => {
  const [loading, setLoading] = useState(
    setTimeout(() => {
      setLoading(false);
    }, 2000)
  );

  useEffect(() => {
    document.title = `Dashboard Landing Pages | 4Show`;
    return () => (document.title = "4Show");
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-evenly",
      }}
      className="fade-in"
    >
      {loading ? (
        [1].map((n) => <MyCard loading key={n} />)
      ) : (
        <MyCard
          landingPage={landingPage}
          setLoading={setLoading}
          key={landingPage.leadId}
          history={history}
        />
      )}
    </div>
  );
};

const MyCard = ({ landingPage, loading, setLoading, history, getAllLeads }) => {
  const { t } = useTranslation();

  const [hasThumb, setHasThumb] = useState(
    loading ? false : landingPage.imagemPrincipal ? true : false
  );
  const [landingDelete, setLandingDelete] = useState(null);
  const [reportsModal, setReportsModal] = useState(false);

  const handleDeleteLanding = () => {
    setLoading(true);
    managerService.deleteLead(landingPage?.leadId).then(() => {
      setLoading(false);
      history.push("/landing-page");
    });
  };

  let imgStyle = { width: "300px", height: "180px", background: "#e9e9e9" };
  return (
    <>
      <Card
        className="fade-in"
        style={{ width: 300, margin: "10px" }}
        cover={
          loading || !hasThumb ? (
            <Skeleton.Image style={imgStyle} active />
          ) : (
            <img
              style={imgStyle}
              alt="thumbnail"
              src={landingPage?.imagemPrincipal}
              onError={() => setHasThumb(false)}
            />
          )
        }
        actions={
          !loading && [
            <EditOutlined
              key="edit"
              onClick={() =>
                history.push(`/landing-page?lp=${landingPage.leadId}`)
              }
            />,
            <FileTextOutlined onClick={() => setReportsModal(true)} />,
            <DeleteOutlined
              key="deletar"
              onClick={() => setLandingDelete(landingPage)}
            />,
          ]
        }
      >
        {loading ? (
          <Meta
            title={
              <Skeleton.Button active size={"default"} shape={"default"} />
            }
            description={
              <Skeleton.Input style={{ width: 200 }} active size={"default"} />
            }
          />
        ) : (
          <Meta
            title={landingPage.nomeLive || t("DASHBOARD.NoName")}
            description={landingPage.sobreLive || t("DASHBOARD.NoDescription")}
          />
        )}
      </Card>

      <ReportsModal
        landingPage={landingPage}
        visible={reportsModal}
        onClose={() => setReportsModal(false)}
      />

      <Modal
        title={t("DASHBOARD.DeleteLive")}
        visible={!!landingDelete}
        onOk={handleDeleteLanding}
        onCancel={() => setLandingDelete(null)}
        style={{ maxWidth: "500px" }}
        width={"80vw"}
        okText={t("DASHBOARD.Delete")}
        cancelText={t("DASHBOARD.Cancel")}
      >
        {t("DASHBOARD.DeactivateLive")}{" "}
        <strong>{landingDelete?.nomeLive}</strong>?{" "}
        {t("DASHBOARD.CantBeUndone")}
      </Modal>
    </>
  );
};

export default withRouter(Dashboard);
