import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as s from "./styles-cart";
import { Input, Modal, Steps } from "antd";
import { UserContext } from "../../../context";
import { customerService } from "../../../services";
import { formatCash } from "../../../utils";

const { Step } = Steps;
const ModalPayment = ({
  visible,
  closeModal,
  finish,
  finishing,
  shippingData,
  cart,
  freteSelecionado,
  condicao,
  unidadeMonetaria,
  style,
  maskStyle,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext); //dados do usuário
  const [payStep, setPayStep] = useState(0); //step em que o pagamento e entrega se encontram
  //payment control
  const [number, setNumber] = useState(""); //número do cartão
  const [expirationDate, setExpirationDate] = useState(""); //validade do cartão
  const [cvv, setCvv] = useState(""); //cvv do cartão
  const [holderName, setHolderName] = useState(""); //nome do titular do cartão
  const [totalValue, setTotalValue] = useState(""); //valor total dos produtos

  //address control
  const [zipcode, setZipcode] = useState(
    shippingData.zipcode ? shippingData.zipcode : ""
  ); //código postal
  const [country, setCountry] = useState(
    user && user.country
      ? user.country.toUpperCase()
      : shippingData.country
      ? shippingData.country
      : ""
  ); //país
  const [state, setState] = useState(
    user && user.state
      ? user.state.toUpperCase()
      : shippingData.state
      ? shippingData.state
      : ""
  ); //estado
  const [city, setCity] = useState(
    user && user.city
      ? user.city.toUpperCase()
      : shippingData.city
      ? shippingData.city
      : ""
  ); //cidade
  const [street, setStreet] = useState(
    shippingData.street ? shippingData.street : ""
  ); //rua
  const [streetNumber, setStreetNumber] = useState(
    shippingData.streetNumber ? shippingData.streetNumber : ""
  ); //número da casa
  const [neighborhood, setNeighborhood] = useState(
    shippingData.neighborhood ? shippingData.neighborhood : ""
  ); //neighborhoodo

  useEffect(() => {
    if (cart) {
      setTotalValue(
        cart
          .map(
            (f) =>
              f.produto.grade
                .map((g) => parseInt(g.quantidade))
                .reduce((a, b) => a + b, 0) * parseFloat(f.produto.preco)
          )
          .reduce((a, b) => a + b, 0)
      );
    }
  }, []);

  const completeAddressWithCep = () => {
    customerService.validateCep(zipcode).then((data) => {
      if (!data.erro) {
        //?Question - vamos mudar isso para mudar o país de acordo com o idioma?
        setCountry("BR");
        if (data.uf) setState(data.uf);
        if (data.localidade) setCity(data.localidade);
        if (data.logradouro) setStreet(data.logradouro);
        if (data.bairro) setNeighborhood(data.bairro);
      }
    });
  };

  return (
    <Modal
      title={t("MODAL-PAYMENT.PaymentTitle")}
      visible={visible}
      confirmLoading={finishing}
      style={style}
      maskStyle={maskStyle}
      width={"80vw"}
      onOk={() =>
        !finishing &&
        (payStep > 1
          ? finish({
              billingAddress: {
                zipcode,
                country: country.toLocaleLowerCase(),
                state: state.toLocaleLowerCase(),
                city,
                street,
                streetNumber,
                neighborhood,
                name: holderName,
              },
              card: {
                number,
                expirationDate: expirationDate.replace("/", ""),
                cvv,
                holderName,
              },
            })
          : setPayStep(payStep + 1))
      }
      onCancel={() =>
        !finishing && (payStep > 0 ? setPayStep(payStep - 1) : closeModal())
      }
      okText={
        payStep > 1 ? t("MODAL-PAYMENT.EndRequest") : t("MODAL-PAYMENT.Next")
      }
      okButtonProps={{
        disabled:
          payStep === 0
            ? !number ||
              (number && number.length < 16) ||
              !expirationDate ||
              !cvv ||
              (cvv && cvv.length < 3) ||
              !holderName
            : payStep === 1
            ? !zipcode ||
              !country ||
              !state ||
              !city ||
              !street ||
              !streetNumber ||
              !neighborhood
            : false,
      }}
      cancelText={
        payStep > 0 ? t("MODAL-PAYMENT.Back") : t("MODAL-PAYMENT.Cancel")
      }
    >
      <Steps current={payStep} size="small" style={{ marginBottom: "5px" }}>
        <Step title={t("MODAL-PAYMENT.Payment")} />
        <Step title={t("MODAL-PAYMENT.Address")} />
        <Step title={t("MODAL-PAYMENT.End")} />
      </Steps>
      <s.CreditCard
        style={{ display: payStep === 0 ? "block" : "none" }}
        className="fade-in"
      >
        <label>{t("MODAL-PAYMENT.NumberLabel")}</label>
        <Input
          type="number"
          placeholder={t("MODAL-PAYMENT.CardNumber")}
          value={number}
          onChange={({ target: t }) =>
            t.value.length <= 19 && setNumber(t.value)
          }
        />
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: window.innerWidth < 955 && 10 }}>
            <label>{t("MODAL-PAYMENT.Validity")}</label>
            <Input
              type="text"
              placeholder={t("MODAL-PAYMENT.ValidityDate")}
              value={expirationDate}
              onChange={({ target: { value } }) => {
                if (
                  !isNaN(value[value.length - 1]) ||
                  value[value.length - 1] === "/" ||
                  value === ""
                ) {
                  if (value.length === 2 && expirationDate.length < 2)
                    setExpirationDate(value + "/");
                  else setExpirationDate(value);
                }
              }}
              maxLength={5}
            />
          </div>
          <div style={{ marginLeft: "auto" }}>
            <label>{t("MODAL-PAYMENT.CVV")}</label>
            <Input
              type="number"
              placeholder={t("MODAL-PAYMENT.CardValidationNumber")}
              value={cvv}
              onChange={({ target: t }) =>
                t.value.length <= 4 && setCvv(t.value)
              }
            />
          </div>
        </div>
        <label>{t("MODAL-PAYMENT.Name")}</label>
        <Input
          type="text"
          placeholder={t("MODAL-PAYMENT.PrintedName")}
          value={holderName}
          onChange={({ target: t }) => setHolderName(t.value)}
        />
      </s.CreditCard>
      <s.CreditCard
        style={{ display: payStep === 1 ? "block" : "none" }}
        className="fade-in"
      >
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: window.innerWidth < 955 && 15 }}>
            <label>{t("MODAL-PAYMENT.CEP")}</label>
            <Input
              type="text"
              name="postal-code"
              placeholder={t("MODAL-PAYMENT.PostalCode")}
              value={zipcode.replace("-", "")}
              maxLength={8}
              autoComplete="none"
              onBlur={completeAddressWithCep}
              onChange={({ target: t }) => {
                if (
                  t.value.length < zipcode.length ||
                  (t.value && !isNaN(parseInt(t.value[t.value.length - 1])))
                )
                  setZipcode(t.value);
              }}
            />
          </div>
          <div style={{ marginLeft: "auto" }}>
            <label>{t("MODAL-PAYMENT.Country")}</label>
            <Input
              type="text"
              placeholder={t("MODAL-PAYMENT.CountryAcronym")}
              value={country}
              onChange={({ target: t }) => setCountry(t.value.toUpperCase())}
              maxLength={2}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: window.innerWidth < 955 && 15 }}>
            <label>{t("MODAL-PAYMENT.State")}</label>
            <Input
              type="text"
              placeholder={t("MODAL-PAYMENT.StateAcronym")}
              value={state}
              onChange={({ target: t }) => setState(t.value.toUpperCase())}
              maxLength={2}
            />
          </div>
          <div style={{ marginLeft: "auto" }}>
            <label>{t("MODAL-PAYMENT.City")}</label>
            <Input
              type="text"
              placeholder={t("MODAL-PAYMENT.YourCity")}
              value={city}
              onChange={({ target: t }) => setCity(t.value)}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: window.innerWidth < 955 && 15 }}>
            <label>{t("MODAL-PAYMENT.Address")}</label>
            <Input
              type="text"
              placeholder={t("MODAL-PAYMENT.StreetName")}
              value={street}
              onChange={({ target: t }) => setStreet(t.value)}
            />
          </div>
          <div style={{ marginLeft: "auto" }}>
            <label>{t("MODAL-PAYMENT.Number")}</label>
            <Input
              type="text"
              placeholder={t("MODAL-PAYMENT.NumberLabel")}
              value={streetNumber}
              onChange={({ target: t }) => setStreetNumber(t.value)}
            />
          </div>
        </div>

        <label>{t("MODAL-PAYMENT.District")}</label>
        <Input
          type="text"
          placeholder={t("MODAL-PAYMENT.DistrictName")}
          value={neighborhood}
          onChange={({ target: t }) => setNeighborhood(t.value)}
        />
      </s.CreditCard>
      <s.CreditCard
        style={{ display: payStep === 2 ? "block" : "none" }}
        className="fade-in"
      >
        <s.ContentBlock>
          <label className="title">{t("MODAL-PAYMENT.OrderResume")}</label>

          <div className="line">
            <label>{t("MODAL-PAYMENT.TotalOrderValue")}</label>
            <label> {formatCash(totalValue, false, unidadeMonetaria)}</label>
          </div>

          <div className="line">
            <label>{t("MODAL-PAYMENT.ShippingValue")}</label>
            <label>
              {freteSelecionado?.freeShipping
                ? t("SHIPPING.FreeShipping")
                : formatCash(
                    parseInt(freteSelecionado.value) / 100,
                    false,
                    unidadeMonetaria
                  )}
            </label>
          </div>
        </s.ContentBlock>

        <s.ContentBlock>
          <label className="title">{t("MODAL-PAYMENT.PaymentInfo")}</label>

          <div className="line">
            <label>
              {t("MODAL-PAYMENT.CardLastNumbers")}{" "}
              {number.substring(number.length - 4, number.length)}
            </label>
          </div>
          <div className="line">
            <label>
              {t("MODAL-PAYMENT.PaymentInstallments")} {condicao}x de{" "}
              {formatCash(totalValue / condicao, false, unidadeMonetaria)}
            </label>
          </div>
        </s.ContentBlock>

        <s.ContentBlock showMargin>
          <label className="title">{t("MODAL-PAYMENT.ShippingAddress")}</label>
          <div className="line">
            <label>
              {street}, {streetNumber}
            </label>
          </div>
          <div className="line">
            <label>
              {neighborhood}, {city} - {state}
            </label>
          </div>
          <div className="line">
            <label>{zipcode}</label>
          </div>
        </s.ContentBlock>

        <label>{t("MODAL-PAYMENT.CorrectInformation")}</label>
      </s.CreditCard>
    </Modal>
  );
};

export default ModalPayment;
