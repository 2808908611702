import { Input } from "antd";
import React, { useEffect, useState } from "react";

const ChangeQuantityInput = ({ initialValue, onBlur = () => {} }) => {
  const [qtdProdutos, setQtdProdutos] = useState(initialValue);

  useEffect(() => {
    setQtdProdutos(initialValue);
  }, [initialValue]);

  return (
    <Input
      value={qtdProdutos}
      onChange={(e) => setQtdProdutos(e.target.value)}
      onBlur={() =>
        onBlur(
          qtdProdutos === "" || qtdProdutos === "0"
            ? setQtdProdutos(1)
            : qtdProdutos
        )
      }
      style={{ textAlign: "center" }}
    />
  );
};

export default ChangeQuantityInput;
