import React, { useState } from "react";
import { Modal, Checkbox } from "antd";
import { useTranslation } from "react-i18next";

const ModalAviso = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const [removeShowWarning, setRemoveShowWarning] = useState(false);

  return (
    <Modal
      title={t("LIVE-TAB-WARNING-MODAL.Warning")}
      visible={open}
      style={{ maxWidth: "500px" }}
      width={"80vw"}
      onOk={() => {
        if (removeShowWarning)
          window.localStorage.setItem("removeWarningOnDemand", "sim");
        setOpen(false);
      }}
      onCancel={() => setOpen(false)}
      okText={"Ok"}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <label>{t("LIVE-TAB-WARNING-MODAL.WarningDescription")}</label>
      <div style={{ display: "flex", margin: "20px 0" }}>
        <Checkbox
          checked={removeShowWarning}
          onChange={() => setRemoveShowWarning(!removeShowWarning)}
        />
        <label style={{ fontSize: 12, color: "grey", marginLeft: 10 }}>
          {t("LIVE-TAB-WARNING-MODAL.DontShowWarning")}
        </label>
      </div>
    </Modal>
  );
};

export default ModalAviso;
