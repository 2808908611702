import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useLayoutEffect,
} from "react";
import { useTranslation } from "react-i18next";
import * as s from "./styles-cart";
import { formatCash } from "../../../utils";
import { Button, Modal, Select } from "antd";
import { AddProductContext } from "../../../context";

const { Option } = Select;

const GradeAberta = ({
  tamanhos,
  grade,
  preco,
  changeProducts,
  disabled,
  productsOutOffStock,
  unavailable,
  produto,
  qtdGrade,
  tamGrade,
  unidadeMonetaria,
  layoutCheckout,
  produtoCarrinho,
  setProdDelete,
  deleteProdIndex,
  vertical,
}) => {
  const { t } = useTranslation();
  const { cartProduct, setCartProduct } = useContext(AddProductContext);
  const [quantities, setQuantities] = useState({ [tamanhos[0]]: 0 }); //quantidades por tamanho
  const [render, setRender] = useState(false); //alterado para renderizar novamente
  const [sizeModal, setSizeModal] = useState(false); //modal com inputs no mobile
  const [withoutStock, setWithoutStock] = useState([]);
  const avaliableQuantitiesFromStockSize = produto.controlStock
    ? produto.stock[produto.tamanhos.indexOf(tamGrade)]
    : 40;
  const [windowMeasures, setWindowMeasures] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeWindow);
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);
  const resizeWindow = () => {
    setWindowMeasures({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const handleGrade = useCallback(() => {
    if (grade) {
      let newQuantities = {};
      tamanhos.forEach((t) => (newQuantities[t] = 0));
      grade.forEach((g) => (newQuantities[g.tamanho] = g.quantidade));
      setQuantities(newQuantities);
    }
  }, [grade, tamanhos]);

  useEffect(() => {
    handleGrade();
  }, [grade, handleGrade]);

  useEffect(() => {
    if (productsOutOffStock.length > 0) {
      productsOutOffStock.map((prod) => {
        setWithoutStock(prod.tamanhos.filter((t) => tamanhos.includes(t)));
      });
    }
  }, [productsOutOffStock]);

  useEffect(() => {
    if (cartProduct && cartProduct?.docId === produto.docId) {
      let tmp = { ...quantities };
      tamanhos.map((t) => {
        tmp[t] = 0;
      });
      tmp[cartProduct.measure] = cartProduct.quantity;
      setQuantities(tmp);
      setCartProduct(false);
    }
  }, [cartProduct]);

  const changeQuantities = (key, value) => {
    if (value === 0)
      if (!window.confirm(t("OPEN-GRID.WantRemoveProductFromCart"))) return;
    let newQuantities = quantities;
    newQuantities[key] = parseInt(value) || 0;
    setQuantities(newQuantities);
    changeProducts(
      Object.entries(newQuantities)
        .map((q) => ({
          tamanho: q[0],
          quantidade: q[1],
        }))
        .filter((p) => p.quantidade > 0)
    );
    setRender(!render);
  };

  const handleChangeSize = (newSize) => {
    const maxStock = produto.controlStock
      ? produto.stock[produto.tamanhos.indexOf(newSize)]
      : 40;
    let newQuantities = quantities;
    newQuantities[tamGrade] = 0;
    newQuantities[newSize] =
      produto?.controlStock && qtdGrade > maxStock ? maxStock : qtdGrade;
    setQuantities(newQuantities);
    changeProducts(
      Object.entries(newQuantities)
        .map((q) => ({
          tamanho: q[0],
          quantidade: q[1],
        }))
        .filter((p) => p.quantidade > 0)
    );
    setRender(!render);
  };

  const renderSizeInputs = (inModal) => (
    <>
      <s.SizesLabels>{t("OPEN-GRID.Size")}</s.SizesLabels>
      <Select
        value={tamGrade}
        defaultValue={tamGrade}
        disabled={disabled}
        onChange={handleChangeSize}
        style={{ width: inModal && "100%", maxWidth: 300 }}
      >
        {tamanhos.map((tam, idx) => (
          <Option
            disabled={
              ((withoutStock?.length > 0 && withoutStock?.includes(tam)) ||
                unavailable ||
                (produto?.stock && produto.stock[idx] === 0)) &&
              produto?.controlStock
            }
            key={tam}
            value={tam}
            title={tam}
          >
            {tam.replace(produto.descricao, "").length > 1
              ? tam.replace(produto.descricao, "")
              : tam}
            {unavailable
              ? t("OPEN-GRID.ProductUnavailable")
              : produto?.controlStock &&
                produto?.stock &&
                produto.stock[idx] === 0
              ? t("OPEN-GRID.ProductSoldoff")
              : ""}
          </Option>
        ))}
      </Select>
      <s.SizesLabels>{t("OPEN-GRID.Quantity")}</s.SizesLabels>
      <Select
        defaultValue={1}
        onChange={(value) => changeQuantities(tamGrade, value)}
        value={qtdGrade}
        disabled={disabled}
        style={{ width: inModal && "100%", maxWidth: 300 }}
      >
        {Array.from(Array(avaliableQuantitiesFromStockSize).keys()).map(
          (qtd) => (
            <Option key={qtd + 1} value={qtd + 1} title={qtd + 1}>
              {qtd + 1}
            </Option>
          )
        )}
      </Select>
    </>
  );

  return (
    <>
      <Modal
        title={t("OPEN-GRID.SizeNQuantity")}
        visible={sizeModal}
        onCancel={() => setSizeModal(false)}
        style={{
          maxWidth: "400px",
          top:
            !vertical && windowMeasures.height <= 736
              ? "33vh"
              : !vertical
              ? "30vh"
              : "45vh",
        }}
        width={"80vw"}
        footer={false}
        bodyStyle={{ padding: 15 }}
        maskStyle={{
          top:
            !vertical && windowMeasures.height <= 736
              ? "31vh"
              : !vertical &&
                windowMeasures.width === 375 &&
                windowMeasures.height === 812
              ? "26vh"
              : !vertical && windowMeasures.width < 955 && "28vh",
          background: "rgba(0, 0, 0, 0,5)",
        }}
      >
        {renderSizeInputs(true)}
      </Modal>
      <div className="qtd-size-col" style={{ width: "150%" }}>
        {renderSizeInputs()}
      </div>
      <div className="resume-col">
        <div className="total-col">
          {windowMeasures.width < 955 ? (
            <div className="vertical-buttons">
              <div
                style={{ marginRight: 10 }}
                onClick={() => setSizeModal(true)}
              >
                <img src={"/assets/icons/editIcon.svg"} alt="editar-produto" />
              </div>
              <div
                onClick={() =>
                  setProdDelete({
                    ...produtoCarrinho,
                    deletingSizeFromProduct: {
                      tamGrade,
                      index: deleteProdIndex,
                    },
                  })
                }
              >
                <img
                  src={"/assets/icons/deleteIcon.svg"}
                  alt="excluir-produto"
                />
              </div>
            </div>
          ) : (
            <Button type="primary" onClick={() => setSizeModal(true)}>
              {t("OPEN-GRID.Alter")}
            </Button>
          )}

          <div className="value-total">
            <span className="title-col">{t("OPEN-GRID.Total")}: </span>
            <strong>
              {formatCash(qtdGrade * preco, false, unidadeMonetaria)}
            </strong>
          </div>
        </div>
      </div>
    </>
  );
};

export default GradeAberta;
