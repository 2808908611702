import React, { useContext, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { firestore } from "../../../services";
import * as s from "./styles-chat";
import { Scrollbars } from "react-custom-scrollbars";
import { ThemeContext } from "../../../context";
import { createLinkElementInsideText, stringToColor } from "../../../utils";

const Chat = ({ live }) => {
  const { t } = useTranslation();
  const { corPrimaria, corSecundaria, verticalChatHeight } =
    useContext(ThemeContext);
  const [chat, setChat] = useState([]); //mensagens
  const [loading, setLoading] = useState(true);
  const [chatRef, setChatRef] = useState(); //ref do scroll
  const [autoScroll, setAutoScroll] = useState(true); //scroll automático ligado / desligado
  const [firstScroll, setFirstScroll] = useState(true); // influencia no timeout do scroll no momento que o usuario entra na live

  const handleSubscribe = useCallback(() => {
    let subscribe = firestore
      .collection("lives")
      .doc(live.liveId)
      .collection("chat")
      .doc(live.salaId)
      .collection("mensagens")
      .orderBy("timeEnvio", "desc")
      .limit(200)
      .onSnapshot((snapshot) => {
        setLoading(false);
        setChat(
          snapshot.docs
            .map((doc) => ({
              ...doc.data(),
              docId: doc.id,
            }))
            .sort((a, b) => a.timeEnvio - b.timeEnvio)
        );
      });
    return () => subscribe();
  }, [live.liveId]);

  useEffect(() => {
    handleSubscribe();
  }, [handleSubscribe]);

  useEffect(() => {
    if (chat && autoScroll && chatRef && (chat.length >= 9 || live.vertical)) {
      if (firstScroll) setFirstScroll(false);
      let time = firstScroll ? 300 : 0;
      setTimeout(() => chatRef.scrollToBottom(), time);
    }
    if (chat && autoScroll && chatRef && (chat.length >= 9 || live.vertical))
      chatRef.scrollToBottom();
  }, [chat]);

  useEffect(() => {
    if (chatRef) chatRef.scrollToBottom();
  }, [loading]);

  const handleUpdate = async ({ scrollTop, scrollHeight, clientHeight }) => {
    // adicionado -1 pois a subtração fica 1 a menos do esperado quando o usuario está scrollando
    if (scrollHeight - parseInt(scrollTop) - 1 <= clientHeight)
      setAutoScroll(true);
    else if (chat.length >= 10) setAutoScroll(false);
  };

  const renderMessage = (msg, docId) => {
    setTimeout(() => {
      let element = document.getElementById(docId);
      if (msg && element?.childNodes.length === 0) {
        if (!msg.includes("http:") && !msg.includes("https:")) {
          element.innerHTML = msg;
        } else {
          let allWords = msg.trim().replace(/\n/g, " ").split(" "); // separa todas as palavras
          element.appendChild(
            createLinkElementInsideText(allWords, false, t("UTILS.ProductLink"))
          );
        }
      }
    }, 300);
  };

  const zoeira = [
    "rafael.feiten@paipe.co",
    "uesleisuptitz@gmail.com",
    "carlos.gabriel@paipe.co",
  ];

  return (
    <s.ChatContainer
      vertical={live?.vertical}
      id="container-chat"
      className="fade-in"
    >
      {/* {live.fixedMessageChat !== null && (
        <div style={{ position: "fixed", width: "100%", top: "50px" }}>
          <s.ChatFixedMessage verticalChat={true} corPrimaria={corPrimaria}>
            {live.fixedMessageChat}
          </s.ChatFixedMessage>
        </div>
      )} */}
      <s.ChatVertical
        corSecundaria={corSecundaria}
        verticalChatHeight={verticalChatHeight}
      >
        <Scrollbars
          style={{
            height: "calc(100% - 154px)",
            marginTop: 30,
            width: "100%",
          }}
          ref={(c) => setChatRef(c)}
          onUpdate={handleUpdate}
          autoHide={false}
        >
          {!loading &&
            chat.map((msg) => {
              return (
                <s.Msg
                  key={msg.docId}
                  rep={zoeira.includes(msg.email)}
                  vertical={live.vertical}
                  colorByName={stringToColor(msg.name.toLowerCase())}
                >
                  {!live.vertical && <span>{msg.name[0].toUpperCase()}</span>}
                  <div>
                    <label className="msg-name">{msg.name.toLowerCase()}</label>
                    <label id={msg.docId} className="msg-content">
                      {renderMessage(msg.mensagem, msg.docId)}
                    </label>
                  </div>
                </s.Msg>
              );
            })}
        </Scrollbars>
      </s.ChatVertical>
    </s.ChatContainer>
  );
};

export default Chat;
