import React, { useState } from "react";
import { Input, Modal, message } from "antd";
import { useTranslation } from "react-i18next";
import { customerService } from "../../../../services";
import styled from "styled-components";
import { MaskedInput } from "antd-mask-input";

const ModalRetirada = ({ visible, onCancel, onOk, retirada }) => {
  const { t } = useTranslation();
  const [zipcode, setZipcode] = useState(retirada?.zipcode || "");
  const [country, setCountry] = useState(retirada?.country || "");
  const [state, setState] = useState(retirada?.state || "");
  const [city, setCity] = useState(retirada?.city || "");
  const [street, setStreet] = useState(retirada?.street || "");
  const [streetNumber, setStreetNumber] = useState(
    retirada?.streetNumber || ""
  );
  const [neighborhood, setNeighborhood] = useState(
    retirada?.neighborhood || ""
  );
  const [deadlineForTake, setDeadlineForTake] = useState(
    retirada?.deadlineForTake || ""
  );
  const [loading, setLoading] = useState(false);

  const checkCep = () => {
    if (zipcode?.length >= 8) {
      setLoading(true);
      customerService
        .validateCep(zipcode)
        .then((data) => {
          if (data.erro) {
            message.error(
              t("LIVE-TAB-WITHDRAWAL-MODAL.CheckCepErrorDescription")
            );
          } else {
            handleForm(data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleForm = (data) => {
    if (data) {
      setCountry("BR");
      setState(data?.uf);
      setCity(data.localidade);
      setStreet(data.logradouro);
      setNeighborhood(data.bairro);
    }
  };

  return (
    <Modal
      title={t("LIVE-TAB-WITHDRAWAL-MODAL.WithdrawalAddress")}
      visible={visible}
      style={{ maxWidth: "500px" }}
      width={"80vw"}
      onOk={() =>
        onOk({
          zipcode,
          country: country.toLowerCase(),
          state: state.toLowerCase(),
          city,
          street,
          streetNumber,
          neighborhood,
          deadlineForTake,
        })
      }
      onCancel={onCancel}
      okText={t("MODAL-SHIPPING.Confirm")}
      okButtonProps={{
        disabled:
          !zipcode ||
          !country ||
          !state ||
          !city ||
          !street ||
          !streetNumber ||
          !neighborhood ||
          !deadlineForTake,
      }}
      cancelText={t("LIVE-TAB-WITHDRAWAL-MODAL.Return")}
      cancelButtonProps={{
        style: { display: "none" },
      }}
    >
      <InputGroup>
        <InputContainer>
          <StyledLabel>{t("LIVE-TAB-WITHDRAWAL-MODAL.Zipcode")}</StyledLabel>
          {/* <Input
            type="text"
            name="postal-code"
            placeholder={t("LIVE-TAB-WITHDRAWAL-MODAL.ZipcodePlaceholder")}
            value={zipcode}
            onBlur={checkCep}
            maxLength={8}
            onChange={({ target: t }) => setZipcode(t.value.replace(/\D/g, ""))}
          /> */}
          <MaskedInput
            mask="00000-000"
            type="text"
            name="postal-code"
            placeholder={t("LIVE-TAB-WITHDRAWAL-MODAL.ZipcodePlaceholder")}
            value={zipcode}
            onBlur={checkCep}
            onChange={({ target: t }) => setZipcode(t.value)}
          />
        </InputContainer>
        <InputContainer>
          <StyledLabel>{t("LIVE-TAB-WITHDRAWAL-MODAL.Country")}</StyledLabel>
          <Input
            type="text"
            name="country"
            placeholder={
              loading
                ? t("LIVE-TAB-WITHDRAWAL-MODAL.Loading")
                : t("LIVE-TAB-WITHDRAWAL-MODAL.CountryAcronym")
            }
            value={country}
            readOnly={loading}
            onChange={({ target: t }) => setCountry(t.value)}
            maxLength={2}
          />
        </InputContainer>
      </InputGroup>
      <InputGroup>
        <InputContainer>
          <StyledLabel>{t("LIVE-TAB-WITHDRAWAL-MODAL.State")}</StyledLabel>
          <Input
            type="text"
            name="state"
            placeholder={
              loading
                ? t("LIVE-TAB-WITHDRAWAL-MODAL.Loading")
                : t("LIVE-TAB-WITHDRAWAL-MODAL.StateAcronym")
            }
            value={state}
            readOnly={loading}
            onChange={({ target: t }) => setState(t.value)}
            maxLength={2}
          />
        </InputContainer>
        <InputContainer>
          <StyledLabel>{t("LIVE-TAB-WITHDRAWAL-MODAL.City")}</StyledLabel>
          <Input
            type="text"
            placeholder={
              loading
                ? t("LIVE-TAB-WITHDRAWAL-MODAL.Loading")
                : t("LIVE-TAB-WITHDRAWAL-MODAL.CityPlaceholder")
            }
            value={city}
            readOnly={loading}
            onChange={({ target: t }) => setCity(t.value)}
          />
        </InputContainer>
      </InputGroup>
      <InputGroup>
        <InputContainer>
          <StyledLabel>{t("LIVE-TAB-WITHDRAWAL-MODAL.Address")}</StyledLabel>
          <Input
            type="text"
            placeholder={
              loading
                ? t("LIVE-TAB-WITHDRAWAL-MODAL.Loading")
                : t("LIVE-TAB-WITHDRAWAL-MODAL.AddressPlaceholder")
            }
            value={street}
            readOnly={loading}
            onChange={({ target: t }) => setStreet(t.value)}
          />
        </InputContainer>
        <InputContainer width="20%">
          <StyledLabel>Nº</StyledLabel>
          <Input
            type="text"
            placeholder={
              loading
                ? t("LIVE-TAB-WITHDRAWAL-MODAL.Loading")
                : t("LIVE-TAB-WITHDRAWAL-MODAL.Number")
            }
            value={streetNumber}
            readOnly={loading}
            onChange={({ target: t }) => setStreetNumber(t.value)}
            maxLength="7"
          />
        </InputContainer>
      </InputGroup>
      <InputContainer>
        <StyledLabel>{t("LIVE-TAB-WITHDRAWAL-MODAL.District")}</StyledLabel>
        <Input
          type="text"
          name="neighborhood"
          placeholder={
            loading
              ? t("LIVE-TAB-WITHDRAWAL-MODAL.Loading")
              : t("LIVE-TAB-WITHDRAWAL-MODAL.DistrictPlaceholder")
          }
          value={neighborhood}
          readOnly={loading}
          onChange={({ target: t }) => setNeighborhood(t.value)}
        />
      </InputContainer>
      <InputContainer>
        <StyledLabel>
          {t("LIVE-TAB-WITHDRAWAL-MODAL.WithdrawalDeadline")}
        </StyledLabel>
        <Input
          type="text"
          placeholder={
            loading
              ? t("LIVE-TAB-WITHDRAWAL-MODAL.Loading")
              : t("LIVE-TAB-WITHDRAWAL-MODAL.WithdrawalDeadline")
          }
          value={deadlineForTake || ""}
          readOnly={loading}
          min={0}
          maxLength="5"
          onChange={({ target: t }) =>
            t.value.length >= 1 && !isNaN(t.value) && t.value > 0
              ? setDeadlineForTake(Math.round(t.value))
              : setDeadlineForTake()
          }
          addonAfter={t("LIVE-TAB-WITHDRAWAL-MODAL.WorkDays")}
        />
      </InputContainer>
    </Modal>
  );
};

const InputGroup = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 5px;
`;

const InputContainer = styled.div`
  width: ${({ width }) => (width ? width : "100%")};
  display: flex;
  flex-wrap: wrap;

  &:first-child {
    margin-right: 10px;
  }

  input[name="neighborhood"] {
    margin-bottom: 5px;
  }

  input[name="country"],
  input[name="state"] {
    text-transform: uppercase;
  }

  input {
    &::placeholder {
      text-transform: capitalize;
    }
  }
`;

const StyledLabel = styled.label`
  width: 100%;
`;

export default ModalRetirada;
