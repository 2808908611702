import React, { createContext } from "react";
import useLives from "./hooks/use-lives";

const LivesContext = createContext({
  lives: {},
  setLives: () => {},
});

const LivesProvider = ({ children }) => {
  const { lives, setLives } = useLives();

  return (
    <LivesContext.Provider
      value={{
        lives,
        setLives,
      }}
    >
      {children}
    </LivesContext.Provider>
  );
};

export { LivesContext, LivesProvider };
