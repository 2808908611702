import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  width: 100%;

  .orientationOption {
    width: 50%;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  .orientationOptionLabel {
    font-size: 12px;
    margin-bottom: 5px;
    color: #808080;
    width: 100%;
    text-align: center;
  }

  .orientationOptionRadio {
    position: relative;
    margin-bottom: 5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    z-index: 1;

    &:hover {
      transition: all 400ms ease-in;
      border-color: #ffc05c !important;
    }

    &::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      top: 3px;
      left: 3px;
      border-radius: 50%;
      background-color: #ffc05c;
    }
  }

  .orientationOption.vertical {
    .orientationOptionRadio {
      background-color: #fff;
      border-width: 1px;
      border-style: solid;
      border-color: ${({ orientation }) =>
        orientation === "vertical" ? "#ffc05c" : "#d9d9d9"};

      &::after {
        content: ${({ orientation }) =>
          orientation === "vertical" ? "" : "none"};
      }
    }
  }

  .orientationOption.horizontal {
    .orientationOptionRadio {
      background-color: #fff;
      border-width: 1px;
      border-style: solid;
      border-color: ${({ orientation }) =>
        orientation === "horizontal" ? "#ffc05c" : "#d9d9d9"};

      &::after {
        content: ${({ orientation }) =>
          orientation === "horizontal" ? "" : "none"};
      }
    }
  }

  .orientationOptionThumb {
    width: 100%;
    border-radius: 4px;
  }

  .orientationOption.vertical {
    .orientationOptionThumb {
      max-width: 54px;
      height: 96px;
    }
  }

  .orientationOption.horizontal {
    .orientationOptionThumb {
      max-height: 75px;
      margin-top: 10.5px;
    }
  }
`;
