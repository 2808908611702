import styled from "styled-components";

export const TabsContainer = styled.div`
  width: 100vw;
  height: 9vh;
  position: fixed;
  bottom: 0px;
  background-color: ${({ bg }) => bg};
  z-index: 3;
  display: flex;
  justify-content: space-evenly;
  button {
    flex: 1;
    margin: 5px 10px;
    border: none;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: white;
    font-family: Poppins;
    font-size: 11px;
    border-radius: 5px;
    img {
      margin-bottom: 3px;
    }
    span {
      position: absolute;
      font-size: 8px;
      bottom: 5.3vh;
      right: 25%;
      border-radius: 15px;
      background: ${({ badgeColor: bg }) => bg};
      display: grid;
      place-items: center;
      height: 15px;
    }
  }
  .tab-active {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const TabsVerticalContainer = styled.div`
  width: calc(100vw - 16px);
  height: 50px;
  position: fixed;
  bottom: 10px;
  margin: 0 8px;

  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: ${({ isChatFocused: f }) =>
    f ? "flex-start" : "space-evenly"};
  button {
    flex: 1;
    margin: 2px 3px;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ bg }) => bg};
    color: white;
    font-family: Poppins;
    font-size: 11px;
    border-radius: 5px;
    max-width: 45px;
    min-width: 45px;
    height: 45px;
    span {
      position: absolute;
      font-size: 10px;
      bottom: 3.2vh;
      right: 1.6%;
      border-radius: 15px;
      background: ${({ badgeColor: bg }) => bg};
      display: grid;
      place-items: center;
      height: 15px;
      z-index: 2;

      //Iphone 6/7/8 Plus
      @media (max-device-height: 736px) and (max-device-width: 414px) and (orientation: portrait) {
        bottom: 4vh;
      }
      //Iphone 5/SE
      @media (max-device-height: 568px) and (max-device-width: 320px) and (orientation: portrait) {
        bottom: 4.5vh;
      }
    }
    img {
      margin-bottom: 3px;
    }

    img {
      width: 20px;
      height: 20px;
      filter: brightness(0) invert(1);
    }
  }
  .tab-active {
    background: rgba(255, 255, 255, 0.1);
  }
  .form-send-msg-chat.animated-linear {
    width: ${({ isChatFocused: f }) => (f ? "100%" : "186px")};
    color: grey;
    margin: 0 0 0 4px;
    /* display: flex;
    align-items: center;
    width: 156px; */
    input {
      background: rgba(255, 255, 255, 0.8);
      width: 100%;
      height: 40px;
      border-radius: 5px 0 0 5px;

      ::placeholder {
        color: grey;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: grey;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: grey;
      }
    }

    .ant-input-group-addon {
      display: ${({ isChatFocused: f }) => !f && "none"};
      border-radius: 0 5px 5px 0;
      border: none;
      background: transparent;
      margin: 0;

      button {
        height: 40px;
        border-radius: 0 5px 5px 0;
        margin: 0;

        span {
          width: 40px;
          height: 40px;
          position: relative;
          bottom: 0;
        }
      }
    }
    /* border-radius: 5px;
    color: white;
    height: 40px; */
  }
`;
