import React, { createContext } from "react";
import useGrades from "./hooks/use-grades";

const GradesContext = createContext({
  userGrades: {},
  setUserGrades: () => {},
});

const GradesProvider = ({ children }) => {
  const { userGrades, setUserGrades } = useGrades();

  return (
    <GradesContext.Provider
      value={{
        userGrades,
        setUserGrades,
      }}
    >
      {children}
    </GradesContext.Provider>
  );
};

export { GradesContext, GradesProvider };
