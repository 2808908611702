import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, message, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { customerService, userService } from "./services/";
import { Admin, Live, Home, CaptureLead } from "./screens";
import { withRouter } from "react-router-dom";
import { Carousel } from "@trendyol-js/react-carousel";
import {
  ThemeContext,
  LivesContext,
  UserContext,
  LanguageContext,
  TabsProvider,
  ViewLandingPageContext,
} from "./context";
import "./language";

Spin.setDefaultIndicator(
  <LoadingOutlined style={{ fontSize: "40px", color: "gray" }} />
);
const Container = styled.div`
  background: #f8f8f8;
  width: 100%;
  @media (max-width: 400px) {
    padding-top: 90px;
  }
  font-family: Poppins;
  min-height: 100vh;
  @media (max-width: 955px) {
    min-height: calc(100vh - 56px);
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    position: fixed;
    left: 30px;
    top: 30px;

    @media (max-width: 955px) {
      margin-bottom: 120px;
    }

    @media (max-width: 400px) {
      margin-bottom: 120px;
    }
  }
  .shadow {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
  }

  .card {
    @media (min-width: 955px) {
      width: 60%;
    }
    @media (max-width: 955px) {
      width: 50%;
    }
  }

  article {
    margin-top: auto;
    display: flex;
    @media (max-width: 955px) {
      flex-direction: column;
    }
    h1 {
      color: #656565;
    }
    @media (max-width: 955px) {
      width: 100%;
    }
  }

  footer {
    width: 60%;
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    position: relative;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    margin-top: 3vw;
    @media (max-width: 955px) {
      width: 80%;
      margin-top: 10vw;
    }
  }
`;

const App = ({ history, location: { pathname } }) => {
  const { i18n, t } = useTranslation();
  const { setLanguage, language } = useContext(LanguageContext);
  const { setUser } = useContext(UserContext);
  const { setLives } = useContext(LivesContext);
  const { setLandingPage } = useContext(ViewLandingPageContext);
  const { setCorSecundaria, setCorPrimaria, setLogo, setShowPoweredBy } =
    useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [logged, setLogged] = useState(false);
  const [live, setLive] = useState();
  const [captureLead, setCaptureLead] = useState();

  useEffect(() => {
    let initialLanguage = "";
    if (window.localStorage.getItem("currentLanguage")) {
      initialLanguage = JSON.parse(
        window.localStorage.getItem("currentLanguage")
      );
    } else {
      ["pt-BR", "en", "es"].includes(navigator.language)
        ? (initialLanguage = navigator.language.replace("-", ""))
        : (initialLanguage = "en");
    }
    if (!window.localStorage.getItem("currentLanguage")) {
      window.localStorage.setItem(
        "currentLanguage",
        JSON.stringify(initialLanguage)
      );
    }
    i18n.changeLanguage(initialLanguage);
    setLanguage(initialLanguage);
  }, []);

  useEffect(() => {
    let queryUrl = pathname.split("/live/");
    let liveUrl = queryUrl[1];
    if (liveUrl) {
      let nameParamLive = liveUrl.split("/");
      if (!nameParamLive[1]) {
        findCaptureLead(nameParamLive[0]);
      } else {
        findLive(liveUrl);
      }
    } else
      userService
        .verifyLogin()
        .then((user) => {
          if (user)
            userService
              .getUser()
              .then((data) => {
                setUser(data.user);
                setLives(data.lives);
                setLogged(true);
                setInitializing(false);
              })
              .catch(() => setInitializing(false));
          else setInitializing(false);
        })
        .catch(() => setInitializing(false));
  }, []);

  const onFinish = ({ email, password }) => {
    setLoading(1);
    userService
      .login(email, password)
      .then(() => {
        userService
          .getUser()
          .then((data) => {
            setUser(data.user);
            setLives(data.lives);
            setLoading(false);
            setLogged(true);
          })
          .catch((e) => {
            setLoading(false);
            message.error(
              e === 402 ? t("APP.ExpiredPlan") : t("APP.LoginErrorDescription")
            );
          });
      })
      .catch((error) => {
        setLoading(false);
        message.error(t("APP.EmailOrPasswordErrorDescription"));
      });
  };

  const findLive = (url) => {
    setLoading(2);
    customerService
      .getLiveByUrl(url)
      .then(({ live, whiteLabel }) => {
        history.push(`/live/${url}`);
        setLive(live);
        setLoading(false);
        if (whiteLabel) {
          if (whiteLabel.corPrimaria) setCorPrimaria(whiteLabel.corPrimaria);
          if (whiteLabel.corSecundaria)
            setCorSecundaria(whiteLabel.corSecundaria);
          if (whiteLabel.logo) setLogo(whiteLabel.logo);
          setShowPoweredBy(whiteLabel.showPoweredBy);
        }
      })
      .catch(() => {
        message.error(t("APP.LiveNotFound"));
        setLoading(false);
        if (initializing) setInitializing(false);
      });
  };

  const findCaptureLead = (url) => {
    setLoading(2);
    customerService
      .getViewLeadByUrl(url)
      .then((data) => {
        history.push(`/live/${url}`);
        setLandingPage(data);
        setCaptureLead(true);
        setLoading(false);
      })
      .catch(() => {
        message.error(t("APP.LiveNotFound"));
        setLoading(false);
        if (initializing) setInitializing(false);
      });
  };

  if (logged) return <Admin setLogged={setLogged} />;
  else if (live)
    return (
      <TabsProvider>
        <Live live={live} setLive={setLive} />
      </TabsProvider>
    );
  else if (captureLead)
    return (
      <TabsProvider>
        <CaptureLead />
      </TabsProvider>
    );
  else
    return (
      <Container className="fade-in">
        <img
          alt="logo"
          className="logo"
          src={"/assets/images/logo4show.svg"}
          style={{ width: "125px", position: "absolute", float: "left" }}
        />
        {initializing ? (
          <Spin style={{ marginTop: "auto" }} />
        ) : (
          <Home onFinish={onFinish} loading={loading} findLive={findLive} />
        )}
        <a
          href={window.location.origin + "/termos-de-uso.pdf"}
          target="_blank"
          style={{ margin: "auto 0 30px 0" }}
        >
          {t("APP.TermsOfUse")}
        </a>
      </Container>
    );
};

export default withRouter(App);
