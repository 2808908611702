import React, { useContext, useState } from "react";
import { InputNumber, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars";
import ModalNewUserSplit from "../modal-new-user-split";
import { DeleteOutlined } from "@ant-design/icons";
import * as s from "./styled-products-tab";
import { PaymentContext } from "../../../../context";
import { formatCash } from "../../../../utils";

const { Option } = Select;
const ModalSplitGeral = ({
  visible,
  onOk = () => {},
  onCancel,
  splitGeral: splitGeralOld = [],
}) => {
  const { t } = useTranslation();
  const { services, pagarmeFees } = useContext(PaymentContext);
  const { pagarme = [] } = services;
  const [newReceiverModal, setNewReceiverModal] = useState(false);
  const [splitGeral, setSplitGeral] = useState(splitGeralOld); //split geral

  var porcent = [...splitGeral]
    .map((s) => parseFloat(s.percentage))
    .reduce((a, b) => a + b, 0);

  var comissionPercent = pagarmeFees?.comissao?.porcentagem
    ? pagarmeFees.comissao.porcentagem
    : false;
  var pixFee = pagarmeFees?.pix?.length > 0 ? pagarmeFees.pix[0].valor : false;
  var creditCardFixedFeeAntifraud =
    pagarmeFees?.cartao?.fixa.length > 0
      ? pagarmeFees.cartao.fixa[0].valor
      : false;
  var creditCardFixedFeeGateway =
    pagarmeFees?.cartao?.fixa?.length > 0
      ? pagarmeFees.cartao.fixa[1].valor
      : false;
  var creditCardMDR =
    pagarmeFees?.cartao?.mdr?.length > 0 ? pagarmeFees.cartao.mdr : false;

  const renderReceiver = (split) => {
    const { legalName: name, percentage = 0 } = split;
    return (
      <s.ReceiverContainer key={name}>
        {name}
        <InputNumber
          type="number"
          min={0}
          max={100}
          parser={(value) => value.replace(",", ".")}
          defaultValue={percentage}
          style={{ width: "75px", marginLeft: "auto" }}
          maxLength={5}
          onChange={(v) => {
            setSplitGeral(
              splitGeral.map((s) => ({
                ...s,
                percentage:
                  s.legalName === name
                    ? !isNaN(parseFloat(v))
                      ? parseFloat(v) > 100
                        ? 100
                        : parseFloat(v)
                      : 0
                    : s.percentage,
              }))
            );
          }}
        />
        <span style={{ color: "gray", marginLeft: 5 }}>%</span>
        <DeleteOutlined
          style={{ marginLeft: "10px" }}
          onClick={() =>
            setSplitGeral(splitGeral.filter((p) => p.legalName !== name))
          }
        />
      </s.ReceiverContainer>
    );
  };

  return (
    <Modal
      title={t("PRODUCTS-TAB-GENERAL-SPLIT-MODAL.GeneralSplit")}
      visible={visible}
      onOk={() => onOk(splitGeral)}
      onCancel={onCancel}
      okText={t("PRODUCTS-TAB-GENERAL-SPLIT-MODAL.SaveSplit")}
      cancelText={t("PRODUCTS-TAB-GENERAL-SPLIT-MODAL.Back")}
      okButtonProps={{
        disabled: porcent !== 100,
      }}
      bodyStyle={{ padding: 0 }}
    >
      <ModalNewUserSplit
        visible={newReceiverModal}
        onCancel={() => setNewReceiverModal(false)}
      />
      <div style={{ margin: "20px" }}>
        <label>
          {t("PRODUCTS-TAB-GENERAL-SPLIT-MODAL.GeneralSplitDescription")}
        </label>
        <a onClick={() => setNewReceiverModal(true)}>
          {" "}
          {t("PRODUCTS-TAB-GENERAL-SPLIT-MODAL.Here")}
        </a>
        .
        <br />
        <label>{t("PRODUCTS-TAB-GENERAL-SPLIT-MODAL.Fee")}:</label>
        {comissionPercent && (
          <p style={{ fontSize: "11px", margin: 0 }}>
            •{" "}
            {`${t(
              "PRODUCTS-TAB-GENERAL-SPLIT-MODAL.Commission"
            )}: ${comissionPercent}%`}
          </p>
        )}
        {pixFee && (
          <p style={{ fontSize: "11px", margin: 0 }}>
            • {`Pix: ${formatCash(pixFee / 100, false, "R$")}`}
          </p>
        )}
        <p style={{ fontSize: "11px", margin: 0 }}>
          {`• ${t("CART.CreditCard")}: `}
          {creditCardFixedFeeAntifraud &&
            `${t("PRODUCTS-TAB-GENERAL-SPLIT-MODAL.Antifraud")} (${formatCash(
              creditCardFixedFeeAntifraud / 100,
              false,
              "R$"
            )}), `}
          {creditCardFixedFeeGateway &&
            `Gateway (${formatCash(
              creditCardFixedFeeGateway / 100,
              false,
              "R$"
            )}), `}
          {creditCardMDR &&
            `MDR: ${creditCardMDR.map(
              (fee) =>
                ` ${fee.parcelaInferior}-${fee.parcelaSuperior} (${fee.porcentagem}%)`
            )}`}
        </p>
        <div style={{ marginTop: "10px" }}>
          <label>{t("PRODUCTS-TAB-GENERAL-SPLIT-MODAL.Receivers")}: </label>
          <Select
            placeholder={t("PRODUCTS-TAB-GENERAL-SPLIT-MODAL.AddReceiver")}
            style={{ width: "170px", marginLeft: "10px" }}
            showSearch
            onChange={(v) =>
              setSplitGeral([
                ...splitGeral,
                {
                  ...pagarme.filter((r) => r.legalName === v)[0],
                  percentage: 1,
                },
              ])
            }
          >
            {pagarme
              .filter(
                (r) => !splitGeral.map((s) => s.legalName).includes(r.legalName)
              )
              .map((r, key) => (
                <Option value={r.legalName} key={`${key}-${r.legalName}`}>
                  {r.legalName}
                </Option>
              ))}
          </Select>
        </div>
      </div>
      <Scrollbars
        style={{
          width: "100%",
          height: "300px",
        }}
      >
        <div style={{ margin: "0 20px" }}>
          {splitGeral.map((s) => renderReceiver(s))}
          <s.TotalContainer porcent={porcent}>
            <label>{t("PRODUCTS-TAB-GENERAL-SPLIT-MODAL.Total")}</label>
            <label>{porcent.toFixed(2)}%</label>
          </s.TotalContainer>
        </div>
      </Scrollbars>
    </Modal>
  );
};

export default ModalSplitGeral;
