import React, { useContext, useState, useEffect } from "react";
import { Button, Radio, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useNeomode, UserContext } from "../../context";
import { neomodeService } from "../../services";

const NeomodeSellers = ({ visible, liveId, goNextStep, goBackStep }) => {
  const { t } = useTranslation();
  const { user: user4Show } = useContext(UserContext);
  const { sellerSelected, setSellerSelected } = useNeomode();

  const [stepSellers, setStepSellers] = useState("location"); //location | sellers
  const [locationPermission, setLocationPermission] = useState(false); //granted | prompt | denied | error
  const [location, setLocation] = useState({}); //{ lat, long }
  const [sellersStatus, setSellersStatus] = useState("loading"); // success - error - loading
  const [sellers, setSellers] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleGetUserPositionSuccess = ({ coords }) => {
    if (coords?.latitude && coords?.longitude) {
      setLocation({
        lat: coords.latitude,
        long: coords.longitude,
      });
      setLocationPermission("granted");
      setStepSellers("sellers");
      handleGetSellers(coords.latitude, coords.longitude);
    } else setLocationPermission("error");
  };

  const handleGetUserPosition = () =>
    navigator.geolocation.getCurrentPosition(
      handleGetUserPositionSuccess,
      () => setLocationPermission("error"),
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );

  useEffect(() => {
    if (visible) {
      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function ({ state }) {
            if (state === "denied") setLocationPermission("denied");
            else handleGetUserPosition();
          });
      } else setLocationPermission("error");
    }
  }, [visible]);

  const getSellers = (liveId, lat, long, codigo) => {
    setSellersStatus("loading");
    neomodeService
      .getSellers(liveId, lat, long, codigo)
      .then(({ sellers }) => {
        setSellersStatus("success");
        setSellers(sellers);
        if (sellers?.length > 0) setSellerSelected(sellers[0].id);
      })
      .catch((error) => {
        if (error) setErrorMessage(error);
        setSellersStatus("error");
      });
  };

  const handleGetSellers = (lat, long) => {
    setStepSellers("sellers");
    getSellers(
      liveId,
      lat || (location?.lat && location.lat),
      long || (location?.long && location.long),
      user4Show.codigo
    );
  };
  if (!visible) return <></>;
  if (stepSellers === "location") {
    if (!locationPermission || locationPermission === "granted")
      return (
        <div
          style={{
            margin: "20px 0 0 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Spin style={{ margin: "20px auto" }} />
          <label style={{ textAlign: "center" }}>
            {t("NEOMODE.LocatingSellers")}
          </label>
        </div>
      );
    else if (locationPermission === "error")
      return (
        <div>
          <br />
          {t("NEOMODE.LocationUserError")}
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
          >
            <Button onClick={goBackStep} style={{ marginRight: 24 }}>
              {t("NEOMODE.Back")}
            </Button>
            <Button
              onClick={() => {
                setLocationPermission(false);
                handleGetUserPosition();
              }}
              style={{ marginRight: 24 }}
            >
              {t("NEOMODE.Try")}
            </Button>
            <Button
              onClick={() => {
                setStepSellers("sellers");
                handleGetSellers();
              }}
            >
              {t("NEOMODE.Continue")}
            </Button>
          </div>
        </div>
      );
    else if (locationPermission === "denied")
      return (
        <div>
          <br />
          {t("NEOMODE.NoPermissionToFindYou")}
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
          >
            <Button onClick={goBackStep} style={{ marginRight: 24 }}>
              {t("NEOMODE.Back")}
            </Button>
            <Button
              onClick={() => {
                setLocationPermission(false);
                handleGetUserPosition();
              }}
              style={{ marginRight: 24 }}
            >
              {t("NEOMODE.Allow")}
            </Button>
            <Button
              onClick={() => {
                setStepSellers("sellers");
                handleGetSellers();
              }}
            >
              {t("NEOMODE.Continue")}
            </Button>
          </div>
        </div>
      );
  } else {
    return sellersStatus === "loading" ? (
      <div
        style={{
          margin: "20px 0 0 0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Spin style={{ margin: "20px auto" }} />
        <label style={{ textAlign: "center" }}>
          {t("NEOMODE.LookingSellers")}
        </label>
      </div>
    ) : sellersStatus === "error" ||
      (sellersStatus === "success" && sellers?.length === 0) ? (
      <div>
        <br />
        {errorMessage || t("NEOMODE.LookingSellersError")}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 24,
          }}
        >
          <Button
            onClick={() => setStepSellers("location")}
            style={{ marginRight: 24 }}
          >
            {t("NEOMODE.Back")}
          </Button>
          <Button onClick={() => handleGetSellers()}>
            {t("NEOMODE.TryAgain")}
          </Button>
        </div>
      </div>
    ) : (
      sellers?.length > 0 && (
        <div>
          <br />
          {t("NEOMODE.ChooseSeller")}
          <div
            style={{
              height: "100%",
              maxHeight: 132,
              overflowY: "auto",
              marginTop: 12,
            }}
          >
            <Radio.Group
              required
              onChange={({ target: t }) => setSellerSelected(t.value)}
              defaultValue={sellers[0].id}
            >
              {sellers.map(({ id, fullName, fullAddress }) => (
                <Radio value={id} key={id}>
                  {fullName}
                  <br />
                  <label style={{ fontSize: "10px", color: "gray" }}>
                    {fullAddress}
                  </label>
                </Radio>
              ))}
            </Radio.Group>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
          >
            <Button onClick={goBackStep} style={{ marginRight: 24 }}>
              {t("NEOMODE.Back")}
            </Button>
            <Button disabled={!sellerSelected} onClick={goNextStep}>
              {t("NEOMODE.Continue")}
            </Button>
          </div>
        </div>
      )
    );
  }
};

export default NeomodeSellers;
