import styled from "styled-components";

export const VariationsList = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background: #f3f3f3;

  .products-prod {
    outline: 0;
    margin-top: 6px;
    width: 140px;
    height: 270px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    label {
      background: var(--corPrimaria);
      color: white;
      text-align: center;
      z-index: 1;
      margin-top: 3px;
      transform: translateY(-13px);
      font-size: 12px;
      align-self: center;
      border-radius: 4px;
      padding: 3px 5px;
      @media (max-width: 795px) {
        font-size: 10px;
        transform: translateY(-11px);
      }
    }

    .content-description {
      display: block;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      padding: 10px 16px 16px 16px;
    }

    .content-price {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (max-width: 795px) {
        width: 70%;
        h3 {
          font-size: 0.9em;
          margin-bottom: 0px;
        }
        h5 {
          font-size: 0.7em;
          margin-bottom: 0px;
        }
      }
    }

    .button-add {
      width: 100%;
      margin: 10px 0px 0px 0px;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      font-size: 0.98em;
      font-weight: 500;

      @media (max-width: 795px) {
        padding: 6px 5px 0px 5px;
        border-radius: 15px;
        width: 100%;
        font-size: 1em;
      }
    }

    .product-img {
      margin-top: 20px;
      cursor: pointer;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    }

    .product-img-grayscale {
      margin-top: 20px;
      cursor: pointer;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      filter: grayscale(1);
    }

    .product-price {
      height: 24px;
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      font-size: 13px;
      padding: 3px 5px;

      h5 {
        text-align: start;
        font-size: 13px;
        font-weight: bold;
        color: black;
        margin-bottom: 0;
        margin-right: 5px;
      }
      h4 {
        text-align: center;
        font-size: 13px;
        color: gray;
        margin-bottom: 0;
      }
    }

    @media (max-width: 425px) {
      .product-price {
        h5 {
          font-size: 12px;
        }
        h4 {
          font-size: 12px;
        }
      }
    }

    @media (max-width: 795px) {
      .product-price {
        transform: translateY(-8px);
        font-size: 13px;
        padding: 3px 5px;
        h5 {
          margin: auto 2px 3px 0;
        }
      }
      max-width: 170px;
      min-height: 160px;

      img {
        min-width: 20px;
        margin-left: 2px;
      }
    }
  }

  .no-products-yet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 105px;
    /* border: 1px solid var(--corPrimaria); */
    border-radius: 8px;
    background: white;
    margin-top: 10px;
    padding: 0 10px;

    article {
      display: flex;
      position: absolute;
      background: ${({ corPrimaria: c }) => c};
      color: white;
      border-radius: 12px;
      padding: 8px 22px;
      top: 4px;
      transform: translateX(60px);
      label {
        margin: 0 2px 2px 0;
        font-size: 12px;
        line-height: calc(5px + 0.1vw);
        color: white;
      }
    }

    label {
      color: var(--corSecundaria);
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      margin-bottom: 6px;
    }

    span {
      color: grey;
      font-size: 10px;
      text-align: center;
    }
  }
`;
