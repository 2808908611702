import React, { useEffect, useState } from "react";
import {
  Modal,
  Table,
  Menu,
  Dropdown,
  message,
  Button,
  Input,
  Radio,
} from "antd";
import { useTranslation } from "react-i18next";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { managerService } from "../../../../services";
import { formatCash } from "../../../../utils";
import * as s from "./styled-products-tab";

const { SubMenu, Item } = Menu;
const VtexCatalog = ({
  open = false,
  products = [],
  onFinish = () => {},
  onClose = () => {},
  unidadeMonetaria,
}) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const [catalogInformation, setCatalogInformation] = useState({
    lastItem: null,
    moreItems: false,
  });
  const [searchCatalogInformation, setSearchCatalogInformation] = useState({
    lastItem: null,
    moreItems: false,
  });
  const [filteredCatalog, setFilteredCatalog] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [searchField, setSearchField] = useState("skuId");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingNewProducts, setLoadingNewProducts] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "produtoId",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.produtoId - b.produtoId,
    },
    {
      title: "SKU's",
      dataIndex: "skus",
      render: (a) => a.join(" - "),
    },
    {
      title: "Produto",
      dataIndex: "imagemUrl",
      render: (dataIndex, row) => (
        <img src={dataIndex} style={{ width: "100px" }} alt={row.produtoNome} />
      ),
    },
    {
      title: "Nome",
      dataIndex: "produtoNome",
    },
    {
      title: "Tamanhos",
      dataIndex: "tamanhos",
      render: (a) => a.join(" - "),
    },
    {
      title: "Estoques (Total)",
      dataIndex: "estoques",
      render: (a) =>
        a.join(" - ") +
        ` (${a.reduce((a, b) => parseInt(a) + parseInt(b), 0)})`,
    },
    {
      title: "Preço Original",
      dataIndex: "precoOriginal",
      render: (dataIndex) => (
        <label style={{ whiteSpace: "nowrap" }}>
          {formatCash(dataIndex, false, unidadeMonetaria)}
        </label>
      ),
    },
    {
      title: "Preço",
      dataIndex: "preco",
      render: (dataIndex) => (
        <label style={{ whiteSpace: "nowrap" }}>
          {formatCash(dataIndex, false, unidadeMonetaria)}
        </label>
      ),
    },
  ];

  useEffect(() => {
    managerService
      .getCategories()
      .then((data) => setCategories(data))
      .catch(() =>
        message.error(t("PRODUCTS-TAB-CATALOG.CategoriesErrorDescription"))
      );
  }, []);

  useEffect(() => {
    setSelectedRows([]);

    if (!loading) {
      setLoading(true);
      setTimeout(() => setLoading(false), 100);
    }
  }, [open]);

  useEffect(() => {
    getproducts();
  }, [selectedCategory]);

  const getproducts = (newProducts) => {
    if (newProducts) setLoadingNewProducts(true);
    else setLoading(true);
    let sendLastItem = newProducts ? catalogInformation.lastItem : null;
    managerService
      .getCatalog(sendLastItem, selectedCategory?.id)
      .then((resp) => {
        if (newProducts) setCatalog([...catalog, ...resp.catalog]);
        else setCatalog(resp.catalog);
        setCatalogInformation({
          lastItem: resp.lastItem,
          moreItems: resp.moreItems,
        });
      })
      .catch(() =>
        message.error(t("PRODUCTS-TAB-CATALOG.CatalogErrorDescription"))
      )
      .finally(() => {
        if (newProducts) setLoadingNewProducts(false);
        else setLoading(false);
      });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, rowsSelected) => setSelectedRows(rowsSelected),
    getCheckboxProps: (record) => ({
      disabled: products.map((p) => p.codigo).includes(record.skus[0]),
    }),
    selectedRows,
  };

  const renderCategories = ({ name, children, id }) => {
    let onClick = () => setSelectedCategory({ name, id });
    if (children && children.length > 0) {
      return (
        <SubMenu
          title={name}
          key={id}
          popupClassName={children.length > 8 && "Submenu-class"}
        >
          <Item key={id} onClick={onClick}>
            {t("PRODUCTS-TAB-CATALOG.All")}
          </Item>
          <Scrollbars
            style={{ height: children.length * 32, width: 200, maxHeight: 380 }}
            renderThumbHorizontal={() => <div style={{ display: "none" }} />}
          >
            {children.map((subCategories) => renderCategories(subCategories))}
          </Scrollbars>
        </SubMenu>
      );
    } else {
      return (
        <Item key={id} onClick={onClick}>
          {name}
        </Item>
      );
    }
  };

  const filterCatalogBySearch = (text) => {
    if (text) {
      setLoadingSearch(true);
      let searchMoreItems = "";
      if (searchCatalogInformation.moreItems) {
        searchMoreItems = searchCatalogInformation.lastItem;
        setLoadingNewProducts(true);
      }
      managerService
        .searchCatalog(searchField, text, searchMoreItems)
        .then((resp) => {
          setFilteredCatalog([...filteredCatalog, ...resp.catalog]);
          if (resp.moreItems)
            setSearchCatalogInformation({
              moreItems: true,
              lastItem: resp.lastItem,
            });
          else if (searchCatalogInformation.moreItems)
            setSearchCatalogInformation({
              moreItems: false,
              lastItem: "",
            });
        })
        .catch((e) =>
          message.error(t("PRODUCTS-TAB-VTEX-CATALOG.SearchCodeError"))
        )
        .finally(() => {
          setLoadingSearch(false);
          if (searchCatalogInformation.moreItems) setLoadingNewProducts(false);
        });
    } else setFilteredCatalog([]);
  };

  return (
    <Modal
      visible={open}
      okText={t("PRODUCTS-TAB-VTEX-CATALOG.Add")}
      okButtonProps={{ disabled: selectedRows.length === 0 }}
      onOk={() => onFinish(selectedRows)}
      cancelText={t("PRODUCTS-TAB-VTEX-CATALOG.back")}
      onCancel={() => onClose()}
      width={"100%"}
      bodyStyle={{ padding: 0 }}
      title={
        <s.OverlayContainer>
          {t("PRODUCTS-TAB-VTEX-CATALOG.VTEXCatalog")}
          <div style={{ display: "flex" }}>
            <Dropdown
              size="small"
              overlay={() => (
                <Menu className="Menu-Class">
                  <Item onClick={() => setSelectedCategory(false)}>
                    {t("PRODUCTS-TAB-CATALOG.All")}
                  </Item>
                  {categories.map((category) => renderCategories(category))}
                </Menu>
              )}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  cursor: "pointer",
                }}
              >
                {selectedCategory?.name
                  ? selectedCategory.name
                  : t("PRODUCTS-TAB-CATALOG.Categories")}{" "}
                <DownOutlined />
              </label>
            </Dropdown>
          </div>
        </s.OverlayContainer>
      }
    >
      {catalog.length === 0 ? (
        <div style={{ textAlign: "center", margin: "50px" }}>
          {loading ? (
            <LoadingOutlined style={{ color: "gray", fontSize: "20px" }} />
          ) : selectedCategory ? (
            t("PRODUCTS-TAB-CATALOG.ProductCategorieNotFound")
          ) : (
            t("PRODUCTS-TAB-VTEX-CATALOG.NoProducts")
          )}
        </div>
      ) : loading ? (
        <div style={{ textAlign: "center", margin: "50px" }}>
          <LoadingOutlined style={{ color: "gray", fontSize: "20px" }} />
        </div>
      ) : (
        open && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <s.SearchContainer>
              <label>
                {t("PRODUCTS-TAB-CATALOG.SearchFor")}
                <Radio.Group
                  onChange={({ target: t }) => {
                    setSearchField(t.value);
                    if (searchCatalogInformation.moreItems) {
                      setSearchCatalogInformation({
                        moreItems: false,
                        lastItem: null,
                      });
                      setFilteredCatalog([]);
                    }
                  }}
                  value={searchField}
                  disabled={loading || loadingSearch}
                >
                  <Radio value={"skuId"}>SKU</Radio>
                  <Radio value={"productId"}>ID</Radio>
                  <Radio value={"searchWord"}>
                    {t("PRODUCTS-TAB-VTEX-CATALOG.Word")}
                  </Radio>
                </Radio.Group>
              </label>
              <Input.Search
                placeholder={
                  searchField === "skuId" || searchField === "productId"
                    ? `${t("PRODUCTS-TAB-VTEX-CATALOG.TypeThe")} ${
                        searchField === "skuId" ? "SKUs" : "IDs"
                      } ${t("PRODUCTS-TAB-VTEX-CATALOG.OfTheProduct")}`
                    : `${t("PRODUCTS-TAB-VTEX-CATALOG.TypeTheWord")}s ${t(
                        "PRODUCTS-TAB-VTEX-CATALOG.OfTheProduct"
                      )}`
                }
                onSearch={(v) => filterCatalogBySearch(v)}
                loading={loadingSearch}
                disabled={loading || loadingSearch}
                style={{ width: "350px", margin: "0 20px 0 0px" }}
                value={searchText}
                onChange={({ target: { value: v } }) => {
                  if (!v || v === "") setFilteredCatalog([]);
                  if (searchCatalogInformation.moreItems)
                    setSearchCatalogInformation({
                      moreItems: false,
                      lastItem: null,
                    });
                  setSearchText(v);
                }}
              />
            </s.SearchContainer>
            <Table
              loading={loading}
              rowSelection={{
                type: `checkbox`,
                ...rowSelection,
              }}
              style={{ width: "100%" }}
              columns={tableColumns}
              rowKey="produtoId"
              pagination={false}
              dataSource={
                filteredCatalog.length > 0 ? filteredCatalog : catalog
              }
            />

            <Button
              onClick={() => {
                if (searchCatalogInformation.moreItems)
                  filterCatalogBySearch(searchText);
                else getproducts(true);
              }}
              type="default"
              className="styleBtn"
              style={{ margin: "20px auto" }}
              loading={loadingNewProducts}
              disabled={
                loadingNewProducts ||
                loading ||
                !catalogInformation.moreItems ||
                (searchText && !searchCatalogInformation.moreItems)
              }
            >
              {searchCatalogInformation.moreItems ||
              catalogInformation.moreItems
                ? t("PRODUCTS-TAB-VTEX-CATALOG.LoadMore")
                : t("PRODUCTS-TAB-VTEX-CATALOG.NoItensToLoad")}
            </Button>
          </div>
        )
      )}
    </Modal>
  );
};

export default VtexCatalog;
