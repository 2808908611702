import styled from "styled-components";
import { motion } from "framer-motion";

export const LiveContainer = styled.div`
  min-height: calc(100vh - 56px);
  width: 100%;
  background: #f3f3f3;
  display: block;
  @media (max-width: 955px) {
    background: rgba(248, 248, 248, 1);
    overflow: hidden;
  }
`;
export const WarningOrientation = styled.div`
  background-image: url(/assets/images/bgPrimary.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  font-size: 23px;
  padding: 15% 9%;
  text-align: center;
  color: white;
  display: block;
  position: absolute;
  z-index: 99;
  label {
    display: inline;
    font-weight: bold;
  }
  @media (orientation: portrait) {
    display: none;
  }
`;
export const Content = styled.div`
  display: flex;
  width: 100%;
  padding-left: 20px;
  @media (max-width: 955px) {
    padding: 0;
    background: rgba(248, 248, 248, 1);
  }
`;
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 955px) {
    background: rgba(248, 248, 248, 1);
    margin-bottom: ${({ vertical: v }) =>
      v ? "0px;min-width: 100vw" : "200px"};
  }
`;
export const Title = styled.div`
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e1240;
  padding: 20px !important;
  @media (max-width: 955px) {
    padding: 15px !important;
    font-size: 16px;
  }
`;
export const Timer = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 0 0 0;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 80px);
`;

export const ElementsContainer = styled.div`
  display: flex;
  padding: 16px;
  gap: 16px;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 590px) {
    width: 100%;
    max-width: 328px;
    margin: 0 auto;
  }

  @media (max-width: 320px) {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  img {
    width: 100%;
    max-width: 768px;
    height: 51vh;
    border-radius: 22px;

    @media (max-width: 955px) {
      height: 40vh;
    }

    @media (max-width: 590px) {
      max-width: 328px;
      height: 176px;
    }
  }

  .welcome {
    display: flex;
    gap: 8px;
    align-items: center;
    width: fit-content;
    padding: 4px 10px;
    border: 1px solid #0000001a;
    border-radius: 100px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  h1 {
    font-size: 40px;
    color: #000000;
    margin: 24px 0;
    font-weight: bold;

    @media (max-width: 955px) {
      font-size: 24px;
    }
  }

  .description {
    font-size: 16px;
    color: #00000099;
    max-width: 756px;
    flex-wrap: wrap;

    @media (max-width: 955px) {
      font-size: 16px;
    }

    //Iphone 5/SE
    @media (min-device-height: 568px) and (max-device-width: 320px) and (orientation: portrait) {
      font-size: calc(10px + 1vw);
    }
    //Moto G4 - Galaxy S5
    @media (min-device-height: 640px) and (max-device-width: 360px) and (orientation: portrait) {
      font-size: calc(10px + 1vw);
    }
  }

  @media (max-width: 955px) {
    align-items: center;
  }
`;

export const TimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 544px;

  .timer-wrapper {
    border-radius: 16px;
    border: 1px solid #0000001a;
    box-shadow: 0px 4px 12px 0px #0000000a;
    padding: 32px 24px;

    label {
      display: flex;
      font-weight: 500;
      font-size: 1rem;
      color: #000000;
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 1280px) {
    margin: 0 auto;
  }

  @media (max-width: 590px) {
    width: 100%;
    max-width: 328px;
    margin: 0 auto;
  }

  @media (max-width: 320px) {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
`;

export const ExclusiveLiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding: 16px;
  height: fit-content;
  border: 1px solid #0000001a;
  box-shadow: 0px 4px 12px 0px #0000000a;

  .text-exclusive {
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    border: 1px solid #944af233;
    border-radius: 100px;
    padding: 0.5rem 1rem;
    color: #944af2;
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 0.875rem;
    background: rgb(148, 74, 242, 5%);
  }

  span {
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    color: #00000099;
    flex-wrap: wrap;
  }

  .validate-code {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .div-icon {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        font-size: 0.875rem;
        font-weight: 600;
        color: black;
      }
    }

    .div-input {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      gap: 0.5rem;

      label {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.25rem;
        color: #00000099;
        margin-right: auto;
      }

      .error-message {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #f44336;
      }

      .div-input-button {
        display: flex;
        width: 100%;

        .text-button {
          font-size: 0.875rem;
          font-weight: 700;
          color: #3e3e3e;
        }

        input {
          padding: 1rem;
          width: 100%;
          height: 2.5rem;
          margin-right: 1rem;
          border-radius: 4px;
          border: 1px solid #000000;
        }

        @media (max-width: 955px) {
          flex-direction: column;
          gap: 0.5rem;
        }
      }
    }
  }
`;

export const MessageVerification = styled.div`
  display: flex;
  margin-top: 16px;
  padding-bottom: 12px;
  align-items: center;
  gap: 12px;

  img {
    width: 14px;
    height: 14px;
  }

  div {
    display: flex;
    flex-direction: column;

    p {
      font-size: 1rem;
      font-weight: 700;
      color: #944af2;
      margin-bottom: -4px;
    }

    span {
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
`;

export const LiveDataContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: black;
  }

  .div-row {
    display: flex;
    gap: 24px;
  }

  .div-column {
    display: flex;
    flex-direction: column;
    width: 50%;

    p {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.25rem;
      color: #000000;
    }

    span {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: #00000099;
    }
  }
`;

export const CountDownContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 6.25rem;
  border: 0.125rem dashed #00000033;
`;

export const ContainerTimeLeft = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 0 16px;

  .border {
    border: 2px solid black;
    width: 8px;
  }

  @media (max-device-width: 500px) and (orientation: portrait) {
    gap: 10px;
  }
`;

export const TimeColumn = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;

  h1 {
    font-size: 32px;
    font-weight: 600;
    margin: 0;
    opacity: ${({ disabled }) => (disabled ? "0.2" : "1")};
    color: black;

    @media (max-width: 955px) {
      font-size: 38px;
    }

    @media (max-device-width: 500px) and (orientation: portrait) {
      font-size: 18px;
    }
  }

  p {
    font-size: 10px;
    font-weight: 500;
    opacity: ${({ disabled }) => (disabled ? "0.2" : "1")};
    color: black;

    @media (max-device-width: 500px) and (orientation: portrait) {
      font-size: 8px;
    }
  }
`;

export const TopBottom = styled.div`
  width: 100%;
  height: 60px;
  background: var(--corPrimaria);
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 150px;
    height: 40px;
    border-radius: 5px;
  }
`;

export const AudienceLoginContainer = styled.div`
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .exclusive {
    font-weight: 500;
    font-size: calc(10px + 1vw);
    margin: 10px 0;
    text-align: center;
  }

  .description {
    font-weight: 300;
    font-size: calc(10px + 1vw);
    margin: 10px;
    text-align: center;
  }

  h1 {
    font-size: calc(10px + 3vw);
    color: ${({ corPrimaria: c }) => c};
    margin: 0 40px 20px 40px;
    text-align: center;
  }
  p {
    font-size: calc(10px + 0.5vw);
    color: ${({ corPrimaria: c }) => c};
    width: 70vw;
    max-width: 450px;
    text-align: center;
  }
  .ant-input-wrapper {
    display: flex;
    justify-content: center;
    input {
      max-width: 300px;
      margin: 0 20px 20px 20px;
    }
    span {
      display: none;
    }
  }
`;
