import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  awinServices,
  customerService,
  firestore,
  googleTagManager,
  paymentService,
  productService,
} from "../../../services";
import * as s from "./styles-cart";
import {
  formatCash,
  sourceImage,
  createLinkElementInsideText,
} from "../../../utils";
import { ModalPaymentPix, CheckoutNeomode } from "../../../components";
import {
  Select,
  Input,
  Button,
  Modal,
  message,
  Drawer,
  Tooltip,
  Radio,
} from "antd";
import {
  DeleteOutlined,
  ShoppingOutlined,
  LoadingOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ModalPayment from "./modal-payment";
import ModalVariations from "./modal-variations";
import ModalFreeShipping from "./modal-free-shipping";
import GradeAberta from "./grade-aberta";
import GradeAbertaB2B from "./grade-aberta-b2b";
import GradeFechada from "./grade-fechada";
import ModalShipping from "./modal-shipping";
import Frete from "./frete";
import {
  GradesContext,
  NeomodeProvider,
  OrdersContext,
  ThemeContext,
  UserContext,
  useTabsNavigation,
  AddProductContext,
} from "../../../context";
import Scrollbars from "react-custom-scrollbars";
import ChangeQuantityInput from "./change-quantity-input";

const { Option } = Select;
const Cart = ({ live, show }) => {
  const { t } = useTranslation();
  const {
    tabActive,
    handleChangeTabActive,
    setCartBadge,
    setMainProductDocId,
    mainProductId,
    setMainProductId,
  } = useTabsNavigation();
  const { corPrimaria, corSecundaria } = useContext(ThemeContext);
  const { user } = useContext(UserContext); //dados do usuário
  const { userGrades } = useContext(GradesContext); //grades do admin da live
  const { handleGetOrders } = useContext(OrdersContext);
  const { addProduct, setAddProduct } = useContext(AddProductContext);
  const [cart, setCart] = useState([]); //favoritos do usuário
  const [products, setProducts] = useState([]); //todos os produtos da live
  const [closedGridEditProduct, setClosedGridEditProduct] = useState(false);
  //Control
  const [enderecoEntrega, setEnderecoEntrega] = useState(false); //endereço de entrega
  const [freteSelecionado, setFreteSelecionado] = useState(false); //frete selecionado para entrega
  const [modalShipping, setModalShipping] = useState(false); //modal de endereço de entrega
  const [modalVariation, setModalVariation] = useState(false); //modal das variações
  const [modalPay, setModalPay] = useState(false); //modal dos dados de pagamento e endereço de cobrança
  const [modalPayPix, setModalPayPix] = useState(false); //modal dos dados de pagamento do pix
  const [modalNeomodeIsOpen, setModalNeomodeIsOpen] = useState(false); //se modal de checkout neomode está aberta
  const [formaPagto, setFormaPagto] = useState("cc"); //forma de pagamento cc / pix
  const [qrCode, setQrCode] = useState(""); //data do qr code
  const [modalFreeShipping, setModalFreeShipping] = useState(false); //modal de aviso de frete grátis
  // const [productId, setProductId] = useState(false); //id do produto para as variações
  // const [productDocId, setProductDocId] = useState(false); //docId do produto para as variações
  const [deletingProd, setDeletingProd] = useState(false); //deletando produto
  const [prodDelete, setProdDelete] = useState(false); //produto com popconfirm que vai ser deletado
  const [changes, setChanges] = useState(false); //se tem alterações não salvas no carrinho
  const [saveTime, setSaveTime] = useState(false); //tempo para o usuário fazer alterações até salvar
  const [saving, setSaving] = useState(false); //se está salvando as alterações
  const [observacao, setObs] = useState(""); //observação do usuário
  const [condicao, setCondicao] = useState(false); //condição de pagamento
  const [customConditions, setCustomConditions] = useState(false); //condicoes custom (quando eh pagarme ou quando for minhas condicoes com parcela mínima)
  const [finishing, setFinishing] = useState(false); //se está finalizando o pedido
  const [orderCompleted, setOrderCompleted] = useState(false);
  const [productsOutOffStock, setProductsOutOffStock] = useState([]);
  const [hasChangedShippingAddres, setHasChangedShippingAddres] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [windowMeasures, setWindowMeasures] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  let liveWithFrete =
    live.formaEntrega.correios?.cepOrigem || live.formaEntrega.freteProprio;
  let minValueFreeShipping = live.formaEntrega?.freteGratis?.valorMinimo;

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);

  const updateWindowWidth = () => {
    setWindowMeasures({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    if (!loading) {
      let subscribe = firestore.collection(`lives/${live.liveId}/produtos`);
      if (live?.tabelaPreco && user?.idTabelaPreco)
        subscribe = subscribe.where("tabelaPreco", "==", user.idTabelaPreco);

      subscribe = subscribe.onSnapshot((snapshot) => {
        setProducts(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            preco: parseFloat(doc.data().preco / 100),
            precoOriginal: parseFloat(
              doc.data()?.precoOriginal ? doc.data().precoOriginal / 100 : 0
            ),
            docId: doc.id,
          }))
        );
      });
      return () => subscribe();
    }
  }, [loading]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 5000);
  }, []);

  useEffect(() => {
    if (!condicao && live?.condicoes.length > 0) setCondicao(live.condicoes[0]);
  }, [live]);

  useEffect(() => {
    if (
      live?.paymentService === "pagarme" &&
      live?.condicoes.length > 0 &&
      cart.length > 0
    ) {
      setCondicao(false);
      let copyCart = [...cart];
      let livePrice = copyCart
        .map(
          (f) =>
            f.produto.grade
              .map((g) => parseInt(g.quantidade))
              .reduce((a, b) => a + b, 0) * parseFloat(f.produto.preco)
        )
        .reduce((a, b) => a + b, 0);
      let parcelaMax = Math.floor(livePrice / (live.valorParcelaMinima / 100));
      setCustomConditions(
        live.condicoes.filter((x, i) => (i === 0 ? true : x <= parcelaMax))
      );
    }
    setCartBadge(cart.length);
  }, [changes, cart]);

  useEffect(() => {
    if (user && (user.email || (user.hash && user.anonimo))) {
      let userCod = user.codigo || user.hash;
      let subscribe = firestore
        .collection("lives")
        .doc(live?.liveId)
        .collection("favoritos")
        .where("codigo", "==", userCod)
        .onSnapshot((snapshot) => {
          setCart(
            snapshot.docs
              .map((doc) => ({
                ...doc.data(),
                produto: {
                  ...doc.data().produto,
                  preco: parseFloat(doc.data().produto.preco / 100),
                  precoOriginal: parseFloat(
                    doc.data().produto?.precoOriginal
                      ? doc.data().produto.precoOriginal / 100
                      : 0
                  ),
                },
                docId: doc.id,
              }))
              .sort((a, b) => a.timeAddFavorito - b.timeAddFavorito)
          );
        });
      return () => subscribe();
    }
  }, [user]);

  useEffect(() => {
    if (!modalVariation)
      setTimeout(() => {
        setMainProductId(false);
        setMainProductDocId(false);
      }, 400); //para produto não sumir antes da modal fechar
  }, [modalVariation]);

  useEffect(() => {
    if (changes) {
      if (saveTime) clearTimeout(saveTime);
      setSaveTime(
        setTimeout(() => {
          let cartCopy = JSON.parse(JSON.stringify(cart));
          let validProducts = cartCopy
            .filter((p) => p.changes && p?.produto?.grade.length > 0)
            .map((p) => {
              p.produto.preco = parseFloat((p.produto.preco * 100).toFixed(2));
              p.produto.precoOriginal = parseFloat(
                (p.produto?.precoOriginal
                  ? p.produto.precoOriginal * 100
                  : 0
                ).toFixed(2)
              );
              if (p.produto.tipoGrade === "fechada")
                p.produto.nomeGrade = userGrades.filter(
                  (g) => g.gradeId === p.produto.gradeId
                )[0].nome;
              delete p.changes;
              return p;
            });
          if (validProducts?.length > 0) {
            setChanges(false);
            setSaving(true);
            setProdDelete(false);
            let count = 0;
            Promise.all(
              validProducts.map(({ docId, produto }) => {
                productService
                  .editFavorite(live.liveId, docId, {
                    produto,
                    customer: { ...user, codigo: user.codigo || user.hash },
                  })
                  .then(() => {
                    googleTagManager.editProduct(produto, "cart");
                    count += 1;
                    if (count === validProducts.length) setSaving(false);
                  })
                  .catch(() => {
                    message.error(t("CART.ProductWith0QuantityWarning"), 6);
                    count += 1;
                    if (count === validProducts.length) setSaving(false);
                  });
              })
            );
          } else {
            message.warning(t("CART.ProductWith0QuantityWarning"), 6);
          }
        }, 3000)
      );
    }
  }, [changes]);

  useEffect(() => {
    if (productsOutOffStock.length > 0) {
      let db = firestore.collection(`lives/${live.liveId}/produtos`);
      productsOutOffStock.map((prodOffStock) => {
        if (prodOffStock.esgotado) {
          let prodIndex = products.findIndex(
            (x) => x.docId === prodOffStock.docId
          );
          db.doc(products[prodIndex].docId).update({
            esgotado: true,
          });
        }
      });
    }
  }, [productsOutOffStock]);

  const verifyProductQuantity = (lista) => {
    let disabled = false;

    for (var i = 0; i < lista.length; i++) {
      if (
        lista[i].produto.grade[0].quantidade &&
        lista[i].produto.quantidadeMaximaVendaProduto
      ) {
        if (
          lista[i].produto.grade[0].quantidade >
          lista[i].produto.quantidadeMaximaVendaProduto
        ) {
          disabled = true;
          break;
        }
      }
    }
    return disabled;
  };

  const changeProducts = (newGrade, index, gradeId, quantiGrade) => {
    let newCart = [...cart];
    newCart[index].produto.grade = newGrade;
    newCart[index].changes = true;
    if (gradeId) {
      newCart[index].produto.gradeId = gradeId;
      newCart[index].produto.quantiGrade = quantiGrade;
    }
    setCart(newCart);
    setCondicao(false); // Necessário para evitar o bug de comprar vários produtos e pagar por apenas um frete.
    if (
      live.layoutCheckout === "padrao" ||
      newCart[index].produto.tipoGrade === "fechada"
    )
      setChanges(changes === 1 ? 2 : 1);
    else {
      setSaving(true);
      let productToSave = JSON.parse(JSON.stringify(newCart[index]));

      productToSave.produto.preco = parseFloat(
        (productToSave.produto.preco * 100).toFixed(2)
      );
      productToSave.produto.precoOriginal = parseFloat(
        (productToSave.produto.precoOriginal * 100).toFixed(2)
      );
      delete productToSave.changes;

      productService
        .verifyQuantityProduct({
          liveId: live.liveId,
          codigo: user.codigo || user.anonimo,
          productId: productToSave.produto.docId,
          quantity: productToSave.produto.grade.reduce(
            (soma, g) => soma + g.quantidade,
            0
          ),
        })
        .then(() => {
          productService
            .editFavorite(live.liveId, productToSave.docId, {
              produto: productToSave.produto,
              customer: { ...user, codigo: user.codigo || user.hash },
            })
            .then(() =>
              googleTagManager.editProduct(productToSave.produto, "cart")
            )
            .finally(() => setSaving(false));
        })
        .catch((e) => {
          if (user.codigo) {
            message.error(
              e.message +
                " " +
                t("PRODUCTS-TAB-VARIATION-MODAL.Available") +
                ": " +
                e.quantityAvailable
            );
          } else {
            message.error(e.message);
          }
          setSaving(false);
        });
    }
  };

  const deleteProduct = () => {
    setDeletingProd(true);
    if (
      prodDelete.deletingSizeFromProduct &&
      prodDelete.produto.grade.length > 1
    ) {
      let { tamGrade, index } = prodDelete.deletingSizeFromProduct;
      let newGrade = prodDelete.produto.grade.filter(
        ({ tamanho }) => tamanho !== tamGrade
      );
      changeProducts(newGrade, index);
      message.success({
        content: t("CART.ProductRemovedWait"),
        duration: 4,
      });
      setDeletingProd(false);
      setProdDelete(false);
    } else {
      productService
        .deleteFavorite(live.liveId, prodDelete.docId, {
          ...user,
          codigo: user.codigo || user.hash,
        })
        .then(() => {
          googleTagManager.removeProductFromCart(prodDelete, "cart");
          setProdDelete(false);
          setDeletingProd(false);
          message.success({ content: t("CART.ProductRemoved"), duration: 2 });
        })
        .catch(() => {
          setDeletingProd(false);
          message.error({
            content: t("CART.ErrorRemoveProduct"),
            duration: 5,
          });
        });
    }
  };

  const handleOutOffStockProducts = (thisProducts) => {
    let tmpOutOffStock = [...productsOutOffStock];
    let tmp = [];

    thisProducts.map((prod, idx) => {
      if (prod.grade.length > 0) {
        tmp[idx] = { produto: prod.descricao, tamanhos: [], docId: prod.docId };
        prod.grade.map(
          (tamanho) =>
            (tmp[idx] = {
              ...tmp[idx],
              tamanhos: [...tmp[idx].tamanhos, tamanho.tamanho],
            })
        );
      }
    });

    for (let i = 0; i < cart.length; i++) {
      if (cart[i].produto.stock?.length > 0) {
        let count = 0;
        cart[i].produto.stock.map((s, idx) => {
          if (s === 0) count++;
        });
        if (count === cart[i].produto.stock.length)
          tmp[i] = { ...tmp[i], esgotado: true };
      }
    }

    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].esgotado)
        message.error({
          content: `${tmp[i].produto} ${t("CART.IsOutOffStock")} :(`,
          duration: 5,
        });
      else
        message.warning({
          content: `${tmp[i].produto} ${t("CART.SizeOutOffStock")}: ${tmp[
            i
          ].tamanhos.toString()}`,
          duration: 5,
        });

      if (!tmpOutOffStock.includes(tmp[i])) tmpOutOffStock.push(tmp[i]);
    }
    setProductsOutOffStock(tmpOutOffStock);
  };

  const checkUserLogged = () => {
    if (
      !user.email ||
      !user.name ||
      (!live?.liveEstrangeira && (!user.codigo || !user.phone))
    ) {
      setFinishing(false);
      if (modalFreeShipping) setModalFreeShipping(false);
      document.getElementById(`btn-login-customer`).click();
      message.info({
        content: t("CART.LoginToEndRequest"),
        duration: 2,
        key: "add-product-to-cart",
      });
      window.scrollTo(false, 0);
      return false;
    }
    return true;
  };

  const finish = () => {
    setFinishing(true);
    if (checkUserLogged()) {
      if (live.checkout === "vtex")
        productService
          .finishOrderVtex(live.liveId, user)
          .then(({ urlRedirect }) => {
            googleTagManager.checkoutPushStep(cart, "finished-vtex");
            setFinishing(false);
            Modal.success({
              title: t("CART.RequestClosed"),
              content: t("CART.RequestClosedRedirect"),
              okText: t("CART.GotIt"),
              onOk: () => {
                var win = window.open(urlRedirect, "_blank");
                win.focus();
              },
            });
            setModalPay(false);
            if (user?.codigo) handleGetOrders(user.codigo, live.liveId);
          })
          .catch(() => {
            setFinishing(false);
            message.error({
              content: t("CART.ErrorCloseRequest"),
              duration: 5,
            });
          });
      else if (live.checkout === "magento") {
        let bodyMagento = [];
        cart.forEach(({ produto }) => {
          produto.grade.forEach((grade) => {
            bodyMagento.push([
              produto.skus[
                produto.tamanhos.findIndex((e) => e === grade.tamanho)
              ],
              grade.quantidade,
            ]);
          });
        });
        productService
          .finishOrderMagento({
            live: live.liveId,
            customer: user,
            items: bodyMagento,
          })
          .then(({ url }) => {
            googleTagManager.checkoutPushStep(cart, "finished-magento");
            setFinishing(false);
            Modal.success({
              title: t("CART.RequestClosed"),
              content: t("CART.RequestClosedRedirect"),
              okText: t("CART.GotIt"),
              onOk: () => {
                var win = window.open(url, "_blank");
                win.focus();
              },
            });
            setModalPay(false);
            if (user?.codigo) handleGetOrders(user.codigo, live.liveId);
          })
          .catch(() => {
            setFinishing(false);
            message.error({
              content: t("CART.ErrorCloseRequest"),
              duration: 5,
            });
          });
      } else {
        let data = {
          observacao,
          condicao,
          customer: { ...user, codigo: user.codigo || user.hash },
        };
        if (liveWithFrete) {
          data.enderecoEntrega = enderecoEntrega;
          data.freteSelecionado = freteSelecionado;
          data.valorFrete = freteSelecionado.value;
        }
        productService
          .finishOrder(live.liveId, data)
          .then(({ order }) => {
            googleTagManager.checkoutPushStep(cart, "finished");
            if (live.awinAdvertiserId && order?.amount && order?.orderId) {
              awinServices.confirmConversionPixel(order.amount, order.orderId);
              awinServices.fallBackConversionPixel(order.amount, order.orderId);
            }
            message.success({
              content: t("CART.RequestCompleted"),
              duration: 2,
            });
            if (live.listaAssinatura)
              Modal.confirm({
                title: t("CART.SignatureRegistered"),
                content: t("CART.YouBecameSubscriber"),
                okText: t("CART.GotIt"),
                cancelButtonProps: { style: { display: "none" } },
              });
            setModalPay(false);
            if (user?.codigo) handleGetOrders(user.codigo, live.liveId);
          })
          .catch((error) => {
            if (
              error.response.data.produtoSemEstoque &&
              error.response.data.produtoSemEstoque.length > 0
            )
              handleOutOffStockProducts(error.response.data.produtoSemEstoque);
            else
              message.error({
                content: t("CART.ErrorCloseRequest"),
                duration: 5,
              });
          })
          .finally(() => {
            setFinishing(false);
            setHasChangedShippingAddres(false);
            if (modalFreeShipping) setModalFreeShipping(false);
          });
      }
    }
  };

  const finishWithPayment = (paymentData) => {
    setFinishing(true);
    let data = {
      ...paymentData,
      customer: { ...user, codigo: user.codigo || user.hash },
      observacao,
      amount: parseInt(
        cart
          .map(
            (f) =>
              f.produto.grade
                .map((g) => parseInt(g.quantidade))
                .reduce((a, b) => a + b, 0) * parseFloat(f.produto.preco * 100)
          )
          .reduce((a, b) => a + b, 0)
      ),
      installments: condicao,
    };
    if (liveWithFrete) {
      data.enderecoEntrega = enderecoEntrega;
      data.freteSelecionado = freteSelecionado;
      data.amount = data.amount + freteSelecionado.value;
      data.valorFrete = freteSelecionado.value;
    }
    paymentService
      .finishOrder(live.liveId, data)
      .then(({ pedidoId = "não informado", produtos = [] }) => {
        googleTagManager.purchase(pedidoId, produtos, "finished-payment");
        setFinishing(false);
        message.success({ content: t("CART.RequestCompleted"), duration: 2 });
        if (live.listaAssinatura)
          Modal.confirm({
            title: t("CART.SignatureRegistered"),
            content: t("CART.YouBecameSubscriber"),
            okText: t("CART.GotIt"),
            cancelButtonProps: { style: { display: "none" } },
          });
        setModalPay(false);
        if (user?.codigo) handleGetOrders(user.codigo, live.liveId);
      })
      .catch((error) => {
        if (
          error.response.data.produtoSemEstoque &&
          error.response.data.produtoSemEstoque.length > 0
        )
          handleOutOffStockProducts(error.response.data.produtoSemEstoque);
        else if (error.response.data.message) {
          setFinishing(false);
          message.error({
            content: error.response.data.message,
            duration: 5,
          });
        } else {
          setFinishing(false);
          message.error({
            content: t("CART.ErrorCloseRequest"),
            duration: 5,
          });
        }
      });
    setModalPay(false);
  };

  const finishWithPix = () => {
    setFinishing(true);
    let body = {
      customer: { ...user, codigo: user.codigo || user.hash },
      observacao,
      amount: parseInt(
        cart
          .map(
            (f) =>
              f.produto.grade
                .map((g) => parseInt(g.quantidade))
                .reduce((a, b) => a + b, 0) * parseFloat(f.produto.preco * 100)
          )
          .reduce((a, b) => a + b, 0)
      ),
    };
    if (liveWithFrete) {
      body.enderecoEntrega = enderecoEntrega;
      body.freteSelecionado = freteSelecionado;
      body.amount = body.amount + freteSelecionado.value;
      body.valorFrete = freteSelecionado.value;
    }
    paymentService
      .finishOrderWithPix(live.liveId, body)
      .then(({ qrCode }) => {
        setQrCode(qrCode);
        message.success({ content: t("CART.RequestCompleted"), duration: 2 });
        setModalPayPix(true);
        if (user?.codigo) handleGetOrders(user.codigo, live.liveId);
      })
      .catch((error) => {
        if (error.response.data.message) {
          setFinishing(false);
          message.error({
            content: error.response.data.message,
            duration: 5,
          });
        } else
          message.error({
            content: t("CART.ErrorCloseRequest"),
            duration: 5,
          });
      })
      .finally(() => setFinishing(false));
  };

  const continueFinish = () => {
    if (live.checkout === "neomode") {
      if (checkUserLogged()) setModalNeomodeIsOpen(true);
    } else if (
      liveWithFrete &&
      freteSelecionado?.type !== "retirada" &&
      (!enderecoEntrega || !enderecoEntrega?.streetNumber || !freteSelecionado)
    ) {
      setModalShipping(true);
    } else if (live.paymentService) {
      if (checkUserLogged()) {
        if (
          live?.formaPagto === "pix" ||
          (live?.formaPagto === "ambas" && formaPagto === "pix")
        )
          finishWithPix();
        else setModalPay(true);
      }
    } else {
      finish();
    }
  };

  const handleFinish = () => {
    let productHasNoQuantity = false;
    cart.forEach((produto) => {
      if (produto.produto.grade.length === 0) productHasNoQuantity = true;
    });
    if (!productHasNoQuantity) {
      setSaving(true);
      Promise.all(
        cart
          .filter((p) => p.changes)
          .map((p) => {
            p.produto.preco = parseFloat((p.produto.preco * 100).toFixed(2));
            if (p.produto.tipoGrade === "fechada")
              p.produto.nomeGrade = userGrades.filter(
                (g) => g.gradeId === p.produto.gradeId
              )[0].nome;
            delete p.changes;
            return p;
          })
          .map(({ docId, produto }) =>
            productService
              .editFavorite(live.liveId, docId, {
                produto,
                customer: { ...user, codigo: user.codigo || user.hash },
              })
              .then(() => googleTagManager.editProduct(produto, "cart"))
          )
      )
        .then(() => {
          if (live.listaAssinatura) {
            let hasAssinaturaInCart = false;
            cart.forEach(({ produto: { produtoAssinatura } }) => {
              if (produtoAssinatura) hasAssinaturaInCart = true;
            });
            if (!hasAssinaturaInCart)
              customerService
                .verifyAssinatura(live.liveId, user.email)
                .then(() => continueFinish())
                .catch(() =>
                  Modal.confirm({
                    title: t("CART.LiveWithSubscription"),
                    content: t("CART.LiveRequestUserSubscription"),
                    okText: t("CART.GotIt"),
                    cancelButtonProps: { style: { display: "none" } },
                  })
                );
            else continueFinish();
          } else continueFinish();
        })
        .catch(() => {
          message.error(t("CART.ErrorSavingCart"));
        })
        .finally(() => setSaving(false));
    } else
      message.error({
        content: t("CART.ProductWithoutQuantity"),
        duration: 8,
      });
  };

  const renderProductDescription = (id, description) => {
    setTimeout(() => {
      let element = document.getElementById(id);
      if (description && element?.childNodes.length === 0) {
        if (!description.includes("http:") && !description.includes("https:")) {
          element.innerHTML = description;
        } else {
          let allWords = description.trim().replace(/\n/g, " ").split(" "); // separa todas as palavras
          element.appendChild(
            createLinkElementInsideText(allWords, true, t("UTILS.ProductLink"))
          );
        }
      }
    }, 300);
  };

  useEffect(() => {
    if (enderecoEntrega?.street && freteSelecionado) {
      if (!hasChangedShippingAddres) handleFinish();
    }
  }, [enderecoEntrega]);

  const handleProductVariations = () => {
    if (live?.layoutCheckout === "padrao")
      return products
        .filter((p) => p.id === mainProductId)
        .filter((p) => !cart.map((f) => f.produto.docId).includes(p.docId));
    else {
      let productVariations = [];
      if (modalVariation) {
        if (modalVariation.tipoGrade === "fechada") {
          productVariations = products
            .filter((x) => x.id === mainProductId && !x.principal)
            .filter((p) => !cart.map((f) => f.produto.docId).includes(p.docId));
        } else {
          productVariations = products.filter(
            (x) => x.id === mainProductId && !x.principal
          );
        }
      } else {
        productVariations = products.filter(
          (x) => x.id === mainProductId && !x.principal
        );
      }

      productVariations.map((v, idx) => {
        productVariations[idx] = {
          ...v,
          grade:
            v.tipoGrade === "aberta"
              ? [{ tamanho: v.tamanhos[0], quantidade: 1 }]
              : userGrades.filter((g) => v.grades.includes(g.gradeId))[0]
                  .grade || [],
          gradeId:
            v.tipoGrade === "aberta" || !v.tipoGrade
              ? null
              : userGrades.filter((g) => v.grades.includes(g.gradeId))[0]
                  .gradeId || null,
        };
      });
      return productVariations.filter((x) => x.grade.length > 0);
    }
  };

  const checkAvailableStock = (tamanho, stock, tamanhos) => {
    if (stock.length > 0) {
      let sizeIndex = tamanhos.indexOf(tamanho);
      return stock[sizeIndex];
    }
    return 0;
  };

  const handleChangeQuantities = (index, value, p) => {
    if (value === 0 || !value) {
      return setProdDelete(p);
    }

    let newQuantities = { ...p.produto.grade[0], quantidade: value };
    changeProducts(
      [newQuantities].filter((x) => x.quantidade > 0),
      index
    );
  };

  const getValueByStockAvaliable = (size, qtd, produto) => {
    if (!isNaN(qtd) && produto?.controlStock && parseInt(qtd) > 0) {
      let valueInStock = parseInt(
        produto.stock[produto.tamanhos.indexOf(size)]
      );
      if (valueInStock <= parseInt(qtd)) {
        if (valueInStock < parseInt(qtd))
          message.warning({
            key: `${produto.docId}-stock`,
            content: `${t("OPEN-GRID.TheProduct")} ${produto.descricao} ${t(
              "OPEN-GRID.OnlyXInStock1"
            )} ${valueInStock} ${t("OPEN-GRID.OnlyXInStock2")} ${size} ${t(
              "OPEN-GRID.OnlyXInStock3"
            )}!`,
            duration: 3,
          });
        return parseInt(valueInStock);
      } else return parseInt(qtd);
    } else return parseInt(qtd);
  };

  const variationsDisabled = (p) => {
    let isDisabled =
      products
        .filter((f) => f.id === p.produto.id)
        .filter((f) => !f.principal)
        .filter((p) => !cart.map((f) => f.produto.docId).includes(p.docId))
        .length === 0;

    if (!isDisabled && !p.produto.principal) isDisabled = true;

    return isDisabled;
  };

  const renderPaymentConditions = () => (
    <>
      <label>{t("CART.PaymentConditions")}*</label>
      <Select
        onChange={(s) => setCondicao(s)}
        style={{ width: 200 }}
        value={condicao}
        dropdownStyle={!live?.vertical && { zIndex: 2 }}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        {(live?.paymentService === "pagarme" ||
        (live.valorParcelaMinima && minValueFreeShipping >= 0)
          ? customConditions
            ? customConditions
            : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
          : live.condicoes || []
        ).map((condition) => (
          <Option value={condition} key={condition} title={false}>
            {condition}
            {live?.paymentService === "pagarme" &&
              t("CART.InterestFreeInstallments")}
          </Option>
        ))}
      </Select>
    </>
  );

  const renderNewCheckoutContainer = () => (
    <s.NewCheckoutContainer corPrimaria={corPrimaria}>
      <div
        className="content"
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "12px",
        }}
      >
        <s.Title
          style={{
            marginLeft: windowMeasures.width > 1600 ? 20 : 0,
            width: windowMeasures.width > 1600 ? "90%" : "100%",
          }}
        >
          <img src="/assets/icons/order.svg" alt={t("CART.RequestSummary")} />
          {t("CART.RequestSummary")}
        </s.Title>
        <section>
          <div className="col" style={{ marginLeft: 20 }}>
            <label>{t("CART.ProductsSum")}</label>
            <p>
              <ShoppingOutlined style={{ marginRight: "3px" }} />
              {cart
                .map((f) =>
                  f.produto.grade
                    .map((g) => parseInt(g.quantidade))
                    .reduce((a, b) => a + b, 0)
                )
                .reduce((a, b) => a + b, 0)}
            </p>

            {!["vtex", "magento", "neomode"].includes(live.checkout) && (
              <>
                {liveWithFrete && (
                  <Frete
                    liveId={live.liveId}
                    formaEntrega={live.formaEntrega}
                    setEnderecoEntrega={setEnderecoEntrega}
                    produtos={cart.map(({ produto }) => ({
                      ...produto.medidas,
                      quantidade: produto.grade
                        .map((g) => parseInt(g.quantidade))
                        .reduce((a, b) => a + b, 0),
                    }))}
                    setFreteSelecionado={setFreteSelecionado}
                    freteSelecionado={freteSelecionado}
                    enderecoEntrega={enderecoEntrega}
                    openShippingModal={() => setModalShipping(true)}
                  />
                )}
                {live.paymentService === "pagarme" &&
                live.formaPagto === "pix" ? (
                  <>
                    <label>{t("CART.PaymentForm")}:</label>
                    <Radio checked={true}>Pix</Radio>
                  </>
                ) : live.paymentService === "pagarme" &&
                  live.formaPagto === "ambas" ? (
                  <>
                    <label>{t("CART.PaymentForm")}*</label>
                    <Radio.Group
                      defaultValue={formaPagto}
                      value={formaPagto}
                      onChange={(e) => setFormaPagto(e.target.value)}
                    >
                      <Radio value="cc">{t("CART.CreditCard")}</Radio>
                      <Radio value="pix">Pix</Radio>
                    </Radio.Group>
                    {formaPagto === "cc" && renderPaymentConditions()}
                  </>
                ) : (
                  renderPaymentConditions()
                )}

                {/* {customConditions?.length &&
                  live?.valorParcelaMinima &&
                  formaPagto !== "pix" && (
                    <label>
                      <b>{t("CART.Attention")}! </b> {t("CART.MinParcelValue")}{" "}
                      <b>
                        {formatCash(
                          parseFloat(live.valorParcelaMinima / 100),
                          false,
                          live?.unidadeMonetaria
                        )}
                      </b>
                      .{t("CART.PleaseBuyMore")}
                    </label>
                  )} */}
              </>
            )}
            {minValueFreeShipping &&
              cart
                .map(
                  (f) =>
                    f.produto.grade
                      .map((g) => parseInt(g.quantidade))
                      .reduce((a, b) => a + b, 0) * parseFloat(f.produto.preco)
                )
                .reduce((a, b) => a + b, 0) >=
                minValueFreeShipping / 100 && (
                <label style={{ margin: "10px 0" }}>
                  <b>{t("CART.WarningFreeShipping")} - </b>{" "}
                  {t("CART.WarningAttentionDescriptionOne")}{" "}
                  {formatCash(
                    minValueFreeShipping / 100,
                    false,
                    live?.unidadeMonetaria
                  )}
                  , {t("CART.WarningAttentionDescriptionTwo")}
                </label>
              )}
            {live?.valorPedidoMinimo &&
              formaPagto !== "pix" &&
              cart
                .map(
                  (f) =>
                    f.produto.grade
                      .map((g) => parseInt(g.quantidade))
                      .reduce((a, b) => a + b, 0) * parseFloat(f.produto.preco)
                )
                .reduce((a, b) => a + b, 0) <
                live?.valorPedidoMinimo / 100 && (
                <div style={{ margin: "10px 0" }}>
                  <label style={{ color: "red", marginBottom: 10 }}>
                    {t("CART.MinBuyValueDescriptionOne")}{" "}
                    {formatCash(
                      live?.valorPedidoMinimo / 100,
                      false,
                      live?.unidadeMonetaria
                    )}{" "}
                    {t("CART.MinBuyValueDescriptionTwo")}
                  </label>
                </div>
              )}
          </div>

          <div
            className="col"
            style={{
              margin: "0 0 15px 20px",
            }}
          >
            <label>{t("CART.Observation")}</label>
            <Input.TextArea
              style={{ maxHeight: "90px", minHeight: "90px" }}
              placeholder={t("CART.PurchaseObservation")}
              value={observacao}
              onChange={({ target: t }) => setObs(t.value)}
            />
          </div>
          <div className="total-value">
            <div className="total-cash">
              <label>{t("CART.TotalOrderValue")}</label>
              <p>
                {freteSelecionado?.value ? (
                  <>
                    {formatCash(
                      cart
                        .map(
                          (f) =>
                            f.produto.grade
                              .map((g) => parseInt(g.quantidade))
                              .reduce((a, b) => a + b, 0) *
                            parseFloat(f.produto.preco)
                        )
                        .reduce((a, b) => a + b, 0) +
                        parseFloat(freteSelecionado.value / 100),
                      false,
                      live?.unidadeMonetaria
                    )}
                  </>
                ) : (
                  <>
                    {formatCash(
                      cart
                        .map(
                          (f) =>
                            f.produto.grade
                              .map((g) => parseInt(g.quantidade))
                              .reduce((a, b) => a + b, 0) *
                            parseFloat(f.produto.preco)
                        )
                        .reduce((a, b) => a + b, 0),
                      false,
                      live?.unidadeMonetaria
                    )}
                  </>
                )}
              </p>
            </div>
            <Button
              type="primary"
              style={{ maxWidth: 202, margin: "10px 0 10px 0" }}
              disabled={
                ["vtex", "magento", "neomode"].includes(live.checkout) // botão finalizar habilitado para lives com integração
                  ? false
                  : saving ||
                    verifyProductQuantity(cart) ||
                    ((live?.formaPagto === "cc" ||
                      (live?.formaPagto === "ambas" && formaPagto === "cc")) &&
                      !condicao) ||
                    (!live?.paymentService && !condicao) ||
                    (liveWithFrete && !freteSelecionado) ||
                    (live.valorPedidoMinimo &&
                      cart
                        .map(
                          (f) =>
                            f.produto.grade
                              .map((g) => parseInt(g.quantidade))
                              .reduce((a, b) => a + b, 0) *
                            parseFloat(f.produto.preco)
                        )
                        .reduce((a, b) => a + b, 0) <
                        live.valorPedidoMinimo / 100)
              }
              loading={finishing}
              onClick={() => {
                let totalValue = cart
                  .map(
                    (f) =>
                      f.produto.grade
                        .map((g) => parseInt(g.quantidade))
                        .reduce((a, b) => a + b, 0) *
                      parseFloat(f.produto.preco)
                  )
                  .reduce((a, b) => a + b, 0);

                if (
                  minValueFreeShipping &&
                  totalValue < minValueFreeShipping / 100
                )
                  setModalFreeShipping(true);
                else handleFinish();
              }}
            >
              {t("CART.EndRequest")}
            </Button>
          </div>
        </section>
      </div>
    </s.NewCheckoutContainer>
  );

  const renderNewContent = () => (
    <s.NewCartContainer className="fade-in shadow" id="cart">
      {modalPayPix && (
        <ModalPaymentPix
          visible={modalPayPix}
          closeModal={() => setModalPayPix(false)}
          qrCode={qrCode}
        />
      )}
      {modalNeomodeIsOpen && (
        <NeomodeProvider>
          <CheckoutNeomode
            visible={modalNeomodeIsOpen}
            closeModal={() => setModalNeomodeIsOpen(false)}
            liveId={live.liveId}
            liveEstrangeira={live.liveEstrangeira}
            observacao={observacao}
            cartTotal={formatCash(
              cart
                .map(
                  (f) =>
                    f.produto.grade
                      .map((g) => parseInt(g.quantidade))
                      .reduce((a, b) => a + b, 0) * parseFloat(f.produto.preco)
                )
                .reduce((a, b) => a + b, 0),
              false,
              live?.unidadeMonetaria
            )}
          />
        </NeomodeProvider>
      )}
      {modalPay && (
        <ModalPayment
          visible={modalPay}
          closeModal={() => setModalPay(false)}
          finish={finishWithPayment}
          finishing={finishing}
          shippingData={enderecoEntrega}
          cart={cart}
          freteSelecionado={freteSelecionado}
          condicao={condicao}
          unidadeMonetaria={live?.unidadeMonetaria}
          style={{ maxWidth: "500px" }}
        />
      )}
      <ModalFreeShipping
        visible={modalFreeShipping}
        finish={() => handleFinish()}
        onCancel={() => setModalFreeShipping(false)}
        loading={finishing}
        unidadeMonetaria={live?.unidadeMonetaria}
        minValueFreeShipping={minValueFreeShipping || 0}
        orderCompleted={orderCompleted}
        setOrderCompleted={() => setOrderCompleted(false)}
      />
      <ModalVariations
        live={live}
        customer={user}
        products={handleProductVariations()}
        visible={modalVariation}
        onCancel={() => setModalVariation(false)}
      />
      <ModalShipping
        enderecoEntrega={enderecoEntrega}
        liveId={live.liveId}
        visible={modalShipping}
        onOk={(shippingAddress) => {
          setEnderecoEntrega(shippingAddress);
          if (shippingAddress.zipcode !== enderecoEntrega.zipcode)
            setFreteSelecionado();
          setModalShipping(false);
        }}
        onCancel={() => setModalShipping(false)}
        style={{ maxWidth: "500px" }}
      />
      {windowMeasures.width < 1600 &&
        cart &&
        cart.length > 0 &&
        renderNewCheckoutContainer()}

      <div
        className="products"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          borderRadius: "12px 0 0 12px",
          borderRight: "1px solid lightgrey",
        }}
      >
        <s.Title id={"cart-header"} showBorder={cart?.length > 0}>
          <img src="/assets/icons/cart.svg" alt={t("CART.RequestSummary")} />
          {t("CART.BagProducts")}
        </s.Title>
        {saving && (
          <s.SavingCart
            style={{ margin: "20px 0" }}
            className="fade-in"
            corPrimaria={corPrimaria}
          >
            <LoadingOutlined style={{ margin: "4px 6px 0 0" }} />
            {t("CART.SavingCart")}
          </s.SavingCart>
        )}
        <div>
          <Modal
            title={t("CART.RemoveProduct")}
            visible={prodDelete}
            onOk={deleteProduct}
            onCancel={() => {
              if (!deletingProd) {
                setProdDelete(false);
                setDeletingProd(false);
              }
            }}
            confirmLoading={deletingProd}
            style={{ maxWidth: "500px" }}
            width={"80vw"}
            okText={t("CART.Remove")}
            cancelText={t("CART.Cancel")}
          >
            {t("CART.ConfirmRemoveProduct")}{" "}
            <strong>{prodDelete && prodDelete.produto.descricao}</strong>?{" "}
            {t("CART.ActionCantBeUndone")}
          </Modal>
          <div style={{ width: "100%" }}>
            {cart && cart.length > 0 ? (
              <Scrollbars
                style={{
                  width: "100%",
                  height: cart.length * 140 + 60,
                  maxHeight: windowMeasures.width > 1600 ? 500 : 400,
                  borderRadius: "12px",
                }}
                renderThumbHorizontal={() => (
                  <div style={{ display: "none" }} />
                )}
              >
                {cart.map((p, i) => {
                  if (p.produto.tipoGrade === "fechada")
                    return (
                      <s.Product key={p.docId} corSecundaria={corSecundaria}>
                        <div className="product-img-container">
                          <img
                            src={sourceImage(p.produto.thumbnail)}
                            alt={p.produto.descricao}
                            className="shadow2"
                          />
                        </div>
                        <div className="name-price-col">
                          <Tooltip placement="top" title={p.produto.descricao}>
                            <p id={p.produto.docId + "description"}>
                              {renderProductDescription(
                                p.produto.docId + "description",
                                p.produto?.descricao
                              )}
                            </p>
                          </Tooltip>
                          {p.produto.available === false &&
                          !p.produto.esgotado ? (
                            <strong style={{ textTransform: "uppercase" }}>
                              {t("CART.ProductUnavailable")}
                            </strong>
                          ) : p.produto.esgotado ? (
                            <strong style={{ textTransform: "uppercase" }}>
                              {t("CART.ProductOutoffStock")}
                            </strong>
                          ) : (
                            <strong>
                              {formatCash(
                                p.produto.preco,
                                false,
                                live?.unidadeMonetaria
                              )}
                            </strong>
                          )}

                          <div className="buttons-container">
                            <Button
                              style={{ marginRight: "5px" }}
                              type="primary"
                              onClick={() => {
                                setMainProductId(p.produto.id);
                                setMainProductDocId(p.produto.docId);
                                if (windowMeasures.width < 955)
                                  handleChangeTabActive("variations");
                                else setModalVariation(true);
                              }}
                              disabled={variationsDisabled(p) || saving}
                            >
                              {variationsDisabled(p)
                                ? t("CART.NoVariations")
                                : t("CART.AddVariaton")}
                            </Button>
                            <Button
                              onClick={() => setProdDelete(p)}
                              disabled={saving}
                            >
                              <DeleteOutlined />
                            </Button>
                          </div>
                        </div>
                        <GradeFechada
                          grade={p.produto.grade}
                          preco={p.produto.preco}
                          disabled={saving}
                          produto={p.produto}
                          tipoGrade={p.produto.tipoGrade || "aberta"}
                          grades={p.produto.grades || []}
                          gradeId={
                            p.produto.gradeId || p.produto.grades[0] || ""
                          }
                          quantiGrade={p.produto.quantiGrade || 1}
                          productsOutOffStock={productsOutOffStock}
                          changeProducts={(g, gId, gQtd) =>
                            changeProducts(g, i, gId, gQtd)
                          }
                          modalSizeProduct={closedGridEditProduct}
                          setModalSizeProduct={setClosedGridEditProduct}
                        />
                      </s.Product>
                    );
                  else {
                    if (live?.layoutCheckout === "padrao") {
                      return (
                        <s.Product
                          key={p.docId}
                          className="shadow"
                          corSecundaria={corSecundaria}
                        >
                          <div className="product-img-container">
                            <img
                              src={sourceImage(p.produto.thumbnail)}
                              alt={p.produto.descricao}
                              className="shadow2"
                            />
                          </div>
                          <div className="name-price-col">
                            <Tooltip
                              placement="top"
                              title={p.produto.descricao}
                            >
                              <p id={p.produto.docId + "description"}>
                                {renderProductDescription(
                                  p.produto.docId + "description",
                                  p.produto?.descricao
                                )}
                              </p>
                            </Tooltip>
                            {p.produto.available === false &&
                            !p.produto.esgotado ? (
                              <strong style={{ textTransform: "uppercase" }}>
                                {t("CART.ProductUnavailable")}
                              </strong>
                            ) : p.produto.esgotado ? (
                              <strong style={{ textTransform: "uppercase" }}>
                                {t("CART.ProductOutoffStock")}
                              </strong>
                            ) : (
                              <strong>
                                {formatCash(
                                  p.produto.preco,
                                  false,
                                  live?.unidadeMonetaria
                                )}
                              </strong>
                            )}

                            <div className="buttons-container">
                              <Button
                                style={{ marginRight: "5px" }}
                                type="primary"
                                onClick={() => {
                                  setMainProductId(p.produto.id);
                                  setMainProductDocId(p.produto.docId);
                                  if (windowMeasures.width < 955)
                                    handleChangeTabActive("variations");
                                  else setModalVariation(true);
                                }}
                                disabled={variationsDisabled(p) || saving}
                              >
                                {variationsDisabled(p)
                                  ? t("CART.NoVariations")
                                  : t("CART.AddVariaton")}
                              </Button>
                              <Button
                                onClick={() => setProdDelete(p)}
                                disabled={saving}
                              >
                                <DeleteOutlined />
                              </Button>
                            </div>
                          </div>
                          <GradeAbertaB2B
                            tamanhos={p.produto.tamanhos}
                            grade={p.produto.grade}
                            preco={p.produto.preco}
                            changeProducts={(g) => changeProducts(g, i)}
                            disabled={saving}
                            productsOutOffStock={productsOutOffStock}
                            unavailable={
                              !p.produto.available || p.produto.esgotado
                            }
                            produto={p.produto}
                            vertical={live?.vertical}
                          />
                        </s.Product>
                      );
                    } else {
                      let grades = p.produto.grade;
                      return grades.map(
                        ({ quantidade: qtdGrade, tamanho: tamGrade }) => (
                          <s.Product
                            key={p.docId + tamGrade}
                            corSecundaria={corSecundaria}
                          >
                            <div className="product-img-container">
                              <img
                                src={sourceImage(p.produto.thumbnail)}
                                alt={p.produto.descricao}
                                className="shadow2"
                              />
                            </div>
                            <div
                              className="name-price-col"
                              style={{ height: "120px" }}
                            >
                              <Tooltip
                                placement="top"
                                title={p.produto.descricao}
                              >
                                <p id={p.produto.docId + "description"}>
                                  {renderProductDescription(
                                    p.produto.docId + "description",
                                    p.produto?.descricao
                                  )}
                                </p>
                              </Tooltip>
                              {p.produto.available === false &&
                              !p.produto.esgotado ? (
                                <strong style={{ textTransform: "uppercase" }}>
                                  {t("CART.ProductUnavailable")}
                                </strong>
                              ) : p.produto.esgotado ? (
                                <strong style={{ textTransform: "uppercase" }}>
                                  {t("CART.ProductOutoffStock")}
                                </strong>
                              ) : (
                                <>
                                  {!p.produto.precoOriginal ||
                                  p.produto.precoOriginal ===
                                    p.produto.preco ? (
                                    <strong>
                                      {live?.unidadeMonetaria || "R$"}
                                      {""}
                                      {p.produto.preco &&
                                        formatCash(
                                          p.produto.preco,
                                          true,
                                          live?.unidadeMonetaria
                                        )}
                                    </strong>
                                  ) : (
                                    <>
                                      <p
                                        style={{
                                          textDecoration: "line-through",
                                          color: "#c4c4c4",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {live?.unidadeMonetaria || "R$"}
                                        {""}
                                        {p.produto.precoOriginal &&
                                          formatCash(
                                            p.produto.precoOriginal,
                                            true,
                                            live?.unidadeMonetaria
                                          )}
                                      </p>
                                      <strong style={{ fontSize: "18px" }}>
                                        {live?.unidadeMonetaria || "R$"}
                                        {""}
                                        {p.produto.preco &&
                                          formatCash(
                                            p.produto.preco,
                                            true,
                                            live?.unidadeMonetaria
                                          )}
                                      </strong>
                                    </>
                                  )}

                                  {/* {!p.produto.precoOriginal &&
                                    <strong>
                                      {p.produto.preco &&
                                        formatCash(
                                          p.produto.preco,
                                          false,
                                          live?.unidadeMonetaria
                                        )}
                                    </strong>
                                  )} */}
                                  {/* {p.produto.precoOriginal && (
                                    <>
                                      <div>
                                        <p
                                          style={{
                                            textDecoration: "line-through",
                                            color: "#c4c4c4",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {p.produto.preco &&
                                            formatCash(
                                              p.produto.preco,
                                              false,
                                              live?.unidadeMonetaria
                                            )}
                                        </p>
                                        <br />
                                        <p style={{ fontSize: "18px" }}>
                                          {p.produto.preco &&
                                            formatCash(
                                              p.produto.preco,
                                              false,
                                              live?.unidadeMonetaria
                                            )}
                                        </p>
                                      </div>
                                    </>
                                  )} */}
                                </>
                              )}

                              <div className="buttons-container">
                                <Button
                                  style={{ marginRight: "5px" }}
                                  type="primary"
                                  onClick={() => {
                                    setMainProductId(p.produto.id);
                                    setMainProductDocId(p.produto.docId);
                                    if (windowMeasures.width < 955)
                                      handleChangeTabActive("variations");
                                    else setModalVariation(true);
                                  }}
                                  disabled={variationsDisabled(p) || saving}
                                >
                                  {variationsDisabled(p)
                                    ? t("CART.NoVariations")
                                    : t("CART.AddVariaton")}
                                </Button>
                                <Button
                                  onClick={() =>
                                    setProdDelete({
                                      ...p,
                                      deletingSizeFromProduct: {
                                        tamGrade,
                                        index: i,
                                      },
                                    })
                                  }
                                  disabled={saving}
                                >
                                  <DeleteOutlined />
                                </Button>
                              </div>
                            </div>
                            <GradeAberta
                              qtdGrade={qtdGrade}
                              tamGrade={tamGrade}
                              tamanhos={p.produto.tamanhos}
                              grade={p.produto.grade}
                              preco={p.produto.preco}
                              changeProducts={(g) => changeProducts(g, i)}
                              disabled={saving}
                              productsOutOffStock={productsOutOffStock}
                              unavailable={
                                !p.produto.available || p.produto.esgotado
                              }
                              produto={p.produto}
                            />
                          </s.Product>
                        )
                      );
                    }
                  }
                })}
              </Scrollbars>
            ) : (
              <s.Empty>
                <img alt="imagem-vazio" src={"/assets/images/emptyCart.png"} />
                <h1>
                  {t("CART.EmptyBag")}
                  <strong>
                    {t("CART.StartTo")} <br></br>
                    {t("CART.LikeProducts")}
                  </strong>
                </h1>
              </s.Empty>
            )}
          </div>
        </div>
      </div>
      {windowMeasures.width >= 1600 &&
        cart &&
        cart.length > 0 &&
        renderNewCheckoutContainer()}
    </s.NewCartContainer>
  );

  const renderContent = (vertical) => (
    <>
      {modalPayPix && (
        <ModalPaymentPix
          visible={modalPayPix}
          closeModal={() => setModalPayPix(false)}
          qrCode={qrCode}
        />
      )}
      {modalNeomodeIsOpen && (
        <NeomodeProvider>
          <CheckoutNeomode
            visible={modalNeomodeIsOpen}
            closeModal={() => setModalNeomodeIsOpen(false)}
            liveId={live.liveId}
            liveEstrangeira={live.liveEstrangeira}
            observacao={observacao}
            cartTotal={formatCash(
              cart
                .map(
                  (f) =>
                    f.produto.grade
                      .map((g) => parseInt(g.quantidade))
                      .reduce((a, b) => a + b, 0) * parseFloat(f.produto.preco)
                )
                .reduce((a, b) => a + b, 0),
              false,
              live?.unidadeMonetaria
            )}
          />
        </NeomodeProvider>
      )}
      {modalPay && (
        <ModalPayment
          visible={modalPay}
          closeModal={() => setModalPay(false)}
          finish={finishWithPayment}
          finishing={finishing}
          shippingData={enderecoEntrega}
          cart={cart}
          freteSelecionado={freteSelecionado}
          condicao={condicao}
          unidadeMonetaria={live?.unidadeMonetaria}
          style={{
            maxWidth: "500px",
            top:
              live?.vertical && windowMeasures.width < 955
                ? "45vh"
                : windowMeasures.width < 955 && "30vh",
          }}
          maskStyle={{
            top:
              !live?.vertical && windowMeasures.height <= 736
                ? "31vh"
                : !live?.vertical &&
                  windowMeasures.width === 375 &&
                  windowMeasures.height === 812
                ? "26vh"
                : !live?.vertical && windowMeasures.width < 955 && "28vh",
            background: "rgba(0, 0, 0, 0,5)",
          }}
        />
      )}
      <ModalVariations
        live={live}
        customer={user}
        products={handleProductVariations()}
        visible={modalVariation}
        onCancel={() => setModalVariation(false)}
      />

      <ModalFreeShipping
        visible={modalFreeShipping}
        finish={() => handleFinish()}
        onCancel={() => setModalFreeShipping(false)}
        loading={finishing}
        unidadeMonetaria={live?.unidadeMonetaria}
        minValueFreeShipping={minValueFreeShipping || 0}
        orderCompleted={orderCompleted}
        setOrderCompleted={() => setOrderCompleted(false)}
      />
      <ModalShipping
        enderecoEntrega={enderecoEntrega}
        liveId={live.liveId}
        visible={modalShipping}
        onOk={(shippingAddress) => {
          if (shippingAddress.zipcode !== enderecoEntrega?.zipcode) {
            setHasChangedShippingAddres(true);
            message.warning({
              content: t("CART.ZipcodeChanged"),
              key: "address-changed",
            });
            setFreteSelecionado();
          }
          setEnderecoEntrega(shippingAddress);
          setModalShipping(false);
        }}
        onCancel={() => setModalShipping(false)}
        style={{
          maxWidth: "500px",
          top:
            live?.vertical && windowMeasures.width < 955
              ? "45vh"
              : windowMeasures.width < 955 && "36vh",
        }}
        maskStyle={{
          top:
            !live?.vertical && windowMeasures.height <= 736
              ? "31vh"
              : !live?.vertical &&
                windowMeasures.width === 375 &&
                windowMeasures.height === 812
              ? "26vh"
              : !live?.vertical &&
                windowMeasures.width < 955 &&
                window.scrollY >= 50
              ? "calc(34vh - 50px)"
              : !live?.vertical && windowMeasures.width < 955 && "34vh",
          background: "rgba(0, 0, 0, 0,5)",
        }}
      />
      <s.CartContainer
        show={show}
        id="cart"
        className="fade-in"
        vertical={vertical}
      >
        <Modal
          title={t("CART.RemoveProduct")}
          visible={prodDelete}
          onOk={deleteProduct}
          onCancel={() => {
            if (!deletingProd) {
              setProdDelete(false);
              setDeletingProd(false);
            }
          }}
          confirmLoading={deletingProd}
          style={{
            maxWidth: "500px",
            top:
              live?.vertical && windowMeasures.width < 955
                ? "45vh"
                : windowMeasures.width < 955 && "30vh",
          }}
          maskStyle={{
            top:
              !live?.vertical && windowMeasures.height <= 736
                ? "31vh"
                : !live?.vertical &&
                  windowMeasures.width === 375 &&
                  windowMeasures.height === 812
                ? "26vh"
                : !live?.vertical && windowMeasures.width < 955 && "28vh",
            background: "rgba(0, 0, 0, 0,5)",
          }}
          width={"80vw"}
          okText={t("CART.Remove")}
          cancelText={t("CART.Cancel")}
          okButtonProps={{
            disabled: changes,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 20,
            }}
          >
            <div>
              {t("CART.ConfirmRemoveProduct")}{" "}
              <strong>{prodDelete && prodDelete.produto.descricao}</strong>?{" "}
            </div>
            {t("CART.ActionCantBeUndone")}
          </div>
        </Modal>
        {cart && cart.length > 0 && (
          <s.CheckOutContainer className="shadow" corPrimaria={corPrimaria}>
            <s.Title noPadding>
              <img
                src="/assets/icons/order.svg"
                alt={t("CART.RequestSummary")}
              />
              {t("CART.RequestSummary")}
            </s.Title>
            <section>
              <div className="col" style={{ marginLeft: 20 }}>
                <label>{t("CART.ProductsSum")}</label>
                <p>
                  <ShoppingOutlined style={{ marginRight: "3px" }} />
                  {cart
                    .map((f) =>
                      f.produto.grade
                        .map((g) => parseInt(g.quantidade))
                        .reduce((a, b) => a + b, 0)
                    )
                    .reduce((a, b) => a + b, 0)}
                </p>
                {!["vtex", "magento", "neomode"].includes(live.checkout) && (
                  <>
                    {liveWithFrete && (
                      <Frete
                        liveId={live.liveId}
                        formaEntrega={live.formaEntrega}
                        setEnderecoEntrega={setEnderecoEntrega}
                        produtos={cart.map(({ produto }) => ({
                          ...produto.medidas,
                          quantidade: produto.grade
                            .map((g) => parseInt(g.quantidade))
                            .reduce((a, b) => a + b, 0),
                        }))}
                        setFreteSelecionado={setFreteSelecionado}
                        freteSelecionado={freteSelecionado}
                        enderecoEntrega={enderecoEntrega}
                        openShippingModal={() => setModalShipping(true)}
                        unidadeMonetaria={live?.unidadeMonetaria}
                      />
                    )}
                    {live.paymentService === "pagarme" &&
                    live.formaPagto === "pix" ? (
                      <>
                        <label>{t("CART.PaymentForm")}</label>
                        <Radio checked={true}>Pix</Radio>
                      </>
                    ) : live.paymentService === "pagarme" &&
                      live.formaPagto === "ambas" ? (
                      <>
                        <label>{t("CART.PaymentForm")}*</label>
                        <Radio.Group
                          defaultValue={formaPagto}
                          value={formaPagto}
                          onChange={(e) => setFormaPagto(e.target.value)}
                        >
                          <Radio value="cc">{t("CART.CreditCard")}</Radio>
                          <Radio value="pix">Pix</Radio>
                        </Radio.Group>
                        {formaPagto === "cc" && renderPaymentConditions()}
                      </>
                    ) : (
                      renderPaymentConditions()
                    )}
                    {/* {customConditions?.length &&
                      live?.valorParcelaMinima &&
                      formaPagto !== "pix" && (
                        <label>
                          <b>{t("CART.Attention")}! </b>{" "}
                          {t("CART.MinParcelValue")} <br />{" "}
                          <b>
                            {formatCash(
                              parseFloat(live.valorParcelaMinima / 100),
                              false,
                              live?.unidadeMonetaria
                            )}
                          </b>
                          .{t("CART.PleaseBuyMore")}
                        </label>
                      )} */}
                  </>
                )}
              </div>

              <div className="col">
                {minValueFreeShipping &&
                  cart
                    .map(
                      (f) =>
                        f.produto.grade
                          .map((g) => parseInt(g.quantidade))
                          .reduce((a, b) => a + b, 0) *
                        parseFloat(f.produto.preco)
                    )
                    .reduce((a, b) => a + b, 0) >=
                    minValueFreeShipping / 100 && (
                    <label style={{ margin: "10px 20px" }}>
                      <b>{t("CART.WarningFreeShipping")} - </b>{" "}
                      {t("CART.WarningAttentionDescriptionOne")}{" "}
                      {formatCash(
                        minValueFreeShipping / 100,
                        false,
                        live?.unidadeMonetaria
                      )}
                      , {t("CART.WarningAttentionDescriptionTwo")}
                    </label>
                  )}
                {live?.valorPedidoMinimo &&
                  formaPagto !== "pix" &&
                  cart
                    .map(
                      (f) =>
                        f.produto.grade
                          .map((g) => parseInt(g.quantidade))
                          .reduce((a, b) => a + b, 0) *
                        parseFloat(f.produto.preco)
                    )
                    .reduce((a, b) => a + b, 0) <
                    live?.valorPedidoMinimo / 100 && (
                    <div style={{ margin: "10px 20px" }}>
                      <label style={{ color: "red", marginBottom: 10 }}>
                        {t("CART.MinBuyValueDescriptionOne")}{" "}
                        {formatCash(
                          live?.valorPedidoMinimo / 100,
                          false,
                          live?.unidadeMonetaria
                        )}{" "}
                        {t("CART.MinBuyValueDescriptionTwo")}
                      </label>
                    </div>
                  )}
              </div>

              <div className="col-price">
                <label style={{ marginLeft: 20 }}>
                  {t("CART.Observation")}
                </label>
                <Input.TextArea
                  style={{
                    maxHeight: "90px",
                    minHeight: "90px",
                    width: "calc(100% - 40px)",
                    marginLeft: "20px",
                  }}
                  placeholder={t("CART.PurchaseObservation")}
                  value={observacao}
                  onChange={({ target: t }) => setObs(t.value)}
                />
                <s.MobileLivePrice>
                  <div className="total-cash">
                    <label>{t("CART.TotalOrderValue")}</label>
                    <p>
                      {freteSelecionado?.value ? (
                        <>
                          {formatCash(
                            cart
                              .map(
                                (f) =>
                                  f.produto.grade
                                    .map((g) => parseInt(g.quantidade))
                                    .reduce((a, b) => a + b, 0) *
                                  parseFloat(f.produto.preco)
                              )
                              .reduce((a, b) => a + b, 0) +
                              parseFloat(freteSelecionado.value / 100),
                            false,
                            live?.unidadeMonetaria
                          )}
                        </>
                      ) : (
                        <>
                          {formatCash(
                            cart
                              .map(
                                (f) =>
                                  f.produto.grade
                                    .map((g) => parseInt(g.quantidade))
                                    .reduce((a, b) => a + b, 0) *
                                  parseFloat(f.produto.preco)
                              )
                              .reduce((a, b) => a + b, 0),
                            false,
                            live?.unidadeMonetaria
                          )}
                        </>
                      )}
                    </p>
                  </div>
                </s.MobileLivePrice>
                <Button
                  type="primary"
                  style={{ maxWidth: 160, marginTop: 10 }}
                  disabled={
                    ["vtex", "magento", "neomode"].includes(live.checkout) // botão finalizar habilitado para lives com integração
                      ? false
                      : saving ||
                        verifyProductQuantity(cart) ||
                        ((live?.formaPagto === "cc" ||
                          (live?.formaPagto === "ambas" &&
                            formaPagto === "cc")) &&
                          !condicao) ||
                        (!live?.paymentService && !condicao) ||
                        (liveWithFrete && !freteSelecionado) ||
                        (live.valorPedidoMinimo &&
                          cart
                            .map(
                              (f) =>
                                f.produto.grade
                                  .map((g) => parseInt(g.quantidade))
                                  .reduce((a, b) => a + b, 0) *
                                parseFloat(f.produto.preco)
                            )
                            .reduce((a, b) => a + b, 0) <
                            live.valorPedidoMinimo / 100)
                  }
                  loading={finishing}
                  onClick={() => {
                    let totalValue = cart
                      .map(
                        (f) =>
                          f.produto.grade
                            .map((g) => parseInt(g.quantidade))
                            .reduce((a, b) => a + b, 0) *
                          parseFloat(f.produto.preco)
                      )
                      .reduce((a, b) => a + b, 0);

                    if (
                      minValueFreeShipping &&
                      totalValue < minValueFreeShipping / 100
                    )
                      setModalFreeShipping(true);
                    else handleFinish();
                  }}
                >
                  {t("CART.EndRequest")}
                </Button>
              </div>
            </section>
          </s.CheckOutContainer>
        )}
        <s.Title noPadding={!cart?.length > 0}>
          <img src="/assets/icons/cart.svg" alt={t("CART.RequestSummary")} />
          {t("CART.BagProducts")}
        </s.Title>
        {saving && (
          <s.SavingCart
            style={{ marginTop: 5 }}
            className="fade-in"
            corPrimaria={corPrimaria}
          >
            <LoadingOutlined style={{ margin: "4px 6px 0 0" }} />
            {t("CART.SavingCart")}
          </s.SavingCart>
        )}
        <div
          style={{
            paddingTop: 5,
            background: "transparent",
          }}
        >
          {cart && cart.length > 0 ? (
            cart.map((p, i) => {
              if (p.produto.tipoGrade === "fechada")
                return (
                  <s.Product
                    key={p.docId}
                    className="shadow"
                    corSecundaria={corSecundaria}
                  >
                    <div className="product-img-container">
                      <img
                        src={sourceImage(p.produto.thumbnail)}
                        alt={p.produto.descricao}
                        className="shadow2"
                      />
                    </div>
                    <div className="name-price-col">
                      <Tooltip placement="top" title={p.produto.descricao}>
                        <p id={p.produto.docId + "description"}>
                          {renderProductDescription(
                            p.produto.docId + "description",
                            p.produto?.descricao
                          )}
                        </p>
                      </Tooltip>
                      {p.produto.available === false && !p.produto.esgotado ? (
                        <strong style={{ textTransform: "uppercase" }}>
                          {t("CART.ProductUnavailable")}
                        </strong>
                      ) : p.produto.esgotado ? (
                        <strong style={{ textTransform: "uppercase" }}>
                          {t("CART.ProductOutoffStock")}
                        </strong>
                      ) : (
                        <strong>
                          {formatCash(
                            p.produto.preco,
                            false,
                            live?.unidadeMonetaria
                          )}
                        </strong>
                      )}
                      <div className="buttons-container">
                        <Button
                          type="secondary"
                          onClick={() => {
                            setMainProductId(p.produto.id);
                            setMainProductDocId(p.produto.docId);
                            if (windowMeasures.width < 955)
                              handleChangeTabActive("variations");
                            else setModalVariation(true);
                          }}
                          disabled={variationsDisabled(p) || saving}
                        >
                          {variationsDisabled(p)
                            ? t("CART.NoVariations")
                            : t("CART.AddVariaton")}
                        </Button>
                        <Button
                          style={{ background: corSecundaria }}
                          onClick={() => setClosedGridEditProduct(true)}
                        >
                          {t("CART.Sizes")}
                        </Button>
                      </div>
                    </div>
                    <GradeFechada
                      grade={p.produto.grade}
                      preco={p.produto.preco}
                      disabled={saving}
                      produto={p.produto}
                      tipoGrade={p.produto.tipoGrade || "aberta"}
                      grades={p.produto.grades || []}
                      gradeId={p.produto.gradeId || p.produto.grades[0] || ""}
                      quantiGrade={p.produto.quantiGrade || 1}
                      unidadeMonetaria={live?.unidadeMonetaria}
                      productsOutOffStock={productsOutOffStock}
                      changeProducts={(g, gId, gQtd) =>
                        changeProducts(g, i, gId, gQtd)
                      }
                      produtoCarrinho={p}
                      setProdDelete={setProdDelete}
                      layoutCheckout={live?.layoutCheckout}
                      modalSizeProduct={closedGridEditProduct}
                      setModalSizeProduct={setClosedGridEditProduct}
                      vertical={live?.vertical}
                    />
                  </s.Product>
                );
              else {
                if (live?.layoutCheckout === "padrao")
                  return (
                    <s.Product
                      key={p.docId}
                      className="shadow"
                      corSecundaria={corSecundaria}
                    >
                      <div className="product-img-container">
                        <img
                          src={sourceImage(p.produto.thumbnail)}
                          alt={p.produto.descricao}
                          className="shadow2"
                        />
                      </div>
                      <div className="name-price-col">
                        <Tooltip placement="top" title={p.produto.descricao}>
                          <p id={p.produto.docId + "description"}>
                            {renderProductDescription(
                              p.produto.docId + "description",
                              p.produto?.descricao
                            )}
                          </p>
                        </Tooltip>
                        {p.produto.available === false &&
                        !p.produto.esgotado ? (
                          <strong style={{ textTransform: "uppercase" }}>
                            {t("CART.ProductUnavailable")}
                          </strong>
                        ) : p.produto.esgotado ? (
                          <strong style={{ textTransform: "uppercase" }}>
                            {t("CART.ProductOutoffStock")}
                          </strong>
                        ) : (
                          <strong>
                            {formatCash(
                              p.produto.preco,
                              false,
                              live?.unidadeMonetaria
                            )}
                          </strong>
                        )}

                        <div className="buttons-container">
                          <Button
                            style={{ marginRight: "5px" }}
                            type="primary"
                            onClick={() => {
                              setMainProductId(p.produto.id);
                              setMainProductDocId(p.produto.docId);
                              if (windowMeasures.width < 955)
                                handleChangeTabActive("variations");
                              else setModalVariation(true);
                            }}
                            disabled={variationsDisabled(p) || saving}
                          >
                            {variationsDisabled(p)
                              ? t("CART.NoVariations")
                              : t("CART.AddVariaton")}
                          </Button>
                        </div>
                      </div>
                      <GradeAbertaB2B
                        tamanhos={p.produto.tamanhos}
                        grade={p.produto.grade}
                        preco={p.produto.preco}
                        changeProducts={(g) => changeProducts(g, i)}
                        disabled={saving}
                        productsOutOffStock={productsOutOffStock}
                        unavailable={!p.produto.available || p.produto.esgotado}
                        produto={p.produto}
                        unidadeMonetaria={live?.unidadeMonetaria}
                        layoutCheckout={live?.layoutCheckout}
                        produtoCarrinho={p}
                        setProdDelete={setProdDelete}
                        vertical={live?.vertical}
                      />
                    </s.Product>
                  );
                else {
                  let grades = p.produto.grade;

                  return grades.map(
                    ({ quantidade: qtdGrade, tamanho: tamGrade }, idx) => (
                      <s.Product
                        key={p.docId + tamGrade}
                        className="shadow"
                        corSecundaria={corSecundaria}
                      >
                        <div className="product-img-container">
                          <img
                            src={sourceImage(p.produto.thumbnail)}
                            alt={p.produto.descricao}
                            className="shadow2"
                          />
                        </div>
                        <div className="name-price-col">
                          <Tooltip placement="top" title={p.produto.descricao}>
                            <p id={p.produto.docId + "description"}>
                              {renderProductDescription(
                                p.produto.docId + "description",
                                p.produto?.descricao
                              )}
                            </p>
                          </Tooltip>
                          <span className="product-size-label">
                            {t("CART.Size")}: {tamGrade}{" "}
                          </span>
                          {p.produto.available === false &&
                          !p.produto.esgotado ? (
                            <strong style={{ textTransform: "uppercase" }}>
                              {t("CART.ProductUnavailable")}
                            </strong>
                          ) : p.produto.esgotado ? (
                            <strong style={{ textTransform: "uppercase" }}>
                              {t("CART.ProductOutoffStock")}
                            </strong>
                          ) : (
                            <div className="quantity-controller">
                              <Button
                                onClick={() =>
                                  handleChangeQuantities(i, qtdGrade - 1, p)
                                }
                                disabled={parseInt(qtdGrade) === 1}
                              >
                                -
                              </Button>
                              <ChangeQuantityInput
                                initialValue={qtdGrade}
                                onBlur={(value) => {
                                  let newQuantity = getValueByStockAvaliable(
                                    tamGrade,
                                    value,
                                    p.produto
                                  );

                                  handleChangeQuantities(i, newQuantity, p);
                                }}
                                type="number"
                              />
                              <Button
                                onClick={() => {
                                  handleChangeQuantities(i, qtdGrade + 1, p);
                                }}
                                disabled={
                                  p.produto.controlStock &&
                                  qtdGrade ===
                                    checkAvailableStock(
                                      tamGrade,
                                      p.produto.stock,
                                      p.produto.tamanhos
                                    )
                                }
                              >
                                +
                              </Button>
                            </div>
                          )}

                          <div className="buttons-container">
                            <Button
                              type="secondary"
                              onClick={() => {
                                setMainProductId(p.produto.id);
                                setMainProductDocId(p.produto.docId);
                                if (windowMeasures.width < 955)
                                  handleChangeTabActive("variations");
                                else setModalVariation(true);
                              }}
                              disabled={variationsDisabled(p) || saving}
                            >
                              {variationsDisabled(p)
                                ? t("CART.NoVariations")
                                : t("CART.AddVariaton")}
                            </Button>
                          </div>
                        </div>
                        <GradeAberta
                          qtdGrade={qtdGrade}
                          tamGrade={tamGrade}
                          tamanhos={p.produto.tamanhos}
                          grade={p.produto.grade}
                          preco={p.produto.preco}
                          changeProducts={(g) => changeProducts(g, i)}
                          disabled={saving}
                          productsOutOffStock={productsOutOffStock}
                          unavailable={
                            !p.produto.available || p.produto.esgotado
                          }
                          produto={p.produto}
                          produtoCarrinho={p}
                          setProdDelete={setProdDelete}
                          unidadeMonetaria={live?.unidadeMonetaria}
                          layoutCheckout={live?.layoutCheckout}
                          deleteProdIndex={i}
                          vertical={live?.vertical}
                        />
                      </s.Product>
                    )
                  );
                }
              }
            })
          ) : (
            <s.Empty className="shadow">
              <img alt="imagem-vazio" src={"/assets/images/emptyCart.png"} />
              <h1>
                {t("CART.EmptyBag")}{" "}
                <strong>
                  {t("CART.StartTo")} <br></br>
                  {t("CART.LikeProducts")}
                </strong>
              </h1>
            </s.Empty>
          )}
        </div>
      </s.CartContainer>
    </>
  );

  if (loading) return <></>;
  else if (windowMeasures.width < 955) {
    if (live.vertical)
      return (
        <s.CartVertical corPrimaria={corPrimaria}>
          <Drawer
            placement="bottom"
            closable
            onClose={() => handleChangeTabActive("chat")}
            visible={tabActive === "cart"}
            style={{ padding: 0 }}
            bodyStyle={{
              padding: 0,
              background: "rgba(248, 248, 248, 1)",
              overflow: "inherit",
            }}
            headerStyle={{ display: "none" }}
            maskStyle={{ background: "transparent" }}
            height={cart && cart.length > 0 ? 400 : 200}
          >
            <CloseOutlined
              style={{ position: "absolute", top: 0, right: 0, padding: 20 }}
              onClick={() => handleChangeTabActive("chat")}
            />
            {renderContent(true)}
          </Drawer>
        </s.CartVertical>
      );
    else {
      if (tabActive === "cart") return renderContent();
      else return <></>;
    }
  } else return renderNewContent();
};

export default Cart;
