import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as s from "./styled-products-tab";
import { useDropzone } from "react-dropzone";
import {
  Input,
  Skeleton,
  Tag,
  Modal,
  Button,
  Select,
  Radio,
  Popover,
  Checkbox,
  message,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import ModalNovaGrade from "./modal-grade";
import Compress from "compress.js";
import {
  formatCash,
  sourceImage,
  preventInvalidNumberOnKeyDown,
  preventInvalidNumberOnPaste,
} from "../../../../utils";
import { GradesContext, UserContext } from "../../../../context";

const compress = new Compress();
const { Search } = Input;
const { Option } = Select;
const ModalVariation = ({
  open,
  variation,
  onClose,
  onFinish,
  deleteVariation,
  liveWithVtex,
  liveWithMagento,
  liveWithNeomode,
  liveWithCorreios,
  newVariation,
  catalog,
  liveWithTabelaPreco,
  controlAllStock,
  tabelaPreco,
  unidadeMonetaria,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext); //dados do usuário
  const { userGrades } = useContext(GradesContext); //grades do usuário
  const [descricao, setDescricao] = useState(variation.descricao || "");
  const [preco, setPreco] = useState(variation.preco || "");
  const [tmpTabelaPreco, setTmpTabelaPreco] = useState(
    variation.tabelaPreco || false
  );
  const [codigo, setCodigo] = useState(variation.codigo || "");
  const [thumbnail, setThumbnail] = useState(variation.thumbnail || null);
  const [imageName, setImageName] = useState(variation.imageName || false);
  const [tamanhos, setTamanhos] = useState(variation.tamanhos || []);
  const [tamanhosGradeFechada, setTamanhosGradeFechada] = useState([]); //tamanhos da grade fechada quando for controlar estoque
  const [stockGradeFechada, setStockGradeFechada] = useState([]); //stock da grade fechada quando for controlar estoque
  const [tipoGrade, setTipoGrade] = useState(variation.tipoGrade || "aberta"); //tipo da grade
  const [grades, setGrades] = useState((variation && variation.grades) || []); //grades do produto quando for do tipo grade fechada
  const [searchText, setSearchText] = useState("");
  const [controlStock, setControlStock] = useState(
    newVariation && controlAllStock ? true : variation.controlStock
  );
  const [stock, setStock] = useState(
    variation?.stock?.length > 0 ? variation.stock : []
  );
  const [backupMeasures, setBackupMeasures] = useState([]);
  const [available, setAvailable] = useState(
    variation && typeof variation.available === "boolean"
      ? variation.available
      : true
  );
  const [searching, setSearching] = useState(false);
  //nova grade control
  const [modalNovaGrade, setModalNovaGrade] = useState(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone();
  const [medidas, setMedidas] = useState(
    variation.medidas || {
      peso: "",
      largura: "",
      altura: "",
      comprimento: "",
    }
  );
  const [invalidValues, setInvalidValues] = useState([]);

  const handleChangeMedidas = ({ target: { value } }, field) =>
    setMedidas({ ...medidas, [field]: parseFloat(value) });

  useEffect(() => {
    if (searching) clearTimeout(searching);
    setSearching(
      setTimeout(() => {
        let productTabelaPrecoByNewCode =
          tabelaPreco?.length > 0
            ? tabelaPreco.filter((tabela) => tabela.sku === codigo)
            : [];
        setTmpTabelaPreco(
          productTabelaPrecoByNewCode.map((e) => ({
            ...e,
            tabelaPreco: e.tabela_preco,
            valor: parseFloat(e.preco),
          }))
        );
      }, 2000)
    );
  }, [codigo]);

  useEffect(() => {
    if (variation?.tipoGrade === "fechada") handleGridMeasures(grades, true);
  }, []);

  useEffect(() => {
    if (tamanhos.length === 0 && controlStock) setControlStock(false);
  }, [tamanhos]);

  const handleGridMeasures = (ids, recalculate) => {
    let tmpStock = [...stock];
    let tmpMeasures = recalculate ? [...tamanhos] : [];
    let tmpBackupMeasures = [];
    let recalculateMeasures = [];

    if (recalculate) {
      for (let i = 0; i < ids.length; i++) {
        let gradeById = userGrades.find((x) => x.gradeId === ids[i]);
        for (let i = 0; i < gradeById.grade.length; i++) {
          if (!tamanhos.includes(gradeById.grade[i].tamanho)) {
            tmpMeasures.push(gradeById.grade[i].tamanho);
          } else if (
            tamanhos.length === 0 &&
            !tmpBackupMeasures.includes(gradeById.grade[i].tamanho)
          )
            tmpBackupMeasures.push(gradeById.grade[i].tamanho);

          let finalMeasures = [...recalculateMeasures, ...tmpMeasures];
          for (let i = 0; i < finalMeasures.length; i++) {
            if (!tmpStock[i]) tmpStock[i] = 1;
          }
          recalculateMeasures = finalMeasures;
          tmpMeasures = [];
          finalMeasures = [];
        }
        setTamanhos(recalculateMeasures.sort());
        setStock(tmpStock);
        setBackupMeasures(tmpBackupMeasures);
      }
    } else {
      let gradeById = userGrades.find((x) => x.gradeId === ids[ids.length - 1]);
      let newMeasuresIndexes = [];
      let oldStockIndexes = [];

      for (let i = 0; i < gradeById.grade.length; i++) {
        if (!tamanhos.includes(gradeById.grade[i].tamanho))
          tmpMeasures.push(gradeById.grade[i].tamanho);
        else tmpBackupMeasures.push(gradeById.grade[i].tamanho);
      }

      let newTamanhos = [...tamanhos, ...tmpMeasures];
      let newBackup = [...backupMeasures, ...tmpBackupMeasures];
      for (let i = 0; i < newTamanhos.length; i++) tmpStock[i] = 1;

      newTamanhos = newTamanhos.sort();
      if (variation?.stock?.length > 0) {
        for (let i = 0; i < tamanhos.length; i++) {
          newMeasuresIndexes.push(newTamanhos.indexOf(tamanhos[i]));
          oldStockIndexes[newTamanhos.indexOf(tamanhos[i])] = stock[i];
        }
      }

      for (
        let i = Math.min(...newMeasuresIndexes);
        i < newTamanhos.length;
        i++
      ) {
        tmpStock[i] = oldStockIndexes[i];
      }

      setTamanhos(newTamanhos);
      setStock(tmpStock);
      setBackupMeasures(newBackup);
    }
  };

  const handleRemoveItem = (ids, copyGrades) => {
    if (ids.length > 0) {
      let tmpGrades = [...copyGrades];
      let tmpMeasures = [...tamanhos];
      let tmpStock = [...stock];
      let tmpBackupStock = [...backupMeasures];
      let removeID = "";
      let removeMeasures = [];
      let removeBackupMeasures = [];

      for (let i = 0; i < copyGrades.length; i++) {
        if (!ids.includes(tmpGrades[i])) removeID = tmpGrades[i];
      }

      let gradeById = userGrades.find((x) => x.gradeId === removeID);
      for (let i = 0; i < gradeById.grade.length; i++) {
        if (!backupMeasures.includes(gradeById.grade[i].tamanho))
          removeMeasures.push(gradeById.grade[i].tamanho);
        else removeBackupMeasures.push(gradeById.grade[i].tamanho);
      }

      for (let i = 0; i < removeMeasures.length; i++) {
        let currentId = tmpMeasures.indexOf(removeMeasures[i]);
        tmpMeasures.splice(currentId, 1);
        tmpStock.splice(currentId, 1);
      }

      for (let i = 0; i < removeBackupMeasures.length; i++)
        tmpBackupStock.splice(tmpBackupStock.indexOf(removeBackupMeasures[i]));

      setTamanhos(tmpMeasures);
      setStock(tmpStock);
      setBackupMeasures(tmpBackupStock);
    } else {
      setTamanhos([]);
      setStock([]);
    }
  };

  const checkInvalidValues = (type) => {
    if (type === "codigo" && !codigo)
      setInvalidValues([...invalidValues, type]);
    else if (type === "descricao" && !descricao)
      setInvalidValues([...invalidValues, type]);
    else if (type === "preco" && !preco)
      setInvalidValues([...invalidValues, type]);
    else if (type === "tamanhos" && tamanhos?.length === 0)
      setInvalidValues([...invalidValues, type]);
    else if (type === "estoque" && stock?.length === 0)
      setInvalidValues([...invalidValues, type]);
    else if (
      type === "medidas" &&
      (!medidas.peso ||
        !medidas.largura ||
        !medidas.altura ||
        !medidas.comprimento)
    )
      setInvalidValues([...invalidValues, type]);
  };

  return (
    <Modal
      title={t("PRODUCTS-TAB-VARIATION-MODAL.AddVariation")}
      visible={open}
      onOk={() => {
        let variationData = {
          ...variation,
          codigo,
          descricao,
          preco,
          tipoGrade,
          thumbnail,
          controlStock,
          tamanhos,
          stock,
          available,
        };
        if (liveWithTabelaPreco) {
          if (variation.tabelaPreco) {
            variationData.tabelaPreco = variation.tabelaPreco;
          } else {
            let productTabelaPreco =
              tabelaPreco?.length > 0
                ? tabelaPreco.filter(
                    (tabela) => tabela.sku === variationData.codigo
                  )
                : [];
            if (productTabelaPreco.length > 0) {
              productTabelaPreco = productTabelaPreco.map(
                ({ tabela_preco, preco }) => ({
                  tabelaPreco: tabela_preco,
                  valor: parseFloat(preco),
                })
              );
              variationData.tabelaPreco = productTabelaPreco;
            }
          }
        }
        variationData.principal = false;
        if (liveWithCorreios) variationData.medidas = medidas;
        if (tipoGrade === "aberta") variationData.tamanhos = tamanhos;
        else variationData.grades = grades;
        if (imageName) variationData.imageName = imageName;
        onFinish(variationData);
      }}
      onCancel={onClose}
      okText={"Ok"}
      cancelText={t("PRODUCTS-TAB-VARIATION-MODAL.Cancel")}
      okButtonProps={{
        disabled:
          (liveWithCorreios &&
            (!medidas.peso ||
              medidas.peso === "" ||
              medidas.peso === 0 ||
              !medidas.altura ||
              medidas.altura === "" ||
              medidas.altura === 0 ||
              !medidas.largura ||
              medidas.largura === "" ||
              medidas.largura === 0 ||
              !medidas.comprimento ||
              medidas.comprimento === "" ||
              medidas.comprimento === 0)) ||
          !descricao ||
          !codigo ||
          (!liveWithTabelaPreco && !preco) ||
          !thumbnail ||
          (tipoGrade === "aberta" &&
            (!tamanhos || (tamanhos && tamanhos.length === 0))) ||
          (tipoGrade === "fechada" &&
            (!grades || (grades && grades.length === 0))) ||
          (controlStock && stock.length !== tamanhos.length),
      }}
    >
      <div style={{ marginBottom: "5px" }}>
        <label>{t("PRODUCTS-TAB-VARIATION-MODAL.CodeSKU")} *</label>
        <Input
          placeholder={t("PRODUCTS-TAB-VARIATION-MODAL.CodeSKUPlaceholder")}
          onChange={({ target: t }) => {
            if (invalidValues.includes("codigo"))
              setInvalidValues(invalidValues.filter((x) => x !== "codigo"));
            setCodigo(t.value);
          }}
          onBlur={() => checkInvalidValues("codigo")}
          style={{
            border: invalidValues.includes("codigo") && "1px solid red",
          }}
          value={codigo}
          disabled={
            (liveWithVtex && catalog === "vtex") ||
            (liveWithNeomode && catalog === "neomode") ||
            (liveWithMagento && catalog === "magento")
          }
        />
        {invalidValues.includes("codigo") && (
          <s.InvalidLabel className="fade-in">
            {t("PRODUCTS-TAB-VARIATION-MODAL.DescriptionRequired")}
          </s.InvalidLabel>
        )}
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <label>{t("PRODUCTS-TAB-VARIATION-MODAL.Image")} (200x180) *</label>
          <div
            {...getRootProps({
              onChange: async ({ target: t }) =>
                await compress
                  .compress([...t.files], {
                    size: 2,
                    quality: 1,
                    maxWidth: 200,
                    maxHeight: 180,
                    resize: true,
                  })
                  .then((d) => {
                    setThumbnail(d[0].prefix + d[0].data);
                    setImageName(d[0].alt);
                  }),
            })}
            name="thumbnail"
            style={{
              ...s.imgProdStyle,
              border: "none",
              opacity: isDragActive ? 0.6 : 1,
              marginBottom: "auto",
            }}
          >
            <input
              {...getInputProps()}
              accept="image/*"
              multiple={false}
              id="input-dropzone"
              disabled={
                (liveWithVtex && catalog === "vtex") ||
                (liveWithMagento && catalog === "magento") ||
                (liveWithNeomode && catalog === "neomode")
              }
            />
            {thumbnail ? (
              <img
                style={s.imgProdStyle}
                alt="thumbnail"
                src={sourceImage(thumbnail)}
              />
            ) : (
              <Skeleton.Image
                style={{
                  ...s.imgProdStyle,
                  border: "1px solid #d9d9d9",
                  width: "120px",
                  height: "120px",
                }}
                active
              ></Skeleton.Image>
            )}
          </div>
        </div>

        <div>
          <label>
            {t("PRODUCTS-TAB-VARIATION-MODAL.ProductDescription")} *
          </label>
          <Input.TextArea
            style={{
              maxHeight: "120px",
              minHeight: "120px",
              border: invalidValues.includes("descricao") && "1px solid red",
            }}
            placeholder={t(
              "PRODUCTS-TAB-VARIATION-MODAL.DescriptionPlaceholder"
            )}
            value={descricao}
            onChange={({ target: t }) => {
              if (invalidValues.includes("descricao"))
                setInvalidValues(
                  invalidValues.filter((x) => x !== "descricao")
                );
              setDescricao(t.value);
            }}
            onBlur={() => checkInvalidValues("descricao")}
            disabled={
              (liveWithVtex && catalog === "vtex") ||
              (liveWithMagento && catalog === "magento") ||
              (liveWithNeomode && catalog === "neomode")
            }
          />
        </div>
      </div>
      {invalidValues.includes("descricao") && (
        <s.InvalidLabel className="fade-in">
          {t("PRODUCTS-TAB-VARIATION-MODAL.DescriptionRequired")}
        </s.InvalidLabel>
      )}

      {!liveWithTabelaPreco ? (
        <div style={{ marginTop: "5px" }}>
          <label>{t("PRODUCTS-TAB-VARIATION-MODAL.Price")} *</label>
          <Input
            type="number"
            min={0}
            onPaste={(e) => preventInvalidNumberOnPaste(e)}
            onKeyDown={(e) => preventInvalidNumberOnKeyDown(e)}
            placeholder="00,00"
            onChange={({ target: t }) => {
              if (invalidValues.includes("preco"))
                setInvalidValues(invalidValues.filter((x) => x !== "preco"));
              if (t.value >= 0 && !isNaN(t.value)) {
                setPreco(t.value);
              }
            }}
            onBlur={() => checkInvalidValues("preco")}
            style={{
              border: invalidValues.includes("preco") && "1px solid red",
            }}
            value={preco}
            disabled={
              (liveWithVtex && catalog === "vtex") ||
              (liveWithMagento && catalog === "magento") ||
              (liveWithNeomode && catalog === "neomode")
            }
          />
          {invalidValues.includes("preco") && (
            <s.InvalidLabel className="fade-in">
              {t("PRODUCTS-TAB-VARIATION-MODAL.PriceRequired")}
            </s.InvalidLabel>
          )}
        </div>
      ) : (
        <>
          {tmpTabelaPreco && tmpTabelaPreco.length > 0 ? (
            <>
              <label style={{ marginRight: "10px" }}>
                {t("PRODUCTS-TAB-VARIATION-MODAL.PriceTable")}
              </label>
              <Popover
                content={
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>{t("PRODUCTS-TAB-VARIATION-MODAL.Table")}</th>
                        <th style={{ textAlign: "right" }}>
                          {t("PRODUCTS-TAB-VARIATION-MODAL.Price")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tmpTabelaPreco.map(({ valor, tabelaPreco: tabela }) => (
                        <tr key={variation.id + tabela}>
                          <th>{tabela}</th>
                          <th style={{ textAlign: "right" }}>
                            {formatCash(valor / 100, false, unidadeMonetaria)}
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
                title={t("PRODUCTS-TAB-VARIATION-MODAL.Prices")}
                trigger="hover"
              >
                <Button style={{ margin: "10px 0" }}>
                  {t("PRODUCTS-TAB-VARIATION-MODAL.CheckPrices")}
                </Button>
              </Popover>
            </>
          ) : (
            <>
              <label style={{ marginRight: "10px" }}>
                {t("PRODUCTS-TAB-VARIATION-MODAL.PriceTable")}
              </label>
              <Button style={{ marginTop: "10px" }} disabled>
                {t("PRODUCTS-TAB-VARIATION-MODAL.NoPrices")}
              </Button>
            </>
          )}
        </>
      )}

      {!(liveWithVtex || liveWithNeomode || liveWithMagento) && (
        <div style={{ marginTop: "5px" }}>
          <label>{t("PRODUCTS-TAB-VARIATION-MODAL.GridModel")} *</label>
          <br />
          <Radio.Group
            onChange={({ target: t }) => {
              if (tipoGrade === "fechada" && tamanhos.length > 0) {
                setTamanhosGradeFechada(tamanhos);
                setStockGradeFechada(stock);
              } else if (
                tipoGrade === "aberta" &&
                tamanhos.length !== tamanhosGradeFechada.length
              ) {
                setTamanhos(tamanhosGradeFechada);
                setStock(stockGradeFechada);
              }
              setTipoGrade(t.value);
            }}
            value={tipoGrade}
          >
            <Radio value={"aberta"}>
              {t("PRODUCTS-TAB-VARIATION-MODAL.Open")}
            </Radio>
            <Radio value={"fechada"} disabled={catalog === "vtex"}>
              {t("PRODUCTS-TAB-VARIATION-MODAL.Close")}
            </Radio>
          </Radio.Group>
          <br />
          <label style={{ color: "gray", fontSize: "13px" }}>
            {tipoGrade === "aberta"
              ? t("PRODUCTS-TAB-VARIATION-MODAL.OpenGridDescription")
              : t("PRODUCTS-TAB-VARIATION-MODAL.CloseGridDescription")}
          </label>
        </div>
      )}
      <div style={{ marginTop: "5px" }}>
        <label>{t("PRODUCTS-TAB-VARIATION-MODAL.ProductCondition")} *</label>
        <Select
          onChange={(value) => setAvailable(value)}
          style={{ width: "100%" }}
          value={available}
          disabled={
            (liveWithVtex && catalog === "vtex") ||
            (liveWithMagento && catalog === "magento") ||
            (liveWithNeomode && catalog === "neomode")
          }
        >
          <Option value={true}>
            {t("PRODUCTS-TAB-VARIATION-MODAL.Available")}
          </Option>
          <Option value={false}>
            {t("PRODUCTS-TAB-VARIATION-MODAL.Unavailable")}
          </Option>
        </Select>
      </div>
      <div style={{ marginTop: "5px" }}>
        {tipoGrade === "aberta" ? (
          <>
            <label>
              {t("PRODUCTS-TAB-VARIATION-MODAL.Size")}
              {!(liveWithVtex || liveWithNeomode || liveWithMagento) && "s"}*
            </label>
            {!(liveWithVtex || liveWithNeomode || liveWithMagento) && (
              <Search
                placeholder={t("PRODUCTS-TAB-VARIATION-MODAL.NewSize")}
                enterButton={<PlusOutlined />}
                value={searchText}
                onBlur={() => checkInvalidValues("tamanhos")}
                style={{
                  border: invalidValues.includes("tamanhos") && "1px solid red",
                }}
                onChange={(e) => {
                  if (invalidValues.includes("tamanhos"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "tamanhos")
                    );
                  setSearchText(e.target.value);
                }}
                onSearch={(v) => {
                  if (v && v.trim() !== "") {
                    if (tamanhos.includes(v.trim())) {
                      message.info(
                        t("PRODUCTS-TAB-GRID-MODAL.SizesSameNameError")
                      );
                    } else setTamanhos([...tamanhos, v]);
                    if (controlStock && stock.length > 0)
                      setStock([...stock, 1]);
                    setSearchText("");
                  } else {
                    message.warning({
                      content: t("PRODUCTS-TAB-PRODUCT-MODAL.InformValidSize"),
                      key: "invalid-size",
                    });
                  }
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginTop: "10px",
              }}
            >
              {tamanhos &&
                tamanhos.map((s, i2) => (
                  <Tag
                    key={`${s}-${i2}`}
                    closable={
                      !(liveWithVtex || liveWithNeomode || liveWithMagento)
                    }
                    onClose={() => {
                      setTamanhos(tamanhos.filter((e) => e !== s));
                      let tmp = [...stock];
                      tmp.splice(i2, 1);
                      setStock(tmp);
                    }}
                    style={{ margin: "0 5px 5px 0" }}
                  >
                    {s}
                  </Tag>
                ))}
            </div>
            {invalidValues.includes("tamanhos") && (
              <s.InvalidLabel className="fade-in">
                {t("PRODUCTS-TAB-VARIATION-MODAL.SizeRequired")}
              </s.InvalidLabel>
            )}
            {tamanhos && tamanhos.length > 0 && (
              <Checkbox
                disabled={
                  liveWithVtex || liveWithTabelaPreco || liveWithNeomode
                }
                style={{ margin: "5px 0" }}
                checked={controlStock}
                onChange={() => {
                  if (stock.length === 0) {
                    let tmp = [];
                    for (let i = 0; i < tamanhos.length; i++) tmp[i] = 1;
                    setStock(tmp);
                  }
                  setControlStock(!controlStock);
                  if (controlStock) setStock([]);
                }}
              >
                {t("PRODUCTS-TAB-VARIATION-MODAL.ControlStock")}
              </Checkbox>
            )}
            {controlStock && (
              <div>
                <label>
                  {t("PRODUCTS-TAB-VARIATION-MODAL.FulfillSizeStock")} *
                </label>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {tamanhos.map((tamanho, idx) => (
                    <s.TamanhosWrapper key={`${tamanho}-${idx}`}>
                      <s.Tamanho>{tamanho}</s.Tamanho>
                      <Input
                        id={tamanho}
                        value={stock[idx]}
                        min={0}
                        onPaste={(e) => preventInvalidNumberOnPaste(e)}
                        onKeyDown={(e) => preventInvalidNumberOnKeyDown(e)}
                        onChange={({ target: { value: v } }) => {
                          if (v >= 0 && !isNaN(v)) {
                            let tmp = [...stock];
                            if (v) tmp[idx] = parseInt(v.replace(/\D/g, ""));
                            else tmp[idx] = 0;
                            setStock(tmp);
                          }
                          if (invalidValues.includes("estoque"))
                            setInvalidValues(
                              invalidValues.filter((x) => x !== "estoque")
                            );
                        }}
                        style={{
                          width: "100%",
                          height: 38,
                          textAlign: "center",
                        }}
                      />
                    </s.TamanhosWrapper>
                  ))}
                </div>
                {invalidValues.includes("estoque") && (
                  <s.InvalidLabel className="fade-in">
                    {t("PRODUCTS-TAB-VARIATION-MODAL.StockRequired")}
                  </s.InvalidLabel>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <label>{t("PRODUCTS-TAB-VARIATION-MODAL.Grids")}</label>
            <br />
            <Select
              mode="multiple"
              onChange={(s) => {
                if (s.length > grades.length) handleGridMeasures(s);
                else handleRemoveItem(s, grades);
                setGrades(s);
              }}
              style={{ width: "71%" }}
              value={grades}
            >
              {userGrades &&
                userGrades.map((g) => (
                  <Option value={g.gradeId} key={g.gradeId}>
                    {g.nome}
                  </Option>
                ))}
            </Select>
            <Button
              type="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => setModalNovaGrade(true)}
            >
              <PlusSquareOutlined />
              {t("PRODUCTS-TAB-VARIATION-MODAL.NewSize")}
            </Button>
            <ModalNovaGrade
              userId={user.id}
              open={modalNovaGrade}
              onClose={() => setModalNovaGrade(false)}
            />
            {userGrades && userGrades.length > 0 && (
              <Checkbox
                style={{ margin: "10px 0" }}
                checked={controlStock}
                disabled={
                  liveWithVtex || liveWithTabelaPreco || liveWithNeomode
                }
                onChange={() => {
                  if (stock.length === 0) {
                    for (let i = 0; i < tamanhos.length; i++) stock[i] = 1;
                  }
                  setControlStock(!controlStock);
                  if (controlStock) setStock([]);
                }}
              >
                {t("PRODUCTS-TAB-VARIATION-MODAL.ControlStock")}
              </Checkbox>
            )}
            {controlStock && (
              <div>
                <label>
                  {t("PRODUCTS-TAB-VARIATION-MODAL.FulfillSizeGridStock")}*
                </label>
                <Scrollbars style={{ width: "100%", height: "110px" }}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    {tamanhos.map((tamanho, idx) => (
                      <s.TamanhosWrapper
                        tamanho={tamanho.length}
                        key={`${tamanho}-${idx}`}
                      >
                        <s.Tamanho id={tamanho}>{tamanho}</s.Tamanho>
                        <Input
                          value={stock[idx]}
                          onChange={({ target: { value: v } }) => {
                            let tmp = [...stock];
                            if (v && v >= 0)
                              tmp[idx] = parseInt(v.replace(/\D/g, ""));
                            else tmp[idx] = 0;
                            setStock(tmp);
                            if (invalidValues.includes("estoque"))
                              setInvalidValues(
                                invalidValues.filter((x) => x !== "estoque")
                              );
                          }}
                          style={{
                            width: "100%",
                            height: 38,
                            textAlign: "center",
                          }}
                        />
                      </s.TamanhosWrapper>
                    ))}
                  </div>
                </Scrollbars>
                {invalidValues.includes("estoque") && (
                  <s.InvalidLabel className="fade-in">
                    {t("PRODUCTS-TAB-VARIATION-MODAL.StockRequired")}
                  </s.InvalidLabel>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {liveWithCorreios && (
        <>
          <label>{t("PRODUCTS-TAB-VARIATION-MODAL.ProductMeasures")} * </label>
          <Tooltip
            overlayStyle={{
              maxWidth: "600px",
            }}
            title={
              <ul
                style={{
                  paddingLeft: "20px",
                }}
              >
                <li>Peso: de 100 (gramas) até 30000 (30kg);</li>
                <li>Comprimento: de 13cm até 100cm;</li>
                <li>Altura: de 1cm até 100cm;</li>
                <li>Largura: de 8cm até 100cm;</li>
                <li>
                  A soma resultante do comprimento + largura + altura não deve
                  superar 200 cm;
                </li>
                <li>
                  A soma resultante do comprimento + o dobro do diâmetro não
                  pode ser menor que 28 cm;
                </li>
              </ul>
            }
          >
            <img
              alt="informação"
              src={"/assets/icons/info.svg"}
              style={{ marginLeft: "10px", transform: "translateY(-4px)" }}
            />
          </Tooltip>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "10px" }}>
              <Input
                addonBefore={t("PRODUCTS-TAB-VARIATION-MODAL.Weight")}
                addonAfter="Kg"
                type="number"
                min={0}
                value={medidas.peso || ""}
                onBlur={() => checkInvalidValues("medidas")}
                onChange={(e) => {
                  if (invalidValues.includes("medidas"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "medidas")
                    );
                  handleChangeMedidas(e, "peso");
                }}
                style={{
                  marginBottom: "10px",
                  border: invalidValues.includes("medidas") && "1px solid red",
                }}
              />
              <Input
                addonBefore={t("PRODUCTS-TAB-VARIATION-MODAL.Width")}
                addonAfter="cm"
                type="number"
                min={0}
                value={medidas.largura || ""}
                onBlur={() => checkInvalidValues("medidas")}
                onChange={(e) => {
                  if (invalidValues.includes("medidas"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "medidas")
                    );
                  handleChangeMedidas(e, "largura");
                }}
                style={{
                  border: invalidValues.includes("medidas") && "1px solid red",
                }}
              />
            </div>
            <div>
              <Input
                addonBefore={t("PRODUCTS-TAB-VARIATION-MODAL.Height")}
                addonAfter="cm"
                type="number"
                value={medidas.altura || ""}
                onBlur={() => checkInvalidValues("medidas")}
                onChange={(e) => {
                  if (invalidValues.includes("medidas"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "medidas")
                    );
                  handleChangeMedidas(e, "altura");
                }}
                style={{
                  marginBottom: "10px",
                  border: invalidValues.includes("medidas") && "1px solid red",
                }}
              />
              <Input
                addonBefore={t("PRODUCTS-TAB-VARIATION-MODAL.Length")}
                addonAfter="cm"
                type="number"
                min={0}
                value={medidas.comprimento || ""}
                onBlur={() => checkInvalidValues("medidas")}
                onChange={(e) => {
                  if (invalidValues.includes("medidas"))
                    setInvalidValues(
                      invalidValues.filter((x) => x !== "medidas")
                    );
                  handleChangeMedidas(e, "comprimento");
                }}
                style={{
                  border: invalidValues.includes("medidas") && "1px solid red",
                }}
              />
            </div>
          </div>
          {invalidValues.includes("medidas") && (
            <s.InvalidLabel className="fade-in">
              {t("PRODUCTS-TAB-VARIATION-MODAL.MeasuresRequired")}
            </s.InvalidLabel>
          )}
        </>
      )}
      {!newVariation && (
        <div>
          <label>{t("PRODUCTS-TAB-VARIATION-MODAL.DeleteVariation")}</label>
          <div>
            <Button
              danger
              onClick={deleteVariation}
              type="primary"
              style={{ width: "60px", marginTop: "12px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ModalVariation;
