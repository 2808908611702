import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;

  label {
    color: grey;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  label {
    text-transform: capitalize;
  }

  span {
    text-transform: lowercase;
  }

  img {
    margin: 15px auto;
    max-width: 350px;
  }
`;

export const InformativeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  label {
    color: grey;
  }

  .required {
    font-size: 12px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px;
`;

export const EmailPreviewContainer = styled.div`
  width: 100%;
  height: 530px;
  margin: 20px 0;

  .container {
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: flex-start;
      background: ${(props) => props.background};
      height: 70px;

      img {
        cursor: pointer;
        height: 50px;
        max-width: 300px;
        margin: 10px;
      }
    }

    .order-received {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #333333;
      height: 75px;

      span {
        font-size: 14px;
        color: #d2d2d2;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;

        label {
          font-size: 16px;
          color: #f6b1ff;
        }
      }
    }

    .order-info {
      height: 200px;
      background: ${(props) => props.background};

      .block {
        background: #d2d2d2;
        width: 225px;
        height: 80px;
        margin: 10px 20px;
      }
    }

    .order-total {
      display: flex;
      align-items: center;
      background: #333333;
      height: 110px;

      .block {
        background: #d2d2d2;
        width: 225px;
        height: 80px;
        margin: 10px 20px;
      }

      .line {
        background: #d2d2d2;
        width: 300px;
        height: 10px;
        margin: 10px 20px;
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: ${(props) => props.background};
      height: 95px;

      img {
        height: 50px;
        max-width: 300px;
        margin: 10px 10px 5px 10px;
      }

      label {
        color: white;
        margin: 0 0 5px 20px;
      }
    }
  }
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  text-align: center;

  label {
    color: var(--corPrimaria);
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  span {
    color: grey;
    font-weight: normal;
    text-transform: lowercase;
  }
`;

export const TestServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px 0;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    width: 100%;
  }
`;

// ---------------- MODAL LOGO ----------------
export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0 0 0;

  .upload-image {
    width: 282px;
  }

  .link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    img {
      margin: 15px 0;
      max-width: 282px;
    }
  }

  .divisor {
    width: 90%;
    height: 1px;
    margin: 20px 0;
    background-color: grey;
  }
`;

export const imgStyle = {
  width: "100%",
  height: "180px",
  background: "white",
  borderRadius: "2px",
};

export const InvalidLabel = styled.span`
  color: red;
  margin-bottom: 5px;
`;
