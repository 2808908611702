import { useState } from "react";

const useUser = () => {
  const [user, setUser] = useState(null);
  const [openedFrom, setOpenedFrom] = useState("");

  return { user, setUser, openedFrom, setOpenedFrom };
};

export default useUser;
