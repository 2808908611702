import React, { useEffect, useState } from "react";
import { Card, Button, notification, Checkbox, Input } from "antd";
import { useTranslation } from "react-i18next";
import {
  UsergroupAddOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
  SaveOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { usersActions } from "./redux";
import { managerService } from "../../../../services";
import {
  jsonToCsv,
  preventInvalidNumberOnKeyDown,
  preventInvalidNumberOnPaste,
} from "../../../../utils";
import * as s from "./styled-online-users-tab";

const OnlineUsersTab = ({ live }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const users = useSelector((state) => state.users);
  const [showViewers, setShowViewers] = useState(
    typeof live?.showViewers === "boolean" ? live.showViewers : true
  );
  const [viewers, setViewers] = useState(live?.viewers || 0);
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false); //gerando relatório dos usuários online
  const [loadingUser, setLoadingUser] = useState(false); //buscando relatório dos usuários
  const [loadingChat, setLoadingChat] = useState(false); //buscando relatório do chat

  useEffect(() => {
    if (live?.docId) {
      dispatch(usersActions.initializeListenUsers(live.docId));
      return () => dispatch(usersActions.clearState());
    }
  }, [live]);

  const finish = async () => {
    setLoading(true);
    managerService
      .manageViewersOnLive(live.docId, {
        viewers: parseInt(viewers),
        showViewers,
      })
      .then(() =>
        notification.success({
          key: "aviso",
          message: t("ONLINE-USERS-TAB.Success"),
          description: t("ONLINE-USERS-TAB.InformationUpdateSuccess"),
          onClick: () => notification.close("aviso"),
          duration: 6,
        })
      )
      .catch(() =>
        notification.error({
          key: "aviso",
          message: t("ONLINE-USERS-TAB.GeneralError"),
          description: t("ONLINE-USERS-TAB.InformationUpdateError"),
          onClick: () => notification.close("aviso"),
          duration: 6,
        })
      )
      .finally(() => setLoading(false));
  };

  const downloadData = (data, filename, format) => {
    var downloadLink = document.createElement("a");
    if (format === "excel") {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `${filename}.xlsx`;
    } else {
      var blob = new Blob(["\ufeff", data]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `${filename}.${format}`;
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const downloadUserReport = (format) => {
    setLoadingUser(format);
    managerService
      .userReport(live.docId, format)
      .then((data) => {
        downloadData(
          data,
          `${t("ONLINE-USERS-TAB.Access")} - ${live.nome}`,
          format
        );
        setLoadingUser(false);
        feedback(true, "user-report");
      })
      .catch((e) => {
        setLoadingUser(false);
        feedback(false, "user-report", e);
      });
  };

  const downloadChatReport = (format) => {
    setLoadingChat(format);
    managerService
      .chatReport(live.docId, format)
      .then((data) => {
        downloadData(
          data,
          `${t("ONLINE-USERS-TAB.ChatComments")} - ${live.nome}`,
          format
        );
        setLoadingChat(false);
        feedback(true, "user-report");
      })
      .catch((e) => {
        setLoadingChat(false);
        feedback(false, "user-report", e);
      });
  };

  const downloadUsersOnlineReport = () => {
    setLoadingUsers(true);
    let csvData = jsonToCsv(
      [
        { codigo: "codigo", name: "nome", email: "email", phone: "telefone" },
        ...users,
      ].map(({ codigo = "", name = "", email = "", phone = "" }) => ({
        codigo,
        nome: name,
        email,
        telefone: phone,
      }))
    );
    downloadData(
      csvData,
      `${t("ONLINE-USERS-TAB.OnlineUsers")} - ${live.nome}`,
      "csv"
    );
    setLoadingUsers(false);
  };

  const feedback = (success, key, description) => {
    if (success)
      notification.success({
        key,
        message: t("ONLINE-USERS-TAB.DownloadConcluded"),
        onClick: () => notification.close(key),
        duration: 10,
      });
    else
      notification.error({
        key,
        message: "Ops!",
        description:
          description && typeof description === "string"
            ? description
            : t("ONLINE-USERS-TAB.DownloadError"),
        onClick: () => notification.close(key),
        duration: 10,
      });
  };

  return (
    <s.Container className="fade-in">
      <Card
        title={
          <>
            <UsergroupAddOutlined style={{ marginRight: "10px" }} />
            {t("ONLINE-USERS-TAB.ControlOnlineUsers")}
          </>
        }
        style={{ width: "400px", margin: "5px" }}
        className="shadow"
      >
        <s.Wrapper>
          <p>{t("ONLINE-USERS-TAB.ControlOnlineUsersDescription")}</p>
          <Checkbox
            checked={showViewers}
            onChange={() => {
              setShowViewers(!showViewers);
              if (showViewers) setViewers(0);
            }}
          >
            <label>{t("ONLINE-USERS-TAB.ShowViewers")}</label>
          </Checkbox>
        </s.Wrapper>
      </Card>

      <Card
        title={
          <>
            <UserAddOutlined style={{ marginRight: "10px" }} />
            {t("ONLINE-USERS-TAB.IncreaseViewers")}
          </>
        }
        style={{ width: "400px", margin: "5px" }}
        className="shadow"
      >
        <s.Wrapper>
          <p>
            {t("ONLINE-USERS-TAB.IncreaseViewersDescriptionOne")} <b>X</b>{" "}
            {t("ONLINE-USERS-TAB.IncreaseViewersDescriptionTwo")}
          </p>
          <div>
            <label>{t("ONLINE-USERS-TAB.SpectatorsNumber")}: </label>
            <Input
              value={viewers}
              onChange={({ target: { value: v } }) => {
                if (v >= 0 && !isNaN(v)) setViewers(parseInt(v));
              }}
              onPaste={(e) => preventInvalidNumberOnPaste(e)}
              onKeyDown={(e) => preventInvalidNumberOnKeyDown(e)}
              type="number"
              disabled={!showViewers}
              style={{ maxWidth: 100, margin: "0 10px" }}
            />
          </div>
        </s.Wrapper>
      </Card>

      <Card
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <UserSwitchOutlined style={{ marginRight: "10px" }} />
            {t("ONLINE-USERS-TAB.UsersNAccess")}
            <div
              style={{ marginLeft: "auto", color: users.length > 0 && "green" }}
            >
              <UserOutlined style={{ marginRight: "3px" }} /> {users.length}
            </div>
          </div>
        }
        style={{ width: "400px", margin: "5px" }}
        className="shadow"
      >
        <p>{t("ONLINE-USERS-TAB.UsersNAccessDescription")}</p>
        <p>
          <strong>{t("ONLINE-USERS-TAB.Attention")}: </strong>
          {t("ONLINE-USERS-TAB.UsersNotInList")}
        </p>
        <Button
          type="primary"
          onClick={() => downloadUserReport("csv")}
          style={{ margin: "20px 20px 0 0" }}
          loading={loadingUser === "csv"}
          disabled={loadingUser === "csv"}
        >
          {t("ONLINE-USERS-TAB.CSVReport")}
        </Button>
        <Button
          type="primary"
          onClick={() => downloadUserReport("excel")}
          style={{ margin: "20px 20px 0 0" }}
          loading={loadingUser === "excel"}
          disabled={loadingUser === "excel"}
        >
          {t("ONLINE-USERS-TAB.EXCELReport")}
        </Button>
        {users.length > 0 && (
          <Button
            type="primary"
            onClick={downloadUsersOnlineReport}
            style={{ margin: "20px 20px 0 0" }}
            loading={loadingUsers}
            disabled={loadingUsers}
          >
            {t("ONLINE-USERS-TAB.CSVOnlineUsers")}
          </Button>
        )}
      </Card>

      <Card
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <FileOutlined style={{ marginRight: "10px" }} />
            {t("ONLINE-USERS-TAB.ChatLive")}
          </div>
        }
        style={{ width: "400px", margin: "5px" }}
        className="shadow"
      >
        <p>{t("ONLINE-USERS-TAB.ChatLiveDescription")}</p>
        <Button
          type="primary"
          onClick={() => downloadChatReport("csv")}
          style={{ margin: "20px 20px 0 0" }}
          loading={loadingChat === "csv"}
          disabled={loadingChat === "csv"}
        >
          {t("ONLINE-USERS-TAB.CSVReport")}
        </Button>
        <Button
          type="primary"
          onClick={() => downloadChatReport("excel")}
          style={{ margin: "20px 20px 0 0" }}
          loading={loadingChat === "excel"}
          disabled={loadingChat === "excel"}
        >
          {t("ONLINE-USERS-TAB.EXCELReport")}
        </Button>
      </Card>

      <s.ButtonContainer>
        <Button
          type="primary"
          htmlType="submit"
          onClick={finish}
          disabled={loading || (viewers !== 0 && !viewers)}
          loading={loading}
        >
          {loading ? t("ONLINE-USERS-TAB.Saving") : t("ONLINE-USERS-TAB.Save")}
          {!loading ? <SaveOutlined /> : <></>}
        </Button>
      </s.ButtonContainer>
    </s.Container>
  );
};

export default OnlineUsersTab;
