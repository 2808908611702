import React, { createContext } from "react";
import useTheme from "./hooks/use-theme";

const ThemeContext = createContext({
  corPrimaria: "",
  corSecundaria: "",
  showPoweredBy: true,
  logo: false,
  setCorPrimaria: () => {},
  setCorSecundaria: () => {},
  setShowPoweredBy: () => {},
  setLogo: () => {},
  verticalChatHeight: 170,
  setVerticalChatHeight: () => {},
});

const ThemeProvider = ({ children }) => {
  const {
    logo,
    setLogo,
    corPrimaria,
    setCorPrimaria,
    corSecundaria,
    setCorSecundaria,
    showPoweredBy,
    setShowPoweredBy,
    verticalChatHeight,
    setVerticalChatHeight,
  } = useTheme();

  return (
    <ThemeContext.Provider
      value={{
        logo,
        setLogo: (novoLogo) => setLogo(novoLogo),
        corPrimaria,
        setCorPrimaria: (novaCorPrimaria) => setCorPrimaria(novaCorPrimaria),
        corSecundaria,
        setCorSecundaria: (novaCorSecundaria) =>
          setCorSecundaria(novaCorSecundaria),
        showPoweredBy,
        setShowPoweredBy: (newShowPoweredBy) =>
          setShowPoweredBy(newShowPoweredBy),
        verticalChatHeight,
        setVerticalChatHeight,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
