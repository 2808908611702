import { cnpj, cpf } from "cpf-cnpj-validator";
import mock from "./mock";
// import { useTranslation } from "react-i18next";

// const { t } = useTranslation();

export function convertImgToBase64URL(url, callback, isProduct) {
  if (url) {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("CANVAS"),
        ctx = canvas.getContext("2d"),
        dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL(`image/png`);
      callback(dataURL);
      canvas = null;
    };
    img.onerror = function () {
      callback(mock.defaultImagebase64, true);
    };
    img.src = url;
  } else if (isProduct) callback(mock.defaultImagebase64);
  else callback(false);
}

export const validateCPF = (value) => {
  return cpf.isValid(value);
};

export const validateCNPJ = (value) => {
  return cnpj.isValid(value);
};

export const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};
export const formatCash = (n, noCash, unidadeMonetaria) => {
  var currencyUnit = unidadeMonetaria ? unidadeMonetaria : "R$";
  if (n) {
    var numero = n.toFixed(2).split(".");
    if (!noCash) {
      numero[0] =
        currencyUnit + " " + numero[0].split(/(?=(?:...)*$)/).join(".");
      return numero.join(",");
    } else {
      numero[0] = numero[0].split(/(?=(?:...)*$)/).join(".");
      return numero.join(",");
    }
  } else {
    if (noCash) return "0,00";
    else if (unidadeMonetaria) return currencyUnit + "0";
    return "R$ 0,00";
  }
};
export const moveToElement = (elementID, targetID) => {
  var top = 0;
  var elementTop = document.getElementById(elementID).offsetTop;
  var width = document.getElementById(elementID).offsetWidth;

  if (document.getElementById(targetID))
    top = document.getElementById(targetID).offsetTop;
  else top = 1000;

  document.getElementById(elementID).style.marginTop = elementTop + "px";
  setTimeout(() => {
    document.getElementById(elementID).style.marginTop = top + "px";
    document.getElementById(elementID).style.top = top + "px";
  }, 200);

  if (window.screen.width < 955) {
    document.getElementById(
      elementID
    ).style.left = `calc(100% - (${width}px + 26px))`;
    setTimeout(() => {
      document.getElementById(elementID).style.opacity = 0;
    }, 100);
  }
};
export const moveToRightElement = (elementID, marginReverse) => {
  var width = document.getElementById(elementID).offsetWidth;

  if (marginReverse) {
    document.getElementById(elementID).style.marginRight = "-1000px";
    document.getElementById(elementID).style.right = "-1000px";
  } else {
    document.getElementById(elementID).style.marginLeft = "1000px";
    document.getElementById(elementID).style.left = "1000px";
  }

  if (window.screen.width < 955) {
    document.getElementById(
      elementID
    ).style.left = `calc(100% - (${width}px + 26px))`;
    setTimeout(() => {
      document.getElementById(elementID).style.opacity = 0;
    }, 100);
  }
};

export const handleChangeTime = (value, oldValue, changeValue) => {
  let greaterThanNine = ["0", "1"].includes(value[0] + "") ? "[0-9]" : "[0-3]";
  const regex1 = new RegExp(`^[0-2]$`);
  const regex2 = new RegExp(`^[0-2]${greaterThanNine}$`);
  const regex3 = new RegExp(`^[0-2]${greaterThanNine}:$`);
  const regex4 = new RegExp(`^[0-2]${greaterThanNine}:[0-5]$`);
  const regex5 = new RegExp(`^[0-2]${greaterThanNine}:[0-5][0-9]$`);
  const regex6 = new RegExp(`^[0-2]${greaterThanNine}:[0-5][0-9]:$`);
  const regex7 = new RegExp(`^[0-2]${greaterThanNine}:[0-5][0-9]:[0-5]$`);
  const regex8 = new RegExp(`^[0-2]${greaterThanNine}:[0-5][0-9]:[0-5][0-9]$`);
  if (value.length === 2 && oldValue.length < 2) value += ":";
  if (value.length === 5 && oldValue.length < 5) value += ":";
  if (
    (value.length >= 1 && value.length >= oldValue.length) ||
    value.length === 1
  ) {
    if (value.length === 1 && regex1.test(value)) changeValue(value);
    if (value.length === 2 && regex2.test(value)) changeValue(value);
    if (value.length === 3 && regex3.test(value)) changeValue(value);
    if (value.length === 4 && regex4.test(value)) changeValue(value);
    if (value.length === 5 && regex5.test(value)) changeValue(value);
    if (value.length === 6 && regex6.test(value)) changeValue(value);
    if (value.length === 7 && regex7.test(value)) changeValue(value);
    if (value.length === 8 && regex8.test(value)) changeValue(value);
  } else if (value.length === 0) changeValue(value);
  else changeValue(oldValue.slice(0, -1));
};

export const sourceImage = (thumbnail) => {
  if (thumbnail && thumbnail.length > 0) {
    if (thumbnail.split(`data:`)[1] || thumbnail.split("://")[1])
      return thumbnail;
    else return `data:image/png;base64, ${thumbnail}`;
  } else return ``;
};

export const csvToJson = (csvText) => {
  let lines = [];
  const linesArray = csvText.split("\n");
  // for trimming and deleting extra space
  linesArray.forEach((e) => {
    const row = e.replace(/[\s]+[,]+|[,]+[\s]+/g, ",").trim();
    lines.push(row);
  });
  // for removing empty record
  // lines.splice(lines.length - 1, 1);
  const result = [];
  const headers = lines[0].split(",");
  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    if (lines[i].length > 0) {
      const currentline = lines[i].split(",");
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
  }
  //return result; //JavaScript object
  // return JSON.stringify(result); //JSON
  return result;
};

export const getFilenameFromUrl = (url) => {
  if (url) {
    var m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      return m[1];
    }
  }
  return "";
};

export const jsonToCsv = (objArray) => {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";
  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";
      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
};

export const duas_casas = (numero) => {
  if (numero) {
    if (numero < 0) numero = 0;
    if (numero <= 9) numero = "0" + numero;
    return numero + "";
  }
  return "00";
};

export const formatTime = (time) => {
  if (time) {
    let hora = duas_casas(Math.trunc(time / 3600));
    let minuto = duas_casas(Math.trunc((time % 3600) / 60));
    let segundo = duas_casas((time % 3600) % 60).substring(0, 2);
    if (segundo === "9.") segundo = "09";
    return hora + ":" + minuto + ":" + segundo;
  }
  return "00:00:00";
};

export const GetIconByLanguage = (language) => {
  if (language === "ptBR") return "/assets/icons/ptBR.svg";
  else if (language === "en") return "/assets/icons/en.svg";
  return "/assets/icons/es.svg";
};

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const createLinkElementInsideText = (
  allTextWords,
  replaceLink,
  translatedText
) => {
  if (allTextWords.length > 0) {
    let urlRegex = /(https?:\/\/[^\s]+)/; // regex para os links
    let par = document.createElement("p"); // gera o elemento <p /> para dar appendChild do elemento <a/> depois
    let linkIndex = 1; // pega os indexes dos links
    let wordsCopy = JSON.parse(JSON.stringify(allTextWords));
    let appendLinkNumber = wordsCopy.filter((x) => urlRegex.test(x)).length > 1;

    for (let i = 0; i < allTextWords.length; i++) {
      if (urlRegex.test(allTextWords[i])) {
        let a = document.createElement("a");
        let textNode = replaceLink ? translatedText : allTextWords[i];
        let linkText = document.createTextNode(textNode);
        a.href = allTextWords[i];
        a.appendChild(linkText);
        a.target = "_blank";
        a.onclick = () => window.open(allTextWords[i]);
        a.innerText = replaceLink
          ? appendLinkNumber
            ? ` (${translatedText} ${linkIndex}) `
            : `(${translatedText})`
          : allTextWords[i];
        par.appendChild(a);
        linkIndex += 1;
      } else par.innerHTML += allTextWords[i] + " ";
    }

    return par;
  }
};

export const stringToColor = function (str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var j = 0; j < 3; j++) {
    var value = (hash >> (j * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

export const shouldAppear = (product, live, videoTime, startTime) => {
  if (product?.showOptions) {
    let time;
    if (live?.aoVivo) {
      let now = new Date().getTime();
      let realStreamingStartTime = now - startTime;
      realStreamingStartTime += videoTime;
      time = realStreamingStartTime / 1000;
    } else time = videoTime;

    let { when, someTime } = product.showOptions;
    if (when === "ever") return true;
    if (
      when === "after-appearing-on-video" &&
      formatTime(time) >= product.tempoInicio
    )
      return true;
    if (when === "after-some-time" && formatTime(videoTime) >= someTime)
      return true;

    return false;
  }
  return true;
};

export const isIOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const preventInvalidNumberOnPaste = (e) => {
  let clipboardData = e.clipboardData || window.clipboardData;
  let pastedData = clipboardData.getData("Text");
  let regex = /[a-zA-Z-+._]+/;
  if (regex.test(pastedData)) {
    e.stopPropagation();
    e.preventDefault();
  }
};

export const preventInvalidNumberOnKeyDown = (e) =>
  ["e", "E", "-"].includes(e.key) && e.preventDefault();

export function getCreditCardBrand(number) {
  // visa
  var re = new RegExp("^4");
  if (number.match(re) != null) return "Visa";

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number
    )
  )
    return "master";

  // AMEX
  re = new RegExp("^3[47]");
  if (number.match(re) != null) return "amex";

  // Discover
  re = new RegExp(
    "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
  );
  if (number.match(re) != null) return "discover";

  // Diners
  re = new RegExp("^36");
  if (number.match(re) != null) return "diners";

  // JCB
  re = new RegExp("^35(2[89]|[3-8][0-9])");
  if (number.match(re) != null) return "jcb";

  // Visa Electron
  re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
  if (number.match(re) != null) return "visa";

  return false;
}

export const onlyMonetaryTypes = [
  "US$",
  "R$",
  "€",
  "¥",
  "£",
  "CHF",
  "AU$",
  "CA$",
  "SEK",
  "NOK",
  "DKK",
  "NZ$",
  "SG$",
  "HK$",
  "MXN",
  "ARS",
  "₽",
  "¥",
  "₹",
  "₩",
];

export function fakeMaskChange(value) {
  let string = value.toString().replace(/[^\d]/g, "");
  switch (string.length) {
    case 7:
      return `${string.substring(0, 2)}.${string.substring(
        2,
        5
      )},${string.substring(5, 7)}`;
    case 6:
      return `${string[0]}.${string.substring(1, 4)},${string.substring(4, 6)}`;
    case 5:
      if (string[0] === "0") {
        return `${string.substring(1, 3)},${string.substring(3, 5)}`;
      } else {
        return `${string.substring(0, 3)},${string.substring(3, 5)}`;
      }
    case 4:
      if (string[0] === "0") {
        return `${string[1]},${string.substring(2, 4)}`;
      } else {
        return `${string.substring(0, 2)},${string.substring(2, 4)}`;
      }
    case 3:
      return `${string[0]},${string.substring(1, 3)}`;
    case 2:
      return `0,${string}`;
    case 1:
      return `0,0${string}`;
  }
}
