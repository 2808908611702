import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 826px) {
    flex-direction: column;
  }
  section {
    display: flex;
    flex-direction: column;
    @media (max-width: 826px) {
      margin: 10px 0;
      border: none;
    }
    width: 290px;
  }
  .wrapper {
    border-right: 1px solid #f0f0f0;
    margin: 0 39px 0 40px;
    height: 362px;
    @media (max-width: 826px) {
      display: none;
    }
  }
`;
export const imgStyle = {
  width: "100%",
  height: "180px",
  background: "white",
  borderRadius: "2px",
};

export const SpaceBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const Shipping = styled.div`
  margin: 0 10px;
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const InvalidLabel = styled.label`
  color: red;
  margin-bottom: 5px;
`;

export const invalidValue = "1px solid red";

export const CountDownContainer = styled.div`
  color: white;
  margin-top: 25px;
  padding: 20px;
  margin-left: 24px;
  background: var(--corPrimaria);
  width: fit-content;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 300;

  h1,
  span {
    color: white;
    margin: 0;
    font-size: 40px;
  }
`;
