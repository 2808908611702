import React, { useState, useCallback } from "react";
import { Modal, Button, Input, Steps, Table, Progress, message } from "antd";
import { useTranslation } from "react-i18next";
import { FileTextOutlined } from "@ant-design/icons";
import convert from "xml-js";

const ModalUploadXml = ({ open, onCancel, handleUpdateXml }) => {
  const { t } = useTranslation();

  const [url, setUrl] = useState("");

  const uploadLink = async (e) => {
    setUrl(url);
    let tmpProducts = []; //lista de produtos no formato correto
    e.preventDefault();

    await fetch(url)
      .then((res) => res.text())
      .then((res) => {
        var xml = convert.xml2json(res, { compact: true, spaces: 4 });
        const xmlJson = JSON.parse(xml);
        xmlJson.rss.channel.item.forEach((xmlProduct, index) => {
          tmpProducts.push({
            codigo: xmlProduct["g:id"]?._text,
            id: index + 1,
            imageName: xmlProduct["g:title"]?._text,
            preco: parseInt(
              xmlProduct["g:sale_price"]?._text.replace(" BRL", "")
            ),
            precoOriginal: parseInt(
              xmlProduct["g:price"]?._text.replace(" BRL", "")
            ),
            redirectLink: xmlProduct["link"]?._cdata.replace(
              "http://",
              "https://"
            ),
            descricao: xmlProduct["g:title"]?._text,
            // tamanhos: ["único"],
            thumbnail: xmlProduct["g:image_link"]?._text,
            tempoInicio: null,
            tempoFim: null,
            tipoGrade: "aberta",
            principal: true,
          });
        });
        message.success(t("ROOM-TAB.UploadSuccess"));
        handleUpdateXml(tmpProducts);
        onCancel();
      })
      .catch(() => {
        message.error(t("ROOM-TAB.UploadError"));
      });
  };
  const uploadFile = async (e) => {
    const file = e.target.files[0];

    e.preventDefault();
    let tmpProducts = []; //lista de produtos no formato correto

    await fetch(URL.createObjectURL(file))
      .then((res) => res.text())
      .then((res) => {
        var xml = convert.xml2json(res, { compact: true, spaces: 4 });
        const xmlJson = JSON.parse(xml);
        xmlJson.rss.channel.item.forEach((xmlProduct, index) => {
          tmpProducts.push({
            codigo: xmlProduct["g:id"]?._text,
            id: index + 1,
            imageName: xmlProduct["g:title"]?._text,
            preco: parseInt(
              xmlProduct["g:sale_price"]?._text.replace(" BRL", "")
            ),
            precoOriginal: parseInt(
              xmlProduct["g:price"]?._text.replace(" BRL", "")
            ),
            redirectLink: xmlProduct["link"]?._cdata.replace(
              "http://",
              "https://"
            ),
            descricao: xmlProduct["g:title"]?._text,
            thumbnail: xmlProduct["g:image_link"]?._text,
            tempoInicio: null,
            descricao: xmlProduct["g:title"]?._text,
            // tamanhos: ["único"],
            thumbnail: xmlProduct["g:image_link"]?._text,
            tempoInicio: false,
            tempoFim: false,
            tipoGrade: "aberta",
            principal: true,
          });
        });
        message.success(t("ROOM-TAB.UploadSuccess"));
        handleUpdateXml(tmpProducts);
        onCancel();
      })
      .catch(() => {
        message.error(t("ROOM-TAB.UploadError"));
      });
  };

  return (
    <>
      <Modal
        visible={open}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: "30px",
            }}
          >
            {t("PRODUCT-TAB.CatalogXml")}

            <FileTextOutlined />
          </div>
        }
        cancelText={t("APP.ComeBack")}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => onCancel()}
        bodyStyle={{ padding: 0 }}
      >
        <div
          style={{
            marginTop: 18,
            marginLeft: 16,
          }}
        >
          {t("PRODUCT-TAB.TextModalXml")}
        </div>
        <section
          style={{
            marginTop: 20,
            marginLeft: 16,
          }}
        >
          <input
            id="xml"
            type="file"
            onChange={uploadFile}
            style={{ display: "none" }}
          />

          <Button onClick={() => document.getElementById(`xml`).click()}>
            {t("ROOM-TAB.Upload")}
          </Button>
        </section>
        <section
          style={{
            marginTop: 20,
            marginLeft: 16,
            marginBottom: 16,
          }}
        >
          <Input
            type="url"
            placeholder="https://www.example.com"
            id="xml-link"
            value={url}
            onChange={({ target: t }) => {
              setUrl(t.value);
            }}
            style={{ width: "calc(100% - 200px)" }}
          />
          <Button type="dashed" onClick={uploadLink}>
            {t("CHAT.Send")}
          </Button>
        </section>
      </Modal>
    </>
  );
};

export default ModalUploadXml;
