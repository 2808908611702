import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import "antd/dist/antd.less";
import {
  GradesProvider,
  LivesProvider,
  OrdersProvider,
  ThemeProvider,
  UserProvider,
  PaymentProvider,
  AddProductProvider,
  LanguageProvider,
  ShowProductProvider,
  ViewLandingPageProvider,
} from "./context";

ReactDOM.render(
  <LanguageProvider>
    <UserProvider>
      <AddProductProvider>
        <ShowProductProvider>
          <GradesProvider>
            <OrdersProvider>
              <PaymentProvider>
                <LivesProvider>
                  <ViewLandingPageProvider>
                    <ThemeProvider>
                      <Router>
                        <App />
                      </Router>
                    </ThemeProvider>
                  </ViewLandingPageProvider>
                </LivesProvider>
              </PaymentProvider>
            </OrdersProvider>
          </GradesProvider>
        </ShowProductProvider>
      </AddProductProvider>
    </UserProvider>
  </LanguageProvider>,
  document.getElementById("root")
);
