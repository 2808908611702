import styled from "styled-components";

export const imgProdStyle = {
  maxWidth: "140px",
  maxHeight: "120px",
  background: "white",
  borderRadius: "2px",
};

export const Prod = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  :first-child {
    border: none;
  }
  article {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 12px;
    :first-child {
      margin: 0 12px 0 0;
    }
    max-width: 200px;
    :last-child {
      max-width: 210px;
      margin: 0 0 0 12px;
    }
  }
`;

export const Dots = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 6px;
  div,
  span {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    margin: 0 3px;
  }
  div {
    background-color: white;
  }
  span {
    background-color: #d9d9d9;
  }
`;

export const Card = styled.div`
  width: 100px;
  height: 80px;
  display: flex;
  border-radius: 12px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;

  img {
    max-width: 100px;
    max-height: 80px;
    margin: 0;
    border-radius: 12px;
  }
  section {
    width: 22px;
    height: 22px;
    color: white;
    background: ${({ corSecundaria: c }) => c};
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      width: 11px;
    }
    border-radius: 7px;
    bottom: 13px;
    right: -20px;
  }
`;
export const ReceiverContainer = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .cash {
    margin: 0px 5px 0px auto;
    color: gray;
    font-size: 13px;
  }
  :last-child {
    border-top: 3px solid #f0f0f0;
    border-bottom: 0;
  }
`;
export const TotalContainer = styled(ReceiverContainer)`
  border-top: 2px solid #f0f0f0;
  label {
    color: ${({ porcent: p }) => (p !== 100 ? "red" : "#383838")};
  }
`;

export const OverlayContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;

  label {
    margin: 0 10px;
  }
`;

export const UploadButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const UploadText = styled.label`
  font-size: 16px;
  color: grey;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  p {
    color: grey;
    font-size: 13px;
  }
`;

export const WrongProductTiming = styled.label`
  color: grey;

  b {
    color: red;
  }
`;

export const DisabledOptionLabel = styled.label`
  font-size: 11px;
  display: flex;

  p {
    color: red;
  }
`;
//---------------------- ESTOQUE ----------------
export const TamanhosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px 5px 0 0;
  max-width: 50px;
`;
export const Tamanho = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgrey;
  width: 50px;
  padding: 6px 10px;
  margin: 0 0px 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const InvalidLabel = styled.label`
  color: red;
  margin-bottom: 5px;
`;

export const WarningContainer = styled.div`
  background: ${({ error }) => (error ? "#ff4d4f" : "#FAC05D")};
  color: white;
  margin-right: 5px;
  padding: 0 5px;
  border-radius: 2px;
`;
