import React, { useState, useEffect, useContext } from "react";
import { Card, Skeleton, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { managerService, userService } from "../../services";
import { UserContext, LivesContext } from "../../context";

const { Meta } = Card;

const Dashboard = ({ history }) => {
  const { t } = useTranslation();
  const { lives } = useContext(LivesContext);
  const [loading, setLoading] = useState(
    setTimeout(() => {
      setLoading(false);
    }, 2000)
  );

  useEffect(() => {
    document.title = `Dashboard | 4Show`;
    return () => (document.title = "4Show");
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-evenly",
      }}
      className="fade-in"
    >
      {loading ? (
        [1, 2, 3, 4].map((n) => <MyCard loading key={n} />)
      ) : lives.length > 0 ? (
        lives.map((l) => (
          <MyCard
            live={l}
            setLoading={setLoading}
            key={l.docId}
            history={history}
          />
        ))
      ) : (
        <legend
          className="fade-in"
          style={{
            margin: " 200px auto",
            maxWidth: "400px",
            textAlign: "center",
            color: "gray",
          }}
        >
          {t("DASHBOARD.NoLives")}{" "}
          <a onClick={() => history.push("live-studio")}>Live Stúdio</a>{" "}
          {t("DASHBOARD.TheyWillShowUp")}
        </legend>
      )}
    </div>
  );
};

const MyCard = ({ live, loading, setLoading, history }) => {
  const { t } = useTranslation();
  const { setUser } = useContext(UserContext);
  const { setLives } = useContext(LivesContext);
  const [hasThumb, setHasThumb] = useState(
    loading ? false : live.thumbnail ? true : false
  );
  const [liveDelete, setLiveDelete] = useState(null);

  const handleDeleteLive = () => {
    setLoading(true);

    managerService.deleteLive(liveDelete?.docId).then(() => {
      userService.getUser().then((data) => {
        setUser(data.user);
        setLives(data.lives);
        setLoading(false);
      });
    });
  };

  let imgStyle = { width: "300px", height: "180px", background: "#e9e9e9" };
  return (
    <>
      <Card
        className="fade-in"
        style={{ width: 300, margin: "10px" }}
        cover={
          loading || !hasThumb ? (
            <Skeleton.Image style={imgStyle} active />
          ) : (
            <img
              style={imgStyle}
              alt="thumbnail"
              src={live.thumbnail}
              onError={() => setHasThumb(false)}
            />
          )
        }
        actions={
          !loading && [
            <EditOutlined
              key="edit"
              onClick={() => history.push(`live-studio?l=${live.docId}`)}
            />,
            <DeleteOutlined
              key="deletar"
              onClick={() => setLiveDelete(live)}
            />,
          ]
        }
      >
        {loading ? (
          <Meta
            title={
              <Skeleton.Button active size={"default"} shape={"default"} />
            }
            description={
              <Skeleton.Input style={{ width: 200 }} active size={"default"} />
            }
          />
        ) : (
          <Meta
            title={live.nome || t("DASHBOARD.NoName")}
            description={live.descricao || t("DASHBOARD.NoDescription")}
          />
        )}
      </Card>
      <Modal
        title={t("DASHBOARD.DeleteLive")}
        visible={!!liveDelete}
        onOk={handleDeleteLive}
        onCancel={() => setLiveDelete(null)}
        style={{ maxWidth: "500px" }}
        width={"80vw"}
        okText={t("DASHBOARD.Delete")}
        cancelText={t("DASHBOARD.Cancel")}
      >
        {t("DASHBOARD.DeactivateLive")} <strong>{liveDelete?.nome}</strong>?{" "}
        {t("DASHBOARD.CantBeUndone")}
      </Modal>
    </>
  );
};

export default withRouter(Dashboard);
