import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Spin } from "antd";
import { useNeomode, UserContext } from "../../context";
import { neomodeService } from "../../services";

const NeomodeLogin = ({ visible, liveId, goNextStep, liveEstrangeira }) => {
  const { t } = useTranslation();
  const { user: user4Show } = useContext(UserContext);
  const { user, setUser, setUserLogged, loadingLogin } = useNeomode();
  const [stepLogin, setStepLogin] = useState("email"); //email | register | password | recover-password
  const [loginStatus, setLoginStatus] = useState("not tested"); // success - error - loading - not tested

  const checkNeomodeUserEmail = (liveId, email) => {
    setLoginStatus("loading");
    neomodeService
      .checkCustomerEmail(liveId, email)
      .then(() => {
        setStepLogin("password");
        setLoginStatus("success");
        setUser({ ...user, email });
      })
      .catch(() => {
        setLoginStatus("error");
      });
  };

  const recoveryNeomodePassword = (liveId) => {
    setLoginStatus("loading");
    neomodeService
      .recoveryPassword(liveId, user.email)
      .then(() => setLoginStatus("success"))
      .catch(() => setLoginStatus("error"));
  };

  const createUserInNeomode = (liveId, body) => {
    body.dateOfBirth = body.dateOfBirth.replaceAll("-", "/");
    setLoginStatus("loading");
    neomodeService
      .createCustomer(liveId, body)
      .then(() => {
        setLoginStatus("success");
        setStepLogin("password");
        setUser(body);
      })
      .catch(() => setLoginStatus("error"));
  };

  const tryLoginWithEmailAndPassword = (liveId, password) => {
    setLoginStatus("loading");
    neomodeService
      .login(liveId, {
        email: user.email,
        codigo: user4Show.codigo,
        password,
      })
      .then(() => {
        setLoginStatus("not tested");
        setUserLogged(true);
        goNextStep();
      })
      .catch(() => {
        setLoginStatus("error");
        setUserLogged(false);
      });
  };

  useEffect(
    () =>
      liveId &&
      user4Show?.email &&
      visible &&
      checkNeomodeUserEmail(liveId, user4Show?.email),
    [liveId, user4Show]
  );

  if (!visible) return <></>;
  if (stepLogin === "email")
    return (
      <div>
        <Form
          onFinish={({ email }) => checkNeomodeUserEmail(liveId, email)}
          initialValues={{ email: user4Show?.email && user4Show.email }}
        >
          <br />
          {t("NEOMODE.FirstNeedLogin")}
          <Form.Item
            name={"email"}
            rules={[
              {
                type: "email",
                message: t("APP.InformValidEmail"),
              },
            ]}
            validateTrigger="onBlur"
            validateStatus={loginStatus === "error" && "error"}
            help={loginStatus === "error" && t("NEOMODE.EmailNoAccount")}
          >
            <Input
              required
              placeholder={t("APP.Email")}
              disabled={loadingLogin}
              onChange={() => setLoginStatus("not tested")}
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
          >
            {loginStatus !== "loading" && (
              <Button
                onClick={() => {
                  setLoginStatus("not tested");
                  setStepLogin("register");
                }}
              >
                {t("NEOMODE.CreateAccount")}
              </Button>
            )}
            <Button
              htmlType="submit"
              loading={loginStatus === "loading"}
              disabled={loginStatus === "loading"}
              style={{ marginLeft: 24 }}
            >
              {["error", "not tested"].includes(loginStatus)
                ? t("NEOMODE.Login")
                : loginStatus === "success"
                ? t("NEOMODE.Login")
                : t("NEOMODE.CheckingEmail")}
            </Button>
          </div>
        </Form>
      </div>
    );
  else if (stepLogin === "register")
    return (
      <div>
        <Form onFinish={(data) => createUserInNeomode(liveId, data)}>
          <br />
          {t("NEOMODE.NeedToCreateYourAccount")}
          <Form.Item
            name="fullName"
            initialValue={user4Show?.name && user4Show.name}
            style={{ margin: 0 }}
          >
            <Input
              required
              style={{ margin: "6px 0" }}
              placeholder={t("NEOMODE.Name")}
            />
          </Form.Item>
          <Form.Item
            name="cpf"
            initialValue={!liveEstrangeira ? user4Show?.codigo : ""}
            style={{ margin: 0 }}
          >
            <Input
              required
              style={{ margin: "6px 0" }}
              placeholder={t("NEOMODE.Code")}
            />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            initialValue={user4Show?.phone && user4Show.phone}
            style={{ margin: 0 }}
          >
            <Input
              required
              style={{ margin: "6px 0" }}
              placeholder={t("NEOMODE.Phone")}
            />
          </Form.Item>
          <Form.Item name="dateOfBirth" style={{ margin: 0 }}>
            <Input
              type="date"
              required
              style={{ margin: "6px 0" }}
              placeholder={t("NEOMODE.Birthday")}
            />
          </Form.Item>
          <Form.Item
            name="email"
            initialValue={user4Show?.email && user4Show.email}
            style={{ margin: 0 }}
          >
            <Input
              required
              style={{ margin: "6px 0" }}
              placeholder={t("NEOMODE.Email")}
            />
          </Form.Item>
          <Form.Item name="password" style={{ margin: 0 }}>
            <Input.Password
              required
              style={{ marginTop: "6px" }}
              placeholder={t("NEOMODE.Password")}
            />
          </Form.Item>
          {loginStatus === "error" && (
            <label style={{ color: "red" }}>
              {t("NEOMODE.ErrorCreateAccount")}
            </label>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
          >
            <Button
              onClick={() => {
                setLoginStatus("not tested");
                setStepLogin("email");
              }}
            >
              Voltar
            </Button>
            <Button
              htmlType="submit"
              loading={loginStatus === "loading"}
              disabled={loginStatus === "loading"}
              style={{ marginLeft: 24 }}
            >
              {t("NEOMODE.CreateAccount")}
            </Button>
          </div>
        </Form>
      </div>
    );
  else if (stepLogin === "password")
    return (
      <div>
        <Form
          onFinish={({ password }) =>
            tryLoginWithEmailAndPassword(liveId, password)
          }
        >
          <br />
          {t("NEOMODE.InformPassword")}
          <Form.Item
            name="password"
            style={{ margin: 0 }}
            validateStatus={loginStatus === "error" && "error"}
            help={loginStatus === "error" && t("NEOMODE.IncorrectPassword")}
          >
            <Input.Password
              required
              style={{ marginTop: "6px" }}
              placeholder={t("NEOMODE.Password")}
              onChange={() => setLoginStatus("not tested")}
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
          >
            {loginStatus !== "loading" && (
              <Button
                onClick={() => {
                  setLoginStatus("not tested");
                  setStepLogin("recover-password");
                  recoveryNeomodePassword(liveId);
                }}
              >
                {t("NEOMODE.ForgotPassword")}
              </Button>
            )}
            <Button
              htmlType="submit"
              loading={loginStatus === "loading"}
              disabled={loginStatus === "loading"}
              style={{ marginLeft: 24 }}
            >
              {!["loading"].includes(loginStatus)
                ? t("NEOMODE.Login")
                : t("NEOMODE.CheckingPassword")}
            </Button>
          </div>
        </Form>
      </div>
    );
  else if (stepLogin === "recover-password")
    return (
      <div
        style={{
          margin: "20px 0 0 0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {loginStatus === "loading" ? (
          <>
            <Spin style={{ margin: "20px auto" }} />
            <label style={{ textAlign: "center" }}>
              {t("NEOMODE.SendingEmailToRecoverPassword")}
            </label>
          </>
        ) : loginStatus === "success" ? (
          <label style={{ textAlign: "center" }}>
            {t("NEOMODE.SendedEmailToRecoverPassword")}
            <b>{" " + user.email}</b>
          </label>
        ) : (
          <label style={{ textAlign: "center" }}>
            {t("NEOMODE.SendEmailToRecoverPasswordError")}
          </label>
        )}
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}
        >
          {loginStatus !== "loading" && (
            <>
              {loginStatus === "error" && (
                <Button onClick={() => recoveryNeomodePassword(liveId)}>
                  {t("NEOMODE.TryAgain")}
                </Button>
              )}
              <Button
                style={{ marginLeft: 20 }}
                onClick={() => {
                  setStepLogin("password");
                  setLoginStatus("not tested");
                }}
              >
                {t("NEOMODE.Back")}
              </Button>
            </>
          )}
        </div>
      </div>
    );
  else return <div>Page not found</div>;
};

export default NeomodeLogin;
