import React, { useContext, useState } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars";
import ModalNewUserSplit from "./modal-new-user-split";
import { PaymentContext } from "../../../context";

const ModalSplits = ({ visible, onCancel }) => {
  const { t } = useTranslation();
  const { services } = useContext(PaymentContext);
  const [splitModal, setSplitModal] = useState(false);
  let split = services.pagarme.length > 0 ? services.pagarme : [];

  return (
    <Modal
      title={`${t("MODAL-SPLIT.MyReceivers")} - Pagar.me`}
      visible={visible}
      onOk={onCancel}
      onCancel={onCancel}
      okText={"Ok"}
      cancelText={t("MODAL-SPLIT.Back")}
      width={448}
      bodyStyle={{ paddingBottom: 0 }}
      cancelButtonProps={{
        style: { display: "none" },
      }}
    >
      <ModalNewUserSplit
        visible={splitModal}
        onCancel={() => setSplitModal(false)}
      />
      <label>
        {t("MODAL-SPLIT.DescriptionOne")}{" "}
        <strong>{t("MODAL-SPLIT.Products")}</strong>,{" "}
        {t("MODAL-SPLIT.DescriptionTwo")}
        <a onClick={() => setSplitModal(true)}> {t("MODAL-SPLIT.Here")}</a>.
      </label>
      <Scrollbars style={{ width: "400px", height: "300px" }}>
        <div>
          {split &&
            split.length > 0 &&
            split.map((s) => (
              <div
                key={s.recipientId}
                style={{
                  padding: "10px 0",
                  borderBottom: "1px solid #f0f0f0",
                  borderRadius: 0,
                  width: "100%",
                }}
              >
                <label>
                  {t("MODAL-SPLIT.Name")}:{" "}
                  <label style={{ color: "gray" }}>{s.legalName}</label>
                </label>
                <br />
                {s.documentNumber && (
                  <>
                    <label>
                      {t("MODAL-SPLIT.Document")}:{" "}
                      <label style={{ color: "gray" }}>
                        {s.documentNumber}
                      </label>
                    </label>
                    <br />
                  </>
                )}
                <label>
                  ID: <label style={{ color: "gray" }}>{s.recipientId}</label>
                </label>
              </div>
            ))}
        </div>
      </Scrollbars>
    </Modal>
  );
};

export default ModalSplits;
